import { QUERY_USERS, DELETE_USER, UsersAction } from './types';
import { IUser } from '../user/types';

export default (state: IUser[] | null = null, action: UsersAction) => {
  switch (action.type) {
    case QUERY_USERS:
      return action.payload;
    case DELETE_USER:
      if (!Array.isArray(state)) {
        return state;
      }

      return [...state.filter(({ _id }) => _id !== action.payload)];
    default:
      return state;
  }
};
