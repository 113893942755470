import React from 'react';
import TableV2 from '../../../TableV2/Table';
import $ from 'jquery';
import Tag from '../../../Tag/Tag';

const Tags = ({
  annotationPermission,
  activeTagId,
  unselectedTagsNotVisible,
  curPageNum,
  fieldIdToTagsMap,
  haveActiveSmartTag,
  updateTable,
  selectTag,
  deleteTag,
  resizeCount,
}) => {
  const pageTags = [];
  Object.entries(fieldIdToTagsMap).forEach(([key, value]) => {
    value.forEach((tag) => {
      if (tag.page === curPageNum) {
        if (unselectedTagsNotVisible && activeTagId !== tag._id) {
          return;
        }
        pageTags.push({ fieldId: key, tag });
      }
    });
  });

  return pageTags.map(({ fieldId, tag }) =>
    tag.isTable ? (
      <TableV2
        key={tag._id + resizeCount}
        isSelected={activeTagId === tag._id}
        containerSize={{
          width: $(`.carousel-item.active img`).width(),
          height: $(`.carousel-item[data-page-number=${tag.page}]`).height(),
        }}
        value={tag}
        onChange={({ table }) => {
          const img = $(`#img-${tag.page}`); // this is very strange
          updateTable(fieldId, tag._id, tag.page, table, img, 'text');
        }}
        onDelete={() => {
          deleteTag(fieldId, tag._id, false, true);
        }}
        onSelect={() => {
          selectTag(fieldId, tag);
        }}
      />
    ) : (
      !tag.section && (
        <Tag
          key={tag._id}
          fieldId={fieldId}
          tag={tag}
          annotationPermission={annotationPermission}
          haveActiveSmartTag={haveActiveSmartTag}
        />
      )
    )
  );
};

export default React.memo(Tags);
