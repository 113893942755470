import React from 'react';
import SmartTag from '../../../SmartTag/SmartTag';

const SmartTags = ({ scaledWidth, scaledHeight, smartTags = {}, curPageNum, img }: any) => {
  const { isSmartTag, activeTag } = smartTags;
  if (!isSmartTag || !activeTag) {
    return;
  }

  const { originFields, referenceFieldsData } = activeTag;
  const smartTagsData = Array.isArray(originFields) ? [...originFields] : [];
  if (!smartTagsData.length || !referenceFieldsData.length) {
    return null;
  }

  const _tags: any[] = [];

  const smartTagRenderType = referenceFieldsData[0].renderType ?? 'cells';

  smartTagsData.forEach(({ tags, _id, srcFieldId }) =>
    tags.forEach((tag: any) => {
        const newTag = {...tag}
      if (newTag.page !== curPageNum) {
        return;
      }
        newTag.fieldId = _id;
        newTag.srcFieldId = srcFieldId;
      if (newTag.isTable) {
          newTag.renderType = smartTagRenderType;
      }

      _tags.push(newTag);
    })
  );

  return _tags.map((tag) => (
    <SmartTag key={tag._id} tag={tag} img={img} resizeHandle={`${scaledHeight}${scaledWidth}`} smartTags={smartTagsData}/>
  ));
};

export default React.memo<any>(SmartTags);
