import React, {useCallback, useMemo, useRef, useState} from 'react';
import { useSelector } from 'react-redux';
import { toPixels } from '../../helpers/common/utils';
import {deleteTag, updateValue, upsertTag} from '../../actions';
import { emptyTagSelector } from '../../store/tag/selectors';
import {documentActiveFieldSelector} from '../../store/document/selectors';
import { useOriginEdit } from './useOriginEdit';
import { useAppDispatch } from '../../hooks/reduxHooks';
import { tagsSelector} from "../../store/annotation/selectors";
import {hashFromCoordinates} from "../../helpers/annotation/utils";
import {SET_TAGS_BY_REGIONS} from "../../store/annotation/types";

const SmartTag = ({ tag, img, resizeHandle,smartTags }) => {
  const dispatch = useAppDispatch();
  const emptyTag = useSelector(emptyTagSelector);
  const tagsByRegion = useSelector(tagsSelector);

  //const activeFieldId = useSelector(activeFieldIdSelector);
  const activeField = useSelector(documentActiveFieldSelector);

  const [isEditMode, setEditMode] = useState(false);
  const emptyArray = useMemo(() => [], []);

  const timeoutRef = useRef(null);

  const { isTable, originElement, controllerElements } = useOriginEdit({
    isEditMode,
    setEditMode,
    tag,
    img,
    resizeHandle,
      smartTags
  });


    const handleTagClick = useCallback(
        (tagInfo) => {
            const regionInPixels = toPixels(tagInfo, [img]);


            if (activeField) {
                // tagInfo.fieldId = activeFieldId;

                const tagHash = hashFromCoordinates(tagInfo);
                const existingTag = tagsByRegion[tagHash];
                if(existingTag){
                    const sameSourceField = activeField._id === existingTag.srcFieldId
                    dispatch({type: SET_TAGS_BY_REGIONS, payload: {[tagHash]: undefined}})
                    dispatch(updateValue(existingTag.fieldId, null, '', false, false, 1));
                    dispatch(deleteTag(existingTag.fieldId,existingTag._id, !sameSourceField, true));
                    if(sameSourceField) return;
                }


                dispatch(upsertTag({...tagInfo}, false, regionInPixels, true,true));
                //dispatch(deSelectField());
            }

        },
        [dispatch, activeField, img,tagsByRegion]
    );

    const handleDoubleClick = useCallback((tagInfo) => {
        if (activeField) return;
        const tagHash = hashFromCoordinates(tagInfo);
        const existingTag = tagsByRegion[tagHash];

        if(!existingTag) return;

        dispatch(deleteTag(existingTag.fieldId, existingTag._id, false, true));
        dispatch(updateValue(existingTag.fieldId, null, '', false, false, 1));
        dispatch({type: SET_TAGS_BY_REGIONS, payload: {[tagHash]: undefined}})
    }, [dispatch, tagsByRegion, activeField]);


    const handleClickTimeout = useCallback((event,tagInfo) => {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => {
            if (event.detail === 2) {
                return handleDoubleClick(tagInfo);
            }
            handleTagClick(tagInfo);
        }, 200);
    },[handleDoubleClick, handleTagClick]);



  const renderTag = useCallback(
    (simpleTag, index) => {
      const { top, left, height, width, _id } = simpleTag;
      const id = _id || `active-tag-${index}`;


      const tagJsx = (
        <div
          key={id}
          id={`smart-tag-${id}`}
          className={`tag smart-tag ${emptyTag && emptyTag.id === id ? 'selected' : ''}
        `}
          style={{ top, left, height, width }}
          onClick={(event) => handleClickTimeout(event,simpleTag)}
          onMouseOver={()=>{
              const element = document.querySelector(`[data-smarttag-id="${id}"]`);
              element && element.classList.add("hover");
          }}
          onMouseLeave={()=>{
              const element = document.querySelector(`[data-smarttag-id="${id}"]`);
              element && element.classList.remove("hover");
          }}
        />
      );
      return tagJsx;
    },
    [emptyTag, handleClickTimeout]
  );

  const simpleTags = useMemo(() => {
    if (isEditMode) {
      return emptyArray;
    }

    let _simpleTags = [];
    if (isTable) {
        switch (tag.renderType) {
            case 'cells': {
                tag.table.tbody.tr.forEach((tr, trIndex) =>
                    tr.td.slice(1).forEach(({ id, height, width, x_left_top, y_left_top, value }, tdIndex) =>
                        _simpleTags.push({
                            _id: id,
                            smartTagId:id,
                            left: `${x_left_top}%`,
                            top: `${y_left_top}%`,
                            height: `${height}%`,
                            width: `${width}%`,
                            smartTagValue: value,
                            rowIndex: trIndex,
                            colIndex: tdIndex,
                        })
                    )
                );
                break;
            }
            case 'columns': {
                const tableStyle = tag?.table?.style;
                if (!tableStyle) return;

                let row = [];
                tag.table.tbody.tr.forEach((tr) => {
                    if (tr.td.length > row.length) {
                        row = tr.td;
                    }
                });

                row.slice(1).forEach(({ id, height, width, x_left_top, y_left_top, value }) =>
                    _simpleTags.push({
                        _id: id,
                        smartTagId:id,
                        left: `${x_left_top}%`,
                        top: `${tableStyle.top}`,
                        height: `${tableStyle.height}`,
                        width: `${width}%`,
                    })
                );
                break;
            }
            case 'rows': {
                const tableStyle = tag?.table?.style;
                if (!tableStyle) return;
                tag.table.tbody.tr.forEach((tr) => {
                    const { id, height, y_left_top } = tr.td[1];
                    _simpleTags.push({
                        _id: id,
                        smartTagId:id,
                        left: `${tableStyle.left}`,
                        top: `${y_left_top}%`,
                        height: `${height}%`,
                        width: `${tableStyle.width}`,
                    });
                });
                break;
            }
            case 'table': {
                if (tag?.table?.style) {
                    const {_id, table: {style: { height, width, left, top }}} = tag;
                    _simpleTags.push({ _id,smartTagId:_id, height, width, left, top });
                }
                break;
            }
            default:
                break;
        }
    } else {
      _simpleTags.push(tag);
    }

    return _simpleTags;
  }, [isEditMode, isTable, emptyArray, tag]);

  return simpleTags.length || isEditMode ? (
    <>
      {simpleTags.map(renderTag)}
      {originElement}
      {controllerElements}
    </>
  ) : null;
};

export default SmartTag;
