import { Action } from '../types';
import { PayloadAction } from '@reduxjs/toolkit/src/createAction';
export const SET_SCALED_VALUE = 'SET_SCALED_VALUE';
export const APPEND_IMAGE_DIMENSIONS = 'APPEND_IMAGE_DIMENSIONS';
export const RESET_ANNOTATION_VALUES = 'RESET_ANNOTATION_VALUES';
export const SET_TAGS_BY_REGIONS = 'SET_TAGS_BY_REGIONS';
export const RESET_TAGS_BY_REGIONS = 'RESET_TAGS_BY_REGIONS';
export const UNDO = 'UNDO';
export const REDO = 'REDO';
export const TOGGLE_SMART_LABEL = 'TOGGLE_SMART_LABEL';

export type ImageDimension = {
  width: number;
  height: number;
};

export type ImageDimensionsMap = {
  [pageNumber: string]: ImageDimension;
};

export interface Annotation {
  scaledValue: number;
  imagesDimensions: ImageDimensionsMap;
  smartLabel: boolean,
   tags?: {
    [key:string]: {
      _id: string;
      fieldId: string
    }
  }
}

export interface SetScaledValue extends Action<Annotation['scaledValue']> {
  type: typeof SET_SCALED_VALUE;
  payload: Annotation['scaledValue'];
}
export interface AppendImagesDimensions extends PayloadAction<{ pageNumber: string; dimensions: ImageDimension }> {
  type: typeof APPEND_IMAGE_DIMENSIONS;
  payload: { pageNumber: string; dimensions: ImageDimension };
}
export interface ResetAnnotationValues extends Action {
  type: typeof RESET_ANNOTATION_VALUES;
}

export interface SetTagsByRegions extends PayloadAction<{[key:string]:{ _id: string; fieldId: string }}> {
  type: typeof SET_TAGS_BY_REGIONS;
  payload: {[key:string]:{ _id: string; fieldId: string }};
}
export interface ResetTagsByRegions extends Action{
  type: typeof RESET_TAGS_BY_REGIONS;
}

export interface SetUndo extends PayloadAction<any> {
  type: typeof UNDO;
  payload: any;
}

export interface SetRedo extends PayloadAction<any> {
  type: typeof REDO;
  payload: any;
}

export interface ToggleSmartLabel extends Action {
  type: typeof TOGGLE_SMART_LABEL;
}



export type AnnotationAction = SetScaledValue | AppendImagesDimensions | ResetAnnotationValues | SetTagsByRegions | ResetTagsByRegions | SetUndo | SetRedo | ToggleSmartLabel;
