import _ from 'lodash';
import {
  ADD_CHECKPOINT,
  RESET_CHECKPOINT,
  REINITIALIZE_CHECKPOINT,
  UPDATE_CHECKPOINT,
  CheckpointAction,
  TCheckpoint,
} from './types';

const INITIAL_STATE: TCheckpoint = { undoNumber: 0, undoPressed: false, redoPressed: false, documentHistory: [] };

const checkpointReducer = (state = INITIAL_STATE, action: CheckpointAction) => {
  const checkpointState = _.cloneDeep(state);
  switch (action.type) {
    case ADD_CHECKPOINT:{
      let index = state.documentHistory.length;
      if (state.undoPressed === true || state.redoPressed === true) {
        const count = state.undoNumber;
        checkpointState.documentHistory.splice(state.documentHistory.length - count, count);
        index = index - count;
        checkpointState.undoPressed = false;
        checkpointState.redoPressed = false;
      }


      if (index === 10) {
        checkpointState.documentHistory.shift();
        index = index - 1;
      }
      let {activeTemplate,tags,activePage} = action.payload;
      const checkpoint = {
        activeTemplate: _.cloneDeep(activeTemplate),
        tags: _.cloneDeep(tags),
        activePage
      }
      checkpointState.documentHistory[index] = checkpoint;
      checkpointState.undoNumber = 0;
      return checkpointState;
    }

    case REINITIALIZE_CHECKPOINT:
      let {activeTemplate,tags,activePage} = action.payload;
      const checkpoint = {
        activeTemplate: _.cloneDeep(activeTemplate),
        tags: _.cloneDeep(tags),
        activePage
      }
      state = { undoNumber: 0, undoPressed: false, documentHistory: [checkpoint] };
      return state;
    case RESET_CHECKPOINT:
      return { ...INITIAL_STATE };
    case UPDATE_CHECKPOINT:
      return { ..._.cloneDeep(state), ...action.payload };
    default:
      return state;
  }
};

export default checkpointReducer;
