import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../hooks/reduxHooks';
import { checkpointSelector } from '../../../../store/checkpoint/selectors';
import Button from '../../../Common/Button';
import { redo, undo } from '../../../../store/checkpoint/actions';

const HistoryButtons = () => {
  const checkpoint = useAppSelector(checkpointSelector);
  const dispatch = useAppDispatch();
  const [state, setState] = useState({
    enableRedo: false,
  });
  const handleUndo = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const enableUndo = checkpoint.documentHistory.length - checkpoint.undoNumber > 1;
    if (enableUndo) {
      setState({ enableRedo: true });
      dispatch(undo());
    }
  };
  const handleRedo = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (checkpoint.undoNumber >= 1 && state.enableRedo) {
      dispatch(redo());
    }
  };

  const enableUndo = checkpoint?.documentHistory?.length - checkpoint?.undoNumber > 1;
  return (
    <div
      className={`btn-group gap-1 ${enableUndo || state.enableRedo ? '' : 'd-none'}`}
      role='group'
      id='change-history'
    >
      <Button variant='tertiary' onClick={handleUndo} icon='HistoryUndo' className={`${enableUndo ? '' : 'disabled'}`}>
        Undo
      </Button>
      <Button
        variant='tertiary'
        onClick={handleRedo}
        icon='HistoryRedo'
        className={`${checkpoint.undoNumber >= 1 && state.enableRedo ? '' : 'disabled'}`}
      >
        Redo
      </Button>
    </div>
  );
};

export default HistoryButtons;
