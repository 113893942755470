import React from 'react';
import { connect } from 'react-redux';
import { getBaseURL } from '../../appConfig';
import { rotatePages } from '../../actions';
import { asyncForEach, openInNewTab } from '../../helpers/common/utils';
import { openConfirmSaveRotationModal } from './service/ModalControllers';
import Button from '../Common/Button';

class RotatePagesModal extends React.Component {
  state = {
    rows: [],
    activeSave: false,
  };

  componentDidMount() {
    let imageLoaded = {};
    this.setInitialState();
    asyncForEach(this.props.document.pages, (page) => {
      imageLoaded[page.page] = false;
    });
    this.setState({ imageLoaded });
  }

  splitArrayIntoChunks = (array, chunkLength) => {
    let chunks = [],
      i = 0,
      n = array.length;
    while (i < n) {
      chunks.push(array.slice(i, (i += chunkLength)));
    }
    return chunks;
  };

  rotateImage = (e, page, direction) => {
    e.preventDefault();
    const rotatedAngle =
      direction === 'left' ? this.state.imageRotation[page] - 90 : this.state.imageRotation[page] + 90;
    let updateAngles = this.state.imageRotation;
    updateAngles[page] = rotatedAngle === -360 || rotatedAngle === 360 ? 0 : rotatedAngle;
    this.setState({ imageRotation: updateAngles });
    this.checkSaveActivation();
  };

  save = async () => {
    this.setState({ processingSave: true });
    const { _id, src, description } = this.props.document;
    const ocr = this.props.document.metadata && this.props.document.metadata.ocr;
    const collectionId = this.props.document.collectionId && this.props.document.collectionId._id;
    const imgRotations = this.state.imageRotation;
    const docName = this.props.document.name;
    this.props.rotatePages(_id, src, description, collectionId, ocr, imgRotations, docName, () => {
      const url = this.props.document.collectionId
        ? `/collection/${this.props.document.collectionId._id}`
        : '/collection';
      openInNewTab(url, '_self');
      this.closeModal();
    });
  };

  closeModal = () => {
    this.props.onClose();
  };

  setInitialState = () => {
    let imageRotation = {};
    const rows = this.splitArrayIntoChunks(this.props.document.pages, 6);
    this.props.document.pages.map((element) => {
      imageRotation[element.page] = 0;
    });
    this.setState({ rows, imageRotation });
  };

  checkSaveActivation = () => {
    let activeSave = false;
    if (this.state.imageRotation) {
      for (const angle of Object.values(this.state.imageRotation)) {
        if (angle !== 0) {
          activeSave = true;
          break;
        }
      }
      this.setState({ activeSave });
    }
  };

  renderImages = () => {
    return (
      <div className='form-group row ms-2'>
        {this.state.rows.map((item, index) => {
          return (
            <div className='row mb-4' key={index}>
              {this.renderImagesRow(index)}
            </div>
          );
        })}
      </div>
    );
  };

  renderImagesRow = (index) => {
    return this.state.rows[index].map((page, i) => {
      const currentPage = page.page;
      const pageNumber = currentPage;
      const fileName = `page_${pageNumber}.jpeg`;
      return (
        <div
          className='rotate-col mb-2'
          style={{
            width: 250,
            height: 150,
          }}
          key={fileName}
        >
          {currentPage}
          <img
            alt=''
            className={this.state.imageLoaded[currentPage] ? 'd-block rotate-img' : 'rotate-images-spinner'}
            style={
              this.state.imageLoaded[currentPage]
                ? {
                    transform: `rotate(${this.state.imageRotation[currentPage]}deg)`,
                    width: '60%',
                  }
                : {}
            }
            src={`${getBaseURL()}/files/thumbnails/${this.props.document.src}/${pageNumber}`}
            onLoad={() => {
              this.setState((state) => {
                return { imageLoaded: { ...state.imageLoaded, [currentPage]: true } };
              });
            }}
          />
          <div className='col mt-2' style={{ minWidth: '76px' }}>
            <div className='row ms-auto me-auto rotate-icons-col'>
              <div className='col-6 me-auto p-0'>
                <Button
                  size={'small'}
                  variant='primary'
                  icon='RotatePageLeft'
                  onClick={(e) => {
                    this.rotateImage(e, currentPage, 'left');
                  }}
                />
              </div>
              <div className='col-6 ms-auto p-0 d-flex justify-content-end'>
                <Button
                  size={'small'}
                  variant='primary'
                  icon='RotatePageRight'
                  onClick={(e) => {
                    this.rotateImage(e, currentPage, 'right');
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      );
    });
  };

  render() {
    return (
      <>
        <div className='modal-backdrop show' onClick={this.props.onClose} />
        <div
          className={`modal fade show d-block`}
          id='rotate-pages'
          tabIndex='-1'
          role='dialog'
          aria-hidden='true'
          style={{ paddingRight: '10px', paddingLeft: '10px' }}
        >
          <div className='modal-dialog modal-dialog-centered rotate-modal-lg' role='document'>
            <div className='modal-content'>
              <form>
                <div className='modal-header'>
                  <h4 className='modal-title'>Rotate Pages</h4>

                  <Button
                    size={'small'}
                    variant='tertiary'
                    icon='ModalClose'
                    className='close-btn'
                    onClick={(e) => {
                      e.preventDefault();
                      this.closeModal();
                    }}
                  />
                </div>
                <div className='modal-body'>
                  <div className='tab-content mt-3'>{this.renderImages()}</div>
                </div>
                <div className='modal-footer'>
                  {!this.state.processingSave ? (
                    <div className='btn-group' role='group'>
                      <Button
                        size={'small'}
                        variant='primary'
                        icon='ModalCancel'
                        className='rounded-start'
                        onClick={() => {
                          this.closeModal();
                        }}
                      >Cancel</Button>
                      <Button
                        size={'small'}
                        variant='secondary'
                        icon='ModalSave'
                        disabled={!this.state.activeSave}
                        className='rounded-end'
                        onClick={() => {
                          openConfirmSaveRotationModal({
                            onResponse: (resp) => {
                              if (resp) {
                                this.save();
                              }
                            },
                          });
                        }}
                      >Save</Button>
                    </div>
                  ) : (
                    <div className='loader loader-primary' />
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default connect(null, { rotatePages })(RotatePagesModal);
