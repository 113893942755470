import React from 'react';
import $ from 'jquery';
import Button from '../Common/Button';

export default class EditTableDialog extends React.PureComponent {
  state = {
    text: this.props.text,
    open: this.props.open,
  };

  render() {
    return (
      <div
        className='modal show text-gray-dark edit-table'
        id='alertModal'
        role='dialog'
        aria-labelledby='alertDialog'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-dialog-centered' role='document'>
          <div className='modal-content'>
            <div className='modal-body'>
              <div className='form-group'>
                <textarea className='form-control' ref='text' rows='2' defaultValue={this.props.text || ''} />
              </div>
            </div>
            <div className='modal-footer'>
              <Button variant='secondary' icon='ModalCancel' onClick={() => this.props.onCancel()} />
              <Button
                variant='primary'
                icon='ModalSave'
                onClick={() => this.props.onEdit($(this.refs.text).val().toString())}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    if (this.props.open) {
      $('#alertModal').modal('show');
    } else {
      $('#alertModal').modal('hide');
    }
  }

  componentDidUpdate() {
    if (this.props.open) {
      $('#alertModal').modal('show');
    } else {
      $('#alertModal').modal('hide');
    }
  }
}
