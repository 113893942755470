import React, { useState } from 'react';
import Slide from '../../Slide';
import 'nprogress/nprogress.css';

import '../Browse/Browse.scss';
import '../Browse/Split.scss';

import Icons from '../../Common/Icons/Icons';
import { openMicrotaskValidationFailureModal } from '../../modal/service/ModalControllers';
import { getBaseURL, getChatBotUrl } from '../../../appConfig';
// import FreezeLoadingModal from '../../modal/FreezeLoadingModal';
import { microtaskFormulasSelector } from '../../../store/document/selectors';
import { useSelector } from 'react-redux';
import { runMicrotaskValidations } from '../../../helpers/common/utils';
import ChatContainer from '../../Chat/ChatContainer';

import SplitPane from '../../SplitPane';
import Toolbar from '../Toolbar';
import TopToolBar from '../Toolbar/TopToolBar';
import ClassifierFields from '../ClassifierFields';
import HistoryButtons from './components/HistoryButtons';
import ResetButton from './components/ResetButton';
import Button from '../../Common/Button';
import ClassifierPages from '../ClassifierPages';
import FreezeLoadingModal from '../../modal/FreezeLoadingModal';
const baseUrl = getBaseURL();
export default React.memo(function BrowserLayout({
  fields,
  isSmartTag,
  activeTemplate,
  activeSmartTag,
  taggingEditorState,
  saveSuccessful, //={document.saveSuccessful}
  saveBtnActive, //={document.saveBtnActive}

  rules, //={this.props.rules}

  handleReset, // ={this.handleReset}
  handleFinish, // ={this.handleFinish}
  handleSave, // ={this.handleSave}
  state_isFinishing, // ={this.state.isFinishing}
  state_finishButtonClicked, // ={this.state.finishButtonClicked}
  selectSearchText, // ={this.selectSearchText}

  state_showFields, // ={this.state.showFields}
  state_saveLoader, // ={this.state.saveLoader}
  state_isSearchSelect, // ={this.state.isSearchSelect}

  documentSrc, //document.src
  documentType,
}) {
  const [isLoadingFields] = useState(false);

  const imageUrlBuilder = (activePage) => {
    const taggingEditor = taggingEditorState === 'pixelPerfect' ? 'labeledimages' : 'images';
    return `${baseUrl}/files/${taggingEditor}/${documentSrc}/image_${activePage}.jpeg`;
  };

  const microtaskFormulas = useSelector(microtaskFormulasSelector);

  const onFinishClick = (e) => {
    if (!activeTemplate.isFinished) {
      const failedFormulas = runMicrotaskValidations({
        documentsTemplate: activeTemplate,
        validationFormulas: microtaskFormulas,
        fields,
      });
      if (failedFormulas.length) {
        openMicrotaskValidationFailureModal({ formulas: failedFormulas });
      } else {
        handleFinish(e);
      }
    } else {
      handleFinish(e);
    }
  };

  return (
    <>
      <div>
        <SplitPane className='split2'>
          <div className='pane3' style={{ height: '100%' }}>
            <div className='fields-block' style={{ height: '100%' }}>
              <div className='card mb-0 fields-viewport '>
                <div className='card-header toolbar-viewport'>
                  <HistoryButtons />
                  <ResetButton handleReset={handleReset} />
                </div>
                <div className='card-body overflow-auto' id='fields-scroll-block'>
                  {isLoadingFields ? <div>Loading fields...</div> : null}
                  <ClassifierFields />
                  {/*TODO Tag Label Switcher button
                  <TagLabelSwitcher/>
                   */}
                  <ClassifierPages />
                </div>
              </div>
              <div className='finish-block btn-group gap-1' id='insight-actions'>
                {rules.finish && activeTemplate && (
                  <Button
                    variant='finish'
                    onClick={onFinishClick}
                    icon={!activeTemplate.isFinished ? 'DocumentFinish' : 'DocumentReopen'}
                    className={`rounded-start ${state_finishButtonClicked ? 'disabled' : ''}`}
                    style={{ flex: 1 }}
                  >
                    {!activeTemplate.isFinished ? 'Finish' : 'Reopen'}
                  </Button>
                )}
                {/*TODO: Simplify this button body*/}
                <Button
                  variant='secondary'
                  onClick={handleSave}
                  icon='Save'
                  className={`rounded-end btn-save ${saveBtnActive && 'flashingButton'}`}
                  disabled={state_saveLoader}
                  style={{ flex: 1 }}
                >
                  Save
                  {state_saveLoader ? (
                    <span className='spinner-border spinner-border-sm ms-2' role='status' aria-hidden='true'></span>
                  ) : (
                    <div className='ms-2'>
                      {saveSuccessful ? (
                        saveSuccessful === 'success' ? (
                          <Icons.DocumentSaveSuccessStatusIcon className='svg-icon-md success-color' />
                        ) : (
                          <Icons.DocumentSaveFailStatusIcon className='svg-icon-md danger-color' />
                        )
                      ) : (
                        ''
                      )}
                    </div>
                  )}
                </Button>
              </div>
            </div>
          </div>
          <div className='pane4'>
            <div className='col'>
              <div className='card viewer-wrapper mb-0'>
                <div>
                  <div
                    className='card-header'
                    style={{
                      maxHeight: '60px',
                      minHeight: '60px',
                    }}
                  >
                    <TopToolBar selectSearchText={selectSearchText} showMetadata={rules.upsert_metadata} />
                  </div>
                  <div className='card-header' style={{ maxHeight: '60px', minHeight: '60px' }}>
                    <Toolbar isSmartTag={isSmartTag} imageUrlBuilder={imageUrlBuilder} />
                  </div>
                  <div className='card-body slider-viewport' style={{ overflow: 'auto' }}>
                    <Slide
                      annotationPermission={rules.annotate}
                      isSearchSelect={state_isSearchSelect}
                      filteredByCurrentPage={state_showFields === 'showCurrentPageFields'}
                      imageUrlBuilder={imageUrlBuilder}
                      haveActiveSmartTag={!!activeSmartTag}
                    />
                    {documentType === 'PDF' && getChatBotUrl() && <ChatContainer />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SplitPane>
      </div>
      {/*TODO:Uncomment after adding deps*/}
      <FreezeLoadingModal isOpen={state_isFinishing} />
    </>
  );
});
