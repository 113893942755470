import React, { useMemo } from 'react';

import CustomModal from '../Common/CustomModal';
import Button from '../Common/Button';

const modes = ['danger', 'success', 'white', 'primary'];

const ConfirmationModal = (props) => {
  const handleNo = props.handleNo || (() => {});

  const mode = useMemo(() => (!modes.includes(props.mode) ? modes[0] : props.mode), [props.mode]);


  const handleSubmit = (e) => {
    e.preventDefault();
    props.handleYes(e);
  };

  return (
    <CustomModal
      open={true}
      modalHandler={() => handleNo('close')}
      onClick={(e) => {
        if (!e.target.closest('.modal-content')) {
          handleNo('close');
        }
      }}
      classNames={props.classNames?.modal ? props.classNames.modal : 'custom-modal-xsm'}
    >
      <div className='modal-content'>
        <div className='modal-header'>
          <h4 className={`modal-title ${props?.classNames?.title || ''}`}>{props.label}</h4>
          <Button variant='tertiary' onClick={props.handleNo} icon='ModalClose' className='close-btn' />
        </div>

        <form onSubmit={handleSubmit} id='form'>
          <div className='modal-body'>{props.message}</div>
          <div className='modal-footer'>
            <div className='btn-group m-0' role='group'>
              {props.disputeBtnLabel && (
                <Button type='submit' variant='tertiary' onClick={props.handleNo} className='btn'>
                  {props.disputeBtnLabel}
                </Button>
              )}
              <Button type='submit' variant={mode || 'success'} onClick={props.handleYes} className='btn'>
                {props.confirmBtnLabel || 'Yes'}
              </Button>
            </div>
          </div>
        </form>
      </div>
    </CustomModal>
  );
};

export default ConfirmationModal;
