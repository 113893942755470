import {
  Annotation,
  AnnotationAction,
  APPEND_IMAGE_DIMENSIONS,
  REDO,
  RESET_ANNOTATION_VALUES,
  RESET_TAGS_BY_REGIONS,
  SET_SCALED_VALUE,
  SET_TAGS_BY_REGIONS,
  TOGGLE_SMART_LABEL,
  UNDO
} from './types';
import {cloneDeep} from "lodash";

const initialState: Annotation = {
  scaledValue: 1,
  imagesDimensions: {},
  smartLabel: false
};

const annotationReducer = (state: Annotation = initialState, action: AnnotationAction): Annotation => {
  const annotation = { ...state };

  switch (action.type) {
    case SET_SCALED_VALUE:
      if (annotation.scaledValue === action.payload) {
        return state;
      }
      if (5 < action.payload || action.payload < 0.1) {
        return state;
      }
      annotation.scaledValue = action.payload;
      return annotation;
    case APPEND_IMAGE_DIMENSIONS:
      const { pageNumber, dimensions } = action.payload;
      if (annotation.imagesDimensions[pageNumber]) {
        return state;
      }
      annotation.imagesDimensions[pageNumber] = dimensions;
      return annotation;
    case RESET_ANNOTATION_VALUES:
      annotation.imagesDimensions = {};
      annotation.scaledValue = 1;
      return annotation;
    case SET_TAGS_BY_REGIONS:
      annotation.tags = {...annotation.tags,...action.payload};
      return annotation;
    case RESET_TAGS_BY_REGIONS:
      annotation.tags = {};
      return annotation;
    case UNDO:
    case REDO:
      const { tags} = action.payload;
      annotation.tags = cloneDeep(tags) ;
      return annotation;
    case TOGGLE_SMART_LABEL:
      annotation.smartLabel = !annotation.smartLabel;
      return annotation;
    default:
      return state;
  }
};

export default annotationReducer;
