import './TextSpan.scss';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { changePage, updateTable, deSelectField, selectTag, upsertTagTextSpan } from '../../../actions';
import { setEmptyTag } from '../../../store/tag/actions';
import Spans from './Spans';

const TextSpans = ({ document, fieldId, scaledWidth, scaledHeight, upsertTagTextSpan }) => {
  const textSpanRef = useRef();
  const [selectedItems, setSelectedItems] = useState({});
  const [start, setStart] = useState();
  const [data, setData] = useState();

  useEffect(() => {
    const { pageLabels, activePage } = document;
    pageLabels[activePage]
      .sort((s1, s2) => {
        if (s1.y > s2.y) {
          return 1;
        }
        if (s1.y < s2.y) {
          return -1;
        }

        return 0;
      })
      .sort((s1, s2) => {
        if (Math.abs(s1.y - s2.y) < 1 && s1.x > s2.x) {
          return 1;
        }
        if (Math.abs(s1.y - s2.y) < 1 && s1.x < s2.x) {
          return -1;
        }

        return 0;
      });
    setData(pageLabels[activePage]);
  }, [document.activePage]);

  const calculateSelectionWidth = useCallback(
    (e, id) => {
      return ((e.clientX - e.target.getBoundingClientRect().left) / window.innerWidth) * 100 * (100 / data[id].w);
    },
    [data]
  );

  const updateBackground = useCallback(
    (e, id) => {
      const selection = {
        ...selectedItems,
      };

      if (start) {
        const endIndex = data.indexOf(data[id]);
        if (endIndex < data.indexOf(data[start.index])) {
          resetSelection();
          return;
        }

        selection[id] = {
          ...selection[id],
          index: id,
          top: data[id].y,
          left: data[id].x,
          width: data[id].w,
          height: data[id].h,
          background: '#2525f275',
        };

        for (let i = start.index; i < selection[id].index; i++) {
          selection[i] = {
            ...selection[i],
            index: i,
            top: data[i].y,
            left: data[i].x,
            width: data[i].w,
            height: data[i].h,
            background: '#2525f275',
          };
        }

        // remove unselected items
        Object.keys(selection).forEach((key) => {
          if (key > data.indexOf(data[id])) {
            delete selection[key];
          }
        });

        setSelectedItems(selection);
      }
    },
    [calculateSelectionWidth, data, selectedItems, start]
  );

  const startSelection = useCallback(
    (e, id) => {
      resetSelection();
      setStart({ ...data[id], x: calculateSelectionWidth(e, id), index: id });
    },
    [data, calculateSelectionWidth]
  );

  const endSelection = useCallback(() => {
    if (Object.values(selectedItems).length) {
      let prevElem = Object.values(selectedItems)[0];
      const rows = [{ start: Object.values(selectedItems)[0] }];
      Object.values(selectedItems).map((item, index) => {
        if (item.top - prevElem.top > 1) {
          rows[rows.length - 1].end = Object.values(selectedItems)[index - 1];
          rows.push({ start: item, end: null });
          prevElem = item;
        } else {
          if (Math.abs(item.top - prevElem.top) > 0.5) {
            rows[rows.length - 1].end = Object.values(selectedItems)[index - 1];
            rows.push({ start: item, end: null });
            prevElem = item;
          }
        }
        if (item.top < rows[rows.length - 1].start.top) {
          rows[rows.length - 1].start.height =
            rows[rows.length - 1].start.top - item.top + rows[rows.length - 1].start.height;
          rows[rows.length - 1].start.top = item.top;
        }
        if (item.top + item.height > rows[rows.length - 1].start.top + rows[rows.length - 1].start.height) {
          rows[rows.length - 1].start.height = item.top - rows[rows.length - 1].start.top + item.height;
        }
      });
      rows[rows.length - 1].end = Object.values(selectedItems).pop();
      rows.map((row, index) => {
        const tagWidth = row.end.width + row.end.left - row.start.left;
        const tag = {
          fieldId: fieldId,
          height: `${row.start.height}%`,
          left: `${row.start.left}%`,
          page: document.activePage,
          tagTitle: null,
          top: `${row.start.top}%`,
          width: `${tagWidth}%`,
          isTextSpan: true,
        };
        const regionInPixels = {
          h: row.start.height,
          heightScale: scaledWidth || textSpanRef.current.getBoundingClientRect().width,
          page: document.activePage,
          w: tagWidth,
          widthScale: scaledHeight || textSpanRef.current.getBoundingClientRect().height,
          x: row.start.left,
          y: row.start.top,
        };
        return upsertTagTextSpan(tag, regionInPixels, fieldId);
      });
      setStart(null);
      resetSelection();
    }
  }, [selectedItems]);

  const resetSelection = () => {
    setSelectedItems({});
  };

  const renderSpans = useMemo(() => {
    return data?.map(({ x, y, w, h }, index) => {
      return (
        <Spans
          id={index}
          key={`${y}_${index}`}
          left={x}
          top={y}
          width={w}
          height={h}
          selectedItems={selectedItems}
          startSelection={startSelection}
          endSelection={endSelection}
          updateBackground={updateBackground}
        />
      );
    });
  }, [document.activePage, start, calculateSelectionWidth, selectedItems, data]);

  return (
    <div ref={textSpanRef} className='text-spans-wrapper' onMouseUp={endSelection}>
      <div style={{ position: 'relative', height: '100%' }}>{renderSpans}</div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    document: state.document,
  };
};

export default connect(mapStateToProps, {
  changePage,
  updateTable,
  setEmptyTag,
  deSelectField,
  selectTag,
  upsertTagTextSpan,
})(TextSpans);
