export const getMatchedSmartHoverTag = (smartHoverData = {}, tag = {}) => {
  let tags = [];
  smartHoverData.fields?.forEach((item) => {
    tags = [...tags, ...item.tags];
  });
  return tags.find((item) => {
    return item.left === tag.left && item.top === tag.top && item.width === tag.width && item.height === tag.height;
  });
};

export const getMatchedSmartHoverTagIndex = (smartHoverData = {}, tag = {}) => {
  let tags = [];
  smartHoverData.fields?.forEach((item) => {
    tags = [...tags, ...item.tags];
  });
  return tags.findIndex((item) => {
    return item.left === tag.left && item.top === tag.top && item.width === tag.width && item.height === tag.height;
  });
};

export const getMatchedTableSmartHoverTag = (smartHoverData = {}, tag = {}) => {
  let tags = [];
  smartHoverData.fields?.forEach((item) => {
    tags = [...tags, ...item.tags];
  });

  return tags.find((item) => {
    return (
      item.left == tag.x_left_top + '%' &&
      item.top == tag.y_left_top + '%' &&
      item.width == tag.width + '%' &&
      item.height == tag.height + '%'
    );
  });
};
