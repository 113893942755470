import React from 'react';
import { connect } from 'react-redux';
import {
  deleteDocumentFields,
  selectField,
  updateValue,
  selectTag,
  deleteTag,
  changePage,
  updateFieldTableType,
  upsertTag,
  deSelectField,
  refreshFormulaFieldComponents,
  setFieldScale,
  resetFieldScale,
} from '../../../actions';
import { deleteEmptyTag } from '../../../store/tag/actions';
import $ from 'jquery';
import 'jquery-ui/ui/widgets/draggable';
import './Field.scss';
import FieldInput from './FieldInput';
import ClassificationFieldInput from '../ClassificationField/ClassificationFieldInput';
import fieldIcon from './FieldIcon';
import FieldTagInput from './FieldTagInput';
import FieldTagComputedInput from './FieldTagComputedInput';
import TableFieldBody from '../../Table/TableFieldBody';
import { removeDrawnRectangle } from '../../../helpers/common/utils';

import { openConfirmFieldDeletionModal } from '../../modal/service/ModalControllers';
// import { openLinkDocumentModal } from '../../modal/service/ModalControllers';

class Field extends React.Component {
  state = {
    isInputClicked: false,
    linkEdited: false,
    showOcrValue: true,
    showFxValue: true,
    collections: [],
    computed: this.props.field.fieldType === 'computation',
    showScaleValue: this.props.field.dataType === 'number',
  };

  componentWillUnmount() {
    if (!this.props.field.tags.length) this.handleShowDeleteModal(this.props.field);
    //Deselect field if needed
    // this.props.deSelectField();
  }

  handleFieldValueChange(e, fieldId, tagId, isOcr) {
    this.props.updateValue(fieldId, tagId, e?.target ? e.target.value : e, false, false, 1, isOcr);
  }
  handleManualChange(e, fieldId, tagId) {
    this.props.updateValue(fieldId, tagId, e.target.value, true, false, 1);
  }

  handleTagClick = (e, tag) => {
    this.props.selectTag(this.props.field._id, tag, (pageNumber) => this.props.changePage(pageNumber));
  };

  removeTag = (e, tag) => {
    this.props.deleteTag(this.props.field._id, tag._id, false, false).then(() => {
      removeDrawnRectangle(this.props.document.activePage);
    });
  };

  emptyFieldValue = (e) => {
    this.props.updateValue(this.props.field._id, null, '', false, false, 1);
  };

  handleShowDeleteModal = (field) => {
    openConfirmFieldDeletionModal({
      fieldName: field.name,
      onResponse: (resp) => {
        if (resp) {
          this.props.deleteDocumentFields([field._id]);
        }
      },
    });
  };

  emptyManualFieldValue = (e, tag, resetFx) => {
    this.props.updateValue(this.props.field._id, tag._id, tag.ocrValue, true, true);
  };

  fieldIsSmartTag = () => !!this.props.field.smartTag && !!Object.keys(this.props.field.smartTag).length;

  toggleShowFxValue = (e) => {
    e.preventDefault();
    this.setState({ showFxValue: !this.state.showFxValue }, () => {
      this.props.resizeVirtualFieldList(Math.random());
    });
  };

  toggleShowOcrValue = (e) => {
    e.preventDefault();
    this.setState({ showOcrValue: !this.state.showOcrValue }, () => {
      this.props.resizeVirtualFieldList(Math.random());
    });
  };

  toggleShowScalingValue = (e) => {
    e.preventDefault();
    this.setState({ showScaleValue: !this.state.showScaleValue }, () => {
      this.props.resizeVirtualFieldList(Math.random());
    });
  };

  render() {
    const { _id, dataType, fieldType, name, group, value, tags, required } = this.props.field;

    const { document } = this.props;
    const { activeTemplate } = document;
    let fields = document.fields;

    if (activeTemplate) {
      fields = activeTemplate.fields;
    }
    const tableTypes = document.tableTypes || {};
    let textModeId = '';
    Object.keys(tableTypes).forEach((key) => {
      if (tableTypes[key] === 'text') {
        textModeId = key;
      }
    });
    let activePage = activeTemplate?.activePage || 1;
    let showTextModeIcon = dataType === 'table';
    const img = $(`#img-${activePage}`);

    const isSmartTag = this.fieldIsSmartTag();
    const isInGroup = !!group;

    return (
      <div
        key={_id}
        id={`field-${_id}`}
        className={`
          field-wrapper
          ${isInGroup ? 'field-group-member' : ''}
        `}
      >
        <div
          className={`card field-card field-card-item
            ${isSmartTag}
            ${isInGroup ? 'mt-3 mb-0' : 'mb-2'}
            field-bg
          `}
          // onClick={this.handleClick}
        >
          <div className='card-header field-card-header'>
            <div
              className={`ps-2 text-start clearfix col field-header-overflow ${
                fieldType === 'classification' && dataType !== 'section' ? 'disabled' : ''
              } d-flex align-items-center`}
              ref={`${
                this.props.annotationPermission &&
                (this.props.field.fieldType !== 'computation' || dataType === 'section')
                  ? 'draggable'
                  : ''
              }`}
            >
              {fieldIcon(this.props.field.fieldType, dataType)}
              <h4 className='card-header-title float-start field-header-text ms-2' title={name}>
                {name}
              </h4>
            </div>
          </div>
          <div className={`card-body field-card-body   ` + (showTextModeIcon && _id === textModeId && 'text-m')}>
            {showTextModeIcon && _id === textModeId ? (
              <div />
            ) : fieldType === 'classification' ? (
              <ClassificationFieldInput
                field={this.props.field}
                fieldValue={value}
                allowCustomClass={true}
                emptyValue={(e) => this.emptyFieldValue(e)}
                required={required}
                tag={this.props.tag}
                upsertTag={this.props.upsertTag}
              />
            ) : tags.length > 0 && fieldType !== 'computation' ? (
              (tags.map((tag, index) => {
                return fieldType === 'extraction and computation' ? (
                  <FieldTagComputedInput
                    key={tag._id}
                    tag={tag}
                    field={this.props.field}
                    fields={fields}
                    fieldValue={value}
                    handleTagClick={(e) => this.handleTagClick(e, tag)}
                    multipleClass={index >= 1 ? 'mt-2' : ''}
                    handleChange={(e, isOcr) => this.handleFieldValueChange(e, _id, tag._id, isOcr)}
                    disabled={dataType === 'area' || !this.props.annotationPermission}
                    handleManualChange={(e) => this.handleManualChange(e, _id, tag._id)}
                    emptyTag={(e) => this.removeTag(e, tag)}
                    emptyManualValue={(e) => this.emptyManualFieldValue(e, tag)}
                    required={required}
                    annotationPermission={this.props.annotationPermission}
                    setFieldScale={this.props.setFieldScale}
                    updateValue={this.props.updateValue}
                    showScaleSelector={
                      tag &&
                      this.state.showScaleValue &&
                      this.props.field.dataType === 'number' &&
                      (this.props.field.fieldType === 'extraction' || fieldType === 'extraction and computation')
                    }
                    documentId={this.props.document._id}
                    showScaleValue={this.state.showScaleValue}
                    showFxValue={this.state.showFxValue}
                    showOcrValue={this.state.showOcrValue}
                    showScaleSystemLine={this.state.showFxValue && this.state.showOcrValue && this.state.showScaleValue}
                    removeSystemLine={
                      (!this.state.showFxValue && !this.state.showOcrValue && this.state.showScaleValue) ||
                      (!this.state.showFxValue && this.state.showOcrValue && !this.state.showScaleValue) ||
                      (this.state.showFxValue && !this.state.showOcrValue && !this.state.showScaleValue) ||
                      (!this.state.showFxValue && !this.state.showOcrValue && !this.state.showScaleValue)
                    }
                    shortSystemLine={
                      (!this.state.showFxValue && this.state.showOcrValue && this.state.showScaleValue) ||
                      (this.state.showFxValue && this.state.showOcrValue && !this.state.showScaleValue) ||
                      (this.state.showFxValue && !this.state.showOcrValue && this.state.showScaleValue)
                    }
                    resetFieldScale={this.props.resetFieldScale}
                  />
                ) : (
                  <FieldTagInput
                    key={`${tag._id}_${index}}`}
                    tag={tag}
                    field={this.props.field}
                    fields={fields}
                    handleTagClick={(e) => this.handleTagClick(e, tag)}
                    multipleClass={index >= 1 ? 'mt-2' : ''}
                    handleChange={(e, isOcr) => this.handleFieldValueChange(e, _id, tag._id, isOcr)}
                    handleManualChange={(e) => this.handleManualChange(e, _id, tag._id)}
                    disabled={dataType === 'area' || !this.props.annotationPermission || dataType === 'table'}
                    emptyTag={(e) => {
                      this.props.field.value && this.props.updateValue(this.props.field._id, null, '', false, false, 1);
                      this.removeTag(e, tag);
                    }}
                    emptyManualValue={(e) => {
                      this.emptyManualFieldValue(e, tag);
                    }}
                    required={required}
                    annotationPermission={this.props.annotationPermission}
                    linkEdited={this.state.linkEdited}
                    setFieldScale={this.props.setFieldScale}
                    updateValue={this.props.updateValue}
                    showScaleSelector={
                      tag &&
                      this.props.field.dataType === 'number' &&
                      (this.props.field.fieldType === 'extraction' || fieldType === 'extraction and computation')
                    }
                    documentId={this.props.document._id}
                    showScaleValue={this.state.showScaleValue}
                    showFxValue={this.state.showFxValue}
                    showOcrValue={this.state.showOcrValue}
                    showFx={this.props.showFx}
                    showScaleSystemLine={this.state.showFxValue && this.state.showOcrValue && this.state.showScaleValue}
                    removeSystemLine={
                      (!this.state.showFxValue && !this.state.showOcrValue && this.state.showScaleValue) ||
                      (!this.state.showFxValue && this.state.showOcrValue && !this.state.showScaleValue) ||
                      (this.state.showFxValue && !this.state.showOcrValue && !this.state.showScaleValue) ||
                      (!this.state.showFxValue && !this.state.showOcrValue && !this.state.showScaleValue)
                    }
                    shortSystemLine={
                      (!this.state.showFxValue && this.state.showOcrValue && this.state.showScaleValue) ||
                      (this.state.showFxValue && this.state.showOcrValue && !this.state.showScaleValue) ||
                      (this.state.showFxValue && !this.state.showOcrValue && this.state.showScaleValue)
                    }
                    resetFieldScale={this.props.resetFieldScale}
                  />
                );
              }) /*: fieldType === 'collection' ? (
              <CollectionFieldInput
                field={this.props.field}
                fieldValue={value}
                emptyValue={(e) => this.emptyFieldValue(e)}
                required={required}
                tag={this.props.tag}
                upsertTag={this.props.upsertTag}
              />
            ) */)
            ) : (
              <FieldInput
                key={`default-${_id}`}
                field={this.props.field}
                fields={fields}
                emptyValue={(e) => this.emptyFieldValue(e)}
                handleChange={(e) => this.handleFieldValueChange(e, _id)}
                disabled={dataType === 'area' || !this.props.annotationPermission}
                required={required}
                annotationPermission={this.props.annotationPermission}
                linkEdited={this.state.linkEdited}
                setFieldScale={this.props.setFieldScale}
                updateValue={this.props.updateValue}
                documentId={this.props.document._id}
                showOcrValue={this.state.showOcrValue}
              />
            )}
            {showTextModeIcon && _id === textModeId && (
              <TableFieldBody
                // resize={this.state.resizeCount}
                page={activePage}
                tableData={fields}
                fields={tableTypes}
                field={this.props.field}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    document: state.document,
    tag: state.tag,
    annotationPermission: state.rules.annotation?.annotate,
  };
};

export default connect(mapStateToProps, {
  selectField,
  deleteDocumentFields,
  updateValue,
  selectTag,
  deleteTag,
  changePage,
  updateFieldTableType,
  upsertTag,
  deleteEmptyTag,
  deSelectField,
  refreshFormulaFieldComponents,
  setFieldScale,
  resetFieldScale,
})(Field);
