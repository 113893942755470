import React from 'react';
import CustomModal from '../Common/CustomModal';

import './MicrotaskValidationFailureModal.scss';

const MicrotaskValidationFailureModal = (props) => {
  const { onClose = () => {}, formulas = [] } = props;

  const renderFormulas = () => {
    return formulas.map((item) => {
      return (
        <div className='formula-item my-3'>
          <div className='formula-name'>{item.name}</div>
          <div className='formula-label'>{item.formulaLabel}</div>
        </div>
      );
    });
  };

  return (
    <CustomModal
      open={true}
      modalHandler={() => onClose()}
      onClick={(e) => {
        if (!e.target.closest('.modal-content')) {
          onClose();
        }
      }}
    >
      <div className='modal-content microtask-validation-modal'>
        <div className='modal-header'>
          <h4 className={`modal-title`}>Validation Failure</h4>
        </div>

        <div className='modal-body'>
          <div
            className='microtask-validation-modal-container ps-1 pe-1'
            style={{ maxHeight: '500px', overflowY: 'auto' }}
          >
            <div className='formula-list'>{renderFormulas()}</div>
          </div>
        </div>
      </div>
    </CustomModal>
  );
};

export default MicrotaskValidationFailureModal;
