import React, {useCallback, useEffect, useLayoutEffect, useMemo, useState} from 'react';
import { connect, useSelector } from 'react-redux';
import {changePage, deSelectField} from '../../actions';
import { getFields } from '../Annotation/Browse/BrowseUtils';
import { imagesDimensionsSelector, scaledValueSelector } from '../../store/annotation/selectors';
import { getMatchedSmartHoverTagIndex } from '../../helpers/smartHover/utils';
import AppSpinner from '../Common/AppSpinner';
import TaggingEditor from '../Annotation/TaggingEditor/TaggingEditor';
import { cloneDeep } from 'lodash';
import {activeTagIdSelector, palettesSelector} from '../../store/document/selectors';
import {useAppDispatch} from "../../hooks/reduxHooks";



const Slide = React.memo(function Slide({
  annotationPermission,
  isSearchSelect,
  haveActiveSmartTag,
  filteredByCurrentPage,
  imageUrlBuilder,
  fields = [],
  curPageNum,
  curPageSrc,
  activeSmartHoverTemplate,
}) {
  const dispatch = useAppDispatch();
  const scaledValue = useSelector(scaledValueSelector);
  const imagesDimensions = useSelector(imagesDimensionsSelector);
  const palettes = useSelector(palettesSelector);
  const activeTagId = useSelector(activeTagIdSelector);
  const [scaledDimensions, setScaledDimensions] = useState({});

  const { fieldIdToTagsMap } = useMemo(() => {
    const _fieldIdToTagsMap = {};
    const _activeSmartHoverTemplateFields = [...(activeSmartHoverTemplate.fields || [])];
    const _fields = cloneDeep(fields);
    _fields.forEach((field) => {
      _fieldIdToTagsMap[field._id] = field.tags.map((tag) => {
        tag.tagTitle = field.group ? `${field.group}: ${field.name}` : field.name;
        tag.smartHover = false;
        tag.palette = palettes[field.srcFieldId];
        const itemIndex = getMatchedSmartHoverTagIndex(activeSmartHoverTemplate, tag);
        if (itemIndex >= 0) {
          _activeSmartHoverTemplateFields.splice(itemIndex, 1);
        }
        return tag;
      });
    });

    _activeSmartHoverTemplateFields.forEach((field) => {
      _fieldIdToTagsMap[field._id] = field.tags.map((tag) => {
        tag.tagTitle = field.group ? `${field.group}: ${field.name}` : field.name;
        tag.smartHover = field.smartHover;
        return tag;
      });
    });
    return {
      fieldIdToTagsMap: _fieldIdToTagsMap,
    };
  }, [activeSmartHoverTemplate, fields, palettes]);

  const [imgLoading, setImageLoading] = useState(true);
  const getScaledDimensions = (containerW, image) => {
    const normalizeScale = containerW / image?.width;
    return { width: containerW * scaledValue, height: image?.height * normalizeScale * scaledValue };
  };

  const deselectHandler = useCallback((e)=>{
    if (e.key === 'Escape') {
      dispatch(deSelectField());
    }
  },[dispatch])


  useEffect(()=>{
    if(activeTagId){
      document.addEventListener('keydown',deselectHandler );
    }
    else{
      document.removeEventListener('keydown',deselectHandler)
    }

  },[activeTagId, deselectHandler])

  useLayoutEffect(() => {
    const zoomContainer = document.getElementsByClassName('carousel-inner')[0];
    const containerW = parseFloat(zoomContainer.offsetWidth);
    const scale = getScaledDimensions(containerW, imagesDimensions[curPageNum]);
    if (scale.height && scale.width) {
      setScaledDimensions(scale);
    }
  }, [curPageNum, imgLoading, scaledValue]);

  return (
    <div className={`slide-wrapper ${imgLoading && 'overflow-hidden'}`}>
      {imgLoading && <AppSpinner />}
      <div
        id='carousel'
        className={`carousel slide ${imgLoading && 'position-absolute overflow-hidden'}`}
        data-ride='carousel'
        data-interval='false'
      >
        <div className='carousel-inner'>
          <div key={curPageNum} className={`carousel-item active`} data-page-number={curPageNum}>
            <TaggingEditor
              curPageNum={curPageNum}
              curPageSrc={curPageSrc}
              fieldIdToTagsMap={fieldIdToTagsMap}
              scaledWidth={scaledDimensions?.width}
              scaledHeight={scaledDimensions?.height}
              annotationPermission={annotationPermission}
              imageUrlBuilder={imageUrlBuilder}
              isSearchSelect={isSearchSelect}
              haveActiveSmartTag={haveActiveSmartTag}
              filteredByCurrentPage={filteredByCurrentPage}
              imgLoading={imgLoading}
              setImageLoading={setImageLoading}
            />
          </div>
        </div>
      </div>
    </div>
  );
});

const mapStateToProps = (state) => {
  const { document } = state;
  const { activePage: curPageNum } = document;
  const curPage = document.pages.find((p) => p.page === curPageNum);
  const curPageSrc = curPage?.src;
  return {
    fields: getFields(document),
    curPageNum,
    curPageSrc,
    activeSmartHoverTemplate: document.activeSmartHoverTemplate,
  };
};

export default connect(mapStateToProps, { changePage })(Slide);
