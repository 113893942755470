import { combineReducers } from 'redux';

import annotationReducer from './annotation/reducers';
import userReducer from './user/reducers';
import usersReducer from './users/reducers';
import searchReducer from './search/reducers';
import rulesReducer from './auth/reducers';
import documentReducer from './document/reducers';
import checkpointReducer from './checkpoint/reducers';
import tagReducer from './tag/reducers';
import templateReducer from './templates/reducers';

export const rootReducer = combineReducers({
  annotation: annotationReducer,
  user: userReducer,
  users: usersReducer,
  search: searchReducer,
  rules: rulesReducer,
  document: documentReducer,
  checkpoint: checkpointReducer,
  tag: tagReducer,
  templates: templateReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
