import React, { FC } from 'react';
import { DataFieldOptionProps } from '../types';

const DataFieldOption: FC<DataFieldOptionProps> = ({
  id,
  selectedDataField,
  dataStructure,
  dataField,
  referenceFieldsData,
  titleOrRenderType,
  disabled,
  onFieldClick,
}) => (
  <div id={id} key={id} className={`d-flex align-items-center px-3 py-2 dropdown-item ${disabled && 'disabled'}`}>
    <div className='title k-flex-1'>{titleOrRenderType}</div>
    <div
      className='form-switch k-flex-1 p-0 k-text-right'
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <input
        type='checkbox'
        className='form-check-input ms-0'
        checked={selectedDataField}
        onChange={() =>
          onFieldClick(selectedDataField, dataStructure, dataField, referenceFieldsData, !selectedDataField)
        }
      />
    </div>
  </div>
);

export default DataFieldOption;
