import React, { ReactNode } from 'react';

import { Splitter, SplitterOnChangeEvent } from '@progress/kendo-react-layout';

interface ISplitPaneProps {
  children: ReactNode | ReactNode[];
}

const SplitPane: React.FC<ISplitPaneProps> = ({ children }: ISplitPaneProps) => {
  const [panes, setPanes] = React.useState<Array<any>>([
    { size: '20%', min: '20%', max: '30%', collapsible: true },
    {},
  ]);
  const onChange = (event: SplitterOnChangeEvent) => {
    setPanes(event.newState);
  };
  return (
    <Splitter panes={panes} orientation={'horizontal'} onChange={onChange}>
      {children}
    </Splitter>
  );
};

export default SplitPane;
