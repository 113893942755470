import React, { useCallback, useEffect, useRef, useState } from 'react';
import SearchInput from '../Toolbar/SearchInput';
import Icons from '../../Common/Icons/Icons';
import SearchControl from '../Toolbar/SearchControl';
import { useSelector } from 'react-redux';
import { documentSelector } from '../../../store/document/selectors';
import $ from 'jquery';

import { useAppDispatch } from '../../../hooks/reduxHooks';
import { emptySearch, highlightSearchResult, search } from '../../../store/search/actions';
import { changePage } from '../../../actions';
import {
  regionSelector,
  searchTagsSelector,
  searchTextSelector,
  selectedTagSelector,
} from '../../../store/search/selectors';
import { TRegionPixels, TSearchLabel } from '../../../store/search/types';
import SearchDropDownOption from '../../SearchDropDown/SearchDropDownOption';
import DropDown from '../../Common/DropDown/DropDown';

//TODO:TRY TO REMOVE DOCUMENT DEPENDANCY FROM THIS COMPONENT
const Search: React.FC = () => {
  const [state, setState] = useState({
    isQnADropDownVisible: false,
    searchLoading: false,
    disableSearchNavButtons: true,
    searchCount: 0,
    searchIndex: 0,
  });

  const document = useSelector(documentSelector);
  const searchTags = useSelector(searchTagsSelector) as TSearchLabel[];
  const searchRegion = useSelector(regionSelector);
  const searchText = useSelector(searchTextSelector);
  const selectedSearchTag = useSelector(selectedTagSelector);

  const dispatch = useAppDispatch();

  const searchInput = useRef<HTMLInputElement>(null);

  const handleSearch = async (e: any) => {
    let value = e.target.value;
    if (e.key === 'ArrowUp') {
      handlePrevSearch(e);
    } else if (e.key === 'ArrowDown') {
      handleNextSearch(e);
    } else if (e.key === 'Enter') {
      e.preventDefault();
      if (value !== '') {
        dispatch(search(document.src, value));
        setState({
          ...state,
          searchLoading: true,
        });
      } else {
        dispatch(emptySearch());
        setState({
          ...state,
          searchLoading: false,
          searchCount: 0,
          disableSearchNavButtons: true,
        });
      }
    }
  };

  const handleNextSearch = (e: any) => {
    e.preventDefault();
    let nextSearch;
    let search = selectedSearchTag;
    if (search && searchTags) {
      let index = ($.inArray(search, searchTags) + 1) % searchTags.length;
      if (index < searchTags.length) {
        nextSearch = searchTags[index];
      } else {
        nextSearch = selectedSearchTag!;
      }
      dispatch(
        highlightSearchResult(nextSearch, async (pageNumber: number) => {
          await dispatch(changePage(pageNumber));
        })
      );
      setState({
        ...state,
        searchIndex: index + 1,
      });
    }
  };

  const handlePrevSearch = (e: any) => {
    e.preventDefault();
    let prevSearch;
    let search = selectedSearchTag;
    if (search && searchTags) {
      let index = ($.inArray(search, searchTags) - 1) % searchTags.length;
      if (index > -1) {
        prevSearch = searchTags[index];
        setState({
          ...state,
          searchIndex: index + 1,
        });
      } else {
        prevSearch = searchTags[searchTags.length - 1];
        setState({
          ...state,
          searchIndex: searchTags.length,
        });
      }
      dispatch(
        highlightSearchResult(prevSearch, async (pageNumber: number) => {
          await dispatch(changePage(pageNumber));
        })
      );
    }
  };

  const handleSearchFromRegion = useCallback(
    (region: TRegionPixels) => {
      //add loading to state
      return dispatch(search(document.src, '', region));
    },
    [dispatch, document.src]
  );

  const handleEmptySearch = (e: any) => {
    e.preventDefault();
    dispatch(emptySearch());
    if (searchInput.current) {
      searchInput.current.value = '';
      searchInput.current.placeholder = 'Search';
    }
    setState({
      ...state,
      searchLoading: false,
      searchCount: 0,
      disableSearchNavButtons: true,
    });
  };

  useEffect(() => {
    searchRegion && handleSearchFromRegion(searchRegion);
  }, [searchRegion, handleSearchFromRegion]);

  useEffect(() => {
    if (!searchTags) return;
    const count = searchTags?.length;

    if (count === 0) {
      dispatch(emptySearch());
      if (searchInput.current !== null) {
        searchInput.current.value = '';
        searchInput.current.placeholder = 'No results';
      }
    } else {
      if (searchInput.current !== null && searchText) {
        searchInput.current.placeholder = '';
        searchInput.current.value = searchText;
      }
      const activePage = searchTags[0].page - 1;
      dispatch(changePage(activePage));
    }
    setState({
      ...state,
      disableSearchNavButtons: false,
      searchLoading: false,
      searchCount: count || 0,
      searchIndex: 1,
    });
  }, [dispatch, searchTags]);

  const handleClickSearch = (e: React.MouseEvent<HTMLButtonElement>, tag: TSearchLabel) => {
    const searchIndex = searchTags?.length && searchTags.indexOf(tag) + 1;
    const count = searchTags.length;
    const index = searchIndex === count ? count : searchIndex % count;
    setState({
      ...state,
      searchIndex: index,
    });
    e.preventDefault();
    dispatch(
      highlightSearchResult(tag, async (pageNumber: number) => {
        await dispatch(changePage(pageNumber));
      })
    );
  };

  return (
    <div className='row ms-1 flex-nowrap w-50'>
      <>
        <form className='ps-2'>
          <div className='d-flex align-items-center '>
            <div className='toolbar-search input-group w-50 flex-nowrap' style={{ minWidth: '180px' }}>
              <SearchInput
                ref={searchInput}
                isLoading={state.searchLoading}
                handleSearch={handleSearch}
                searchCount={state.searchCount}
                searchIndex={state.searchIndex}
              />
              <DropDown
                btnIcon={<Icons.DropdownChevronDown />}
                className='dropdown search-dropdown'
                btnClass='no-radius-left btn btn-primary '
                disabled={!searchTags?.length}
                appendTo={null}
              >
                <div className='dropdown-menu-container'>
                  {searchTags?.map((tag) => {
                    return (
                      <SearchDropDownOption
                        key={`${tag.x}_${tag.y}_${tag.page}`}
                        onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleClickSearch(e, tag)}
                        isActive={tag === selectedSearchTag}
                        page={tag.page}
                        value={tag.value}
                      />
                    );
                  }) || null}
                </div>
              </DropDown>
            </div>
            {!state.disableSearchNavButtons ? (
              <div className='col-auto'>
                <SearchControl
                  handlePrevSearch={handlePrevSearch}
                  handleNextSearch={handleNextSearch}
                  handleEmptySearch={handleEmptySearch}
                />
              </div>
            ) : null}
          </div>
        </form>
      </>
    </div>
  );
};

export default Search;
