import React from 'react';
import ModalService from './ModalService';

import ConfirmationModal from '../ConfirmationModal';
import RotatePagesModal from '../RotatePagesModal';
import Icons from '../../Common/Icons/Icons';
import WorkflowTemplateFlow from '../../Annotation/WorkflowTemplateFlow/WorkflowTemplateFlow';
import SelectAlternativeMicrotaskModal from '../../Annotation/WorkflowTemplateFlow/modals/SelectAlternativeMicrotaskModal';
import SelectDocumentTemplate from '../../Annotation/WorkflowTemplateFlow/modals/SelectDocumentTemplate';
import MicrotaskValidationFailureModal from '../MicrotaskValidationFailureModal';
import MetaInfo from '../../Annotation/MetaInfo/MetaInfo';
import FieldModal from '../FieldModal';

function openConfirmDialog(props) {
  const { key = 'confirm-modal', onResponse } = props;
  const modalHandler = ModalService.open({
    key,
    render() {
      return (
        <ConfirmationModal
          label='Confirmation'
          message={`Are you sure?`}
          handleYes={() => {
            modalHandler.close();
            onResponse(true);
          }}
          handleNo={(source) => {
            modalHandler.close();
            onResponse(false, source);
          }}
          {...props}
        />
      );
    },
  });
}

function openConfirmResetDataStructureModal({ onResponse, activeTemplateName }) {
  return openConfirmDialog({
    key: 'resetTemplate',
    label: 'Reset Insight',
    message: `This action cannot be undone! All annotated data of "${activeTemplateName}" Insight will be lost. Are you sure you want to proceed?`,
    onResponse,
  });
}

function openConfirmUnsavedDataLostModal({ label, activeTemplateName, onResponse }) {
  return openConfirmDialog({
    key: 'unsavedDataLostModal',
    label,
    message: `All unsaved changes of "${activeTemplateName}" Insight will be lost. Are you sure you want to proceed?`,
    onResponse,
  });
}

function openConfirmFieldDeletionModal({ fieldName, onResponse }) {
  return openConfirmDialog({
    key: 'deleteFieldConfirm',
    label: `Delete field: ${fieldName}`,
    message: 'Are you sure?',
    onResponse,
  });
}

function openConfirmTransferDataModal({ onResponse }) {
  return openConfirmDialog({
    key: 'sendDocumentModal',
    label: 'Transfer data',
    confirmBtnLabel: <Icons.DocumentTransfer className='icon-sm' />,
    onResponse,
    mode: 'success',
  });
}

function openConfirmIgoneTransferDataValidationModal({ errorMessage, onResponse }) {
  return openConfirmDialog({
    key: 'sendDocumentValidationModal',
    label: 'Validation error',
    confirmBtnLabel: <Icons.DocumentTransfer className='icon-sm' />,
    message: errorMessage,
    onResponse,
  });
}

function openConfirmSaveRotationModal({ onResponse }) {
  return openConfirmDialog({
    key: 'saveRotations',
    label: 'Confirm actions',
    message: 'All your changes to the document will be lost. Are you sure you want to continue?',
    onResponse,
  });
}

function openRotatePagesModal({ document }) {
  const modalHandler = ModalService.open({
    key: 'rotateModal',
    render() {
      return <RotatePagesModal document={document} onClose={() => modalHandler.close()} />;
    },
  });
}

function openMetaInfoModal() {
  const modalHandler = ModalService.open({
    key: 'metaInfoModal',
    render() {
      return <MetaInfo onClose={() => modalHandler.close()} />;
    },
  });
}

function openWorkflowTemplateFlowModal({ finishAction, changeTemplate, onCancel }) {
  const modalHandler = ModalService.open({
    key: 'workflow-template-flow-modal',
    render() {
      return (
        <WorkflowTemplateFlow
          onClose={(doNotCancel = false) => {
            modalHandler.close();

            if (!doNotCancel) {
              onCancel();
            }
          }}
          finishAction={finishAction}
          changeTemplate={changeTemplate}
        />
      );
    },
  });
}

function openSelectAlternativeMicrotaskModal({ microtaskId, alternativeMicrotasks, onSubmit }) {
  const modalHandler = ModalService.open({
    key: 'select-main-or-alternative-microtask-modal',
    render() {
      return (
        <SelectAlternativeMicrotaskModal
          onClose={() => {
            modalHandler.close();
          }}
          microtaskId={microtaskId}
          alternativeMicrotasks={alternativeMicrotasks}
          onSubmit={onSubmit}
        />
      );
    },
  });
}

function openSelectDocumentTemplate({ templateIds, onSubmit }) {
  const modalHandler = ModalService.open({
    key: 'select-document-template',
    render() {
      return (
        <SelectDocumentTemplate
          onClose={() => {
            modalHandler.close();
          }}
          templateIds={templateIds}
          onSubmit={onSubmit}
        />
      );
    },
  });
}

function openConfirmMoveToCollectionStepModal({ onResponse }) {
  return openConfirmDialog({
    key: 'moveToCollectionStep',
    label: 'Move document between collections',
    message: `The document will be moved to other collection`,
    confirmBtnLabel: 'Yes',
    mode: 'primary',
    onResponse,
  });
}

function openMicrotaskValidationFailureModal(props = {}) {
  const { formulas } = props;
  const modalHandler = ModalService.open({
    key: 'microtaskValidationFailureModal',
    render() {
      return (
        <MicrotaskValidationFailureModal
          onClose={() => {
            modalHandler.close();
          }}
          formulas={formulas}
        />
      );
    },
  });
}

function openFieldModal({ message, title, ...rest }) {
  const modalHandler = ModalService.open({
    key: 'field-modal',
    render() {
      return (
        <FieldModal
          onClose={() => {
            modalHandler.close();
          }}
          message={message}
          title={title}
          {...rest}
        />
      );
    },
  });
}

export {
  openConfirmDialog,
  openConfirmResetDataStructureModal,
  openConfirmUnsavedDataLostModal,
  openConfirmFieldDeletionModal,
  openConfirmTransferDataModal,
  openConfirmIgoneTransferDataValidationModal,
  openConfirmSaveRotationModal,
  openRotatePagesModal,
  openMetaInfoModal,
  openWorkflowTemplateFlowModal,
  openSelectAlternativeMicrotaskModal,
  openSelectDocumentTemplate,
  openConfirmMoveToCollectionStepModal,
  openMicrotaskValidationFailureModal,
  openFieldModal,
};
