import { createSelector } from 'reselect';

import { Rules } from './types';
import { RootState } from '../store';

const rulesState: (state: RootState) => Rules = (state) => state.rules;

export const rulesSelector = createSelector(rulesState, (value) => value);

export const selectDataRecipeRule = createSelector(rulesState, (value) => value.data_recipe);

export const selectCollectionRule = createSelector(rulesState, (value) => value.collection);

export const selectTemplateRule = createSelector(rulesState, (value) => value.template);

export const selectAnnotationRights = createSelector(rulesState, (value) => value.annotation.annotate);

export const selectWorkflowRule = createSelector(rulesState, (value) => value.workflow);
export const selectDocumentAIRule = createSelector(rulesState, (value) => value.model);
