import { Dispatch } from 'redux';
import innolytiqApi from '../../api/innolytiqApi';
import { AxiosResponse } from 'axios';

import { User, SET_RULES, SetRulesAction } from './types';

export const signIn: (email: string, password: string, callback: (isValid: boolean) => void) => void =
  (email, password, callback) => async (dispatch: Dispatch) => {
    try {
      const response: AxiosResponse = await innolytiqApi.post<User>('users/login', { email, password });
      const user: User = response.data;
      const xAuthToken = response.headers['x-auth'];
      localStorage.setItem('jwtToken', xAuthToken);
      localStorage.setItem('userId', user._id);
      innolytiqApi.defaults.headers['x-auth'] = xAuthToken;

      let role = user.roles[0];
      for (let keys in user.roles) {
        for (let resource in user.roles[keys].rules) {
          for (let action in user.roles[keys].rules[resource]) {
            role.rules[resource][action] = role.rules[resource][action] || user.roles[keys].rules[resource][action];
          }
        }
      }

      dispatch<SetRulesAction>({
        type: SET_RULES,
        payload: role.rules,
      });

      callback(true);
    } catch (error) {
      callback(false);
    }
  };

export const signOut: () => void = () => {
  localStorage.removeItem('jwtToken');
  innolytiqApi.post('users/logout');
};

export const setRules = () => async (dispatch: Dispatch) => {
  const response: AxiosResponse = await innolytiqApi.get<User>('users/myself/rules');
  try {
    const rules = response.data;

    dispatch<SetRulesAction>({
      type: SET_RULES,
      payload: rules,
    });
  } catch (error) {
    console.log(error);
  }
};
