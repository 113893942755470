// by Rouben Meschian - rmeschian@gmail.com

import { merge } from './redips-wrapper';
import { createCell, createHResizer, createTableRow, createVResizer } from './tableFactory';
import { isString } from './utils';

/**
 * Converts the given numeric value to the percent value of the given container width/height.
 */
function getPercFromPx(px, containerWidthOrHeightPx) {
  px = parseNum(px);
  containerWidthOrHeightPx = parseNum(containerWidthOrHeightPx);
  const perc = (px / containerWidthOrHeightPx) * 100;
  const rounded = Math.round((perc + Number.EPSILON) * 10000) / 10000;
  return rounded;
}

/**
 * Converts the given percent value to a numeric value using the given container width/height.
 */
function getPxFromPerc(percent, containerWidthOrHeightPx) {
  percent = parseNum(percent);
  containerWidthOrHeightPx = parseNum(containerWidthOrHeightPx);
  return (percent * containerWidthOrHeightPx) / 100;
}

function convertTableToPx(table, containerSize) {
  return _convertTableToPxOrPerc(table, 'px', containerSize);
}

function convertTableToPerc(table, containerSize) {
  return _convertTableToPxOrPerc(table, '%', containerSize);
}

function _convertTableToPxOrPerc(newTargetTable, mode, containerSize) {
  const convertFunc = mode === 'px' ? getPxFromPerc : getPercFromPx;

  const newTargetTr = newTargetTable.thead.tr[0];

  const tableWidthPx =
    newTargetTable.style.width === 'auto'
      ? newTargetTable.thead.tr[0].th.reduce((accum, th, i) => {
          if (i === 0) return accum;
          return accum + parseNum(th?.style?.width);
        }, 0)
      : getPxFromPerc(newTargetTable?.style?.width, containerSize.width);

  const tableHeightPx =
    newTargetTable.style.height === 'auto'
      ? newTargetTable.tbody.tr.reduce((accum, tr) => accum + parseNum(tr.td[0]?.style?.height), 0)
      : getPxFromPerc(newTargetTable?.style?.height, containerSize.height);

  // convert Table position
  newTargetTable.style.left = `${convertFunc(newTargetTable?.style?.left, containerSize.width)}${mode}`;
  newTargetTable.style.top = `${convertFunc(newTargetTable?.style?.top, containerSize.height)}${mode}`;

  // convert Table size
  newTargetTable.style.width = mode === 'px' ? 'auto' : `${convertFunc(tableWidthPx, containerSize.width)}${mode}`;
  newTargetTable.style.height = mode === 'px' ? 'auto' : `${convertFunc(tableHeightPx, containerSize.height)}${mode}`;

  // convert Table column widths
  newTargetTr.th.forEach((th, idx) => {
    if (idx === 0) return; // ignore the corner th (the first th)
    let w = convertFunc(th?.style?.width, tableWidthPx);
    if (!th.style) th.style = {};
    th.style.width = `${w}${mode}`;
  });

  // convert Table row heights
  newTargetTable.tbody.tr.forEach((tr, i) => {
    let a = newTargetTable;
    let h = convertFunc(tr.td[0]?.style?.height, tableHeightPx);
    if (!tr.td[0].style) tr.td[0].style = {};
    tr.td[0].style.height = `${h}${mode}`;
  });

  return newTargetTable;
}

/**
 *
 * Returns the numeric value of the given px or percent value.
 * @example
 *   parseNum("44px") // returns 44
 *   parseNum("20%")  // returns 20
 */
function parseNum(val) {
  if (!val) return 0;
  if (isString(val)) {
    return parseFloat(val);
  }
  return val;
}

function insertCol(table, index) {
  const updatedTd = {};
  updateCellIndexes(table, false);

  table.thead.tr[0].th.splice(index, 0, createVResizer());

  table.tbody.tr.forEach((tr, trIndex) => {
    const newTd = createCell();

    tr.td.splice(index, 0, newTd);

    let prevTd = tr.td[index - 1];
    if (prevTd.isPlaceholder) {
      prevTd = prevTd.rowspanSrc || prevTd.colspanSrc;
      if (prevTd.colspanSrc) prevTd = prevTd.colspanSrc;
    }

    if (!prevTd) return;

    const {
      colspan: prevColspan = 1,
      rowspan: prevRowspan = 1,
      colIndex: prevColIndex,
      rowIndex: prevRowIndex,
    } = prevTd;

    // avoid placeholder indexes
    if (trIndex !== prevRowIndex) {
      newTd.isPlaceholder = true;
      newTd.colspanSrc = prevTd;
      return;
    }

    // prevTd now contains the td with the actual colspan/rowspan info (not placeholder)

    if (prevColspan > 1) {
      const prevTdColspan = index - prevColIndex;
      const newTdColspan = prevTd.colspan - prevTdColspan + 1;
      prevTd.colspan = prevTdColspan;
      newTd.colspan = newTdColspan;
      newTd.rowspan = prevRowspan;
    } else if (prevRowspan > 1) {
      newTd.rowspan = prevRowspan; // if parent has colspan, we need to do the same
    }
  });

  removeAllPlaceholders(table);
  return table;
}

function _deleteCol(table, index) {
  updateCellIndexes(table, false);

  table.thead.tr[0].th.splice(index, 1);

  table.tbody.tr.forEach((tr, trIndex) => {
    const targetTd = tr.td[index];

    if (targetTd.isPlaceholder) {
      const colspanSrc = targetTd.colspanSrc;
      if (colspanSrc) {
        colspanSrc.colspan--;
      }
    } else {
      const { colspan = 1 } = targetTd;
      if (colspan > 1) {
        let prevTd = tr.td[index - 1];
        if (prevTd.isPlaceholder) {
          prevTd = prevTd.colspanSrc;
        }
        if (prevTd) {
          const { colspan: prevColspan = 1, colIndex: prevColIndex } = prevTd;
          prevTd.colspan = prevColspan + colspan - 1;
        }
      }
    }

    tr.td.splice(index, 1);
  });

  removeAllPlaceholders(table);
  return table;
}
function deleteCol(table, thIndex) {
  const deleteTh = table.thead.tr[0].th[thIndex];
  const updatedTh = table.thead.tr[0].th[thIndex + 1];
  const newWidth = parseNum(deleteTh?.style?.width) + parseNum(updatedTh?.style?.width);

  _deleteCol(table, thIndex + 1);

  if (!table.thead.tr[0].th[thIndex].style) table.thead.tr[0].th[thIndex].style = {};
  table.thead.tr[0].th[thIndex].style.width = `${newWidth}%`;
}

function insertRow(table, index) {
  const updatedTd = {};
  updateCellIndexes(table, false);

  const targetRow = createTableRow({
    td: [createHResizer()],
  });

  table.tbody.tr.splice(index, 0, targetRow);

  // loop over each parent td
  const prevTr = table.tbody.tr[index - 1];
  if (prevTr) {
    prevTr.td.forEach((prevTd, curColumnIndex) => {
      if (curColumnIndex === 0) return;

      const newTd = createCell();
      targetRow.td.push(newTd);

      if (prevTd.isPlaceholder) {
        prevTd = prevTd.rowspanSrc || prevTd.colspanSrc;
        if (prevTd.colspanSrc) prevTd = prevTd.colspanSrc;
      }

      if (!prevTd) return;

      const {
        rowspan: prevRowspan = 1,
        colspan: prevColspan = 1,
        colIndex: prevColIndex,
        rowIndex: prevRowIndex,
      } = prevTd;

      // avoid placeholder indexes
      if (prevColIndex !== curColumnIndex) {
        newTd.isPlaceholder = true;
        newTd.colspanSrc = prevTd;
        return;
      }

      // prevTd now contains the td with the actual colspan/rowspan info (not placeholder)

      if (prevRowspan > 1) {
        const prevTdRowspan = index - prevRowIndex;
        const newTdRowspan = prevTd.rowspan - prevTdRowspan + 1;
        prevTd.rowspan = prevTdRowspan;
        newTd.rowspan = newTdRowspan;
        newTd.colspan = prevColspan;
      } else if (prevColspan > 1) {
        newTd.colspan = prevColspan; // if parent has colspan, we need to do the same
      }
    });
  } else {
    table.thead.tr.forEach((th, thIndex) => {
      if (thIndex === 0) return;
      const newTd = createCell();
      targetRow.td.push(newTd);
    });
  }

  removeAllPlaceholders(table);
  return table;
}

function _deleteRow(table, index) {
  const updatedTd = {};
  updateCellIndexes(table, false);

  const targetTr = table.tbody.tr[index];

  // loop over each parent td
  const prevTr = table.tbody.tr[index - 1];
  if (prevTr) {
    prevTr.td.forEach((prevTd, prevTdIndex) => {
      if (prevTdIndex === 0) return;

      const targetTd = targetTr.td[prevTdIndex];
      if (targetTd.isPlaceholder) {
        const rowspanSrc = targetTd.rowspanSrc;
        if (rowspanSrc && updatedTd[rowspanSrc.id] === undefined) {
          rowspanSrc.rowspan--;
          updatedTd[rowspanSrc.id] = true;
        }
      } else {
        const { rowspan = 1 } = targetTd;
        if (rowspan > 1) {
          if (prevTd.isPlaceholder) {
            prevTd = prevTd.rowspanSrc || prevTd.colspanSrc;
            if (prevTd.colspanSrc) prevTd = prevTd.colspanSrc;
          }

          const { rowspan: prevRowspan = 1 } = prevTd;

          if (!updatedTd[prevTd.id]) {
            prevTd.rowspan = prevRowspan + rowspan - 1;
            updatedTd[prevTd.id] = true;
          }
        }
      }
    });
  }

  table.tbody.tr.splice(index, 1);

  removeAllPlaceholders(table);
  return table;
}
function deleteRow(table, trIndex) {
  const targetHeightPerc = parseNum(table.tbody.tr[trIndex].td[0]?.style?.height);

  _deleteRow(table, trIndex);

  const nextRow = table.tbody.tr[trIndex - 1];
  if (!nextRow.td[0].style) nextRow.td[0].style = {};
  nextRow.td[0].style.height = `${parseNum(nextRow.td[0]?.style?.height) + targetHeightPerc}%`;
}

/**
 * Apply absolute row index and column index to each cell in the tbody.
 */
function updateCellIndexes(table, removePlaceholders = true) {
  table.tbody.tr.forEach((tr, trIndex) => {
    for (let tdIdx = 0; tdIdx < tr.td.length; tdIdx++) {
      const td = tr.td[tdIdx];
      td.rowIndex = trIndex;
      td.colIndex = tdIdx;

      const { colspan = 1, rowspan = 1 } = td;
      for (let colspanIndex = 1; colspanIndex < colspan; colspanIndex++) {
        tr.td.splice(
          tdIdx + colspanIndex,
          0,
          createCell({
            isPlaceholder: true,
            rowspan,
            rowIndex: trIndex,
            colIndex: tdIdx + colspanIndex,
            colspanSrc: td,
          })
        );
      }
      tdIdx += colspan - 1;
    }
  });

  table.tbody.tr.forEach((tr, trIndex) => {
    for (let tdIdx = 0; tdIdx < tr.td.length; tdIdx++) {
      const td = tr.td[tdIdx];
      td.rowIndex = trIndex;
      td.colIndex = tdIdx;

      const { rowspan = 1 } = td;
      for (let rowspanIndex = 1; rowspanIndex < rowspan; rowspanIndex++) {
        table.tbody.tr[trIndex + rowspanIndex].td.splice(
          tdIdx,
          0,
          createCell({
            isPlaceholder: true,
            rowIndex: trIndex + rowspanIndex,
            colIndex: tdIdx,
            rowspanSrc: td.colspanSrc || td,
          })
        );
      }
    }
  });

  if (removePlaceholders) {
    removeAllPlaceholders(table);
  }
}

function removeAllPlaceholders(table) {
  table.tbody.tr.forEach((tr) => {
    tr.td = tr.td.filter((td) => !td.isPlaceholder);
  });
}

function computeCoordinatesForEachCell(table, containerSize) {
  convertTableToPx(table, containerSize);
  updateCellIndexes(table);

  const tableTopPx = parseNum(table?.style?.top),
    tableLeftPx = parseNum(table?.style?.left);

  table.tbody.tr.forEach((tr) => {
    tr.td.forEach((td, tdIndex) => {
      if (tdIndex === 0) return; // ignore resizer column

      const { colspan = 1, rowspan = 1, colIndex, rowIndex } = td;

      const cellTopPx =
        tableTopPx +
        table.tbody.tr.slice(0, rowIndex).reduce((accum, tr) => accum + parseNum(tr.td[0]?.style?.height), 0);
      const cellLeftPx =
        tableLeftPx +
        table.thead.tr[0].th.slice(0, colIndex).reduce((accum, th) => accum + parseNum(th?.style?.width), 0);

      td.y_left_top = getPercFromPx(cellTopPx, containerSize.height);
      td.x_left_top = getPercFromPx(cellLeftPx, containerSize.width);

      const cellHeightPx = table.tbody.tr
        .slice(rowIndex, rowIndex + rowspan)
        .reduce((accum, tr) => accum + parseNum(tr.td[0]?.style?.height), 0);

      const cellWidthPx = table.thead.tr[0].th
        .slice(colIndex, colIndex + colspan)
        .reduce((accum, th) => accum + parseNum(th?.style?.width), 0);

      td.height = getPercFromPx(cellHeightPx, containerSize.height);
      td.width = getPercFromPx(cellWidthPx, containerSize.width);
    });
  });

  convertTableToPerc(table, containerSize);
}

function isTableInPercent(table) {
  return JSON.stringify(table.style).indexOf('%') !== -1;
}

function mergeCells(table, cellId1, cellId2) {
  // merge target cells
  merge(table, cellId1, cellId2);

  // ------ remove unused columns (ones where all are merged) ------

  updateCellIndexes(table, false);

  let unusedColIndex = -1;
  let unusedRowIndex = -1;

  // find empty columns
  for (let i = 1; i < table.thead.tr[0].th.length; i++) {
    if (
      table.tbody.tr.filter((tr) => {
        // get the td where colIndex is the
        const targetTd = tr.td.find((td) => td.colIndex === i);
        return targetTd && targetTd.isPlaceholder !== true;
      }).length === 0
    ) {
      unusedColIndex = i - 1;
      break;
    }
  }

  // remove unused rows
  for (let i = 0; i < table.tbody.tr.length; i++) {
    if (
      table.tbody.tr[i].td.filter((td, i) => {
        if (i === 0) return false;
        return td.isPlaceholder !== true;
      }).length === 0
    ) {
      unusedRowIndex = i;
      break;
    }
  }

  removeAllPlaceholders(table);

  if (unusedColIndex > -1) {
    deleteCol(table, unusedColIndex);
  }

  if (unusedRowIndex > -1) {
    deleteRow(table, unusedRowIndex);
  }
}

export {
  isTableInPercent,
  mergeCells,
  insertCol,
  deleteCol,
  insertRow,
  deleteRow,
  parseNum,
  convertTableToPx,
  convertTableToPerc,
  computeCoordinatesForEachCell,
  getPercFromPx,
  getPxFromPerc,
  updateCellIndexes,
};
