import React from 'react';

const Spans = React.memo(
  ({ id, left, top, width, height, selectedItems, startSelection, endSelection, updateBackground }) => {
    return (
      <div
        id={id}
        key={id}
        className='red-border'
        style={{
          position: 'absolute',
          left: `${left}%`,
          top: `${top}%`,
          width: `${width}%`,
          height: `${height}%`,
          // border: '1px solid red',
          background: selectedItems[id]?.background,
          zIndex: 1024,
          cursor: 'text',
        }}
        onMouseDown={(e) => startSelection(e, id)}
        onMouseUp={(e) => endSelection}
        onMouseMove={(e) => updateBackground(e, id)}
      ></div>
    );
  }
);

export default Spans;
