import React, {ReactNode, useEffect} from 'react';
import {Popover, PopoverCollision, PopoverMargin} from '@progress/kendo-react-tooltip';
import { Button } from '@progress/kendo-react-buttons';
import './DropDown.scss';

interface IDropDownProps {
  position?: 'top' | 'bottom' | 'left' | 'right';
  btnIcon?: ReactNode;
  btnText?: string;
  btnClass?: string;
  children: ReactNode | ReactNode[] | null;
  className?: string;
  disabled?: boolean;
  variant?: string;
  style?: {}
  margin?:  PopoverMargin | undefined,
  collision?: PopoverCollision | undefined;
  appendTo?: null | HTMLElement,
  initShow?: boolean,
  onClick?: ()=>void | undefined
  onClose?: ()=>void | undefined
}
const DropDown: React.FC<IDropDownProps> = (props) => {
  const {
    disabled,
    position = 'bottom',
    btnIcon,
    btnText,
    children,
    className,
    btnClass = '',
    variant,
    margin,
    collision,
    appendTo,
    initShow= false,
    onClick,
    onClose,
    ...rest
  } = props;

  const anchor = React.useRef<Button>(null);
  const [show, setShow] = React.useState(initShow);

  useEffect(()=>{
    setShow(initShow);
  },[initShow])

  const clickHandler = (event: any) => {
    let containingElement = document.querySelector('.k-popover')!;
    if ((containingElement && containingElement.contains(event.target) )|| event.target.closest('.k-popover')) {
      return;
    } else {
      setShow(false);
      document.body.removeEventListener('click', clickHandler);
      if(typeof onClose === 'function') onClose()
    }
  };
  const handleClick = () => {
    setShow(!show);
    if(typeof onClick === 'function') onClick()
    if (!show) {
      setTimeout(() => document.body.addEventListener('click', clickHandler), 100);
    }
  };

  return (
    <div {...rest}>
      <Button
        type='button'
        onClick={handleClick}
        ref={anchor}
        className={`prevent-click ${btnClass} btn-${variant} ${btnIcon ? 'icon-btn' : ''}`}
        disabled={disabled || !children}
      >
        <i>{btnIcon}</i>
        {btnText}
      </Button>
      <Popover positionMode='absolute' show={show} anchor={anchor.current && anchor.current.element} position={position} callout={false} margin={margin} collision={collision} appendTo={appendTo}>
        <div className={`dropdown-menu ${className}`}>{children}</div>
      </Popover>
    </div>
  );
};

export default DropDown;
