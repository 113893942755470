import React from 'react';
import Selectbox from '../SelectBox';
import { getOcrTypes } from '../../../../appConfig';
import { DEFAULT_OCR_TYPES } from '../../../../helpers/common/constants';

let OCR_TYPES = JSON.parse(getOcrTypes());

if (OCR_TYPES.length === 0) {
  OCR_TYPES = DEFAULT_OCR_TYPES;
}

const OcrSelectbox = ({ label, value, className = 'form-label', handleChange }) => {
  return (
    <>
      <label className={className}>{label}</label>
      <div className='col'>
        <Selectbox
          classes={`selectbox`}
          id='select2-metadata-ocr'
          value={OCR_TYPES.find((o) => o.value === value)}
          options={OCR_TYPES}
          isMulti={false}
          isSearchable={true}
          name='ocr'
          onChange={handleChange}
        />
      </div>
    </>
  );
};

export default OcrSelectbox;
