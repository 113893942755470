// ===================================================
// STATIC UTILITY METHODS

import _ from 'lodash';
function areqEqualShalow(a, b) {
  if (a === b) return true;
  return _.isEqual(a, b);
}

function memo(func, areEqual = areqEqualShalow) {
  let resp;
  let cacheProps;
  return (props) => {
    if (!cacheProps || !areEqual(cacheProps, props)) {
      resp = func(props);
      cacheProps = props;
    }
    return resp;
  };
}

const getGroupIdToFieldsMap = memo(function (fields) {
  return fields.reduce((map, field) => {
    const groupKey = field.groupKey || 'undefined';
    if (!map[groupKey]) map[groupKey] = [];
    map[groupKey].push(field);
    return map;
  }, {});
});

const filterFields = memo(function ({
  activePage,
  fields: inputFields = [],
  filterOption = 'showAllFields',
  searchFieldValue = '',
}) {
  let fields = searchFieldValue
    ? inputFields.filter((f) => {
        return f.group
          ? f.group.toLowerCase().indexOf(searchFieldValue) !== -1 ||
              (f.name.toLowerCase().indexOf(searchFieldValue) !== -1 &&
                f.group.toLowerCase().indexOf(searchFieldValue) === -1)
          : f.name.toLowerCase().indexOf(searchFieldValue) !== -1;
      })
    : inputFields;

  if (filterOption === 'showAllFields') {
    return fields;
  }

  const groupIdToFieldsMap = getGroupIdToFieldsMap(fields);

  if (filterOption === 'showAnnotatedFields') {
    return fields.filter((field) => {
      let fieldIsComputed = field.fieldType === 'computation' || field.fieldType === 'extraction and computation';
      let fieldValueIsNotEmpty = field.value !== undefined && field.value !== '';

      if (field.groupKey) {
        let fieldGroup = groupIdToFieldsMap[field.groupKey];
        for (let f of fieldGroup) {
          fieldValueIsNotEmpty = f.value !== undefined && f.value !== '';
          fieldIsComputed = f.fieldType === 'computation' || f.fieldType === 'extraction and computation';
          if ((fieldIsComputed ? f.value !== 0 && fieldValueIsNotEmpty : fieldValueIsNotEmpty) || f.tags.length > 0) {
            return true;
          }
        }
      } else if ((fieldIsComputed ? field.value !== 0 : fieldValueIsNotEmpty) || field.tags.length > 0) {
        return true;
      }
      return false;
    });
  }

  if (filterOption === 'showEmptyFields') {
    return fields.filter((field) => {
      let fieldIsComputed = field.fieldType === 'computation' || field.fieldType === 'extraction and computation';
      let fieldValueIsNotEmpty = field.value !== '' && field.value !== undefined;

      if (field.groupKey) {
        let fieldGroup = groupIdToFieldsMap[field.groupKey];
        for (let f of fieldGroup) {
          fieldIsComputed = f.fieldType === 'computation' || f.fieldType === 'extraction and computation';
          fieldValueIsNotEmpty = f.value !== '' && f.value !== undefined;
          if ((fieldIsComputed ? f.value !== 0 && fieldValueIsNotEmpty : fieldValueIsNotEmpty) || f.tags.length > 0) {
            return false;
          }
        }
        return true;
      } else if ((fieldIsComputed ? field.value === 0 : fieldValueIsNotEmpty) && field.tags.length === 0) {
        return true;
      }
      return false;
    });
  }

  if (filterOption === 'showCurrentPageFields') {
    return fields.filter((field) => {
      if (field.groupKey) {
        let fieldGroup = groupIdToFieldsMap[field.groupKey];
        for (let f of fieldGroup) {
          if (f.tags && f.tags.length > 0) {
            for (let t of f.tags) {
              if (t.page === activePage) {
                return true;
              }
              break;
            }
          }
        }
      } else {
        for (let t of field.tags) {
          if (t.page === activePage) {
            return true;
          }
        }
      }
      return false;
    });
  }
  return fields;
});

const filterFieldsOnSmartTag = memo(function ({
  referenceFieldsData, // smartTags?.activeTag?.referenceFieldsData
  fields: inputFields = [],
  taggingEditorState,
}) {
  if (!referenceFieldsData || !inputFields.length) {
    return inputFields;
  }
  if (taggingEditorState === 'pixelPerfect') {
    return inputFields.filter(
      ({ smartTag, dataType }) => (!smartTag || !smartTag.dataStructure) && dataType !== 'area'
    );
  }

  if (referenceFieldsData && referenceFieldsData.length) {
    const fieldsDataIdsSet = referenceFieldsData.reduce((s, fieldData) => {
      s.add(fieldData._id);
      return s;
    }, new Set());

    return inputFields.filter((field) => {
      return field?.smartTag?.dataStructure && fieldsDataIdsSet.has(field._id);
    });
  }

  return inputFields;
});

function fieldSortComparator(a, b) {
  if (!a.order || !b.order) {
    return !a.order ? 1 : -1;
  }
  if (a.order === b.order && a.srcFieldId === b.srcFieldId) {
    if (a.repeatParent === b._id) return 1;
    if (b.repeatParent === a._id) return -1;
    if (a.repeatParent === b.repeatParent) return 0;
    else if (a._id < b._id) {
      return 1;
    } else return -1;
  }
  if (a.order >= b.order) {
    return 1;
  }
  return -1;
}

function getIsSmartTag(document) {
  return !!document?.smartTags?.isSmartTag;
}

function getActiveSmartTag(document) {
  return document?.smartTags?.activeTag;
}

function getActiveTemplate(document) {
  return document?.activeTemplate;
}

function getFields(document) {
  const template = getActiveTemplate(document);
  if (template) return template.fields;
  if (document?.fields) return document.fields;
  return [];
}
export {
  getGroupIdToFieldsMap,
  filterFields,
  filterFieldsOnSmartTag,
  fieldSortComparator,
  getIsSmartTag,
  getActiveSmartTag,
  getActiveTemplate,
  getFields,
};
