import { PayloadAction } from '@reduxjs/toolkit/src/createAction';
import { DocumentDataStructure } from '../document/types';
import {DataStructure} from "../datastructure/types";
import {Annotation} from "../annotation/types";

export const ADD_CHECKPOINT = 'ADD_CHECKPOINT';
export const RESET_CHECKPOINT = 'RESET_CHECKPOINT';
export const REINITIALIZE_CHECKPOINT = 'REINITIALIZE_CHECKPOINT';
export const UPDATE_CHECKPOINT = 'UPDATE_CHECKPOINT';
export const UNDO = 'UNDO';
export const REDO = 'REDO';

export type CheckpointActionType =
  | typeof ADD_CHECKPOINT
  | typeof RESET_CHECKPOINT
  | typeof REINITIALIZE_CHECKPOINT
  | typeof UPDATE_CHECKPOINT
  | typeof UNDO
  | typeof REDO;

export type TCheckpoint = {
  undoNumber: number;
  undoPressed: boolean;
  redoPressed?: boolean;
  documentHistory: Partial<DocumentDataStructure>[];
};

export type TCheckpointStructure = {
  activeTemplate: DataStructure,
  tags: Annotation['tags'],
  activePage: DocumentDataStructure['activePage']
}

export interface CheckpointAction extends PayloadAction<TCheckpointStructure> {
  type: CheckpointActionType;
  payload: TCheckpointStructure;
}
