import React, { useCallback, useEffect, useState } from 'react';
import { useSelectboxOptions } from '../../../Common/SelectBox/hooks/hooks';
import { queryMicrotasksNamesByIds } from '../../../../store/microtasks/actions';
import Icons from '../../../Common/Icons/Icons';
import CustomModal from '../../../Common/CustomModal';
import Selectbox from '../../../Common/SelectBox';

const SelectAlternativeMicrotaskModal = ({ onClose, microtaskId, alternativeMicrotasks, onSubmit }) => {
  const [isMain, setIsMain] = useState(true);

  const [loading, setLoading] = useState(false);
  const [microtask, setMicrotask] = useState(null);

  const microtaskOptions = useSelectboxOptions({
    getter: () =>
      new Promise((resolve) => {
        if (!alternativeMicrotasks?.length) {
          return resolve([]);
        }

        queryMicrotasksNamesByIds(alternativeMicrotasks).then(resolve);
      }),
    iterator: ({ _id, name }) => ({ label: name, value: _id }),
    onStartLoading: () => {
      setLoading(true);
    },
    onEndLoading: () => {
      setLoading(false);
    },
  });

  useEffect(() => {
    if (!microtaskOptions.length || microtask) {
      return;
    }

    setMicrotask(microtaskOptions[0]);
  }, [microtaskOptions, microtask]);

  const handleSubmit = useCallback(() => {
    const _microtaskId = isMain ? microtaskId : microtask.value;
    if (!_microtaskId) {
      return;
    }

    onSubmit(_microtaskId);
    onClose();
  }, [microtask, onSubmit, isMain]);

  const handleClose = useCallback(() => {
    onSubmit();
    onClose();
  }, [onClose, onSubmit]);

  const handleSetIsMain = useCallback(() => {
    setMicrotask(null);
    setIsMain(true);
  }, []);

  const handleSetIsAlternative = useCallback(() => {
    setIsMain(false);
  }, []);

  const renderInputs = () => (
    <>
      <div className='form-group'>
        <div className='form-check form-switch'>
          <input
            type='checkbox'
            id='isMain'
            className='form-check-input'
            name='isMain'
            checked={isMain}
            onChange={handleSetIsMain}
          />
          <label className='form-check-label' htmlFor='isMain'>
            I held fast to my duty, unwavering despite any obstacles!
          </label>
        </div>
      </div>

      {!!alternativeMicrotasks.length && (
        <div className='form-group'>
          <div className='form-check form-switch'>
            <input
              type='checkbox'
              id='isAlternative'
              className='form-check-input'
              name='isAlternative'
              checked={!isMain}
              onChange={handleSetIsAlternative}
            />
            <label className='form-check-label' htmlFor='isAlternative'>
              With a heavy heart, I had to embark on an entirely new endeavor.
            </label>
          </div>
        </div>
      )}

      {!isMain && (
        <div className='form-group'>
          <label className='form-label'>Expert microtask</label>
          <Selectbox
            isLoading={loading}
            name='main-and-alternative-microtasks'
            value={microtask}
            onChange={setMicrotask}
            options={microtaskOptions}
          />
        </div>
      )}
    </>
  );

  return (
    <CustomModal
      open={true}
      modalHandler={handleClose}
      onClick={(e) => {
        e.preventDefault();
        if (!e.target.closest('.modal-content')) {
          handleClose();
        }
      }}
      classNames={{
        modal: 'custom-modal-xsm',
      }}
    >
      <div className='modal-content'>
        <div className='modal-header'>
          <h4 className='modal-title workflow-template-flow-modal-empty-title'></h4>
        </div>
        <div className='modal-body'>
          <form onSubmit={handleSubmit}>
            <div className='form-group'>
              <label className='form-label'>
                Did you stick to your original mission or venture off in a new direction?
              </label>
            </div>

            {loading ? (
              <div className='d-flex align-items-center'>
                <div className='spinner-border text-primary documents-loader my-3' role='status'></div>
              </div>
            ) : (
              renderInputs()
            )}
          </form>
        </div>
        <div className='modal-footer'>
          <div className='btn-group' role='group'>
            <button type='button' className='btn btn-secondary' onClick={handleClose}>
              <Icons.ModalCancel className='svg-icon-md' />
            </button>
            <button
              type='button'
              className='btn btn-primary'
              onClick={handleSubmit}
              disabled={loading || (isMain ? false : !microtask)}
            >
              <Icons.ModalSave className='svg-icon-md' />
            </button>
          </div>
        </div>
      </div>
    </CustomModal>
  );
};

export default SelectAlternativeMicrotaskModal;
