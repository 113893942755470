import React from 'react';

type TSearchDropDownOption = {
  isActive?: boolean;
  page: number;
  value: string;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

const SearchDropDownOption = ({ page, value, onClick, isActive = false }: TSearchDropDownOption) => {
  return (
    <button
      type='button'
      className={`dropdown-item d-flex align-items-center ${isActive ? 'active' : ''}`}
      onClick={onClick}
    >
      <span className='badge me-2'>page {page}</span>
      <p className='py-1 my-auto text-truncate'>{value}</p>
    </button>
  );
};

export default SearchDropDownOption;
