import React from 'react';
import { connect } from 'react-redux';
import {
  showLabels,
  clearLabels,
  queryOriginSmartTags,
  clearSmartTags,
  setTaggingEditorState,
  setActiveSmartTag,
  updateSmartTags,
  tagsBringSateToggle,
  toggleTagsVisibility,
} from '../../../actions';
import $ from 'jquery';
import _ from 'lodash';
import { appendImagesDimensions, setScaledValue } from '../../../store/annotation/actions';

import { search, emptySearch, highlightSearchResult } from '../../../store/search/actions';
import Button from '../../Common/Button';
import PageSwitcher from "./components/PageSwitcher";

const zoomDiff = 0.2;
const PAGE_STRUCTURE_ID = '5e419ed1f4b20400137938d3';

class Toolbar extends React.Component {
  state = {
    label: false,
    searchLoading: false,
    searchIndex: 0,
    searchCount: 0,
    disableSearchNavButtons: true,
    clickChangeTool: false,
    isSearchSelect: false,
    zoomInfo: {
      increaseScaleCoefficient: function (scaledValue) {
        return +(scaledValue + zoomDiff).toFixed(1);
      },
      decreaseScaleCoefficient: function (scaledValue) {
        return +(scaledValue - zoomDiff).toFixed(1);
      },
    },
    isQnADropDownVisible: false,
    isTextTruncated: false,
  };

  constructor(props) {
    super(props);

    this.docNameRef = React.createRef();
  }

  componentWillMount() {
    this.appendImagesDimensions(this.props.document.activePage);
    document.addEventListener('keydown', this.keyDownHandler, false);
    const { smartTags } = this.props.document;
    if (this.state.label !== !!smartTags?.isSmartTag) {
      this.setState({
        label: !!smartTags?.isSmartTag,
      });
    }
  }

  async componentDidMount() {
    this.checkTextTruncation();
    window.addEventListener('resize', this.checkTextTruncation);
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.scaledValue !== prevProps.scaledValue ||
      prevProps.document.activePage !== this.props.document.activePage
    ) {
      this.appendImagesDimensions(this.props.document.activePage);
    }
    if (prevProps.isSmartTag !== this.props.isSmartTag && this.props.isSmartTag) {
      await this.props.queryOriginSmartTags(true);
    }
  }

  handleChangeTool = (e) => {
    e.preventDefault();
    this.setState({
      clickChangeTool: !this.state.clickChangeTool,
    });
  };

  /*TODO:Remove after check smart tag drop down
  async UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      document: { smartTags },
    } = nextProps;

    const isSmartTag = smartTags && smartTags.isSmartTag;
    console.log(isSmartTag, this.state.label);
    if (isSmartTag !== this.state.label) {
      this.setState({ label: isSmartTag });
      await this.queryLabels(isSmartTag);
    }
  }*/

  componentWillUnmount() {
    window.removeEventListener('resize', this.checkTextTruncation);
  }

  checkTextTruncation = () => {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      const spanElement = this.docNameRef.current;
      if (spanElement) {
        const isTextTruncated = spanElement.scrollWidth > spanElement.offsetWidth;
        if (isTextTruncated !== this.state.isTextTruncated) {
          this.setState({ isTextTruncated });
        }
      }
    }, 100);
  };

  handleZoomIn = (e) => {
    e.preventDefault();
    const { zoomInfo } = this.state;
    const scaledValue = zoomInfo.increaseScaleCoefficient(this.props.scaledValue);
    this.props.setScaledValue(scaledValue);
  };

  handleZoomOut = (e) => {
    e.preventDefault();
    const { zoomInfo } = this.state;
    const scaledValue = zoomInfo.decreaseScaleCoefficient(this.props.scaledValue);
    this.props.setScaledValue(scaledValue);
  };

  handleZoomRangeChange = (e) => {
    this.props.setScaledValue(+e.target.value);
  };

  appendImagesDimensions = (page) => {
    const pageNumberKey = page ? page.toString() : this.props.document.activePage.toString();
    if (this.props.imagesDimensions[pageNumberKey]) {
      return;
    }

    const image = new Image();
    image.src = this.props.imageUrlBuilder(pageNumberKey);
    image.onload = () => {
      const dimensions = {
        width: image.width,
        height: image.height,
      };
      this.props.appendImagesDimensions(pageNumberKey, dimensions);
    };
  };


  keyDownHandler = (event) => {
    const activePage =
      this.props.document.activePage !== this.props.document.pages && this.props.document.pages.length
        ? this.props.document.activePage + 1
        : 1;
    const taggingEditor = $('#tagging-editor-' + activePage);
    if (!_.isEmpty(taggingEditor)) {
      if (
        !document.activeElement.classList.contains('form-control') &&
        !document.activeElement.classList.contains('zoom-range')
      ) {
        if (event.keyCode === 37) {
          this.handlePrevPage(event);
        }
        if (event.keyCode === 39) {
          this.handleNextPage(event);
        }
      }
    }
  };

  /*TODO:Remove after clarification what is the purpose
  queryLabels = async (label = this.state.label) => {
    if (label && this.props.isSmartTag) {
      await this.showLabels(null, null, 'smartTag');
      return;
    }

    await this.clearLabels();
  };*/

  async clearLabels() {
    if (this.props.smartTags && this.props.smartTags.isSmartTag && this.props.smartTags.activeTag) {
      await this.props.setActiveSmartTag(null);
    }

    await this.props.setTaggingEditorState('');
    this.props.clearLabels();
  }

  toggleTagsVisibility = (e) => {
    e.preventDefault();
    this.props.toggleTagsVisibility();
  };

  toggleQnADropDown = () => {
    this.setState({ ...this.state, isQnADropDownVisible: !this.state.isQnADropDownVisible });
  };

  isPageStructureInsight = () => {
    return this.props.document.templates.find(({ template }) => template?._id === PAGE_STRUCTURE_ID);
  };

  render() {
    if (!this.props.document) {
      return '';
    }

    return (
      <div>
        <div className='d-flex align-items-center gap-3' id='document-tools'>
          <div className='col-auto btn-group ps-0' role='group'>
            <Button variant='primary' onClick={this.handleZoomOut} icon={'ZoomOut'} fillMode='flat' />
            <input
              name='scaleCoefficient'
              type='range'
              className='p-0 mx-2 d-lg-block'
              min='0.5'
              max='4'
              step='0.1'
              value={this.props.scaledValue}
              onChange={this.handleZoomRangeChange}
            />
            <Button variant='primary' onClick={this.handleZoomIn} icon={'ZoomIn'} fillMode='flat' />
          </div>

          <PageSwitcher/>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  document: state.document,
  smartTags: (state.document && state.document.smartTags) ?? null,
  scaledValue: state.annotation.scaledValue,
  imagesDimensions: state.annotation.imagesDimensions,
});

export default connect(mapStateToProps, {
  emptySearch,
  search,
  highlightSearchResult,
  showLabels,
  clearLabels,
  clearSmartTags,
  setTaggingEditorState,
  setActiveSmartTag,
  queryOriginSmartTags,
  updateSmartTags,
  tagsBringSateToggle,
  toggleTagsVisibility,
  appendImagesDimensions,
  setScaledValue,
})(Toolbar);
