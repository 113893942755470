function ModalService() {
  let handlers = [];
  const keyToModals = {};

  function open(props) {
    const { key = JSON.stringify(props) } = props;
    keyToModals[key] = props;
    _fireChange();
    return {
      close: () => close(key),
    };
  }
  function close(key) {
    if (keyToModals[key]) {
      delete keyToModals[key];
      _fireChange();
    }
  }

  function _fireChange() {
    handlers.forEach((f) => f());
  }

  function onChange(handler) {
    if (handlers.indexOf(handler) === -1) handlers.push(handler);
  }
  function removeOnChange(handler) {
    handlers = handlers.filter((h) => h !== handler);
  }

  function getModalsMap() {
    return keyToModals;
  }

  return {
    open,
    close,
    onChange,
    removeOnChange,
    getModalsMap,
  };
}

export default new ModalService();
