import React from 'react';

type TSearchInput = {
  handleSearch: (e: any) => Promise<void>;
  isLoading: boolean;
  searchCount: number;
  searchIndex: number;
};

const SearchInput = React.forwardRef<HTMLInputElement, TSearchInput>(
  ({ handleSearch, isLoading, searchCount, searchIndex }, ref) => {
    const paddingValue = searchIndex && searchIndex.toString().length + searchCount.toString().length + 1;
    const paddingStyle = {
      paddingRight: `${2 * paddingValue * 4 + 3}px`,
    };

    return (
      <>
        <input
          ref={ref}
          type='input'
          className={`form-control rounded-start rounded-0`}
          placeholder='Search'
          onKeyDown={handleSearch}
          id='search'
          style={paddingStyle}
        />
        {isLoading ? (
          <span className='loader-search loader loader-primary loader-sm' />
        ) : searchCount > 0 ? (
          <span className='loader-search'>
            {searchIndex}/{searchCount}
          </span>
        ) : null}
      </>
    );
  }
);

export default SearchInput;
