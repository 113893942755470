import React from 'react';

import CustomModal from '../Common/CustomModal';

const FreezeLoadingModal = (props) => (
  <CustomModal open={props.isOpen} modalHandler={() => {}} classNames={'custom-modal-xsm'}>
    <div className='modal-content bg-transparent border-0'>
      <div className='d-flex align-items-center'>
        <div className='spinner-border text-primary documents-loader my-3' role='status'></div>
      </div>
    </div>
  </CustomModal>
);

export default FreezeLoadingModal;
