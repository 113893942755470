import React, { useEffect, useState } from 'react';
import { Widget, addResponseMessage, toggleMsgLoader, toggleInputDisabled } from 'react-chat-widget';
import { initByDocuemnt, newUserMessage, uploadPdfFile } from './services';
import { MELODY_AVATAR, WELCOME_MESSAGE } from './constants';

import 'react-chat-widget/lib/styles.css';
import './Chat.scss';
import { AxiosResponse } from 'axios';

type TChat = {
  documentSrc: string;
  documentId: string;
  documentName: string;
  userName: string;
  pdfFileLoad: Promise<AxiosResponse<any, any>>;
  chatApiUrl: string;
  isInitByDocument: boolean;
};

const Chat = ({ documentName, documentId, userName, pdfFileLoad, chatApiUrl, isInitByDocument }: TChat) => {
  const [initSrc, setInitSrc] = useState<string | null>(null);

  useEffect(() => {
    if (isInitByDocument) {
      initByDocuemnt({ chatApiUrl, documentId })
        .then((responce) => {
          // Remove id= until BE fix it
          setInitSrc(responce.data.replace('id=', '').trim());
        })
        .catch(() => {
          initFileUpload();
        });
    } else {
      initFileUpload();
    }
  }, [pdfFileLoad, addResponseMessage]);

  const initFileUpload = async () => {
    try {
      const pdfFileBlob = await pdfFileLoad.then((responce) => responce.data);
      await uploadPdfFile({ chatApiUrl, pdfFileBlob, documentName }).then((responce) => {
        // Remove id= until BE fix it
        setInitSrc(responce.data.replace('id=', '').trim());
      });
    } catch (error) {
      console.log(error);
      setInitSrc(null);
    }
  };

  useEffect(() => {
    initSrc && addResponseMessage(WELCOME_MESSAGE);
  }, [initSrc, addResponseMessage]);

  const handleNewUserMessage = (newMessage: string) => {
    toggleMsgLoader();
    toggleInputDisabled();

    if (initSrc) {
      newUserMessage({ chatApiUrl, newMessage, src: initSrc })
        .then((response) => {
          const message = response.data.replace('Result=', '').trim();
          addResponseMessage(message);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          toggleMsgLoader();
          toggleInputDisabled();
        });
    }
  };

  return (
    <div className='chat'>
      {initSrc && (
        <Widget
          profileAvatar={MELODY_AVATAR}
          title={`${userName} < > Melody`}
          subtitle={false}
          handleNewUserMessage={handleNewUserMessage}
        />
      )}
    </div>
  );
};

export default Chat;
