import { Action } from '../types';

export const SET_RULES = 'SET_RULES';

export interface User {
  _id: string;
  email: string;
  firstName: string;
  lastName: string;
  roles: Roles[];
}

export interface Roles {
  role: string;
  rules: Rules;
}

export interface Rights {
  [name: string]: boolean;
}

export interface Rule {
  [name: string]: Rights;
}

export interface Rules {
  [name: string]: Rule;
}

export interface SetRulesAction extends Action<Rules> {
  type: typeof SET_RULES;
  payload: Rules;
}

export type RolesAction = SetRulesAction;
