import React from 'react';
import { connect } from 'react-redux';
import { updateFieldTableType, updateTable } from '../../actions';
import $ from 'jquery';
import EditTableDialog from './EditTableDialog';

class TableFieldBody extends React.PureComponent {
  state = {
    openDialog: false,
    cellText: null,
    cellId: null,
  };

  render() {
    const field = this.props.field;
    const text = this.props.fields ? this.props.fields[field._id] === 'text' : false;
    let showTable = false;
    let tagId;

    let table;
    let tableData;

    // select first Table data and render it
    field.tags.forEach((tag) => {
      if ('table' in tag) {
        showTable = true;

        tableData = tag.table;
        tagId = tag._id;

        if (tableData.tbody) table = tableData;
      }
    });

    if (table) {
      return (
        <div className='text-center table-card'>
          {this.state.openDialog && (
            <EditTableDialog
              open={this.state.openDialog}
              onEdit={(text) => this.editCellData(text, tableData, tagId)}
              onCancel={() =>
                this.setState({
                  openDialog: false,
                  cellText: null,
                  cellId: null,
                })
              }
              text={this.state.cellText}
            />
          )}
          {showTable && text && this.renderTable(table, tagId, tableData)}
        </div>
      );
    } else {
      return (
        <div className='text-center table-card pt-4 pb-4'>
          <span className='loader loader-primary loader-sm' />
        </div>
      );
    }
  }

  componentWillUnmount() {
    $(`table tr td`).removeClass('highlight');
  }

  renderTable(table, tagId, tableData) {
    return (
      <div>
        <table className='sidebar-table' style={{ minWidth: table.thead.tr[0].th.length * 140 }}>
          <thead>
            {table.thead.tr[0].th.map((th, trIndex) => {
              const { id, style = {} } = th;
              return <th key={id} style={style}></th>;
            })}
          </thead>
          <tbody>
            {table.tbody.tr.map((tr, trIndex) => {
              return (
                <tr key={tr.id}>
                  {tr.td.map((tdData, tdIndex) => {
                    const { id, colspan = 1, rowspan = 1, value = '', style = {} } = tdData;
                    if (tdIndex === 0) {
                      return <td key={id} style={style}></td>;
                    }
                    return (
                      <td
                        key={id}
                        onClick={() => this.highlightCell(id, 'id' + tagId)}
                        title={value}
                        id={id}
                        colSpan={colspan}
                        rowSpan={rowspan}
                        style={{ ...style, height: 1 }}
                      >
                        {rowspan > 1 ? (
                          <input
                            className='form-control multiple w-100 h-100'
                            ref={id}
                            rows={rowspan}
                            defaultValue={value}
                            onChange={() => this.editCellData($(this.refs[id]).val(), tableData, tagId, id)}
                          />
                        ) : (
                          <input
                            className='form-control w-100 h-100'
                            ref={id}
                            rows={rowspan}
                            defaultValue={value}
                            onChange={() => this.editCellData($(this.refs[id]).val(), tableData, tagId, id)}
                          />
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }

  highlightCell(key, tableId) {
    key = key.split('-');
    if ($(`table#${tableId} tr:eq(${key[0]})`).find(`td:eq(${key[1]})`).hasClass('highlight')) {
      // $(`Table#${tableId} tr td`).removeClass('highlight');
    } else {
      $(`table#${tableId} tr td`).removeClass('highlight');
      $(`table#${tableId} tr:eq(${key[0]})`).find(`td:eq(${key[1]})`).addClass('highlight');
    }
  }

  editCellData(text, table, tagId, cellId) {
    this.setState({ openDialog: false, cellText: null, cellId: null });

    if (table) {
      trLoop: for (let trIndex = 0; trIndex < table.tbody.tr.length; trIndex++) {
        const tr = table.tbody.tr[trIndex];
        tdLoop: for (let tdIndex = 1; tdIndex < tr.td.length; tdIndex++) {
          const td = tr.td[tdIndex];
          if (td.id === cellId) {
            td.value = text;
            break trLoop;
          }
        }
      }
    }

    const img = $(`#img-${this.props.page}`);
    this.props.updateTable(this.props.field._id, tagId, this.props.page, table, img, 'graph');
  }
}

export default connect(null, { updateFieldTableType, updateTable })(TableFieldBody);
