import React, { MouseEventHandler, MutableRefObject, ReactNode } from 'react';
import { Button as KendoButton } from '@progress/kendo-react-buttons';
import iconsMap from '../Icons';
import { ButtonProps } from '@progress/kendo-react-buttons/dist/npm/Button';
import { openInNewTab } from '../../../helpers/common/utils';

interface IButtonProps extends ButtonProps {
  className?: string;
  children?: ReactNode | ReactNode[];
  icon?: keyof typeof iconsMap;
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
  variant?: string;
  ref?: MutableRefObject<KendoButton>;
  link?: string;
  iconClass?: string
}

const Button: React.FC<IButtonProps> = (props) => {
  const {
    children,
    type = 'button',
    className = '',
    icon,
    onClick: clickHandler,
    variant = 'primary',
    link,
      iconClass,
    ...rest
  } = props;

  const getIcon = () => {
    const Icon = icon && iconsMap[icon];
    return Icon ? <Icon className={iconClass}/> : null;
  };

  const handleClick: React.MouseEventHandler<HTMLButtonElement> | undefined = (e) => {
    return link ? openInNewTab(link) : typeof clickHandler === 'function' ? clickHandler(e) : false;
  };

  return (
    <KendoButton
      className={`${className} btn-${variant} ${icon ? 'icon-btn' : ''}`}
      onClick={handleClick}
      type={type}
      {...rest}
    >
      <>
        <i>{getIcon()}</i>
        {children}
      </>
    </KendoButton>
  );
};

export default Button;
