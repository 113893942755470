// by Rouben Meschian - rmeschian@gmail.com

import $ from 'jquery';
import { convertTableToPerc, updateCellIndexes } from '../TableUtils';
import { tableUtil } from './table-util';
import { createCell, createVResizer, createHResizer, createTableRow, createTable } from '../tableFactory';
import { clone } from '../utils';

// ===============================================================
// CREATE NEW FORMAT FROM OLD FORMAT

function readOldFormat(tableData) {
  const tableId = 'tableid';
  const fieldId = 'fieldid';

  const $div = $('<div>');
  const containerSize = { width: 1000, height: 1000 };

  tableUtil(
    $,
    tableId,
    tableData,
    () => {},
    () => {},
    tableData.y_left_top,
    tableData.x_left_top,
    fieldId,
    '#2525f2',
    1,
    true,
    true,
    $div,
    containerSize
  );

  // create v3
  const newTableData = clone(tableData);

  const newTable = createTable({
    style: {
      left: $div.find('table')[0].style.left,
      top: $div.find('table')[0].style.top,
      width: 'auto',
      height: 'auto',
    },
  });

  $div
    .find('tr')
    .first()
    .find('td:not(:first)') // first is the top left corner, for which the factory creates a th
    .toArray()
    .forEach((th) => {
      const resizer = createVResizer();
      resizer.style.width = th.style.width;
      newTable.thead.tr[0].th.push(resizer);
    });

  newTable.tbody.tr = $div
    .find('tr:not(:first)') // the first in the old Table was used as the header row
    .toArray()
    .map((tr, trIndex) => {
      const children = $(tr).find('td').toArray();

      return createTableRow({
        td: children.map((td, tdIndex) => {
          if (tdIndex === 0) {
            const resizer = createHResizer();
            resizer.style.height = td.style.height;
            return resizer;
          }

          return createCell({
            colspan: td.colSpan,
            rowspan: td.rowSpan,
          });
        }),
      });
    });

  newTableData.table = newTable;

  convertTableToPerc(newTableData.table, containerSize);
  updateCellIndexes(newTable);

  const numTotalColumns = newTable.thead.tr[0].th.length;
  newTable.tbody.tr.forEach((tr, trIndex) => {
    tr.td = tr.td.filter((td, tdIndex) => {
      return td.colIndex < numTotalColumns;
    });
  });

  if (newTableData.cells && newTableData.cells.data) {
    newTableData.cells.data.forEach((rowData, rowIdx) => {
      rowData.forEach((cellData, colIdx) => {
        newTableData.table.tbody.tr[rowIdx].td[colIdx + 1].value = cellData.text;
      });
    });
  }

  return newTableData;
}

export { readOldFormat };
