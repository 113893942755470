import { PayloadAction } from '@reduxjs/toolkit/src/createAction';

export const SEARCH_IN_DOCUMENT = 'SEARCH_IN_DOCUMENT';
export const EMPTY_SEARCH = 'EMPTY_SEARCH';
export const HIGHLIGHT_SEARCH_RESULT = 'HIGHLIGHT_SEARCH_RESULT';
export const SET_SEARCH_REGION = 'SET_SEARCH_REGION';

export interface ISearchLabelTag {
  h: number;
  w: number;
  x: number;
  y: number;
  page: number;
  text?: string;
}
export type TSearchLabel = ISearchLabelTag & {
  value: string;
  pageHeight: string;
  pageWidth: string;
};

export interface ISearchResult {
  labels: TSearchLabel[];
  text?: string;
  labelQnA?: ISearchLabelTag;
}
export type TRegionPixels = {
  x: number;
  y: number;
  w: number;
  h: number;
  widthScale: number;
  heightScale: number;
};

export interface Search extends PayloadAction<ISearchResult> {
  type: typeof SEARCH_IN_DOCUMENT;
  payload: ISearchResult;
}
export interface EmptySearch extends PayloadAction {
  type: typeof EMPTY_SEARCH;
}

export interface HighlightSearch extends PayloadAction<TSearchLabel> {
  type: typeof HIGHLIGHT_SEARCH_RESULT;
  payload: TSearchLabel;
}

export interface SetSearchRegion extends PayloadAction<TRegionPixels> {
  type: typeof SET_SEARCH_REGION;
  payload: TRegionPixels;
}

export type SearchAction = Search | EmptySearch | HighlightSearch | SetSearchRegion;
