import { DataStructure, Field } from '../datastructure/types';

export const UPDATE_TEMPLATE_STATUS = 'UPDATE_TEMPLATE_STATUS';
export const COPY_TEMPLATE_DATA = 'COPY_TEMPLATE_DATA';
export const SAVE_DOCUMENT_TEMPLATE_DATA = 'SAVE_DOCUMENT_TEMPLATE_DATA';
export const SET_DATA_LOST_DIALOG = 'SET_DATA_LOST_DIALOG';
export const RESET_DATA_LOST_DIALOG = 'RESET_DATA_LOST_DIALOG';
export const QUERY_DOCUMENTS_STATUSES = 'QUERY_DOCUMENTS_STATUSES';
export const SET_STEP_FINISH_TIMESTAMP = 'SET_STEP_FINISH_TIMESTAMP';
export const RESET_FIELD_SCALE_VALUE = 'RESET_FIELD_SCALE_VALUE';
export const SET_DOCUMENT_SMART_HOVER_TEMPLATES = 'SET_DOCUMENT_SMART_HOVER_TEMPLATES';
export const SET_ACTIVE_SMART_HOVER_TEMPLATE = 'SET_ACTIVE_SMART_HOVER_TEMPLATE';

export const QUERY_DOCUMENT = 'QUERY_DOCUMENT';
export const SELECT_FIELD = 'SELECT_FIELD';
export const UPDATE_DOCUMENT_FIELDS = 'UPDATE_DOCUMENT_FIELDS';
export const APPEND_DOCUMENT_FIELDS = 'APPEND_DOCUMENT_FIELDS';
export const APPEND_TAG = 'APPEND_TAG';
export const UPSERT_TAG = 'UPSERT_TAG';
export const UPSERT_TAGS = 'UPSERT_TAGS';
export const DELETE_TAG = 'DELETE_TAG';
export const SELECT_TAG = 'SELECT_TAG';
export const CHANGE_PAGE = 'CHANGE_PAGE';
export const UPSERT_DOCUMENT_FIELDS = 'UPSERT_DOCUMENT_FIELDS';
export const DELETE_FIELDS = 'DELETE_FIELDS';
export const APPLY_TEMPLATE = 'APPLY_TEMPLATE';
export const DETACH_TEMPLATE = 'DETACH_TEMPLATE';
export const UPDATE_VALUE = 'UPDATE_VALUE';
export const UPDATE_DOCUMENT_FIELDS_ORDER = 'UPDATE_DOCUMENT_FIELDS_ORDER';

export const TAGGING_EDITOR_STATE = 'TAGGING_EDITOR_STATE';
export const TOGGLE_TAGS_VISIBILITY = 'TOGGLE_TAGS_VISIBILITY';

export const SET_IS_SMART_TAG = 'SET_IS_SMART_TAG';
export const SET_ACTIVE_SMART_TAG = 'SET_ACTIVE_SMART_TAG';
export const SET_ORIGIN_SMART_TAGS = 'SET_ORIGIN_SMART_TAGS';
export const UPDATE_SMART_TAG_ORIGIN_TAG = 'UPDATE_SMART_TAG_ORIGIN_TAG';
export const SET_REFERENCE_SMART_TAGS = 'SET_REFERENCE_SMART_TAGS';
export const SET_PIXELPERFECT_OPTION_DISABLED = 'SET_PIXELPERFECT_OPTION_DISABLED';
export const CLEAR_SMART_TAGS = 'CLEAR_SMART_TAGS';

export const SHOW_LABELS = 'SHOW_LABELS';
export const CLEAR_LABELS = 'CLEAR_LABELS';
export const CLEAR_DOCUMENT = 'CLEAR_DOCUMENT';

export const REPEAT_DOCUMENT_FIELDS = 'REPEAT_DOCUMENT_FIELDS';
export const SELECT_TEMPLATE = 'SELECT_TEMPLATE';
export const UPDATE_TABLE = 'UPDATE_TABLE';
export const UPDATE_FIELD_TABLE_TYPE = 'UPDATE_FIELD_TABLE_TYPE';
export const DESELECT_FIELD = 'DESELECT_FIELD';
export const DATA_TRANSFER_STATUS = 'DATA_TRANSFER_STATUS';
export const QUERY_DOCUMENT_LABELS = 'QUERY_DOCUMENT_LABELS';
export const TAGS_BRING_TO_FRONT = 'TAGS_BRING_TO_FRONT';
export const TAGS_BRING_TO_BACK = 'TAGS_BRING_TO_BACK';
export const UNDO = 'UNDO';
export const REDO = 'REDO';
export const REFRESH_FIELD_COMPONENTS = 'REFRESH_FIELD_COMPONENTS';

export const SET_FIELD_SCALE = 'SET_FIELD_SCALE';
export const CLEAN_USER_DATA = 'CLEAN_USER_DATA';
export const SELECT_TEMP_FIELD = 'SELECT_TEMP_FIELD';
export const DESELECT_TEMP_FIELD = 'DESELECT_TEMP_FIELD';
export const APPEND_TEMP_TAG = 'APPEND_TEMP_TAG';
export const APPEND_FIELD_TO_TEMPLATE = 'APPEND_FIELD_TO_TEMPLATE';
export const RESET_ACTIVE_TEMPLATE = 'RESET_ACTIVE_TEMPLATE';

export interface DocumentDataStructure {
  name?: string;
  template: DataStructure;
  version: number;
  isFinished: boolean;
  activeFieldId?: string;
  activeTagId?: string;
  activePage: number;
  fields: Field[];
  activeTemplate: DataStructure,
  smartTags: DataStructure
}

export type DocumentItem = {
  collectionId: string;
  description: string;
  enableAppView: boolean;
  isLocked: boolean;
  name: string;
  src: string;
  status: string;
  timestamp: string;
  type: string;
  _id: string;
  numberOfPages: number;
};

export type TInsightField = {
  fieldType: string;
  dataType: string;
  required: boolean;
  multiple: boolean;
  repeatable: boolean;
  allowCustomClass: boolean;
  value: string;
  hasValidValue: boolean;
  order: number;
  useExtractedInComputation: boolean;
  _id: string;
  tags: [];
  mapping: [];
  group: string;
  groupKey: string;
  name: string;
  description: string;
  valueFormula: string;
  validationFormula: string;
  valueFormatter: string;
  classes: string;
  defaultValue: string;
  smartHover?: boolean;
};

export type TInsightFields = TInsightField[];

export type TInsight = {
  version: number;
  isLocked: boolean;
  _id: string;
  name: string;
  __v: number;
  fields: TInsightFields;
};

export type CollectionAppView = {
  _id: string;
  name: string;
  url: string;
};
