import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { upsertTag, changePage, updateTable, deSelectField, selectTag, deleteTag } from '../../../actions';
import 'jquery-ui/ui/widgets/droppable';
import '../../Tag/Tag.scss';
import '../../SmartTag/SmartTag.scss';

import ReactResizeDetector from 'react-resize-detector';
import './TaggingEditor.css';
import { getFields } from '../Browse/BrowseUtils';
import TextSpans from '../TextSpans';
import SmartTags from './Components/SmartTags';
import SectionTags from './Components/SectionTags';
import SearchTags from './Components/SearchTags';
import Tags from './Components/Tags';
import {
  _addDrawListener,
  clearDrawedRectangle,
  destroyDrawTool,
  droppableDisable,
  droppableEnable,
  initDrawTool,
  removeDrawListener,
} from './Components/DrawTools';
import {useAppSelector} from "../../../hooks/reduxHooks";
import {smartLabelSelector} from "../../../store/annotation/selectors";

const TaggingEditor = React.memo(function TaggingEditor({
  scaledWidth,
  scaledHeight,
  isSearchSelect,
  annotationPermission,
  activeFieldId,
  taggingEditorState,
  labels,
  activeTagId,
  smartTags,
  searchTags,
  selectedSearchTag,
  tagsBringedToBack,
  unselectedTagsNotVisible,
  activeTemplate,
  fields,

  filteredByCurrentPage,
  imageUrlBuilder,
  upsertTag,
  changePage,
  updateTable,
  deSelectField,
  selectTag,
  deleteTag,

  imgLoading,
  setImageLoading,

  curPageNum = 1, // page number of the current page
  fieldIdToTagsMap,
  haveActiveSmartTag,
  activeSmartHoverTemplate,
  activeField,
}) {
  const allowDrawing = !haveActiveSmartTag;

  const [resizeCount, setResizeCount] = useState(0);
  const [imageUrl, setImageUrl] = useState('');
  const $droppableRef = useRef();
  const droppableDomNodeRef = useRef();
  const pageToCanvasMapRef = useRef({});
  const pageToImgMapRef = useRef({});
  const onResize = useCallback(() => setResizeCount(resizeCount + 1), [resizeCount]);
  const finishImageLoading = useCallback(() => setImageLoading(false), []);


  const smartLabel = useAppSelector(smartLabelSelector)

  const addDrawListener = useCallback(
    ($droppable) => {
      if (!$droppable) return;
      removeDrawListener($droppable);
      _addDrawListener({
        scaledWidth,
        scaledHeight,
        isSearchSelect,
        activeFieldId,
        curPageNum,
        taggingEditorState,
        labels,

        activeTemplate,
        fields,

        filteredByCurrentPage,

        upsertTag,
        deSelectField,

        canvas: document.getElementById(`canvas-${curPageNum}`),
        $droppable,
        activeField,
      });
    },
    [
      scaledWidth,
      scaledHeight,
      isSearchSelect,
      activeFieldId,
      curPageNum,
      taggingEditorState,
      labels,
      activeTemplate,
      fields,
      filteredByCurrentPage,
      upsertTag,
      deSelectField,
      activeField,
    ]
  );

  const escClicked = useCallback(
    (evt) => {
      if (evt.keyCode !== 27) {
        return;
      }
      const canvas = pageToCanvasMapRef.current[`canvas-${curPageNum}`];
      clearDrawedRectangle(canvas);
    },
    [curPageNum]
  );

  const droppableDomNodeRefCurrent = droppableDomNodeRef.current;

  const $droppable = useMemo(() => {
    if ($droppableRef.current) {
      destroyDrawTool($droppableRef.current);
    }

    const $droppable = initDrawTool({
      taggingEditorState,
      labels,
      fields,
      upsertTag,
      curPageNum,
      droppableDomNode: droppableDomNodeRefCurrent,
    });

    $droppableRef.current = $droppable;
    return $droppable;
  }, [taggingEditorState, labels, fields, upsertTag, curPageNum, droppableDomNodeRefCurrent]);

  useEffect(() => {
    if (!$droppable) {
      return;
    }
    if (!allowDrawing) {
      droppableDisable($droppable);
      const canvas = pageToCanvasMapRef.current[`canvas-${curPageNum}`];
      removeDrawListener($droppable);
      clearDrawedRectangle(canvas);
    } else {
      droppableEnable($droppable);
      addDrawListener($droppable);
    }
    if (isSearchSelect) {
      addDrawListener($droppable);
    }
  }, [allowDrawing, addDrawListener, curPageNum, $droppable, isSearchSelect]);

  useEffect(() => {
    document.addEventListener('keydown', escClicked, false);
    return () => {
      document.removeEventListener('keydown', escClicked, false);
    };
  }, [escClicked]);

  useEffect(() => {
    return () => destroyDrawTool($droppableRef.current);
  }, []);

  useEffect(() => {
    const _imageUrl = imageUrlBuilder(curPageNum);

    if (imageUrl === _imageUrl) {
      return;
    }

    setImageLoading(true);
    setImageUrl(_imageUrl);
  }, [imageUrlBuilder]);

  const field = activeField || fields.find((f) => f._id === activeFieldId);

  return (
    <div
      style={{
        marginLeft: 'auto',
        marginRight: 'auto',
        width: scaledWidth,
        height: scaledHeight,
      }}
      className='zoom-target noselect'
    >
      <div
        className={`tagging-editor ${tagsBringedToBack ? '' : 'smart-tags-to-back'} ${smartLabel ? 'smart' : 'active'}-labels`}
        id={`tagging-editor-${curPageNum}`}
        ref={droppableDomNodeRef}
      >
        <canvas
          id={`canvas-${curPageNum}`}
          ref={(...args) => (pageToCanvasMapRef.current[`canvas-${curPageNum}`] = args[0])}
          className='canvas'
          style={{
            width: scaledWidth,
            height: scaledHeight,
          }}
        />
        <ReactResizeDetector handleWidth handleHeight onResize={onResize} />
        <img
          className='d-block w-100 page-img'
          ref={(...args) => (pageToImgMapRef.current[`img-${curPageNum}`] = args[0])}
          id={`img-${curPageNum}`}
          src={imageUrl}
          alt='slide'
          style={{
            width: scaledWidth,
            height: scaledHeight,
          }}
          onLoad={finishImageLoading}
        />
        {!!activeFieldId && field?.dataType === 'text-span' && (
          <TextSpans scaledWidth={scaledWidth} scaledHeight={scaledHeight} fieldId={activeFieldId} />
        )}
        {imgLoading ? null : (
          <>
            <SmartTags
              {...{
                scaledWidth,
                scaledHeight,
                smartTags,
                curPageNum,
                img: pageToImgMapRef.current[`img-${curPageNum}`],
              }}
            />

            <Tags
              {...{
                annotationPermission,
                activeTagId,
                unselectedTagsNotVisible,
                curPageNum,
                fieldIdToTagsMap,
                haveActiveSmartTag,
                updateTable,
                selectTag,
                deleteTag,
                resizeCount,
                activeSmartHoverTemplate,
              }}
            />
            <SectionTags
              {...{
                annotationPermission,
                activeTagId,
                unselectedTagsNotVisible,
                curPageNum,
                fieldIdToTagsMap,
                haveActiveSmartTag,
                updateTable,
                selectTag,
                resizeCount,
                fields,
              }}
            />
            <SearchTags
              searchTags={searchTags}
              curPageNum={curPageNum}
              changePage={changePage}
              selectedSearchTag={selectedSearchTag}
            />
          </>
        )}
      </div>
    </div>
  );
});

const mapStateToProps = (state) => {
  const { document = {}, search = {} } = state;

  let {
    activeFieldId,
    taggingEditorState,
    labels,
    activeTagId,
    smartTags,
    tagsBringedToBack,
    src,
    unselectedTagsNotVisible,
    activeSmartHoverTemplate,
    activeField,
    activeTemplate,
  } = document;

  let { selectedSearchTag, selectedSearchQnATag, searchTags } = search;

  const fields = getFields(document);

  if (activeTemplate) {
    activeTagId = activeTemplate.activeTagId;
    activeFieldId = activeTemplate.activeFieldId;
  }
  return {
    activeFieldId,
    taggingEditorState,
    labels,
    activeTagId,
    smartTags,
    searchTags,
    selectedSearchQnATag,
    selectedSearchTag,
    tagsBringedToBack,
    src,
    activeTemplate,
    fields,
    unselectedTagsNotVisible,
    tag: state.tag,
    activeSmartHoverTemplate,
    activeField,
  };
};

export default connect(mapStateToProps, {
  upsertTag,
  changePage,
  updateTable,
  deSelectField,
  selectTag,
  deleteTag,
})(TaggingEditor);
