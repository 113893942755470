import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { activeTagIdSelector } from '../../store/document/selectors';
import { selectTag, deleteTag, updateValue } from '../../actions';

import { useTagUI } from './useTagUI';
import { useAppDispatch } from '../../hooks/reduxHooks';
import Icons from "../Common/Icons";

const Tag = ({ haveActiveSmartTag, tag, fieldId, annotationPermission, section }) => {
  const dispatch = useAppDispatch();
  const [isInitedOnce, setIsInitedOnce] = useState(false);

  const tagElement = useRef();
  const activeTagId = useSelector(activeTagIdSelector);

  const setTagElementRef = useCallback(
    (element) => {
      tagElement.current = annotationPermission ? element : null;
    },
    [annotationPermission]
  );

  const {
    initTagUI,
    enable: enableTagUI,
    disable: disableTagUI,
    resizerGrids,
    tagClassNames,
    unresizeable,
  } = useTagUI({
    tagElement,
  });

  useEffect(() => {
    if (!isInitedOnce) {
      setIsInitedOnce(true);
      initTagUI({
        tag,
        fieldId,
      });
    }

    if (haveActiveSmartTag) {
      disableTagUI();
    }
  }, [disableTagUI, fieldId, tag, haveActiveSmartTag, initTagUI, isInitedOnce]);

  let clickTimeout;

  const handleClickTimeout = (event) => {
    clearTimeout(clickTimeout);
    clickTimeout = setTimeout(() => {
      if (event.detail === 2) {
        return handleDoubleClick(event);
      }
      handleClick();
    }, 200);
  };

  const handleClick = () => {
    if (tag.smartHover) return;
    dispatch(selectTag(fieldId, tag));
    //TODO: Opens window with tag info
    // openFieldModal({ message: 'message', title: 'title', fieldId: fieldId });
  };

  const handleDoubleClick = useCallback((e) => {
    e.stopPropagation();
    dispatch(deleteTag(fieldId, tag._id, false, true,true,tag));
    dispatch(updateValue(fieldId, null, '', false, false, 1));
  }, [dispatch, fieldId, tag]);

  const selectedTagInView = useCallback(() => {
    const { current: element } = tagElement;
    if (!element) {
      return;
    }

    setTimeout(() => {
      element.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
    });
  }, [tagElement]);

  const isActiveTag = useMemo(() => activeTagId === tag._id, [activeTagId, tag._id]);
  useEffect(() => {
    if (!isActiveTag) {
      return;
    }

    selectedTagInView();
  }, [isActiveTag, selectedTagInView]);

  const tagStyles = useMemo(
    () => ({
      top: tag.top,
      left: `${parseFloat(tag.left)-0.10}%`,
      height: tag.height,
      width: tag.width,
      background: `${tag.palette}40`,
      labelBackground: `${tag.palette}`,
      borderColor: tag.palette,
    }),
    [tag.top, tag.left, tag.height, tag.width, tag.palette]
  );

  useEffect(() => {
    if (isActiveTag && !haveActiveSmartTag) {
      enableTagUI();
      return;
    }

    disableTagUI();
  }, [isActiveTag, haveActiveSmartTag, enableTagUI, disableTagUI]);

  return (
    <div
      id={`tag-${tag._id}`}
      data-smarttag-id = {tag.smartTagId}
      className={`tag ${
        !tag.smartHover
          ? ` ${isActiveTag ? 'selected' : ''}
        ${tagClassNames}
        ${haveActiveSmartTag ? 'disable-all' : ''}`
          : 'smartHover'
      }          `}
      style={tagStyles}
      ref={setTagElementRef}
      onClick={handleClickTimeout}
    >
      <div className='tag-label' style={{borderColor:tagStyles.borderColor,background:tagStyles.labelBackground}}>
        {tag.tagTitle}
        <span className='btn btn-sm ms-2' onClick={handleDoubleClick}><Icons.Delete className='dark-color'/></span>
      </div>
      {section === 'start' && <span className='down-arrow'>&#x2B07;</span>}
      {!section ? resizerGrids : unresizeable}
      {section === 'end' && <span className='up-arrow'>&#x2B06;</span>}
      <button type='button' className='close' data-bs-dismiss aria-hidden='true'>
        ×
      </button>
    </div>
  );
};

export default Tag;
