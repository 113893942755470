import { PayloadAction } from '@reduxjs/toolkit/src/createAction';

export const SET_EMPTY_TAG = 'SET_EMPTY_TAG';

export interface IEmptyTag {
  id: string;
  tagInfo: TTagInfo;
  regionInPixels: TRegions;
}

type TRegions = {
  x: number;
  y: number;
  w: number;
  h: number;
  widthScale: number;
  heightScale: number;
};

type TTagInfo = {
  _id: string;
  left: string;
  top: string;
  height: string;
  width: string;
  smartTagValue: string;
  rowIndex: number;
  colIndex: number;
};

export interface SetEmptyTag extends PayloadAction<IEmptyTag> {
  type: typeof SET_EMPTY_TAG;
  payload: IEmptyTag;
}

export interface DeleteEmptyTag extends PayloadAction<null> {
  type: typeof SET_EMPTY_TAG;
  payload: null;
}
export type TagAction = SetEmptyTag | DeleteEmptyTag;
