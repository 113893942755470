import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import $ from 'jquery';

import 'jquery-ui/ui/widgets/draggable';
import 'jquery-ui/ui/widgets/resizable';
import 'jquery-ui/themes/base/resizable.css';

import { activePageSelector } from '../../store/document/selectors';
import { useTag } from './useTag';

export const useTagUI = ({ tagElement, smartTagMode = false }) => {
  const page = useSelector(activePageSelector);

  const { updateTag } = useTag({ smartTagMode });

  // Draggable
  const draggableEnable = useCallback(() => {
    if (!tagElement?.current) {
      return;
    }

    const $element = $(tagElement.current);
    if (!$element.length || typeof $element.draggable !== 'function') {
      return;
    }
    $element.draggable('enable');
  }, [tagElement]);

  const draggableDisable = useCallback(() => {
    if (!tagElement?.current) {
      return;
    }

    const $element = $(tagElement.current);
    if (!$element.length || typeof $element.draggable !== 'function') {
      return;
    }
    $element.draggable({ disabled: true });
  }, [tagElement]);

  // Resizable
  const resizableEnable = useCallback(() => {
    if (!tagElement?.current) {
      return;
    }

    const $element = $(tagElement.current);
    if (!$element.length || typeof $element.resizable !== 'function') {
      return;
    }
    $element.resizable('enable');
  }, [tagElement]);

  const resizableDisable = useCallback(() => {
    if (!tagElement?.current) {
      return;
    }

    const $element = $(tagElement.current);
    if (!$element.length || typeof $element.resizable !== 'function') {
      return;
    }
    $element.resizable({ disabled: true });
  }, [tagElement]);

  // Enable handlers
  const enable = useCallback(() => {
    draggableEnable();
    resizableEnable();
  }, [draggableEnable, resizableEnable]);

  // Disable handlers
  const disable = useCallback(() => {
    draggableDisable();
    resizableDisable();
  }, [draggableDisable, resizableDisable]);

  // Init
  const initTagUI = useCallback(
    ({ tag, fieldId }) => {
      if (!tagElement?.current) {
        return;
      }

      const img = $(`#img-${page}`);

      const $tag = $(tagElement.current);
      $tag
        .draggable({
          containment: 'parent',
          stop: (event, ui) => {
            const element = ui.helper[0];
            updateTag({
              tag,
              $tag,
              fieldId,
              element,
              img,
              page,
            });
          },
        })
        .resizable({
          stop: (event, ui) => {
            if (event.target.tagName.toLowerCase() !== 'div') {
              return;
            }
            const element = ui.element[0];
            updateTag({
              tag,
              $tag,
              fieldId,
              element,
              img,
              page,
              resizeMode: true,
            });
          },
          handles: {
            nw: '.nwgrip',
            ne: '.negrip',
            sw: '.swgrip',
            se: '.segrip',
            n: '.ngrip',
            e: '.egrip',
            s: '.sgrip',
            w: '.wgrip',
          },
        });
    },
    [page, tagElement, updateTag]
  );

  const destroy = useCallback(() => {
    if (!tagElement?.current) {
      return;
    }

    const $tag = $(tagElement.current);

    $tag.draggable('destroy');
    $tag.resizable('destroy');
  }, [tagElement]);

  const resizerGrids = useMemo(
    () => (
      <>
        <div className='ui-resizable-handle ui-resizable-nw nwgrip' />
        <div className='ui-resizable-handle ui-resizable-ne negrip' />
        <div className='ui-resizable-handle ui-resizable-sw swgrip' />
        <div className='ui-resizable-handle ui-resizable-se segrip' />

        <div className='ui-resizable-handle ui-resizable-n ngrip' />
        <div className='ui-resizable-handle ui-resizable-s sgrip' />
        <div className='ui-resizable-handle ui-resizable-e egrip' />
        <div className='ui-resizable-handle ui-resizable-w wgrip' />
      </>
    ),
    []
  );

  const unresizeable = useMemo(
    () => (
      <>
        <div className='nwgrip' />
        <div className='negrip' />
        <div className='swgrip' />
        <div className='segrip' />

        <div className='ngrip' />
        <div className='sgrip' />
        <div className='egrip' />
        <div className='wgrip' />
      </>
    ),
    []
  );

  const tagClassNames = 'ui-draggable ui-draggable-handle ui-resizable';

  return {
    initTagUI,
    draggableEnable,
    draggableDisable,
    enable,
    resizableEnable,
    resizableDisable,
    disable,
    resizerGrids,
    unresizeable,
    tagClassNames,
    destroy,
  };
};
