import { PayloadAction } from '@reduxjs/toolkit/src/createAction';
import { DataStructure } from '../datastructure/types';

export const QUERY_TEMPLATES = 'QUERY_TEMPLATES';

export interface QueryTemplatesByIds extends PayloadAction<DataStructure[]> {
  type: typeof QUERY_TEMPLATES;
  payload: DataStructure[];
}

export type TemplatesAction = QueryTemplatesByIds;
