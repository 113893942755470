import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  activeTemplateIdSelector,
  documentCurrentWorkflowStepSelector,
  workflowStepSelectorByTemplateId,
} from '../../../store/document/selectors';
import {
  openConfirmDialog,
  openSelectDocumentTemplate,
  openSelectAlternativeMicrotaskModal,
} from '../../modal/service/ModalControllers';

import './WorkflowTemplateFlow.scss';

const WorkflowTemplateFlow = ({ onClose, finishAction, changeTemplate }) => {
  const [protocolStep, setProtocolStep] = useState('hasAlternativeMicrotasks');
  const [completedMicrotask, setCompletedMicrotask] = useState(null);

  const templateId = useSelector(activeTemplateIdSelector);
  const currentWorkflowStep = useSelector(documentCurrentWorkflowStepSelector);

  const currentStepId = currentWorkflowStep?.step;

  const stepByTemplateId = useSelector(workflowStepSelectorByTemplateId(templateId));
  const stepForkByTemplateId =
    stepByTemplateId?.forkedTo && stepByTemplateId[stepByTemplateId.forkedTo]
      ? stepByTemplateId[stepByTemplateId.forkedTo]
      : undefined;

  const stepTemplates = useMemo(() => stepForkByTemplateId?.templates || [], [stepForkByTemplateId]);

  const stepTemplate = useMemo(
    () => stepTemplates?.find((t) => t.template === templateId),
    [stepTemplates, templateId]
  );

  const notWorkedStepTemplates = useMemo(
    () =>
      stepTemplates
        .filter((t) => t.finishTimestamp === null && t.template.toString() !== templateId.toString())
        .map((t) => t.template),
    [stepTemplates, templateId]
  );

  const templateStepId = stepByTemplateId?.step;

  const hasAlternativeMicrotasks = stepForkByTemplateId?.alternativeMicrotasks?.length;

  const flowProtocol = useMemo(
    () => ({
      hasAlternativeMicrotasks: {
        type: 'codeCheck',
        check: () => hasAlternativeMicrotasks,
        options: {
          true: {
            target: 'showAlternativeMicrotasks',
          },
          false: {
            target: 'microtaskConfirmation',
          },
        },
      },

      showAlternativeMicrotasks: {
        type: 'userSelectOne',
        render: (onAnyResponse) => {
          openSelectAlternativeMicrotaskModal({
            microtaskId: stepTemplate?.microtask?.defined,
            alternativeMicrotasks: stepForkByTemplateId?.alternativeMicrotasks || [],
            onSubmit: (microtask) => {
              setCompletedMicrotask(microtask);
              onAnyResponse(!microtask ? 'close' : '');
            },
          });
        },
        options: {
          any: {
            target: 'microtaskConfirmation',
          },
        },
      },

      microtaskConfirmation: {
        type: 'userConfirmation',
        render: (onResponse) =>
          openConfirmDialog({
            key: 'microtaskConfirmation',
            label: '',
            message: `I solemnly promise that this assigned task has been completed with my very best effort exactly according to the description. I strive for excellence and make sure the job is done in a way that most accurately reflects my knowledge. With utmost candidness, I can confidently say that this task has been finished to the very highest standard. My diligence and attention to detail have allowed successful execution of all requirements. As such, I firmly attest: This mission has been satisfactorily accomplished according to what was requested of me.`,
            disputeBtnLabel: 'No',
            disputeMode: 'white',
            confirmBtnLabel: 'Yes',
            mode: 'success',
            classNames: {
              title: 'workflow-template-flow-modal-empty-title',
            },
            onResponse: (state, source) => {
              if (source === 'close') {
                onClose();
                return;
              }

              onResponse(state);
            },
          }),
        options: {
          true: {
            target: 'isAllTemplatesFinished',
          },
          false: {
            target: 'close',
          },
        },
      },

      isAllTemplatesFinished: {
        type: 'codeCheck',
        check: () => {
          return !notWorkedStepTemplates.length;
        },
        options: {
          true: {
            target: 'triggerWorkflowNextStepIfHas',
          },
          false: {
            target: 'showCurrentMicrotaskInsights',
          },
        },
      },

      showCurrentMicrotaskInsights: {
        type: 'userSelectOne',
        render: (onAnyResponse) => {
          openSelectDocumentTemplate({
            templateIds: notWorkedStepTemplates,
            onSubmit: (documentTemplateObjId) => {
              onAnyResponse(!documentTemplateObjId ? 'close' : 'blank');

              finishAction({ completedMicrotask }, () => {
                if (documentTemplateObjId) {
                  changeTemplate(documentTemplateObjId);
                }
                onClose(true);
              });
            },
          });
        },
        options: {
          any: {
            target: 'blank',
          },
        },
      },

      triggerWorkflowNextStepIfHas: {
        type: 'flowCloseAction',
        eval: () => {
          const workflowChanges = { completedMicrotask };
          finishAction(workflowChanges);

          onClose(true);
        },
      },

      blank: {
        type: 'blank',
      },

      close: {
        type: 'flowCloseAction',
        eval: () => {
          onClose();
        },
      },
    }),
    [templateStepId, currentStepId, onClose]
  );

  const runProtocol = useCallback(
    (protocolStep) => {
      const protocol = flowProtocol[protocolStep];

      if (!protocol) {
        console.log(`Can not find protocol with key:${protocolStep}`);
        return;
      }

      const changeProtocolStep = (protocolOptions, resultKey) => {
        const { target } = protocolOptions[resultKey] || { target: '' };

        if (!target) {
          if (resultKey === 'close') {
            setProtocolStep(resultKey);
            return;
          }

          console.log(
            `Can not find new target in protocol with resultKey:${resultKey} in protocolOptions:${protocolOptions}`
          ); // If was here that was a bug
          return;
        }
        setProtocolStep(target);
      };

      switch (protocol.type) {
        case 'codeCheck':
          const result = protocol.check();
          changeProtocolStep(protocol.options, result ? 'true' : 'false');
          return;
        case 'userConfirmation':
          protocol.render((result) => {
            changeProtocolStep(protocol.options, result ? 'true' : 'false');
          });
          return;
        case 'userSelectOne':
          protocol.render((key) => {
            changeProtocolStep(protocol.options, key === 'close' ? key : 'any');
          });
          return;
        case 'flowCloseAction':
          protocol.eval();
          return;
        case 'blank':
          return;
        default:
      }
    },
    [flowProtocol]
  );

  useEffect(() => {
    if (!stepByTemplateId || stepForkByTemplateId?.isDone || !stepTemplate || stepTemplate.finishTimestamp) {
      finishAction();
      onClose();
      return;
    }

    runProtocol(protocolStep);
  }, [protocolStep, stepByTemplateId, stepForkByTemplateId, stepTemplate]);

  return '';
};

export default WorkflowTemplateFlow;
