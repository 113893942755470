import React from 'react';
import './Search.scss';

class SearchTag extends React.Component {
  componentDidMount() {
    const { active, index, searchTag } = this.props;
    if (active) {
      const elem = document.getElementById(`search-${index}`);
      if (elem) {
        this.props.changeSearchPage(searchTag.page - 1);
        elem.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
      }
    }
  }

  scrollIntoView() {
    const { index } = this.props;
    const elem = document.getElementById(`search-${index}`);
    if (elem) {
      elem.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
    }
  }

  render() {
    const { searchTag, active, index } = this.props;
    if (active) {
      this.scrollIntoView();
    }
    if (searchTag.search) {
      return (
        <div key={`search-merge-${index}`} id={`search-merge-${index}`}>
          {searchTag.search.map((search, i) => (
            <div
              key={`search-merge-${index}-${i}`}
              className={active ? 'search-highlight' : 'search-item'}
              style={{
                top: search.y + '%',
                left: search.x + '%',
                height: search.h + '%',
                width: search.w + '%',
              }}
            ></div>
          ))}
        </div>
      );
    } else {
      return (
        <div
          id={`search-${index}`}
          className={active ? 'search-highlight' : 'search-item'}
          style={{
            top: searchTag.y + '%',
            left: searchTag.x + '%',
            height: searchTag.h + '%',
            width: searchTag.w + '%',
          }}
        ></div>
      );
    }
  }
}

export default SearchTag;
