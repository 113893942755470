// by Rouben Meschian - rmeschian@gmail.com

function guid() {
  let d = new Date().getTime();
  return 'guid_xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === 'x' ? r : (r & 0x7) | 0x8).toString(16);
  });
}

function clone(data) {
  return JSON.parse(JSON.stringify(data));
}

const string = 'string';
function isString(val) {
  return typeof val === string;
}

export { guid, clone, isString };
