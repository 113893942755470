import innolytiqApi from '../api/innolytiqApi';

const currentAndPreviousFieldIds = [
  '616996dc892315eb7dbf0072',
  '616996dc892315eb7dbf0074',
  '616996de892315eb7dbf0078',
  '616996de892315eb7dbf007a',
  '616996f5892315eb7dbf00e0',
  '616996f6892315eb7dbf00e2',
  '61699724892315eb7dbf01ae',
  '61699724892315eb7dbf01b0',
  '6169973a892315eb7dbf0210',
  '6169973b892315eb7dbf0212',
  '61699723892315eb7dbf01a8',
  '61699723892315eb7dbf01aa',
  '6169974f892315eb7dbf026c',
  '61699750892315eb7dbf026e',
  '61699721892315eb7dbf01a2',
  '61699721892315eb7dbf01a4',
  '61699764892315eb7dbf02c8',
  '61699765892315eb7dbf02ca',
  '61699762892315eb7dbf02bc',
  '61699762892315eb7dbf02be',
  '61699760892315eb7dbf02b6',
  '61699761892315eb7dbf02b8',
  '6169975c892315eb7dbf02a4',
  '6169975d892315eb7dbf02a6',
  '616997de892315eb7dbf04cc',
  '616997de892315eb7dbf04ce',
  '6169981f892315eb7dbf05ec',
  '6169981f892315eb7dbf05ee',
  '61699838892315eb7dbf064c',
  '61699838892315eb7dbf064e',
  '616997dc892315eb7dbf04c6',
  '616997dd892315eb7dbf04c8',
];

const insightFieldsToValidate = [
  '616996dbccdae52d79700333',
  '616996f4ccdae52d79700822',
  '61699739ccdae52d79701585',
  '6169974dccdae52d797019d1',
  '61699720ccdae52d79701080',
  '6169975bccdae52d79701ce0',
  '616997dbccdae52d79703534',
  '634555555efa572d40972daa',
];

const totalAssetsTemplateId = '616996dbccdae52d79700333';
const totalAssetsCurrent = '616996dc892315eb7dbf0072';
const totalAssetsPrevious = '616996dc892315eb7dbf0074';

const totalLiabsTemplateId = '61699720ccdae52d79701080';
const totalLiabsCurrent = '61699721892315eb7dbf01a2';
const totalLiabsPrevious = '61699721892315eb7dbf01a4';

/**
 * Validate specified templates for running data transfer.
 *
 * @param {*} id: document id to validate data.
 */
export const validateDataTransfer = async (id) => {
  try {
    const response = await innolytiqApi.get(`documents/${id}/includeinsights`);

    if (response) {
      const document = response.data;
      if (document.templates && document.templates.length > 0) {
        const totalAssetsTemplate = document.templates.find((item) => {
          return item.template._id.toString() === totalAssetsTemplateId;
        });
        const totalLiabsTemplate = document.templates.find((item) => {
          return item.template._id.toString() === totalLiabsTemplateId;
        });

        const assetsCurrentField = totalAssetsTemplate?.fields.find(
          (field) => field._id.toString() === totalAssetsCurrent
        );
        const assetsPreviousField = totalAssetsTemplate?.fields.find(
          (field) => field._id.toString() === totalAssetsPrevious
        );
        const liabsCurrentField = totalLiabsTemplate?.fields.find(
          (field) => field._id.toString() === totalLiabsCurrent
        );
        const liabsPreviousField = totalLiabsTemplate?.fields.find(
          (field) => field._id.toString() === totalLiabsPrevious
        );

        if (
          !(
            assetsCurrentField?.value === liabsCurrentField?.value &&
            assetsPreviousField?.value === liabsPreviousField?.value
          )
        ) {
          return { type: 'difference', value: 'Difference found in calculations.' };
        }

        let notValidatedFields = [];

        insightFieldsToValidate.forEach((id) => {
          const template = document.templates.find((item) => {
            return item.template._id.toString() === id;
          });
          if (template && template.fields.length > 0) {
            notValidatedFields = template.fields.filter((field) => {
              return (
                currentAndPreviousFieldIds.includes(field.srcFieldId) &&
                field.fieldType === 'extraction and computation' &&
                !extractedAndComputedValuesEqual(field)
              );
            });
          }
        });

        if (notValidatedFields.length > 0) {
          return { type: 'fields', value: notValidatedFields };
        }
      }
    }
    return false;
  } catch (error) {
    console.log(error, 'validateDataTransfer error');
    return {};
  }
};

const extractedAndComputedValuesEqual = (field) => {
  if (field.tags.length > 0) {
    return field.tags[0].value === field.value;
  }
};
