const idToTimeout = {};
const idToFunction = {};

const runFunction = (id) => {
  const f = idToFunction[id];
  delete idToTimeout[id];
  delete idToFunction[id];
  f();
};

export const withDelay = (id, f, delay) => {
  const timeout = idToTimeout[id];
  if (timeout) {
    clearTimeout(timeout);
  }
  idToFunction[id] = f;
  idToTimeout[id] = setTimeout(() => {
    runFunction(id);
  }, delay);
};
