export const CLEAR_DOCUMENTS = 'CLEAR_DOCUMENTS';
export const QUERY_DOCUMENT = 'QUERY_DOCUMENT';
export const SELECT_FIELD = 'SELECT_FIELD';
export const UPDATE_DOCUMENT_FIELDS = 'UPDATE_DOCUMENT_FIELDS';
export const APPEND_DOCUMENT_FIELDS = 'APPEND_DOCUMENT_FIELDS';
export const APPEND_TAG = 'APPEND_TAG';
export const UPSERT_TAG = 'UPSERT_TAG';
export const DELETE_TAG = 'DELETE_TAG';
export const SELECT_TAG = 'SELECT_TAG';
export const CHANGE_PAGE = 'CHANGE_PAGE';
export const QUERY_TEMPLATES = 'QUERY_TEMPLATES';
export const UPSERT_DOCUMENT_FIELDS = 'UPSERT_DOCUMENT_FIELDS';
export const DELETE_FIELDS = 'DELETE_FIELDS';
export const UPDATE_VALUE = 'UPDATE_VALUE';
export const UPDATE_DOCUMENT_FIELDS_ORDER = 'UPDATE_DOCUMENT_FIELDS_ORDER';

export const TAGGING_EDITOR_STATE = 'TAGGING_EDITOR_STATE';
export const TOGGLE_TAGS_VISIBILITY = 'TOGGLE_TAGS_VISIBILITY';

export const SET_IS_SMART_TAG = 'SET_IS_SMART_TAG';
export const SET_ACTIVE_SMART_TAG = 'SET_ACTIVE_SMART_TAG';
export const SET_ORIGIN_SMART_TAGS = 'SET_ORIGIN_SMART_TAGS';
export const UPDATE_SMART_TAG_ORIGIN_TAG = 'UPDATE_SMART_TAG_ORIGIN_TAG';
export const SET_REFERENCE_SMART_TAGS = 'SET_REFERENCE_SMART_TAGS';
export const SET_PIXELPERFECT_OPTION_DISABLED = 'SET_PIXELPERFECT_OPTION_DISABLED';
export const CLEAR_SMART_TAGS = 'CLEAR_SMART_TAGS';

export const SHOW_LABELS = 'SHOW_LABELS';
export const CLEAR_LABELS = 'CLEAR_LABELS';
export const CLEAR_DOCUMENT = 'CLEAR_DOCUMENT';
export const UPSERT_TEMPLATE_FIELDS = 'UPSERT_TEMPLATE_FIELDS';

export const REPEAT_DOCUMENT_FIELDS = 'REPEAT_DOCUMENT_FIELDS';
export const QUERY_TEMPLATE = 'QUERY_TEMPLATE';
export const SELECT_TEMPLATE = 'SELECT_TEMPLATE';
export const QUERY_DATA_RECIPE = 'QUERY_DATA_RECIPE';
export const CLEAN_DATA_RECIPE = 'CLEAN_DATA_RECIPE';
export const CLEAN_COLLECIONS = 'CLEAN_COLLECIONS';
export const UPDATE_FIELD_TABLE_TYPE = 'UPDATE_FIELD_TABLE_TYPE';
export const DESELECT_FIELD = 'DESELECT_FIELD';
export const DATA_TRANSFER_STATUS = 'DATA_TRANSFER_STATUS';
export const QUERY_DOCUMENT_LABELS = 'QUERY_DOCUMENT_LABELS';
export const TAGS_BRING_TO_FRONT = 'TAGS_BRING_TO_FRONT';
export const TAGS_BRING_TO_BACK = 'TAGS_BRING_TO_BACK';
export const ADD_CHECKPOINT = 'ADD_CHECKPOINT';
export const RESET_CHECKPOINT = 'RESET_CHECKPOINT';
export const REINITIALIZE_CHECKPOINT = 'REINITIALIZE_CHECKPOINT';
export const REFRESH_FIELD_COMPONENTS = 'REFRESH_FIELD_COMPONENTS';

export const SET_FIELD_SCALE = 'SET_FIELD_SCALE';
export const CLEAN_USER_DATA = 'CLEAN_USER_DATA';
