import { Dispatch } from 'redux';
import {
  EMPTY_SEARCH,
  HIGHLIGHT_SEARCH_RESULT,
  ISearchResult,
  SEARCH_IN_DOCUMENT,
  SearchAction,
  SET_SEARCH_REGION,
  TRegionPixels,
  TSearchLabel,
} from './types';
import innolytiqApi from '../../api/innolytiqApi';
import { store } from '../store';

export const searchInDocument =
  ({ labels, text, labelQnA }: ISearchResult) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch<SearchAction>({
        type: SEARCH_IN_DOCUMENT,
        payload: {
          labels,
          text,
          labelQnA,
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

export const search =
  (documentId: string, searchText: string | undefined, regionInPixels?: TRegionPixels) =>
  async (dispatch: Dispatch) => {
    const response = await innolytiqApi.post(`documents/search/${documentId}`, {
      searchText,
      regionInPixels,
    });
    if (response.data) dispatch({ type: SEARCH_IN_DOCUMENT, payload: response.data });
  };

export const emptySearch = () => async (dispatch: Dispatch) => {
  dispatch({ type: EMPTY_SEARCH });
};

export const highlightSearchResult = (search: TSearchLabel, goToPage: Function) => async (dispatch: Dispatch) => {
  dispatch({ type: HIGHLIGHT_SEARCH_RESULT, payload: search });
  if (goToPage) {
    goToPage(search.page - 1);
  }
};

export const setSearchRegion = (region: TRegionPixels) => {
  store.dispatch({
    type: SET_SEARCH_REGION,
    payload: region,
  });
};
