import { Action } from '../types';

export const QUERY_USER = 'QUERY_USER';
export const UPSERT_USER = 'UPSERT_USER';

export const CLEAN_USER_DATA = 'CLEAN_USER_DATA';

export interface IUser {
  _id: string;
  email: string;
  firstName: string;
  lastName: string;
  roles: IRole[];
}
interface IRole {
  _id: string;
  role: string;
  rules: IRules;
}
interface IRules {
  [key: string]: Record<string, string>;
}

export interface QueryUser extends Action<IUser['_id']> {
  type: typeof QUERY_USER;
}

export interface UpsertUser extends Action<IUser> {
  type: typeof UPSERT_USER;
}

export interface CleanUserData extends Action {
  type: typeof CLEAN_USER_DATA;
}

export type UserAction = QueryUser | UpsertUser | CleanUserData;
