import numeral from 'numeral';
import { getValidValue, isNumber } from './utils';

/**
 * Format number.
 */
export const formatNumber = (value, format = '0,0.00') => {
  try {
    if (isNumber(value)) {
      return numeral(value).format(format);
    } else {
      return '0.00';
    }
  } catch (e) {}
};

/**
 * Parse value using metadata then format.
 */
export const parseAndFormatNumber = (value, { thousandsSeparator, negativeSign, decimalPoint }) => {
  const parsedValue = getValidValue(value, thousandsSeparator, negativeSign, true, decimalPoint, false, null);
  return formatNumber(parsedValue);
};
