import { createSelector } from 'reselect';
import { getCurrentWorkflow, getWorkflowCurrentStep, getWorkflowStepByTemplateId } from './utils';
import { CollectionAppView } from './types';

const stateSelector = (state: any) => state['document'];

export const documentSelector = createSelector(stateSelector, (value) => (value && value.toJS ? value.toJS() : value));

export const documentTemplatesSelector = createSelector(stateSelector, (value) => {
  if (!value) {
    return null;
  }

  if (typeof value.get === 'function') {
    return value.get('templates').toJS();
  }

  return value.templates ?? [];
});

export const documentIdSelector = createSelector(stateSelector, (value) => {
  if (!value) {
    return '';
  }

  if (typeof value.get === 'function') {
    return value.get('_id').toJS();
  }

  return value._id || '';
});

export const taggingEditorStateSelector = createSelector(stateSelector, (value) => {
  if (!value) {
    return '';
  }

  if (typeof value.get === 'function') {
    return value.get('taggingEditorState').toJS();
  }

  return value.taggingEditorState || '';
});

export const documentLabelsSelector = createSelector(stateSelector, (value) => value?.labels);

export const smartTagsActiveTagHashSelector = createSelector(
  stateSelector,
  (value) => value?.smartTags?.activeTag?.hash
);

export const activePageSelector = createSelector(stateSelector, (value) => value?.activePage || 1);

export const activeTemplateIdSelector = createSelector(
  stateSelector,
  (document) => document.activeTemplate.template?._id
);

export const documentCurrentWorkflowSelector = createSelector(stateSelector, getCurrentWorkflow);

export const documentCurrentWorkflowStepSelector = createSelector(
  documentCurrentWorkflowSelector,
  getWorkflowCurrentStep
);

export const microtaskFormulasSelector = createSelector(
  activeTemplateIdSelector,
  documentCurrentWorkflowSelector,
  (templateId, documentWorkflow) => {
    if (!documentWorkflow || !templateId) {
      return [];
    }
    const stepByTemplateId: any = getWorkflowStepByTemplateId(documentWorkflow, templateId);
    if (!stepByTemplateId) {
      return [];
    }
    const { forkedTo } = stepByTemplateId;
    const stepForkByTemplateId = forkedTo && stepByTemplateId[forkedTo] ? stepByTemplateId[forkedTo] : undefined;

    return stepForkByTemplateId?.microtaskFormulas || [];
  }
);

export const workflowStepSelectorByTemplateId = (templateId: string) =>
  createSelector(documentCurrentWorkflowSelector, (documentWorkflow: any) =>
    getWorkflowStepByTemplateId(documentWorkflow, templateId)
  );

export const metadataSelector = createSelector(stateSelector, (value) => value?.metadata);

export const activeFieldIdSelector = createSelector(documentSelector, (document) => {
  if (!document) {
    return null;
  }

  // TODO: Need to define document reducer return type, after can remove "@ts-ignore"
  // @ts-ignore
  // const activeTemplate = templates.find(({ _id }) => _id === document.activeTemplateId);
  const activeTemplate = document.activeTemplate;
  return activeTemplate ? activeTemplate?.activeFieldId : null;
});

/**
 * Select all labels.
 */
export const pageLabelsSelector = createSelector(documentSelector, (document) => {
  return document.pageLabels;
});

/**
 * Select active template fields.
 */
export const selectActiveTemplateFields = createSelector(documentSelector, (document) => {
  // const { activeTemplateId, templates } = document;
  //
  // if (!activeTemplateId) {
  //   return [];
  // }

  // TODO: Need to define document reducer return type, after can remove "@ts-ignore"
  // @ts-ignore
  // const activeTemplate = templates.find(({ _id }) => _id === activeTemplateId);

  return document?.activeTemplate?.fields || [];
});

export const currentPageLabelsSelector = createSelector(documentSelector, (document) => {
  const { pageLabels, activePage } = document;

  return activePage in pageLabels ? pageLabels[activePage] : [];
});

export const activeFieldSiblingsSelectorByGroup = createSelector(documentSelector, (document) => {
  if (!document) {
    return [];
  }

  const { activeTemplateId, templates } = document;
  let { activeFieldId } = document;

  if (!activeTemplateId) {
    return [];
  }

  // TODO: Need to define document reducer return type, after can remove "@ts-ignore"
  // @ts-ignore
  const activeTemplate = templates.find(({ _id }) => _id === activeTemplateId);
  activeFieldId = activeTemplate ? activeTemplate.activeFieldId : null;

  if (!activeFieldId) {
    return [];
  }

  // @ts-ignore
  const groupKey = activeTemplate.fields.find((f) => f._id === activeFieldId).groupKey;

  // @ts-ignore
  return [...activeTemplate.fields.filter((f) => f.groupKey === groupKey)];
});

export const activeTagIdSelector = createSelector(documentSelector, (document) => {
  if (!document) {
    return null;
  }

  // const { activeTagId: tagId } = document;
  //
  let activeTagId = null;

  if (document.activeTemplate) {
    // TODO: Need to define document reducer return type, after can remove "@ts-ignore"
    // @ts-ignore
    //const activeTemplate = templates.find(({ _id }) => _id === document.activeTemplateId);
    activeTagId = document.activeTemplate ? document.activeTemplate.activeTagId : null;
  }

  return activeTagId;
});

export const documentSmartHoverTemplatesSelector = createSelector(stateSelector, (document) => {
  return document.smartHoverTemplates;
});

export const documentActiveSmartHoverTemplateId = createSelector(stateSelector, (document) => {
  return document?.activeSmartHoverTemplate?._id;
});

export const documentSrcSelector = createSelector(stateSelector, (document) => {
  return document?.src;
});

export const documentNameSelector = createSelector(stateSelector, (document) => {
  return document?.name;
});

export const documentActiveFieldSelector = createSelector(stateSelector, (document) => {
  return document?.activeField;
});

export const templateOriginFieldsSelector = createSelector(stateSelector, (document) => {
  return document?.originFields;
});

export const templateFieldsSelector = createSelector(stateSelector, (document) => {
  return document?.activeTemplate?.fields;
});

export const activeTemplateSelector = createSelector(stateSelector, (document) => {
  return document?.activeTemplate;
});

export const palettesSelector = createSelector(stateSelector, (document) => {
  return document?.palettes;
});

export const saveBtnActiveSelector = createSelector(stateSelector, (value) => value?.saveBtnActive);

export const documentTypeSelector = createSelector(stateSelector, (value) => value?.type);

export const siblingApplicationsSelector = createSelector(
  stateSelector,
  (value) => value?.applications || ([] as CollectionAppView[])
);

export const siblingAppViewsSelector = createSelector(
  stateSelector,
  (value) => value?.collectionId?.appViews || ([] as CollectionAppView[])
);

export const smartTagsSelector = createSelector(stateSelector, (value) => value?.smartTags);
