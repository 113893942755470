import React from 'react';
import Tag from '../../../Tag/Tag';

interface ISectionTagsProps {
  annotationPermission: boolean;
  curPageNum: number;
  haveActiveSmartTag: boolean;
  fields: any[];
}

const SectionTags = ({ annotationPermission, curPageNum, haveActiveSmartTag, fields }: ISectionTagsProps) => {
  const pageSections: any[] = [];
  fields.forEach((field) => {
    if (field.dataType === 'section') {
      if (field.tags && field.tags.length > 0) {
        field.tags.forEach((t: any) => {
          if (t.section) {
            Object.keys(t.section).forEach((key) => {
              if (t.section[key] && t.section[key].page === curPageNum) {
                pageSections.push({ tag: t.section[key], section: key });
              }
            });
          }
        });
      }
    }
  });
  return (
    <>
      {pageSections.map(({ tag, section }, index) => (
        <Tag
          key={`${tag._id}_${index}`}
          fieldId={tag.fieldId}
          section={section}
          tag={tag}
          annotationPermission={annotationPermission}
          haveActiveSmartTag={haveActiveSmartTag}
        />
      ))}
    </>
  );
};

export default React.memo<ISectionTagsProps>(SectionTags);
