import $ from 'jquery';
import {
  calculateLabelPositions,
  calculateLabelsSize,
  getPosition,
  pxToPercentageH,
  toPercent,
  toPixels,
} from '../../../../helpers/common/utils';
import { setSearchRegion } from '../../../../store/search/actions';
import { isFunction } from 'lodash';

function initDrawTool({ taggingEditorState, labels, fields, upsertTag, curPageNum, droppableDomNode }) {
  const $droppable = $(droppableDomNode);
  $droppable.droppable({
    drop: (event, ui) => {
      const fieldId = ui.helper.attr('data-field-id');
      const img = $droppable.find('.page-img').first();
      let position = getPosition(ui.offset, $droppable);
      if (ui.helper.hasClass('dragHelper')) {
        const field = fields.find((f) => f._id === fieldId);

        const tagInfo = {
          fieldId,
          page: curPageNum,
        };

        let dims;
        if (taggingEditorState !== 'pixelPerfect' || (labels && labels.length === 0) || !labels) {
          dims = {
            y: position.y,
            x: position.x,
            width: ui.helper.css('width'),
            height: ui.helper.css('height'),
          };

          const percentDims = toPercent(dims, img);
          $.extend(dims, percentDims);
        } else {
          let result = calculateLabelsSize(ui, $droppable, labels, true, img);
          dims = {
            y: result.y + '%',
            x: result.x + '%',
            width: result.w + '%',
            height: result.h + '%',
          };
        }

        if (field.dataType === 'table') {
          const x_left_top =
              dims.x.slice(0, dims.x.length - 1) -
              pxToPercentageH((1 * $(`.carousel-item.active img`).height()) / 100, img) +
              0.98,
            y_left_top = dims.y.slice(0, dims.y.length - 1),
            width = 20,
            height = 5;

          tagInfo.isTable = true;
          tagInfo.table = {
            style: {
              left: `${x_left_top}%`,
              top: `${y_left_top}%`,
              width: `${width}%`,
              height: `${height}%`,
            },
          };
        } else {
          tagInfo.width = dims.width;
          tagInfo.height = dims.height;
          tagInfo.top = dims.y;
          tagInfo.left = dims.x;
        }
        if (field.dataType === 'section') {
          tagInfo.page = curPageNum;
          tagInfo.width = '100%';
          tagInfo.height = '0.15%';
          tagInfo.top = dims.y;
          tagInfo.left = '0%';
        }

        const regionInPixels = toPixels(tagInfo, img);
        upsertTag(tagInfo, true, regionInPixels, true);
      }
    },
  });

  return $droppable;
}

function _addDrawListener({
  scaledWidth,
  scaledHeight,
  isSearchSelect,
  activeFieldId,
  curPageNum,
  taggingEditorState,
  labels,
  fields,
  upsertTag,
  canvas, //  document.getElementById(`canvas-${curPageNum}`)
  $droppable,
  activeField,
}) {
  // let canvas = document.getElementById(`canvas-${curPageNum}`);
  let ctx = canvas.getContext('2d');
  let canvasx = $(canvas).offset().left;
  let canvasy = $(canvas).offset().top;
  let img = $(`#img-${curPageNum}`);
  let last_mousex,
    last_mousey = 0;
  let mousex,
    mousey = 0;
  let width,
    height = 0;
  let mousedown = false;

  $droppable.on({
    mousedown: (event) => {
      if (event.target.tagName.toLowerCase() === 'canvas' || event.target.tagName.toLowerCase() === 'img') {
        img = $(`#img-${curPageNum}`);
        canvas = document.getElementById(`canvas-${curPageNum}`);
        ctx = canvas.getContext('2d');
        canvasx = $(canvas).offset().left;
        canvasy = $(canvas).offset().top;
        last_mousex = parseInt(event.pageX - canvasx);
        last_mousey = parseInt(event.pageY - canvasy);
        mousedown = true;
      }
    },
    mousemove: (event) => {
      if (event.target.tagName.toLowerCase() === 'canvas' || event.target.tagName.toLowerCase() === 'img') {
        mousex = parseInt(event.pageX - canvasx);
        mousey = parseInt(event.pageY - canvasy);
        if (mousedown) {
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          ctx.beginPath();
          let imageWidth = scaledWidth ? scaledWidth : img.innerWidth();
          let imageHeight = scaledHeight ? scaledHeight : img.innerHeight();
          ctx.canvas.width = imageWidth;
          ctx.canvas.height = imageHeight;
          width = mousex - last_mousex;
          height = mousey - last_mousey;
          ctx.rect(last_mousex, last_mousey, width, height);
          ctx.strokeStyle = 'grey';
          ctx.globalCompositeOperation = 'copy';
          ctx.fillStyle = '2525f2';
          ctx.lineWidth = 0.25;
          ctx.stroke();
          ctx.closePath();
        }
      }
    },
    mouseup: (event) => {
      if ((event.target.tagName.toLowerCase() === 'canvas' || event.target.tagName.toLowerCase() === 'img') && mousedown) {
        let ui = {
          y: last_mousey,
          x: last_mousex,
          w: Math.abs(width),
          h: Math.abs(height),
        };
        if (width < 0 && height < 0) {
          ui.y = last_mousey + height;
          ui.x = last_mousex + width;
        } else if (width < 0) {
          ui.x = last_mousex + width;
        } else if (height < 0) {
          ui.y = last_mousey + height;
        }
        let imageWidth = scaledWidth ? scaledWidth : img.innerWidth();
        let imageHeight = scaledHeight ? scaledHeight : img.innerHeight();

        let fieldId = activeFieldId;
        const field = activeField || fields.find((f) => f._id === fieldId);

        let x = (ui.x / imageWidth) * 100;
        let y = (ui.y / imageHeight) * 100;
        let uiWidth = (ui.w / imageWidth) * 100;
        let uiHeight = (ui.h / imageHeight) * 100;
        if (taggingEditorState !== 'pixelPerfect' || (labels && labels.length === 0) || !labels) {
          x = x + '%';
          y = y + '%';
          uiWidth = uiWidth + '%';
          uiHeight = uiHeight + '%';
        } else {
          let result = calculateLabelPositions(x, y, uiWidth, uiHeight, [], labels);
          x = result.x + '%';
          y = result.y + '%';
          uiWidth = result.w + '%';
          uiHeight = result.h + '%';
        }

        const dim = {
          x,
          y,
          top: y,
          left: x,
          width: uiWidth,
          height: uiHeight,
        };

        let tagInfo = {
          page: curPageNum,
          width: dim.width,
          height: dim.height,
          top: dim.y,
          left: dim.x,
        };

        const regionInPixels = toPixels(dim, img);

        if (isSearchSelect) {
          regionInPixels.page = curPageNum;
          setSearchRegion(regionInPixels);
        } else if (field) {
          let tableUpdateNeeded = false;
          if (field.dataType === 'section') {
            tagInfo = {
              page: curPageNum,
              width: '100%',
              height: '0.15%',
              top: dim.y,
              left: '0%',
            };
          }
          if (field && field.dataType === 'table' && (!field.tags || field.tags.length === 0)) {
            const x_left_top =
                dim.x.slice(0, dim.x.length - 1) -
                pxToPercentageH((1 * $(`.carousel-item.active img`).height()) / 100, img) +
                0.98,
              y_left_top = dim.y.slice(0, dim.y.length - 1),
              width = (ui.w / imageWidth) * 100,
              height = (ui.h / imageHeight) * 100;
            tagInfo.isTable = true;
            tagInfo.table = {
              style: {
                left: `${x_left_top}%`,
                top: `${y_left_top}%`,
                width: `${width}%`,
                height: `${height}%`,
              },
            };
            tableUpdateNeeded = true;
          }
          tagInfo['fieldId'] = fieldId;
          if (field.dataType === 'table' && tableUpdateNeeded) {
            upsertTag(tagInfo, false, regionInPixels, true);
          } else if (field.dataType !== 'table') {
            upsertTag(tagInfo, false, regionInPixels, true);
          }
        }
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        mousedown = false;
      }
    },
  });
}

function droppableEnable($droppable) {
  if ($droppable && isFunction($droppable.droppable)) {
    $droppable.droppable('enable');
  }
}

function droppableDisable($droppable) {
  if ($droppable && isFunction($droppable.droppable)) {
    $droppable.droppable({ disabled: true });
  }
}

function clearDrawedRectangle(canvas) {
  if (canvas) {
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);
  }
}

function removeDrawListener($droppable) {
  if ($droppable) {
    $droppable.unbind('mousedown');
    $droppable.unbind('mousemove');
    $droppable.unbind('mouseup');
  }
}

function destroyDrawTool({ $droppable }) {
  if ($droppable && isFunction($droppable.droppable)) {
    $droppable.droppable('destroy');
  }
}

export {
  initDrawTool,
  _addDrawListener,
  droppableEnable,
  droppableDisable,
  clearDrawedRectangle,
  removeDrawListener,
  destroyDrawTool,
};
