import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import Signin from './components/Authentication/Signin';
import Browse from './components/Annotation/Browse';
import Error from './components/Error';
import history from './history';

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      exact={true}
      render={(props) => {
        const currentPathName = history.location.pathname;
        const redirectAfterSignIn = currentPathName.includes('signin') ? '' : `?redirectTo=${currentPathName}`;
        return localStorage.getItem('jwtToken') ? (
          <Component {...props} rules={props.rules} />
        ) : (
          <Redirect to={`/signin${redirectAfterSignIn}`} />
        );
      }}
    />
  );
};

export default [
  {
    path: '/signin',
    component: Signin,
    exact: true,
    exculeTopNav: true,
  },
  {
    path: '/:appId/:id',
    section: 'annotation',
    component: (props) => <PrivateRoute {...props} component={Browse} />,
  },
  {
    path: '/error',
    section: 'annotation',
    component: (props) => <PrivateRoute {...props} component={Error} />,
  },
  // {
  //   path: '/browse/:id',
  //   section: 'annotation',
  //   exact: true,
  //   component: (props) => <PrivateRoute {...props} component={Browse} />,
  // },
];
