import React, { useState, Fragment, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { parseAndFormatNumber } from '../../../helpers/common/formatter';
import { metadataSelector } from '../../../store/document/selectors';
import FieldScaleSelect from './FieldScaleSelect';
import Icons from '../../Common/Icons';

const FieldTagInput = (props) => {
  const { dataType } = props.field;
  const { value, ocrValue } = props.tag;

  const [fxApplicable] = useState(['number', 'date'].includes(dataType));
  const [editMode, setEditMode] = useState(false);

  const metadata = useSelector(metadataSelector);

  const removeTag = (e) => {
    e.preventDefault();
    props.resetFieldScale(props.field._id);
    props.emptyTag();
  };

  const removeManualTag = (e) => {
    e.preventDefault();
    props.resetFieldScale(props.field._id);
    props.emptyManualValue();
  };

  const fxValueToShow = useMemo(() => {
    return dataType === 'date' ? value : parseAndFormatNumber(value, metadata);
  }, [value, metadata, dataType]);

  const enableEditMode = useCallback(() => {
    setEditMode(true);
  }, []);

  const disableEditMode = useCallback(() => {
    setEditMode(false);
  }, []);

  return (
    <Fragment>
      <div className={`position-relative ${props.multipleClass}`}>
        {fxApplicable &&
          !props.removeSystemLine &&
          (props.shortSystemLine ? <div className='system-line-short'></div> : <div className='system-line'></div>)}
        <div
          className={`input-group input-group-merge ${!props.removeSystemLine ? 'ms-2' : ''} pe-2 ${
            !props.showOcrValue ? 'hidden-div' : ''
          }`}
        >
          {dataType === 'link' && !props.linkEdited ? (
            <div className='form-control form-control-sm'>
              <a className='text-nowrap link-color d-flex' href={value} target='_blank'>
                {value}
              </a>
            </div>
          ) : (
            <input
              spellCheck='false'
              type='text'
              className={`form-control form-control-sm`}
              onKeyDown={props.handleFieldValueOnKeyDown}
              onChange={(e) => props.handleChange(e, true)}
              onClick={props.handleTagClick}
              value={fxApplicable ? ocrValue : value}
              id={props.inputId}
              disabled={props.disabled}
            />
          )}
          {fxApplicable && props.annotationPermission && (
            <div className='px-2 py-1 order-1'>{props.field.tags && <Icons.Abc height={25} />}</div>
          )}
          <div className='input-group-text px-1 py-0 ps-2'>
            {props.field.tags && props.annotationPermission && (
              <span onClick={(e) => removeTag(e)}>
                <Icons.FieldEraser className='input-button cursor-pointer primary-color' />
              </span>
            )}
          </div>
        </div>
        {props.showScaleSelector && dataType === 'number' && (
          <FieldScaleSelect
            showScaleValue={props.showScaleValue}
            field={props.field}
            setFieldScale={props.setFieldScale}
            value={value ? value.toString() : ''}
            tagId={props.tag?._id}
            documentId={props.documentId}
            updateValue={props.updateValue}
            showScaleSystemLine={props.showScaleSystemLine}
            removeSystemLine={props.removeSystemLine}
            showOcrValue={props.showOcrValue}
            tagInput={true}
          />
        )}
        {fxApplicable && (
          <div
            className={`input-group input-group-merge pt-2 ${!props.removeSystemLine ? 'ms-2' : ''} pe-2  ${
              !props.showFxValue ? 'hidden-div' : ''
            }`}
          >
            <input
              spellCheck='false'
              type='text'
              className={'form-control form-control-sm text-end'}
              onChange={props.handleManualChange}
              onKeyDown={props.handleFieldValueOnKeyDown}
              onClick={props.handleTagClick}
              value={editMode ? value : fxValueToShow}
              disabled={props.disabled}
              onFocus={enableEditMode}
              onBlur={disableEditMode}
            />
            <div className='px-2 py-1 order-1'>{props.field.tags && <Icons.Fx />}</div>
            <div className='input-group-text px-1 py-0 ps-2'>
              {props.field.tags && (
                <span
                  onClick={(e) => {
                    removeManualTag(e);
                  }}
                >
                  <Icons.FieldEraser className='input-button cursor-pointer primary-color' />
                </span>
              )}
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default FieldTagInput;
