import React from 'react';
import SearchTag from '../../SearchTag/SearchTag';

const SearchTags = ({ searchTags = [], curPageNum, changePage, selectedSearchTag }) => {
  return searchTags.map((search, index) => {
    if (Number(search.page) === curPageNum) {
      return (
        <SearchTag
          key={index}
          index={index}
          searchTag={search}
          changeSearchPage={changePage}
          active={search === selectedSearchTag}
        />
      );
    }
    return null;
  });
};

export default React.memo(SearchTags);
