import { Action } from '../types';
import { IUser } from '../user/types';
export const QUERY_USERS = 'QUERY_USERS';
export const DELETE_USER = 'DELETE_USER';

export interface QueryUsers extends Action {
  type: typeof QUERY_USERS;
}

export interface DeleteUser extends Action<IUser['_id']> {
  type: typeof DELETE_USER;
}

export type UsersAction = QueryUsers | DeleteUser;
