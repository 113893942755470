import React, { memo } from 'react';
import { Dialog } from '@progress/kendo-react-dialogs';

const CustomModal = (props) => {
  const { open, modalHandler, children, classNames = '', ...rest } = props;

  return (
    <>
      {open && (
        <Dialog onClose={modalHandler} className={classNames} {...rest}>
          {children}
        </Dialog>
      )}
    </>
  );
};

export default memo(CustomModal);
