import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import innolytiqApi from '../../api/innolytiqApi';
import {
  documentIdSelector,
  documentNameSelector,
  documentSrcSelector,
  documentTemplatesSelector,
} from '../../store/document/selectors';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { userSelector } from '../../store/user/selectors';
import { getChatBotUrl } from '../../appConfig';

import Chat from './Chat';

import 'react-chat-widget/lib/styles.css';
import './Chat.scss';
import { TInsight } from '../../store/document/types';
import { queryUser } from '../../store/user/actions';

const PAGE_LAYOUT = '63e62d4c506fa900110c0428';
const TABLE = '5e8c19702ddf8e00a20f62c4';

const ChatContainer = () => {
  const documentSrc = useAppSelector(documentSrcSelector);
  const documentName = useAppSelector(documentNameSelector);
  const documentId = useAppSelector(documentIdSelector);
  const templates = useAppSelector(documentTemplatesSelector);
  const user = useAppSelector(userSelector);
  const dispatch = useAppDispatch();

  const pageLayoutAndTable = useMemo(
    () =>
      templates.filter(
        ({ template }: { template: TInsight }) => template?._id === PAGE_LAYOUT || template?._id === TABLE
      ).length,
    [templates]
  );

  useEffect(() => {
    const userId = localStorage.getItem('userId');
    userId && dispatch(queryUser(userId));
  }, [dispatch]);

  const pdfFileLoad = useMemo(
    () => innolytiqApi.get(`files/pdf/${documentSrc}`, { responseType: 'blob' }),
    [documentSrc]
  );

  return (
    <>
      {user?.firstName && documentSrc && documentName && documentId && (
        <Chat
          documentId={documentId}
          documentSrc={documentSrc}
          documentName={documentName}
          userName={user.firstName}
          pdfFileLoad={pdfFileLoad}
          chatApiUrl={getChatBotUrl()}
          isInitByDocument={pageLayoutAndTable === 2}
        />
      )}
    </>
  );
};

export default ChatContainer;
