import React, { useEffect, useMemo, useState } from 'react';
import {
  openConfirmIgoneTransferDataValidationModal,
  openConfirmTransferDataModal,
} from '../../modal/service/ModalControllers';
import { dataTransfer } from '../../../actions';
import Icons from '../../Common/Icons/Icons';
import { getTransferValidation } from '../../../appConfig';
import { validateDataTransfer } from '../../../validators/validator';
import { useAppDispatch } from '../../../hooks/reduxHooks';
import Button from '../../Common/Button';

type ITransferButton = {
  transferStatus: {
    statusCode?: number;
    isSuccess?: boolean;
    timestamp: string;
    message?: string;
    messages?: string[];
  };
  handleSelect?: (id: string) => void;
  id: string;
};

const TransferData = ({ transferStatus, handleSelect, id }: ITransferButton) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const useValidationForTransfer = useMemo(() => getTransferValidation() === 'hansenwolf', []);

  /**
   * Run validation before transfering documnet data.
   *
   * @param {*} id: document id to validate data.
   */
  const validateAndTransferData = async (id: string) => {
    const validationError = await validateDataTransfer(id);
    if (!validationError) {
      dispatch(dataTransfer(id));
    } else {
      if (validationError && validationError.type && validationError.value) {
        openConfirmIgoneTransferDataValidationModal({
          errorMessage:
            validationError.type === 'fields' ? (
              <div>
                {((Array.isArray(validationError.value) && validationError.value) || []).map((f) => (
                  <p>{`${f.group} - ${f.name}`}</p>
                ))}
                <p className='text-danger'>Do you want to transfer data anyway?</p>
              </div>
            ) : (
              <div>
                <p>{validationError.value}</p>
                <p className='text-danger'>Do you want to transfer data anyway?</p>
              </div>
            ),
          onResponse: (resp: boolean) => {
            setLoading(resp);
            if (resp) {
              dispatch(dataTransfer(id));
            }
          },
        });
      } else {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    setLoading(transferStatus?.statusCode === -1);
  }, [transferStatus]);

  return (
    <div
      className={`btn btn-primary d-flex align-items-center ${(loading && 'disabled') || ''}`}
      onClick={() => {
        !loading &&
          openConfirmTransferDataModal({
            onResponse: (resp: boolean) => {
              if (resp) {
                setLoading(true);

                useValidationForTransfer ? validateAndTransferData(id) : dispatch(dataTransfer(id));
              }
            },
          });
        handleSelect && handleSelect(id);
      }}
    >
      <Button variant='primary' icon='DocumentTransfer' />
      {loading ? (
        <span className='spinner-border spinner-border-sm ms-1' />
      ) : transferStatus && transferStatus.statusCode ? (
        transferStatus.statusCode === 200 ? (
          <Icons.DocumentTransferSuccessStatus className='success-color ms-2' />
        ) : (
          <Icons.DocumentTransferFailStatus className='danger-color ms-2' />
        )
      ) : (
        ''
      )}
    </div>
  );
};

export default TransferData;
