import { Dispatch } from 'redux';
import innolytiqApi from '../../api/innolytiqApi';
import { QUERY_TEMPLATES, QueryTemplatesByIds } from './types';

export const queryTemplatesByIds = (ids: string[]) => async (dispatch: Dispatch) => {
  try {
    const { data } = await innolytiqApi.post('templates/templatesByIds', { ids });
    dispatch<QueryTemplatesByIds>({
      type: QUERY_TEMPLATES,
      payload: data,
    });
  } catch (e) {
    console.log(e);
    return [];
  }
};
