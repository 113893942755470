import { SET_EMPTY_TAG, TagAction } from './types';

const tagReducer = (state = null, action: TagAction) => {
  switch (action.type) {
    case SET_EMPTY_TAG:
      return action.payload;
    default:
      return state;
  }
};
export default tagReducer;
