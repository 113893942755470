import { Dispatch } from 'redux';

import innolytiqApi from '../../api/innolytiqApi';

import {
  MicrotaskForCreateRequest,
  CREATE_MICROTASK,
  CreateMicrotask,
  DELETE_MICROTASK,
  DeleteMicrotask,
  Microtask,
  QUERY_MICROTASKS,
  QueryMicrotasks,
  RENAME_MICROTASK,
  RenameMicrotask,
  MicrotaskName,
} from './types';
import { AxiosResponse } from 'axios';
import history from '../../history';
import { queryTemplatesNames } from '../../actions';
import { DataStructure } from '../datastructure/types';

export const queryMicrotasks = (callback?: (list: Microtask[] | null) => void) => async (dispatch: Dispatch) => {
  type ResponseType = { microtasks: Microtask[] };

  try {
    const {
      data: { microtasks: _microtasks },
    } = await innolytiqApi.get<ResponseType, AxiosResponse<ResponseType>>('microtasks');

    const templates = (await queryTemplatesNames()) as DataStructure[];
    const templatesNameMap = templates.reduce((map, template) => {
      const templateId = template?._id;
      // @ts-ignore
      map[templateId] = template?.name;
      return map;
    }, {});

    const microtasks = _microtasks.map((m) => {
      m.insights =
        m?.insights.map((mkI) => {
          const id = typeof mkI.insight === 'string' ? mkI.insight : mkI.insight?._id;
          // @ts-ignore
          mkI.name = templatesNameMap[id];

          return mkI;
        }) || [];

      return m;
    });

    dispatch<QueryMicrotasks>({ type: QUERY_MICROTASKS, payload: microtasks });
    if (callback) {
      callback(microtasks);
    }
  } catch (e) {
    console.log(e);
    if (callback) {
      callback(null);
    }
  }
};

export const queryMicrotasksNames = async () => {
  type ResponseType = { microtasks: MicrotaskName[] };

  try {
    const {
      data: { microtasks },
    } = await innolytiqApi.get<ResponseType, AxiosResponse<ResponseType>>('microtasks/names');
    return microtasks;
  } catch (e) {
    console.log(e);
    return [] as MicrotaskName[];
  }
};

export const queryMicrotasksNamesByIds = async (microtaskIds: Microtask['_id'][]) => {
  type ResponseType = { microtasks: MicrotaskName[] };

  try {
    const filterByIds = microtaskIds || [];
    const {
      data: { microtasks },
    } = await innolytiqApi.get<ResponseType, AxiosResponse<ResponseType>>('microtasks', {
      params: {
        filterByIds,
      },
    });
    return microtasks;
  } catch (e) {
    console.log(e);
    return [] as MicrotaskName[];
  }
};

export const createMicrotask = (microtaskData: MicrotaskForCreateRequest) => async (dispatch: Dispatch) => {
  type ResponseType = { microtask: Microtask };

  try {
    const {
      data: { microtask },
    } = await innolytiqApi.post<ResponseType, AxiosResponse<ResponseType>>('microtasks', { microtask: microtaskData });
    dispatch<CreateMicrotask>({ type: CREATE_MICROTASK, payload: microtask });
    history.push(`microtasks/${microtask._id}`);
  } catch (e) {
    console.log(e);
  }
};

export const renameMicrotask = (id: Microtask['_id'], name: Microtask['name']) => async (dispatch: Dispatch) => {
  try {
    await innolytiqApi.patch<ResponseType, AxiosResponse<ResponseType>>(`microtasks/${id}/rename`, { name });
    dispatch<RenameMicrotask>({
      type: RENAME_MICROTASK,
      payload: { _id: id, name } as Pick<Microtask, '_id' | 'name'>,
    });
  } catch (e) {
    console.log(e);
  }
};

export const deleteMicrotask = (id: Microtask['_id']) => async (dispatch: Dispatch) => {
  try {
    await innolytiqApi.delete(`microtasks/${id}`);
    dispatch<DeleteMicrotask>({ type: DELETE_MICROTASK, payload: id });
  } catch (e) {
    console.log(e);
  }
};

export const queryMicrotaskById =
  (id: string, callback?: (item: Microtask | null) => void) => async (dispatch: Dispatch) => {
    type ResponseType = { workflow: Microtask };

    try {
      const { data } = await innolytiqApi.get<ResponseType, AxiosResponse<ResponseType>>(`microtasks/${id}`);

      const microtask = data.workflow;
      const templates = (await queryTemplatesNames()) as DataStructure[];
      const templatesNameMap = templates.reduce((map, template) => {
        const templateId = template?._id;
        // @ts-ignore
        map[templateId] = template?.name;
        return map;
      }, {});

      microtask.insights =
        microtask.insights.map((mkI: any) => {
          const id = typeof mkI.insight === 'string' ? mkI.insight : mkI.insight?._id;
          // @ts-ignore
          mkI.name = templatesNameMap[id];

          return mkI;
        }) || [];

      if (callback) {
        callback(microtask);
      }
    } catch (e) {
      console.log(e, 'e');
    }
  };
