import React, {useEffect, useState, FC, useCallback} from 'react';
import { useSelector } from 'react-redux';
import { Map } from 'immutable';
import { DataStructure, Field } from '../../store/datastructure/types';
import { documentTemplatesSelector } from '../../store/document/selectors';
import { DocumentDataStructure } from '../../store/document/types';
import _ from 'lodash';

import { changePage, deSelectField, setActiveSmartTag, setTaggingEditorState } from '../../actions';
import { selectDataStructures } from '../../store/datastructure/selectors';

import Icons from '../Common/Icons';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';

import './SmartTagDropdown.scss';
import { deleteEmptyTag } from '../../store/tag/actions';
import { emptyTagSelector } from '../../store/tag/selectors';
import { Props, OriginType, ReferencePropType, OnFieldClickType, ReferenceSmartTagType, ReferenceType } from './types';
import { RootState } from '../../store/store';
import DropdownOrDataFieldTag from './Components/DropdownOrDataFieldTag';
import DropDown from '../Common/DropDown/DropDown';
import PixelPerfect from './Components/PixelPerfect';
import { queryTemplatesByIds } from '../../store/templates/actions';

export const SmartTagDropDown: FC<Props> = ({
  currentPageNumber,
  smartTags,
}) => {
  const dispatch = useAppDispatch();

  const [originTags, setOriginTags] = useState<OriginType>(null);
  const [referenceTags, setReferenceTags] = useState<ReferenceType>([]);
  const [openedItem,setOpened] = useState<string>();

  let templatesMap = Map<string, DataStructure>();

  const emptyTag = useSelector(emptyTagSelector);

  const tplObject = {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  const templates = useAppSelector<DataStructure[]>(selectDataStructures) || [];
  templates.forEach((template, index, array) => {
    // @ts-ignore
    tplObject[template._id] = template;

    if (index + 1 === array.length) {
      templatesMap = Map(tplObject);
    }
  });


  let attachedTemplatesMap = Map<string, DataStructure>();
  const templatesObjs = {};
  (useSelector<RootState, DocumentDataStructure[]>(documentTemplatesSelector) ?? []).forEach(
    ({ template }, index, array) => {
      if (!template || !template._id) {
        return;
      }
      // @ts-ignore
      templatesObjs[template._id] = template;

      if (index + 1 === array.length) {
        attachedTemplatesMap = Map(templatesObjs);
      }
    }
  );

  //Search and query missing templates from smart tags reference
  const initGroupedTemplates = useCallback(() => {
    if (!smartTags?.isSmartTag) {
      return;
    }

    const { reference } = smartTags;
    if (!reference || !reference.length) {
      return;
    }
    const templatesNeedToGet = reference
      .filter(({ dataStructure }) => {
        return !templatesMap.get(dataStructure)?.fields;
      })
      .map(({ dataStructure }) => dataStructure)
      .filter((value, index, self) => self.indexOf(value) === index);

    const needToQuery = !!templatesNeedToGet.length;
    if (!needToQuery) {
      return;
    }

    let missingTemplates: any[] = [];
    for (let index = 0; index < templatesNeedToGet.length; index++) {
      missingTemplates.push(templatesNeedToGet[index]);
    }
    dispatch(queryTemplatesByIds(missingTemplates));
  },[dispatch, smartTags, templatesMap]);

  useEffect(() => {
    if (!templates?.length) initGroupedTemplates();
  }, [initGroupedTemplates, templates]);

  useEffect(() => {
    if (!smartTags) {
      return;
    }

    setOriginTags(smartTags.origin ?? {});

    const tempReferences: ReferencePropType = smartTags.reference ?? [];
    let references: ReferenceType = [];
    if (tempReferences.length) {
      references = _.groupBy(tempReferences, ({ dataStructure }) => dataStructure);
    }
    setReferenceTags(references);
  }, [smartTags]);

  const activeTagHash = ((smartTags && smartTags.activeTag && smartTags.activeTag.hash) ?? '').split('_');

  const onFieldClick: OnFieldClickType = (selected, dataStructure, value, referenceFieldsData, activate) => {
    if (!originTags) {
      return;
    }
    
    dispatch(deSelectField());
    if (!activate) {
      dispatch(setActiveSmartTag(null));
      return;
    }

    if (selected) {
      return;
    }

    if (emptyTag) {
      dispatch(deleteEmptyTag());
    }


    const hash = [dataStructure, value, referenceFieldsData[0].renderType].join('_');

    const tagsPagesList: number[] = [];
    const originFields = originTags[`${dataStructure}_${value}`];
    if (!originFields) {
      return;
    }

    let haveOneTagOnCurrentPage = false;
    originFields.forEach(({ tags }) =>
      tags.forEach(({ page }) => {
        const _page = +page || 0;
        tagsPagesList.push(_page);
        if (!haveOneTagOnCurrentPage && _page === currentPageNumber) {
          haveOneTagOnCurrentPage = true;
        }
      })
    );

    if (!haveOneTagOnCurrentPage && tagsPagesList.length) {
      dispatch(changePage(Math.min(...tagsPagesList) - 1));
    }

    dispatch(setTaggingEditorState('smartTag'));
    const activeTag = {
      originFields,
      dataStructure,
      dataField: value,
      referenceFieldsData,
      hash,
    };

    dispatch(setActiveSmartTag(activeTag));

  };

  const pixelPerfectElement: JSX.Element | null =
    !smartTags || (smartTags && !smartTags.pixelPerfectOptionDisabled) ? <PixelPerfect /> : null;

  const dropDownOptions =
    !attachedTemplatesMap.size || !referenceTags || !Object.keys(referenceTags).length || !originTags
      ? null
      : Object.keys(referenceTags).map((dataStructure) => {
          // @ts-ignore
          const referenceFields: ReferenceSmartTagType[] = referenceTags[dataStructure];

          const documentTemplate = attachedTemplatesMap.get(dataStructure);
          const selectedDataStructure = activeTagHash[0] === dataStructure;

          const notAttachedTemplate = !templatesMap.has(dataStructure) ? undefined : templatesMap.get(dataStructure);
          const fieldsList = documentTemplate?.fields?.length
              ? documentTemplate.fields
              : notAttachedTemplate?.fields || [];

          const fields = _.groupBy<Field>(fieldsList, ({ _id }) => _id);
          const title = documentTemplate?.name || notAttachedTemplate?.name;

          return !title ? null : (
            <DropDown
              key={dataStructure}
              className='tagging-editor-state-dropdown'
              position='left'
              data-dropdown-key={dataStructure}
              btnIcon={<Icons.DropdownChevronLeft className='m-0' />}
              btnText={title}
              initShow={openedItem === dataStructure }
              btnClass={`btn bg-transparent dropdown-item dropdown-toggle smart-tag-toggle
                    ${selectedDataStructure ? 'active' : ''}
                  `}
              disabled={!documentTemplate && !notAttachedTemplate}
              onClick={()=>setOpened(dataStructure)}
              onClose={()=>setOpened('')}
              collision={{
                  vertical: 'fit',
                  horizontal: 'fit',
              }}
            >
              {!documentTemplate && !notAttachedTemplate ? null : (
                <div>
                  {!referenceFields || !referenceFields.length
                    ? null
                    : referenceFields.map(({ dataField, referenceFieldsData }, index) => {
                        const originFieldTags = originTags[`${dataStructure}_${dataField}`];
                        // @ts-ignore
                        const [{ name }] = fields[dataField] || [{}];
                        return (
                          <DropdownOrDataFieldTag
                            key={index}
                            dataStructure={dataStructure}
                            dataField={dataField}
                            referenceFieldsData={referenceFieldsData}
                            onFieldClick={onFieldClick}
                            label={name || dataField || 'Not found Field Name'}
                            activeTagHash={activeTagHash}
                            disabled={!originFieldTags || !originFieldTags.length}
                          />
                        );
                      })}
                </div>
              )}
            </DropDown>
          );
        });

  return (
    <>
      <DropDown
        btnText='Smart Tag'
        btnIcon={<Icons.SmartTagDropdownOpener />}
        btnClass='btn btn-primary rounded-end'
        className='tagging-editor-state-dropdown'
        margin={{vertical:0,horizontal:70}}
        collision={{
          vertical: 'fit',
          horizontal: 'fit',
        }}
      >
        {!pixelPerfectElement && !dropDownOptions ? (
          <div className={`btn bg-transparent dropdown-item dropdown-toggle dropdown-toggle`}>
            <span className='title'>Loading...</span>
          </div>
        ) : null}

        {pixelPerfectElement}
        {dropDownOptions}
      </DropDown>
    </>
  );
};

export default SmartTagDropDown;
