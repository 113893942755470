import { CLEAR_DOCUMENT, CLEAN_DATA_RECIPE, CLEAN_COLLECIONS, RESET_CHECKPOINT, CLEAN_USER_DATA } from './types';
import { RESET_ANNOTATION_VALUES } from '../store/annotation/types';

export const cleanDocument = () => (dispatch) => {
  dispatch({
    type: RESET_CHECKPOINT,
  });
  dispatch({
    type: RESET_ANNOTATION_VALUES,
  });
  dispatch({
    type: CLEAR_DOCUMENT,
  });
};

export const cleanCollections = () => (dispatch) => {
  dispatch({
    type: CLEAN_COLLECIONS,
  });
};

export const cleanUserDataFromState = () => (dispatch) => {
  dispatch({
    type: CLEAN_USER_DATA,
  });
};

export const cleanDataRecipeState = () => (dispatch) => {
  dispatch({
    type: CLEAN_DATA_RECIPE,
  });
};
