export const getCurrentWorkflow = (document) =>
  document?.workflows?.length ? document?.workflows[document?.workflows?.length - 1] : undefined;

export const getStepFork = (step) => (step.forkedTo ? step[step.forkedTo] : undefined);

const findStepInRootStep = (rootStep, callBack) => {
  if (!rootStep) {
    return;
  }

  let foundStep;
  const recursion = (step) => {
    if (foundStep) {
      return;
    }

    const found = callBack(step);
    if (found) {
      foundStep = step;
      return;
    }

    const stepFork = getStepFork(step);
    if (!stepFork) {
      return;
    }

    if (!stepFork?.next) {
      return;
    }

    return recursion(stepFork.next);
  };

  recursion(rootStep);

  return foundStep;
};

export const getWorkflowCurrentStep = (documentWorkflow) => {
  if (!documentWorkflow) {
    return;
  }

  const { currentStepId, rootStep } = documentWorkflow;

  const foundStep = findStepInRootStep(
    rootStep,
    (step) => (step.step?._id || step.step).toString() === currentStepId.toString() && currentStepId
  );

  if (!foundStep) {
    console.trace(
      `Can not get current documentWorkflow:${documentWorkflow._id} active step by currentStepId:${currentStepId}`
    );
  }

  return foundStep;
};

export const getWorkflowStepByTemplateId = (documentWorkflow, templateId) => {
  templateId = templateId.toString();
  const { rootStep, currentStepId } = documentWorkflow;

  const foundStep = findStepInRootStep(rootStep, (step) => {
    if (currentStepId && step.step !== currentStepId) {
      return false;
    }

    const { forkedTo } = step;

    const fork = forkedTo in step && step[forkedTo] ? step[forkedTo] : null;

    if (!fork?.isRunned) {
      return false;
    }

    const { templates, additionalTemplateIds, type, moveToCollection } = fork;
    if (type === 'moveToCollection' && moveToCollection && moveToCollection.template === templateId) {
      return true;
    }

    const hasTemplateAttachedInFork =
      additionalTemplateIds.includes(templateId) || [...templates].some((t) => t.template.toString() === templateId);

    return hasTemplateAttachedInFork;
  });

  if (!foundStep) {
    console.trace(`Can not get current documentWorkflow:${documentWorkflow._id} step by templateId:${templateId}`);
  }

  return foundStep;
};

export const getDocumentWorkflowStepByTemplateId = (document, templateId) => {
  const currentWorkflow = getCurrentWorkflow(document);
  if (!currentWorkflow) {
    return;
  }

  const step = getWorkflowStepByTemplateId(currentWorkflow, templateId);

  return step && step?.forkedTo in step && step[step.forkedTo] ? step[step.forkedTo] : undefined;
};

export const WorkflowUtils = {
  get: {
    rootStepToForks: (rootStep, eachFork) => {
      const stepsList = [];

      const recursion = (conditionalWrappedStep) => {
        const stepDefaultFork = conditionalWrappedStep.default;
        if (!stepDefaultFork) {
          return;
        }

        let defaultForkToPush =
          typeof eachFork === 'function' ? eachFork(conditionalWrappedStep, stepDefaultFork) : stepDefaultFork;
        stepsList.push(defaultForkToPush);

        if (stepDefaultFork?.next && Object.keys(stepDefaultFork.next).length) {
          recursion(stepDefaultFork.next);
        }

        if (conditionalWrappedStep.condition) {
          const stepConditionalFork = conditionalWrappedStep.conditional;
          if (!stepConditionalFork) {
            return;
          }

          let conditionalForkToPush =
            typeof eachFork === 'function'
              ? eachFork(conditionalWrappedStep, stepConditionalFork)
              : stepConditionalFork;
          stepsList.push(conditionalForkToPush);

          if (stepConditionalFork?.next && Object.keys(stepConditionalFork.next).length) {
            recursion(stepConditionalFork.next);
          }
        }
      };

      recursion(rootStep);

      return stepsList;
    },
  },
};

export const getFieldFromFields = (fields = [], fieldId) =>
  fields.find(({ _id }) => _id.toString() === fieldId.toString());

export const getTagFromField = ({ tags = [] }, tagId) => tags.find(({ _id }) => _id.toString() === tagId.toString());

export const getFieldFromSmartTagOrigins = (origins, srcFieldId, fieldId) => {
  let field = undefined;

  const originKey = getOriginFromSmartTagOrigins(origins, srcFieldId);
  if (originKey) {
    field = getFieldFromFields(origins[originKey], fieldId);
  }

  return field;
};

export const getOriginFromSmartTagOrigins = (origins, srcFieldId) => {
  let key = undefined;

  Object.entries(origins).some(([_key]) => {
    if (!_key.includes(srcFieldId)) {
      return false;
    }
    key = _key;
    return true;
  });

  return key;
};
