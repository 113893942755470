import { Dispatch } from 'redux';
import {
  SET_SCALED_VALUE,
  APPEND_IMAGE_DIMENSIONS,
  ImageDimension,
  SetScaledValue,
  AppendImagesDimensions,
  Annotation, TOGGLE_SMART_LABEL, ToggleSmartLabel,
} from './types';
import { RootState } from '../reducers';

export const setScaledValue =
  (scaledValue: Annotation['scaledValue']) => (dispatch: Dispatch, getState: () => RootState) => {
    try {
      const {
        annotation: { scaledValue: prevScaledValue },
      } = getState();
      if (prevScaledValue === scaledValue) {
        return;
      }

      dispatch<SetScaledValue>({
        type: SET_SCALED_VALUE,
        payload: scaledValue,
      });
    } catch (e) {
      console.log(e);
    }
  };

export const appendImagesDimensions = (pageNumber: string, dimensions: ImageDimension) => (dispatch: Dispatch) => {
  try {
    dispatch<AppendImagesDimensions>({
      type: APPEND_IMAGE_DIMENSIONS,
      payload: { pageNumber, dimensions },
    });
  } catch (e) {
    console.log(e);
  }
};

export const setTagsLabelView = () => (dispatch: Dispatch) => {
  try {
    dispatch<ToggleSmartLabel>({
      type: TOGGLE_SMART_LABEL,
    });
  } catch (e) {
    console.log(e);
  }
};
