// export var ROW = 0;

export const tableUtil = (
  jquery,
  tableId,
  tableData,
  callback,
  deleteCallback,
  top,
  left,
  fieldId,
  color,
  page,
  forTextMode,
  hasPermission,
  $tableContainer,
  { width: X, height: Y }
) => {
  const $ = jquery;
  var startX,
    startY,
    startWidth,
    startHeight,
    tableWidth,
    tableHeight,
    tableTop,
    tableLeft,
    $handle,
    $secondTd,
    lastElement,
    startSecondWidth = 0,
    startSecondHeight = 0,
    minColHeightWidth = 14,
    heightArray = [],
    widthArray = [],
    colWidthArray = [],
    colHeightArray = [],
    pressed = false,
    resizeType = 'width',
    // X = $(`.carousel-item.active img`).width(),
    // Y = $(`.carousel-item[data-page-number=${page}]`).height(),

    cellRowSpan = [];

  const createTable = function () {
    var w = ((tableData.width * X) / 100).toFixed(0);
    var h = ((tableData.height * Y) / 100).toFixed(0);
    $tableContainer.html(
      `<table 
        style="position: absolute; 
            width: ${w}px; 
            height: ${h}px; 
            left: ${((left * X) / 100).toFixed()}px;
            top: ${((top * Y) / 100).toFixed()}px;
            border-color: ${color}px;
            color: ${color};
            z-index: 4000;
            table-layout:fixed"
        id="${tableId}" 
        class="table table-resizable ${fieldId}">
        <tbody><tr style='height: 0px !important; display: contents'><td></td></tr></tbody>
        </table>`
    );
    // $tableContainer.find(`Table#${tableId}`).css({width: w+"px", height: h+"px"});
    const hasRowSpan = {};
    const hasColSpan = {};
    cellRowSpan = [];

    tableData.vertical_dividers.forEach(function (item, index) {
      $tableContainer.find(`table#${tableId} tbody`).find('tr').append('<td></td>');
    });

    tableData.horizontal_dividers.forEach(function (item, index) {
      if (!hasRowSpan[index]) {
        hasRowSpan[index] = [];
      }
      if (!hasColSpan[index]) {
        hasColSpan[index] = [];
      }
      tableData.vertical_dividers.forEach(function (_item, _index) {
        if (item.interruptions.length > 0) {
          item.interruptions.forEach(function (a, z) {
            if (a.x_offset_start === 0 && a.x_offset_end === tableData.vertical_dividers[_index].x_offset) {
              if (hasRowSpan[index]) {
                hasRowSpan[index].push(0);
              } else {
                hasRowSpan[index] = [0];
              }
              tableData.vertical_dividers.forEach(function (b, ind) {
                if (a.x_offset_start === b.x_offset && ind - _index > 2) {
                  for (let i = index + 1; i < ind - 1; i++) {
                    hasRowSpan[index + i] = [_index.toString()];
                  }
                }
              });
            } else if (a.x_offset_start === _item.x_offset) {
              if (hasRowSpan[index]) {
                hasRowSpan[index].push(_index + 1);
              } else {
                hasRowSpan[index] = [_index + 1];
              }
              tableData.vertical_dividers.forEach(function (b, ind) {
                if (a.x_offset_start === b.x_offset && ind - _index > 2) {
                  for (let i = index + 1; i < ind - 1; i++) {
                    hasRowSpan[index + i] = [(_index + 1).toString()];
                  }
                }
              });
            }
          });
        }

        if (_item.interruptions.length > 0) {
          _item.interruptions.forEach(function (a, z) {
            if (a.y_offset_start === 0 && a.y_offset_end === tableData.horizontal_dividers[index].y_offset) {
              if (hasColSpan[index]) {
                hasColSpan[0].push(_index);
              } else {
                hasColSpan[0] = [_index];
              }
              tableData.horizontal_dividers.forEach(function (b, ind) {
                if (a.y_offset_start === b.y_offset && ind - index > 2) {
                  for (let i = index + 1; i < ind - 1; i++) {
                    hasColSpan[index + i] = [_index.toString()];
                  }
                }
              });
            } else if (a.y_offset_start === item.y_offset) {
              if (hasColSpan[index + 1]) {
                hasColSpan[index + 1].push(_index);
              } else {
                hasColSpan[index + 1] = [_index];
              }
              tableData.horizontal_dividers.forEach(function (b, ind) {
                if (a.y_offset_start === b.y_offset && ind - index > 2) {
                  for (let i = index + 1; i < ind - 1; i++) {
                    hasColSpan[index + 1 + i] = [_index.toString()];
                  }
                }
              });
            }
          });
        }
      });
    });

    tableData.horizontal_dividers.forEach(function (item, index) {
      $tableContainer.find(`table#${tableId} tbody`).append(`<tr id="${index}"></tr>`);
      tableData.vertical_dividers.forEach(function (_item, _index) {
        let rowspan = 1;
        let colspan = 1;
        Object.keys(hasRowSpan).forEach(function (key) {
          if (index.toString() === key) {
            hasRowSpan[key].forEach(function (a) {
              if (a === _index) {
                rowspan++;
                let nextSameValue = true;
                for (let b = index + 1; b < Object.keys(hasRowSpan).length && nextSameValue; b++) {
                  if (hasRowSpan[b] && hasRowSpan[b].indexOf(_index) !== -1) {
                    if (hasRowSpan[b].indexOf(_index) !== -1) {
                      const ind = hasRowSpan[b].indexOf(_index);
                      hasRowSpan[b][ind] = hasRowSpan[b][ind].toString();
                      rowspan++;
                    }
                  } else {
                    nextSameValue = false;
                  }
                }
              }
            });
          }
        });

        Object.keys(hasColSpan).forEach(function (key) {
          if (index.toString() === key && hasColSpan[key].length > 0) {
            hasColSpan[key].forEach(function (a, ind) {
              if (a === _index) {
                for (let b = ind; b <= hasColSpan[key].length; b++) {
                  if (hasColSpan[key][b] - hasColSpan[key][b - 1] === 1 && parseInt(b.toString()) <= _index + 1) {
                    hasColSpan[key][b] = hasColSpan[key][b].toString();
                  }
                }
              }
            });
          }
        });

        Object.keys(hasColSpan).forEach(function (key) {
          if (index.toString() === key && hasColSpan[key].length > 0) {
            hasColSpan[key].forEach(function (a, ind) {
              if (a === _index) {
                colspan++;
                for (let b = ind + 1; b <= hasColSpan[key].length; b++) {
                  if (hasColSpan[key][b] - hasColSpan[key][b - 1] === 1) {
                    colspan++;
                  } else {
                    break;
                  }
                }
              }
            });
          }
        });

        cellRowSpan.push({
          rowIndex: index + 1,
          colIndex: _index + 1,
          rowspan,
          colspan,
        });
        if (_index === 0) {
          $tableContainer
            .find(`table#${tableId} tbody tr:last`)
            .append(`<td></td><td colspan="${colspan}" rowspan="${rowspan}"></td>`);
        } else {
          $tableContainer
            .find(`table#${tableId} tbody tr:last`)
            .append(`<td colspan="${colspan}" rowspan="${rowspan}"></td>`);
        }
      });
    });

    $tableContainer
      .find(`table#${tableId}`)
      .find('tr')
      .each(function (index) {
        if (index !== 0) {
          const row = this;
          $(row)
            .find('td')
            .each(function (_index) {
              const colspan = this.colSpan;
              if (this.rowSpan > 1) {
                for (let a = index; a < index + this.rowSpan - 1; a++) {
                  if ($tableContainer.find(`table#${tableId} tr#${a}`).find('td[colspan=1]')[_index]) {
                    $tableContainer.find(`table#${tableId} tr#${a}`).find('td[colspan=1]')[_index].remove();
                  } else {
                    $tableContainer.find(`table#${tableId} tr#${a}`).find('td[colspan=1]').last().remove();
                  }
                }
              }
            });
        }
      });
  };

  const getColIndex = function (id) {
    var newId = id.split('-')[1].split('_');
    newId.splice(0, 1);
    newId = newId.join('_');
    return newId;
  };

  const trTdSizes = function () {
    tableHeight = $tableContainer.find(`table#${tableId}`).height();
    tableWidth = $tableContainer.find(`table#${tableId}`).width();
    let sumOfAllWidths = 0;
    let sumOfAllHeight = 0;
    $tableContainer
      .find('Table#' + tableId + ' tbody tr:first')
      .find('td')
      .each(function (index, item) {
        this.height = 0;
      });
    $tableContainer
      .find(`table#${tableId}`)
      .find('tr')
      .each(function (index) {
        $(this)
          .find('td')
          .each(function (_index) {
            if (_index === 0 && index !== 0 && tableData.horizontal_dividers[index - 1]) {
              let heightByPx;
              if (index === 1) {
                if (index === tableData.horizontal_dividers.length) {
                  heightByPx = ((tableHeight * tableData.horizontal_dividers[index - 1].y_offset) / 100).toFixed(0);
                } else {
                  heightByPx = ((tableHeight * tableData.horizontal_dividers[index - 1].y_offset) / 100).toFixed(0);
                }
              } else if (index === tableData.horizontal_dividers.length) {
                heightByPx = (
                  (tableHeight *
                    (tableData.horizontal_dividers[index - 1].y_offset -
                      tableData.horizontal_dividers[index - 2].y_offset)) /
                  100
                ).toFixed(0);
              } else {
                heightByPx = (
                  (tableHeight *
                    (tableData.horizontal_dividers[index - 1].y_offset -
                      tableData.horizontal_dividers[index - 2].y_offset)) /
                  100
                ).toFixed(0);
              }
              sumOfAllHeight = sumOfAllHeight + parseInt(heightByPx);
              $(this).css({ height: heightByPx });
            }
            if (index === 0 && _index !== 0 && tableData.vertical_dividers[_index - 1]) {
              let widthByPx;
              if (_index === 1) {
                widthByPx = ((tableWidth * tableData.vertical_dividers[_index - 1].x_offset) / 100).toFixed(0);
              } else {
                widthByPx = (
                  (tableWidth *
                    (tableData.vertical_dividers[_index - 1].x_offset -
                      tableData.vertical_dividers[_index - 2].x_offset)) /
                  100
                ).toFixed(0);
              }
              sumOfAllWidths = sumOfAllWidths + parseInt(widthByPx);
              $(this).css({ width: widthByPx });
            }
          });
      });

    if (
      $tableContainer.find(`table#${tableId} tr:first-child td`)[1] &&
      $tableContainer.find(`table#${tableId} tr:first-child td`)[1].offsetLeft > 0
    ) {
      const width = $tableContainer.find(`table#${tableId} td#${tableId + '-0_1'}`).width();
      $tableContainer
        .find(`table#${tableId} td#${tableId + '-0_1'}`)
        .width(width + $tableContainer.find(`table#${tableId} tr:first-child td`)[1].offsetLeft);
    }
  };

  const addItemsToResizeTable = function (from = '') {
    // redips.init();
    let objectIncludeRowSpansIndex = {};
    const width = ((1 * $(`.carousel-item.active img`).width()) / 100).toFixed(0) + 'px';
    const height = ((1 * $(`.carousel-item.active img`).height()) / 100).toFixed(0) + 'px';
    $tableContainer
      .find('Table#' + tableId)
      .find('tr')
      .each(function (index) {
        this.id = tableId + '-' + index;
        let colSpanCount = 0;
        if (!objectIncludeRowSpansIndex[index]) {
          objectIncludeRowSpansIndex[index] = [];
        }
        $(this)
          .find('td')
          .each(function (_index) {
            if (_index === 0) {
              colSpanCount = 0;
            } else if (_index === 1) {
              colSpanCount = this.colSpan;
            } else {
              colSpanCount = colSpanCount + this.colSpan;
            }

            // indexing cells by id
            if (this.colSpan > 1) {
              let id = tableId + '-' + index;
              if (objectIncludeRowSpansIndex[index].length > 0) {
                objectIncludeRowSpansIndex[index].forEach((i) => {
                  const longI = i;
                  i = i.split('_');
                  if (parseInt(i[0]) === index) {
                    id = tableId + '-' + index;
                    i.splice(0, 1);
                    i.forEach((a, ind) => {
                      if (parseInt(a) <= colSpanCount && _index !== 0) {
                        for (
                          let b = colSpanCount + (objectIncludeRowSpansIndex[index].indexOf(longI) + 1) - this.colSpan;
                          b < colSpanCount + (objectIncludeRowSpansIndex[index].indexOf(longI) + 1);
                          b++
                        ) {
                          id = id + '_' + (b + 1);
                        }
                      } else {
                        for (let i = colSpanCount - this.colSpan; i < colSpanCount; i++) {
                          id = id + '_' + (i + 1);
                        }
                      }
                    });
                  }
                });
              } else {
                for (let i = colSpanCount - this.colSpan; i < colSpanCount; i++) {
                  id = id + '_' + (i + 1);
                }
              }
              this.id = id;
            } else {
              let id = colSpanCount;
              if (objectIncludeRowSpansIndex[index].length > 0) {
                objectIncludeRowSpansIndex[index].forEach((i, _ind) => {
                  const longI = i;
                  i = i.split('_');
                  if (parseInt(i[0]) === index) {
                    if (parseInt(i[1]) < colSpanCount + objectIncludeRowSpansIndex[index].indexOf(longI) + 1) {
                      id = colSpanCount + (objectIncludeRowSpansIndex[index].indexOf(longI) + 1);
                    }
                  }
                });
                this.id = tableId + '-' + index + '_' + id;
              } else {
                this.id = tableId + '-' + index + '_' + colSpanCount;
              }
            }

            if (this.rowSpan > 1) {
              for (let i = 1; i < this.rowSpan; i++) {
                this.id
                  .split('-')[1]
                  .split('_')
                  .forEach(function (id, ind) {
                    if (ind !== 0) {
                      if (objectIncludeRowSpansIndex[index + i]) {
                        let colIndex = [parseInt(id)];
                        objectIncludeRowSpansIndex[index + i].forEach(function (item) {
                          colIndex.push(parseInt(item.split('_')[1]));
                        });
                        colIndex.sort((a, b) => a - b);
                        colIndex = colIndex.map((a) => {
                          return index + i + '_' + a;
                        });
                        objectIncludeRowSpansIndex[index + i] = colIndex;
                      } else {
                        objectIncludeRowSpansIndex[index + i] = [index + i + '_' + id];
                      }
                    }
                  });
              }
            }

            if (index === 0 || _index === 0) {
              this.setAttribute('class', 'contain-resizer');
              $(this).css('border', 'none');
            }

            if (index === 1 && _index === 1) {
              $(this).append('<div class="drag-item">&#9776;</div>');
            }

            if (index === 0 && _index > 0) {
              // firs row, add col resizer
              $(this).find('div.col-resizer').remove();
              $(this).find('div.col-adder').remove();
              if (_index === 1) {
                $(this).append(`<div style="color: ${color}" class="table-left-resizer">&#9660;</div>`);
              }
              $(this).append(
                `<div style="color: ${color}" class="col-resizer">&#9660</div><div style="height: ${height};" class="col-adder"></div>`
              );
            } else if (_index === 0 && index > 0) {
              // first cell of each row, add row resizer
              $(this).find('div.row-resizer').remove();
              $(this).find('div.row-adder').remove();
              if (index === 1) {
                $(this).append(`<div style="color: ${color}" class="table-top-resizer">&#9654;</div>`);
              }
              $(this).append(
                `<div style="color: ${color}" class="row-resizer">&#9654</div><div style="width: ${width};" class="row-adder"></div>`
              );
            }
            if (_index > 1 && index > 0) {
              /// new
              // add lines for merge cells
              if ($tableContainer.find(this.tagName.toLowerCase() + '#' + this.id + ' .merge-h').length > 0) {
                $tableContainer.find(this.tagName.toLowerCase() + '#' + this.id + ' .merge-h').remove();
              }
              $(this).append('<div class="merge-h"></div>');
            }

            if (_index > 0 && index > 0 && index !== $tableContainer.find(`table#${tableId}`).find('tr').length - 1) {
              /// new
              // add lines for merge rows
              if ($tableContainer.find(this.tagName.toLowerCase() + '#' + this.id + ' .merge-v').length > 0) {
                $tableContainer.find(this.tagName.toLowerCase() + '#' + this.id + ' .merge-v').remove();
              }
              if (this.rowSpan + index < $tableContainer.find(`table#${tableId}`).find('tr').length) {
                $(this).append('<div class="merge-v"></div>');
              }
            }

            if (widthArray.length > 0) {
              $(this).width(widthArray[_index]);
            }
            if (_index > 0) {
              $(this).css('height', '');
            }
            if (heightArray.length > 0 && _index === 0) {
              $(this).height(heightArray[index]);
            }
            if (index > 0) {
              $(this).css('width', '');
            }
          });
        if (this.rowSpan + index < $tableContainer.find(`table#${tableId}`).find('tr').length) {
          $(this).append('<div class="merge-v"></div>');
        }
      });

    let prev = 0;
    let lastTd;
    let startedRemoveMoreElements = false;
    $tableContainer
      .find(`table#${tableId}`)
      .find('tr')
      .each(function (index) {
        const rowIndex = index;
        if (index === 0) {
          lastTd = $(this).find('td').last()[0].id.split('_').pop();
        }
        $(this)
          .find('td')
          .each(function (_index) {
            // indexing cells by id after merge
            let thisColId = getColIndex(this.id);
            const rowNumber = rowIndex + this.rowSpan;
            if (
              $tableContainer.find(`tr#${tableId}-${rowNumber}`).find(`td#${tableId}-${rowNumber}_${thisColId}`)
                .length === 0
            ) {
              $(this).find('.merge-v').remove();
            }
            if (
              prev !== 0 &&
              parseInt(this.id.split('_').pop()) - parseInt(prev.id.split('_').pop()) !== this.colSpan
            ) {
              $(this).find('.merge-h').remove();
            }
            if ($(this).prev('td').length > 0 && this.rowSpan !== $(this).prev('td')[0].rowSpan) {
              $(this).find('.merge-h').remove();
            }
            prev = this;

            // delete more element
            if (parseInt($(this)[0].id.split('_').pop()) > parseInt(lastTd)) {
              const element = $tableContainer
                .find(`table#${tableId} tr#${tableId}-${index}`)
                .find('td[colspan=1]')
                .last();
              if (element.length > 0) {
                startedRemoveMoreElements = true;
                element.remove();
              }
            }
          });
        if (startedRemoveMoreElements && index === $tableContainer.find(`table#${tableId}`).find('tr').length - 1) {
          addItemsToResizeTable();
        }
      });

    heightArray = [];
    widthArray = [];
  };

  const cellRowSpanes = (data) => {
    cellRowSpan = [];
    let cellRowSpanByRowId = [];
    if (data && cellRowSpan.length === 0) {
      const hasRowSpan = {};
      const hasColSpan = {};
      data.horizontal_dividers.forEach(function (item, index) {
        if (!hasRowSpan[index]) {
          hasRowSpan[index] = [];
        }
        if (!hasColSpan[index]) {
          hasColSpan[index] = [];
        }
        data.vertical_dividers.forEach(function (_item, _index) {
          if (item.interruptions.length > 0) {
            item.interruptions.forEach(function (a, z) {
              if (a.x_offset_start === 0 && a.x_offset_end === data.vertical_dividers[_index].x_offset) {
                if (hasRowSpan[index]) {
                  hasRowSpan[index].push(0);
                } else {
                  hasRowSpan[index] = [0];
                }
                data.vertical_dividers.forEach(function (b, ind) {
                  if (a.x_offset_start === b.x_offset && ind - _index > 2) {
                    for (let i = index + 1; i < ind - 1; i++) {
                      hasRowSpan[index + i] = [_index.toString()];
                    }
                  }
                });
              } else if (a.x_offset_start === _item.x_offset) {
                if (hasRowSpan[index]) {
                  hasRowSpan[index].push(_index + 1);
                } else {
                  hasRowSpan[index] = [_index + 1];
                }
                data.vertical_dividers.forEach(function (b, ind) {
                  if (a.x_offset_start === b.x_offset && ind - _index > 2) {
                    for (let i = index + 1; i < ind - 1; i++) {
                      hasRowSpan[index + i] = [(_index + 1).toString()];
                    }
                  }
                });
              }
            });
          }

          if (_item.interruptions.length > 0) {
            _item.interruptions.forEach(function (a, z) {
              if (a.y_offset_start === 0 && a.y_offset_end === data.horizontal_dividers[index].y_offset) {
                if (hasColSpan[index]) {
                  hasColSpan[0].push(_index);
                } else {
                  hasColSpan[0] = [_index];
                }
                data.horizontal_dividers.forEach(function (b, ind) {
                  if (a.y_offset_start === b.y_offset && ind - index > 2) {
                    for (let i = index + 1; i < ind - 1; i++) {
                      hasColSpan[index + i] = [_index.toString()];
                    }
                  }
                });
              } else if (a.y_offset_start === item.y_offset) {
                if (hasColSpan[index + 1]) {
                  hasColSpan[index + 1].push(_index);
                } else {
                  hasColSpan[index + 1] = [_index];
                }
                data.horizontal_dividers.forEach(function (b, ind) {
                  if (a.y_offset_start === b.y_offset && ind - index > 2) {
                    for (let i = index + 1; i < ind - 1; i++) {
                      hasColSpan[index + 1 + i] = [_index.toString()];
                    }
                  }
                });
              }
            });
          }
        });
      });

      data.horizontal_dividers.forEach(function (item, index) {
        data.vertical_dividers.forEach(function (_item, _index) {
          let rowspan = 1;
          let colspan = 1;
          Object.keys(hasRowSpan).forEach(function (key) {
            if (index.toString() === key) {
              hasRowSpan[key].forEach(function (a) {
                if (a === _index) {
                  rowspan++;
                  let nextSameValue = true;
                  for (let b = index + 1; b < Object.keys(hasRowSpan).length && nextSameValue; b++) {
                    if (hasRowSpan[b] && hasRowSpan[b].indexOf(_index) !== -1) {
                      if (hasRowSpan[b].indexOf(_index) !== -1) {
                        const ind = hasRowSpan[b].indexOf(_index);
                        hasRowSpan[b][ind] = hasRowSpan[b][ind].toString();
                        rowspan++;
                      }
                    } else {
                      nextSameValue = false;
                    }
                  }
                }
              });
            }
          });

          Object.keys(hasColSpan).forEach(function (key) {
            if (index.toString() === key && hasColSpan[key].length > 0) {
              hasColSpan[key].forEach(function (a, ind) {
                if (a === _index) {
                  for (let b = ind; b <= hasColSpan[key].length; b++) {
                    if (hasColSpan[key][b] - hasColSpan[key][b - 1] === 1 && parseInt(b.toString()) <= _index + 1) {
                      hasColSpan[key][b] = hasColSpan[key][b].toString();
                    }
                  }
                }
              });
            }
          });

          Object.keys(hasColSpan).forEach(function (key) {
            if (index.toString() === key && hasColSpan[key].length > 0) {
              hasColSpan[key].forEach(function (a, ind) {
                if (a === _index) {
                  colspan++;
                  for (let b = ind + 1; b <= hasColSpan[key].length; b++) {
                    if (hasColSpan[key][b] - hasColSpan[key][b - 1] === 1) {
                      colspan++;
                    } else {
                      break;
                    }
                  }
                }
              });
            }
          });

          cellRowSpan.push({
            rowIndex: index + 1,
            colIndex: _index + 1,
            rowspan,
            colspan,
          });
          if (cellRowSpanByRowId.length == 0) {
            cellRowSpanByRowId[index] = [
              {
                key: `${index + 1}_${_index + 1}`,
                rowIndex: index,
                colIndex: _index,
                rowspan,
                colspan,
              },
            ];
          } else {
            if (cellRowSpanByRowId[index]) {
              cellRowSpanByRowId[index].push({
                key: `${index + 1}_${_index + 1}`,
                rowIndex: index,
                colIndex: _index,
                rowspan,
                colspan,
              });
            } else {
              cellRowSpanByRowId[index] = [
                {
                  key: `${index + 1}_${_index + 1}`,
                  rowIndex: index,
                  colIndex: _index,
                  rowspan,
                  colspan,
                },
              ];
            }
          }
        });
      });
    }
    $tableContainer
      .find(`table#${tableId}`)
      .find('tr')
      .each(function (index) {
        const row = this;
        $(row)
          .find('td')
          .each(function (_index) {
            if (this.id) {
              let key = this.id.split('-')[1].split('_');
              const rowIndex = parseInt(key[0]);
              const colIndex = parseInt(key[1]);
              let found = cellRowSpan.find((c) => c.rowIndex === rowIndex && c.colIndex === colIndex);
              if (found) {
                this.rowSpan = found.rowspan;
                this.colSpan = found.colspan;
              }
            }
          });
      });
    $tableContainer
      .find(`table#${tableId}`)
      .find('tr')
      .each(function (index) {
        if (index !== 0) {
          const row = this;
          $(row)
            .find('td')
            .each(function (_index) {
              const colspan = this.colSpan;
              if (this.rowSpan > 1) {
                let col = getColIndex(this.id);
                for (let a = index; a < index + this.rowSpan - 1; a++) {
                  let rowElements = cellRowSpanByRowId[a];
                  let underCell = rowElements.find((r) => r.colIndex === col - 1);
                  if (underCell) {
                    $tableContainer.find(`table#${tableId} t#${tableId}-${underCell.key}`).remove();
                  }
                }
              }
            });
        }
      });
  };

  // create redips container
  // const redips = {};

  // // REDIPS.Table initialization
  // redips.init = function () {
  //   // define reference to the REDIPS.Table object
  //   var rt = REDIPS.Table;
  //   // activate onmousedown event listener on cells within Table with id="global key"
  //   rt.ondblclick(tableId, true);
  //   // show cellIndex (it is nice for debugging)
  //   rt.cell_index(false);
  //   // define background color for marked cell
  //   rt.color.cell = '#9BB3DA';
  // };

  // // function merges Table cells
  // redips.merge = function () {
  //   // first merge cells horizontally and leave cells marked
  //   REDIPS.Table.merge('h', false);
  //   // and then merge cells vertically and clear cells (second parameter is true by default)
  //   REDIPS.Table.merge('v');
  // };

  // $(document)
  //   .ready(() => {
  createTable();
  // redips.init();
  addItemsToResizeTable();
  trTdSizes();
  // if (forTextMode) {
  //   setTimeout(() => {
  //     showJSON(tableId, callback, page, 'forTextMode', tableData);
  //   }, 100);
  // }
  let copied = { ...tableData };
  cellRowSpanes(copied);
  // });

  // .on({
  //   mousemove: function (event) {
  //     if (hasPermission) {
  //       if (pressed && resizeType === 'width') {
  //         const changedWidth = event.pageX - startX;
  //         if (
  //           minColHeightWidth < startWidth + changedWidth &&
  //           (minColHeightWidth < startSecondWidth - changedWidth || lastElement)
  //         ) {
  //           $tableContainer.find(`Table#${tableId} tr td#${tableId + '-0_0'}`).width(0);
  //           if ($secondTd.length === 0) {
  //             $tableContainer.find('Table#' + tableId)
  //               .find(`tr#${tableId + '-0'}`)
  //               .find('td')
  //               .each(function (index) {
  //                 if (index !== 0) {
  //                   if (
  //                     index ===
  //                     $tableContainer.find(`Table#${tableId}`)
  //                       .find(`tr#${tableId + '-0'}`)
  //                       .find('td').length -
  //                       1
  //                   ) {
  //                     $(this).width(startWidth + changedWidth);
  //                   } else {
  //                     $(this).width(colWidthArray[index]);
  //                   }
  //                 }
  //               });
  //             $tableContainer.find(`Table#${tableId}`).width(tableWidth + changedWidth);
  //           } else if (startSecondWidth > 0) {
  //             $secondTd.width(startSecondWidth - changedWidth);
  //             $handle.parent('td').width(startWidth + changedWidth);
  //           }
  //         }
  //       } else if (pressed && resizeType === 'height') {
  //         const changedHeight = event.pageY - startY;
  //         if (
  //           minColHeightWidth < startHeight + changedHeight &&
  //           (minColHeightWidth < startSecondHeight - changedHeight || lastElement)
  //         ) {
  //           if (startSecondHeight > 0) {
  //             $secondTd.height(startSecondHeight - changedHeight);
  //             $handle.parent('td').height(startHeight + changedHeight);
  //           }
  //           if ($secondTd.length === 0) {
  //             $tableContainer.find(`Table#${tableId}`)
  //               .find('tr')
  //               .each(function (index) {
  //                 $(this)
  //                   .find('td')
  //                   .each(function (_index) {
  //                     if (_index === 0 && index > 0) {
  //                       if (index === $tableContainer.find(`Table#${tableId}`).find('tr').length - 1) {
  //                         $(this).height(startHeight + changedHeight);
  //                       } else {
  //                         $(this).height(colHeightArray[index - 1]);
  //                       }
  //                     }
  //                     if (_index === 0 && index === 0) {
  //                     }
  //                   });
  //               });
  //             $tableContainer.find('Table#' + tableId).height(tableHeight + changedHeight);
  //           }
  //         }
  //       } else if (pressed && resizeType === 'tableTop') {
  //         const changedHeight = event.pageY - startY;
  //         if (minColHeightWidth < startHeight - changedHeight) {
  //           $tableContainer.find(`Table#${tableId}`)
  //             .find('tr')
  //             .each(function (index) {
  //               $(this)
  //                 .find('td')
  //                 .each(function (_index) {
  //                   if (_index === 0 && index > 0) {
  //                     if (index === 1) {
  //                       $(this).height(startHeight - changedHeight);
  //                     } else {
  //                       $(this).height(colHeightArray[index - 1]);
  //                     }
  //                   }
  //                 });
  //             });
  //           $tableContainer.find(`Table#${tableId}`).height(tableHeight - changedHeight);
  //           $tableContainer.find(`Table#${tableId}`).css({ top: tableTop + changedHeight });
  //         }
  //       } else if (pressed && resizeType === 'tableLeft') {
  //         const changedWidth = event.pageX - startX;
  //         if (minColHeightWidth < startWidth - changedWidth) {
  //           $tableContainer.find(`Table#${tableId} tr td#${tableId + '-0_0'}`).width(0);
  //           $tableContainer.find('Table#' + tableId)
  //             .find(`tr#${tableId + '-0'}`)
  //             .find('td')
  //             .each(function (index) {
  //               if (index !== 0) {
  //                 if (index === 1) {
  //                   $(this).width(startWidth - changedWidth);
  //                 } else {
  //                   $(this).width(colWidthArray[index]);
  //                 }
  //               }
  //             });
  //           $tableContainer.find(`Table#${tableId}`).width(tableWidth - changedWidth);
  //           $tableContainer.find(`Table#${tableId}`).css({ left: tableLeft + changedWidth });
  //         }
  //       } else if (pressed && resizeType === 'drag') {
  //         $tableContainer.find(`.${fieldId}`).draggable();
  //         $tableContainer.find(`.${fieldId}`).droppable({
  //           drop: function (event, ui) {
  //             const x =
  //               (($tableContainer.find(`Table#${tableId}`)[0].offsetLeft + event.originalEvent.offsetX - 8) /
  //                 $tableContainer.find(`Table#${tableId}`).parent('div').width()) *
  //               100;
  //             const y = (($tableContainer.find(`Table#${tableId}`)[0].offsetTop + event.originalEvent.offsetY - 11) / Y) * 100;
  //             $tableContainer.find(`Table#${tableId}`).css({
  //               left: x + '%',
  //               top: y + '%',
  //             });

  //             showJSON(tableId, callback, page, 'drag');
  //           },
  //         });
  //       }
  //     }
  //   },
  //   mouseup: function () {
  //     if (hasPermission) {
  //       tableWidth = $tableContainer.find('Table#' + tableId).width();
  //       tableHeight = $tableContainer.find('Table#' + tableId).height();
  //       if (pressed) {
  //         pressed = false;
  //         showJSON(tableId, callback, page, 'mouseup');
  //       }
  //     }
  //   },
  // })
  // .on('mousedown', `.Table-resizable#${tableId} td .col-resizer`, function (event) {
  //   // resize cols
  //   colWidthArray = [];
  //   resizeType = 'width';
  //   tableWidth = $tableContainer.find('Table#' + tableId).width();
  //   $handle = $(this);
  //   pressed = true;
  //   startX = event.pageX;
  //   startWidth = $handle.parent('td').width();
  //   const key = $handle.closest('td')[0].id.split('-')[0];
  //   const id = $handle.closest('td')[0].id.split('-')[1].split('_');
  //   const secondTdId = key + '-' + id[0] + '_' + (parseInt(id[1]) + 1);
  //   $secondTd = $tableContainer.find('td#' + secondTdId);
  //   if ($secondTd.length > 0) {
  //     startSecondWidth = $secondTd.width();
  //     lastElement = false;
  //   } else {
  //     lastElement = true;
  //   }
  //   $tableContainer.find('Table#' + tableId)
  //     .find(`tr#${tableId + '-0'}`)
  //     .find('td')
  //     .each(function () {
  //       colWidthArray.push($(this).width());
  //     });
  // })
  // .on('mousedown', `.Table-resizable#${tableId} td .row-resizer`, function (event) {
  //   // resize rows
  //   colHeightArray = [];
  //   $handle = $(this);
  //   resizeType = 'height';
  //   tableHeight = $tableContainer.find('Table#' + tableId).height();
  //   pressed = true;
  //   startY = event.pageY;
  //   startHeight = $handle.parent('td').height();
  //   const key = $handle.closest('td')[0].id.split('-')[0];
  //   const id = $handle.closest('td')[0].id.split('-')[1].split('_');
  //   const secondTdId = key + '-' + (parseInt(id[0]) + 1) + '_' + id[1];
  //   $secondTd = $tableContainer.find('td#' + secondTdId);
  //   if ($secondTd.length > 0) {
  //     startSecondHeight = $secondTd.height();
  //     lastElement = false;
  //   } else {
  //     lastElement = true;
  //   }
  //   $tableContainer.find(`Table#${tableId}`)
  //     .find('tr')
  //     .each(function (index) {
  //       $(this)
  //         .find('td')
  //         .each(function (_index) {
  //           if (_index === 0 && index > 0) {
  //             colHeightArray.push($(this).height());
  //           }
  //         });
  //     });
  // })
  // .on('mousedown', `.Table-resizable#${tableId} td .Table-top-resizer`, function (event) {
  //   // resize top row of Table
  //   colHeightArray = [];
  //   $handle = $(this);
  //   resizeType = 'tableTop';
  //   tableHeight = $tableContainer.find('Table#' + tableId).height();
  //   tableTop = $tableContainer.find(`Table#${tableId}`)[0].offsetTop;
  //   pressed = true;
  //   startY = event.pageY;
  //   startHeight = $handle.parent('td').height();
  //   const key = $handle.closest('td')[0].id.split('-')[0];
  //   const id = $handle.closest('td')[0].id.split('-')[1].split('_');
  //   const secondTdId = key + '-' + (parseInt(id[0]) + 1) + '_' + id[1];
  //   $secondTd = $tableContainer.find('td#' + secondTdId);
  //   if ($secondTd.length > 0) {
  //     startSecondHeight = $secondTd.height();
  //     lastElement = false;
  //   } else {
  //     lastElement = true;
  //   }
  //   $tableContainer.find(`Table#${tableId}`)
  //     .find('tr')
  //     .each(function (index) {
  //       $(this)
  //         .find('td')
  //         .each(function (_index) {
  //           if (_index === 0 && index > 0) {
  //             colHeightArray.push($(this).height());
  //           }
  //         });
  //     });
  // })
  // .on('mousedown', `.Table-resizable#${tableId} td .Table-left-resizer`, function (event) {
  //   // resize left col of Table
  //   colWidthArray = [];
  //   resizeType = 'tableLeft';
  //   tableWidth = $tableContainer.find('Table#' + tableId).width();
  //   tableLeft = $tableContainer.find(`Table#${tableId}`)[0].offsetLeft;
  //   $handle = $(this);
  //   pressed = true;
  //   startX = event.pageX;
  //   startWidth = $handle.parent('td').width();
  //   const key = $handle.closest('td')[0].id.split('-')[0];
  //   const id = $handle.closest('td')[0].id.split('-')[1].split('_');
  //   const secondTdId = key + '-' + id[0] + '_' + (parseInt(id[1]) + 1);
  //   $secondTd = $tableContainer.find('td#' + secondTdId);
  //   if ($secondTd.length > 0) {
  //     startSecondWidth = $secondTd.width();
  //     lastElement = false;
  //   } else {
  //     lastElement = true;
  //   }
  //   $tableContainer.find('Table#' + tableId)
  //     .find(`tr#${tableId + '-0'}`)
  //     .find('td')
  //     .each(function () {
  //       colWidthArray.push($(this).width());
  //     });
  // })
  // .on('dblclick', `.Table-resizable#${tableId} .col-adder`, function (event) {
  //   // Add Column
  //   if ($(this).closest('td')[0]) {
  //     const tdId = $(this).closest('td')[0].id;
  //     let colIdArray = tdId.split('-')[1].split('_');
  //     colIdArray.splice(0, 1);
  //     let addedColWidth = (event.pageX - $tableContainer.find('td#' + tdId).offset().left - 1).toFixed(0);
  //     if (addedColWidth < minColHeightWidth) {
  //       addedColWidth = minColHeightWidth;
  //     }
  //     let thisTdWidth = $tableContainer.find('td#' + tdId).width() - addedColWidth;
  //     if (thisTdWidth <= minColHeightWidth) {
  //       thisTdWidth = minColHeightWidth;
  //     }
  //     $tableContainer.find(`Table#${tableId}`)
  //       .find('tr')
  //       .each(function (index) {
  //         $(this)
  //           .find('td')
  //           .each(function (_index) {
  //             if (index === 0 && _index === 0) {
  //               $tableContainer.find(`Table#${tableId}`).find('div.row-adder').remove();
  //               $tableContainer.find(`Table#${tableId}`).find('div.row-resizer').remove();
  //             }
  //             let a = this.id.split('-')[1].split('_');
  //             a.splice(0, 1);
  //             if (a.some((r) => colIdArray.indexOf(r) >= 0)) {
  //               const height = $(this).height();
  //               let colspan = this.colSpan;
  //               const spanIndex = a.indexOf(colIdArray[colIdArray.length - 1]);
  //               if (colspan > 1 && spanIndex > -1) {
  //                 colspan = spanIndex + 1;
  //               }
  //               if (index === 0) {
  //                 $tableContainer.find('td#' + tdId).width(thisTdWidth);
  //                 $(this).before(
  //                   '<td class="contain-resizer" style=' +
  //                     'width:' +
  //                     addedColWidth +
  //                     'px;' +
  //                     'height:' +
  //                     height +
  //                     'px' +
  //                     '></td>'
  //                 );
  //               } else if (tdId.split('_')[1] === '0') {
  //                 // $(this).before('<td style=' + 'height:' + height + 'px' + '></td>');
  //                 $(this).before(`<td style='height: ${height} + px'></td>`);
  //               } else {
  //                 const aLast = colIdArray[0];
  //                 const bLast = a[0];
  //                 if (colspan > 1 && aLast === bLast) {
  //                   $(this).before(
  //                     '<td rowspan=' + this.rowSpan + ' colspan=' + (this.colSpan + 1 - colspan) + '></td>'
  //                   );
  //                 } else {
  //                   $(this).after(
  //                     '<td rowspan=' + this.rowSpan + ' colspan=' + (this.colSpan + 1 - colspan) + '></td>'
  //                   );
  //                 }
  //                 this.colSpan = colspan;
  //               }
  //             }
  //           });
  //       });
  //     addItemsToResizeTable();
  //     showJSON(tableId, callback, page, 'add col');
  //   }
  // })
  // .on('dblclick', `.Table-resizable#${tableId} .row-adder`, function (event) {
  //   // Add Row
  //   if ($(this).closest('td')[0]) {
  //     const tdId = $(this).closest('td')[0].id;
  //     let addedColHeight = event.pageY - $tableContainer.find('td#' + tdId).offset().top - 1 - 0.4375;
  //     let thisTdHeight = $tableContainer.find('td#' + tdId).height() - addedColHeight;
  //     $tableContainer.find('td#' + tdId).height(thisTdHeight);

  //     let objectIncludeRowSpansIndex = {};
  //     $tableContainer.find(`Table#${tableId}`)
  //       .find('tr')
  //       .each(function (index) {
  //         if (!objectIncludeRowSpansIndex[index]) {
  //           objectIncludeRowSpansIndex[index] = [];
  //         }
  //         const tdCount = $(this).find('td').length;
  //         const colSpans = [];

  //         $(this)
  //           .find('td')
  //           .each(function (_ind) {
  //             colSpans.push(this.colSpan);
  //             if (this.rowSpan > 1) {
  //               for (let i = 1; i < this.rowSpan; i++) {
  //                 let colId = getColIndex(this.id);
  //                 if (objectIncludeRowSpansIndex[index + i]) {
  //                   objectIncludeRowSpansIndex[index + i].push(index + '_' + colId);
  //                 } else {
  //                   objectIncludeRowSpansIndex[index + i] = [index + '_' + colId];
  //                 }
  //               }
  //             }
  //           });

  //         let rowHTML = '';
  //         for (let i = 1; i <= tdCount; i++) {
  //           const width = $tableContainer.find('td#' + (tdId.split('_')[0] + '_' + (i - 1))).width();
  //           if (i === 1) {
  //             rowHTML =
  //               rowHTML +
  //               '<tr><td colspan=' +
  //               colSpans[i - 1] +
  //               ' class="contain-resizer" style=' +
  //               'height:' +
  //               addedColHeight +
  //               'px;' +
  //               'width:' +
  //               width +
  //               'px' +
  //               '>' +
  //               '<div style=' +
  //               'color:' +
  //               color +
  //               ' class="row-resizer">&#9654</div><div class="row-adder"></div></td>';
  //           } else if (getRowIndex(tdId) === 0) {
  //             rowHTML = `${rowHTML} <td colspan='${colSpans[i - 1]}' style='width: ${width}px'></td>`;
  //           } else {
  //             rowHTML = rowHTML + '<td colspan=' + colSpans[i - 1] + '></td>';
  //           }
  //           if (i === tdCount) {
  //             rowHTML = rowHTML + '</tr>';
  //           }
  //         }
  //         if (index === getRowIndex(tdId)) {
  //           if (index === 0) {
  //             $tableContainer.find(`Table#${tableId}`).find('div.col-adder').remove();
  //             $tableContainer.find(`Table#${tableId}`).find('div.col-resizer').remove();
  //           }
  //           $(this).before(rowHTML);
  //         }
  //       });

  //     const rowIndex = getRowIndex(tdId);

  //     if (objectIncludeRowSpansIndex[rowIndex].length > 0) {
  //       objectIncludeRowSpansIndex[rowIndex].forEach(function (item, index) {
  //         const thisRowSPan = $tableContainer.find('td#' + tableId + '-' + item)[0].rowSpan;
  //         $tableContainer.find('td#' + tableId + '-' + item)[0].rowSpan = rowIndex - parseInt(item.split('_')[0]) + 1;
  //         const secondRowSPan = thisRowSPan - (rowIndex - parseInt(item.split('_')[0]));

  //         item = item.split('_');
  //         item.splice(0, 1);
  //         item.forEach(function (id) {
  //           id = parseInt(id);
  //           $tableContainer.find('tr#' + tableId + '-' + rowIndex)
  //             .find('td')
  //             .each(function () {
  //               const _this = this;
  //               if (id - parseInt(this.id.split('_').pop()) === 1) {
  //                 $tableContainer.find(_this).after(
  //                   '<td id=' + (tableId + '-' + rowIndex + '_' + id) + ' rowspan=' + secondRowSPan + '></td>'
  //                 );
  //               }
  //             });
  //         });
  //       });
  //     }
  //     addItemsToResizeTable();
  //     showJSON(tableId, callback, page, 'add row');
  //   }
  // })
  // .on('dblclick', `.Table-resizable#${tableId}  .col-resizer`, function () {
  //   // Delete Column
  //   if ($tableContainer.find(`Table#${tableId}`).find('tr').first().find('td').length > 2) {
  //     pressed = false;
  //     if ($(this).parent('td')[0]) {
  //       const tdId = $(this).parent('td')[0].id.split('-')[1].split('_');
  //       const tdSecondId = tableId + '-' + tdId[0] + '_' + (parseInt(tdId[1]) + 1);
  //       const parentTdWidth = $(this).parent('td').width();
  //       const tWidth = $tableContainer.find(`Table#${tableId}`).width();
  //       if ($tableContainer.find('td#' + tdSecondId).length > 0) {
  //         $tableContainer.find('td#' + tdSecondId).width($(this).parent('td').width() + $tableContainer.find('td#' + tdSecondId).width()); // merge width
  //       } else {
  //         $tableContainer.find(`Table#${tableId}`).width(tWidth - parentTdWidth);
  //       }

  //       $tableContainer.find(`Table#${tableId}`)
  //         .find('tr')
  //         .each(function (index) {
  //           heightArray.push($(this).height());
  //           $(this)
  //             .find(`td[id*=_${tdId[1]}]`)
  //             .each(function (_index) {
  //               const colIds = this.id.split('_').splice(1);
  //               if (colIds.indexOf(tdId[1]) > -1) {
  //                 if (this.id.split('_').length > 2) {
  //                   if (this.colSpan === 1) {
  //                     const id = this.id.split('_');
  //                     const secondID = id[0] + '_' + (parseInt(id[id.length - 1]) + 1);
  //                     $tableContainer.find('td#' + secondID)[0] && $tableContainer.find('td#' + secondID)[0].remove();
  //                   } else {
  //                     const last = this.id.split('_').pop();
  //                     const lastTd = $tableContainer.find(`Table#${tableId} tr#${tableId + '-' + index}`).find('td:last-child')[0];
  //                     const tdPop = tdId.pop();
  //                     if (tdPop !== last || (lastTd.colSpan > 1 && tdPop === lastTd.id.split('_').pop())) {
  //                       this.colSpan = this.colSpan - 1;
  //                     }
  //                   }
  //                 } else {
  //                   $(this).remove();
  //                   $(this).find('div').remove();
  //                 }
  //               }
  //             });
  //         });
  //       setTimeout(() => {
  //         addItemsToResizeTable();
  //         showJSON(tableId, callback, page, 'delete col');
  //       });
  //     }
  //   }
  // })
  // .on('dblclick', `.Table-resizable#${tableId} .row-resizer`, function () {
  //   // Delete Row
  //   if ($tableContainer.find(`Table#${tableId}`).find('tr').length > 2 && ROW === 0) {
  //     pressed = false;
  //     if ($(this).parent('td')[0]) {
  //       const tdId = $(this).parent('td')[0].id.split('-')[1].split('_');
  //       const tdSecondId = tableId + '-' + (parseInt(tdId[0]) + 1) + '_' + tdId[1];
  //       const objectIncludeRowSPans = {};
  //       const tdHeight = $(this).parent('td').height();
  //       const tblHeight = $tableContainer.find(`Table#${tableId}`).height();
  //       $tableContainer.find(`Table#${tableId}`)
  //         .find('tr')
  //         .each(function (index) {
  //           $(this)
  //             .find('td')
  //             .each(function (_index) {
  //               if (!objectIncludeRowSPans[index]) {
  //                 objectIncludeRowSPans[index] = [];
  //               }
  //               if (this.rowSpan > 1) {
  //                 for (let i = 1; i < this.rowSpan; i++) {
  //                   if (objectIncludeRowSPans[index + i]) {
  //                     objectIncludeRowSPans[index + i].push(this.id.split('-')[1]);
  //                   } else {
  //                     objectIncludeRowSPans[index + i] = [this.id.split('-')[1]];
  //                   }
  //                 }
  //               }
  //             });
  //         });

  //       const rowIndex = getRowIndex($(this).parent('td')[0].id);
  //       $tableContainer.find('tr#' + tableId + '-' + rowIndex)
  //         .find('td')
  //         .each(function (index) {
  //           let colId = this.id.split('-')[1].split('_');
  //           const _this = this;

  //           if (this.rowSpan > 1) {
  //             $tableContainer.find('tr#' + tableId + '-' + (rowIndex + 1))
  //               .find('td')
  //               .each(function () {
  //                 let findId = this.id.split('-')[1].split('_');
  //                 findId.splice(0, 1);
  //                 const ID = findId.join('_');
  //                 findId.forEach((_id) => {
  //                   if (
  //                     parseInt(colId[1]) - parseInt(_id.split('_').pop()) === 1 &&
  //                     $tableContainer.find('tr#' + tableId + '-' + (rowIndex + 1)).find('td').length <
  //                       $tableContainer.find('tr#' + tableId + '-' + 0).find('td').length
  //                   ) {
  //                     $tableContainer.find('td#' + tableId + '-' + (rowIndex + 1 + '_' + ID)).after(
  //                       '<td id=' +
  //                         tableId +
  //                         '-' +
  //                         (rowIndex + 1 + '_' + (parseInt(_id) + 1)) +
  //                         ' colspan=' +
  //                         _this.colSpan +
  //                         ' rowspan=' +
  //                         (_this.rowSpan - 1) +
  //                         '></td>'
  //                     );
  //                   }
  //                 });
  //               });
  //           }
  //         });

  //       if (objectIncludeRowSPans[rowIndex] && objectIncludeRowSPans[rowIndex].length > 0) {
  //         objectIncludeRowSPans[rowIndex].forEach(function (_tId) {
  //           if (rowIndex < $tableContainer.find('td#' + tableId + '-' + _tId)[0].rowSpan) {
  //             $tableContainer.find('td#' + tableId + '-' + _tId)[0].rowSpan = $tableContainer.find('td#' + tableId + '-' + _tId)[0].rowSpan - 1;
  //           } else {
  //             _tId = _tId.split('_');
  //             _tId.splice(0, 1);
  //             _tId.forEach(function (a) {
  //               $tableContainer.find('td#' + tableId + '-' + (rowIndex + 1 + '_' + a)).remove();
  //             });
  //             _tId = _tId.join('_');
  //             $tableContainer.find('td#' + tableId + '-' + (rowIndex + 1 + '_' + _tId)).remove();
  //           }
  //         });
  //       }

  //       if ($tableContainer.find('td#' + tdSecondId).length > 0) {
  //         ROW = 1;
  //         $tableContainer.find('td#' + tdSecondId).height($(this).parent('td').height() + $tableContainer.find('td#' + tdSecondId).height()); // merge height
  //       } else {
  //         $tableContainer.find(`Table#${tableId}`).height(tblHeight - tdHeight);
  //       }
  //       $(this).parent('td').parent('tr').remove();
  //     }

  //     setTimeout(function () {
  //       addItemsToResizeTable();
  //       showJSON(tableId, callback, page, 'delete row');
  //     }, 100);
  //   }
  // })
  // .on('dblclick', `#${tableId} .drag-item`, function () {
  //   // Delete Table
  //   deleteCallback();
  // })
  // .on('dblclick', `.Table-resizable#${tableId}  .merge-h`, function () {
  //   // merge cells
  //   redips.init();
  //   redips.merge();
  //   addItemsToResizeTable('merge');
  //   let copied = showJSON(tableId, callback, page, 'merge H');
  //   cellRowSpanes(copied);
  // })
  // .on('dblclick', `.Table-resizable#${tableId} .merge-v`, function () {
  //   // merge cells
  //   redips.init();
  //   redips.merge();
  //   addItemsToResizeTable('merge');
  //   let copied = showJSON(tableId, callback, page, 'merge V');
  //   cellRowSpanes(copied);
  // })
  // .on('mouseover', `.Table-resizable#${tableId} .drag-item`, function (event) {
  //   if (hasPermission) {
  //     // Table draggable: true
  //     pressed = true;
  //     resizeType = 'drag';
  //     $tableContainer.find(`.${fieldId}`).draggable();
  //     $tableContainer.find(`.${fieldId}`).draggable('enable');
  //   }
  // })
  // .on('mouseleave', `.Table-resizable#${tableId} .drag-item`, function (event, ui) {
  //   // Table draggable: false

  //   if (hasPermission) {
  //     pressed = false;
  //     resizeType = '';
  //     $tableContainer.find(`.${fieldId}`).draggable('disable');
  //   }
  // });

  return $tableContainer;
};

// const getRowIndex = function (id) {
//   id = id.split('-')[1].split('_')[0];
//   return parseInt(id);
// };

// export const showJSON = function (tableId, callback, page, from) {
//   if ($(`Table#${tableId}`).length > 0) {
//     const TABLE_JSON = { horizontal_dividers: [], vertical_dividers: [] };
//     let firstRow;
//     let firstCol;
//     const x = $(`.carousel-item.active img`).width();
//     const y = $(`.carousel-item.active img`).height();
//     const TW = $(`Table#${tableId}`).width();
//     const TH = $(`Table#${tableId}`).height();
//     TABLE_JSON['y_left_top'] = ($(`Table#${tableId}`)[0].offsetTop / y) * 100;
//     TABLE_JSON['x_left_top'] = ($(`Table#${tableId}`)[0].offsetLeft / x) * 100;
//     TABLE_JSON['height'] = ($(`Table#${tableId}`)[0].offsetHeight / y) * 100;
//     TABLE_JSON['width'] = ($(`Table#${tableId}`)[0].offsetWidth / x) * 100;
//     TABLE_JSON['key'] = $(`Table#${tableId}`)[0].id;
//     $(`Table#${tableId}`)
//       .find('tr')
//       .each(function (index) {
//         if (index !== 0) {
//           if (index === $(`Table#${tableId}`).find('tr').length - 1) {
//             TABLE_JSON.horizontal_dividers.push({
//               y_offset: parseFloat((((this.offsetTop + this.offsetHeight) / TH) * 100).toString()),
//               interruptions: [],
//             });
//           } else {
//             TABLE_JSON.horizontal_dividers.push({
//               y_offset: parseFloat((((this.offsetTop + this.offsetHeight) / TH) * 100).toString()),
//               interruptions: [],
//             });
//           }
//         } else {
//           firstRow = $(this).height();
//           const length = $(this).find('td').length - 1;
//           $(this)
//             .find('td')
//             .each(function (_index) {
//               if (_index === length) {
//                 TABLE_JSON.vertical_dividers.push({
//                   x_offset: parseFloat((((this.offsetLeft + this.offsetWidth) / TW) * 100).toString()),
//                   interruptions: [],
//                 });
//               } else if (_index !== 0) {
//                 if (_index === 1) {
//                   TABLE_JSON.vertical_dividers.push({
//                     x_offset: parseFloat((((this.offsetLeft + this.offsetWidth) / TW) * 100).toString()),
//                     interruptions: [],
//                   });
//                 } else {
//                   TABLE_JSON.vertical_dividers.push({
//                     x_offset: parseFloat((((this.offsetLeft + this.offsetWidth) / TW) * 100).toString()),
//                     interruptions: [],
//                   });
//                 }
//               } else {
//                 firstCol = parseFloat((((this.offsetLeft + this.offsetWidth) / TW) * 100).toString());
//               }
//             });
//         }
//       });

//     $(`Table#${tableId}`)
//       .find('tr')
//       .each(function (index) {
//         if (index !== 0) {
//           $(this)
//             .find('td')
//             .each(function (_index) {
//               if (_index !== 0) {
//                 if (this.id && this.id !== '') {
//                   const colId = this.id.split('-')[1].split('_');
//                   const rowId = getRowIndex(this.id);
//                   if (this.colSpan > 1) {
//                     const from = parseInt(colId[1]);
//                     const to = parseInt(colId.pop());
//                     for (let i = from; i < to; i++) {
//                       if (TABLE_JSON.vertical_dividers[i - 1]) {
//                         TABLE_JSON.vertical_dividers[i - 1].interruptions.push({
//                           y_offset_start: rowId === 1 ? firstRow : TABLE_JSON.horizontal_dividers[rowId - 2].y_offset,
//                           y_offset_end: TABLE_JSON.horizontal_dividers[rowId + this.rowSpan - 2].y_offset,
//                         });
//                       }
//                     }
//                   }
//                   if (this.rowSpan > 1) {
//                     const from = rowId;
//                     const to = rowId + this.rowSpan;
//                     const colId = this.id.split('-')[1].split('_');
//                     const start = parseInt(colId[1]) - 2;
//                     const end = parseInt(colId.pop()) - 1;
//                     for (let i = from; i < to - 1; i++) {
//                       TABLE_JSON.horizontal_dividers[i - 1].interruptions.push({
//                         x_offset_start:
//                           this.id.split('_')[1] === '1' ? firstCol : TABLE_JSON.vertical_dividers[start].x_offset,
//                         x_offset_end: TABLE_JSON.vertical_dividers[end].x_offset,
//                       });
//                     }
//                   }
//                 }
//               }
//             });
//         }
//       });
//     ROW = 0;
//     const tableData = [];
//     $(`Table#${tableId}`)
//       .find('tr')
//       .each(function (index) {
//         if (index !== 0) {
//           const rowData = [];
//           $(this)
//             .find('td')
//             .each(function (_index) {
//               if (_index !== 0) {
//                 rowData.push({
//                   key: index + '-' + _index,
//                   col_span: this.colSpan,
//                   row_span: this.rowSpan,
//                   text: '',
//                   x_left_top: (($(`Table#${tableId}`)[0].offsetLeft + this.offsetLeft) / x) * 100 + 0.03,
//                   y_left_top: (($(`Table#${tableId}`)[0].offsetTop + this.offsetTop) / y) * 100 + 0.03,
//                   width: ($(this).width() / x) * 100 - 0.03,
//                   height: ($(this).height() / y) * 100 - 0.03,
//                 });
//               }
//             });
//           if (rowData.length > 0) {
//             tableData.push(rowData);
//           }
//         }
//       });
//     TABLE_JSON['cells'] = {
//       data: tableData,
//       id: TABLE_JSON.key,
//       y_left_top: TABLE_JSON.y_left_top,
//       x_left_top: TABLE_JSON.x_left_top,
//       width: TABLE_JSON.width,
//       height: TABLE_JSON.height,
//     };
//     TABLE_JSON['updated'] = true;
//     callback(TABLE_JSON);
//     return TABLE_JSON;
//   }
// };

// export const tableTagToCellTag = ({ page, Table, Table: { height, width, x_left_top, y_left_top } }) => ({
//   _id: Table._id,
//   height: `${height}%`,
//   width: `${width}%`,
//   left: `${x_left_top}%`,
//   top: `${y_left_top}%`,
//   page,
//   hasValidOcrValue: true,
//   hasValidValue: true,
// });
