import React, { useRef } from 'react';
import Select, { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import './selectbox.scss';

const IconOption = (props) => {
  const icon = !props.data.iconPath ? null : (
    <img className={'me-3'} src={props.data.iconPath} alt={props.data.label} />
  );

  return (
    <components.Option {...props}>
      {icon}
      {props.data.label}
    </components.Option>
  );
};

const SelectBox = (props) => {
  const {
    id,
    withIcons,
    value,
    isMulti,
    isCreatable,
    placeholder,
    noOptionsMessage,
    onChange,
    onInputChange,
    isLoading,
    options,
    selectErrorClass,
    classes,
    isClearable,
    isValid,
    name,
    defaultValue,
    inputValue,
    isOptionDisabled,
    isDisabled,
    menuPlacement = 'bottom',
    styles,
    formatOptionLabel,
    ...rest
  } = props;

  const components = {};

  const selectRef = useRef(null);
  const _withIcons = withIcons && Array.isArray(options) && !!options.find(({ iconPath }) => !!iconPath);

  if (_withIcons) {
    components.Option = IconOption;
  }

  return isCreatable ? (
    <CreatableSelect
      ref={selectRef}
      styles={styles}
      components={components}
      inputValue={inputValue}
      isClearable={isClearable}
      isMulti={isMulti}
      name={name}
      onChange={onChange}
      defaultValue={defaultValue}
      onInputChange={onInputChange}
      placeholder={placeholder || ''}
      options={options}
      value={value}
      isDisabled={isDisabled}
      menuPlacement={menuPlacement}
      formatCreateLabel={(userInput) => `Add new group ' ${userInput} '`}
      defaultMenuIsOpen={false}
      className={`selectbox form-control ${selectErrorClass} ${classes} ${isValid === false ? 'border-danger' : ''}`}
      classNamePrefix='selectbox'
      {...rest}
    />
  ) : (
    <Select
      ref={selectRef}
      id={id}
      styles={styles}
      components={components}
      isClearable={!!isClearable}
      isLoading={isLoading}
      className={`selectbox form-control ${selectErrorClass} ${classes} ${isValid === false ? 'border-danger' : ''}`}
      classNamePrefix='selectbox'
      name={name}
      closeMenuOnSelect={!isMulti}
      placeholder={placeholder || ''}
      noOptionsMessage={() => noOptionsMessage || 'No results found'}
      isMulti={isMulti}
      isOptionDisabled={isOptionDisabled}
      isDisabled={isDisabled}
      value={value}
      defaultValue={defaultValue}
      onChange={onChange}
      options={options}
      menuPlacement={menuPlacement}
      onFocus={() => (!isValid ? (selectRef.current.select.controlRef.style.border = '1px solid #2bb673') : null)}
      onBlur={() => (!isValid ? (selectRef.current.select.controlRef.style.border = '1px solid #4d4e4f') : null)}
      isSearchable={true}
      formatOptionLabel={formatOptionLabel}
      {...rest}
    />
  );
};

export default SelectBox;
