// @ts-nocheck
import _ from 'lodash';
import {
  APPEND_DOCUMENT_FIELDS,
  APPEND_FIELD_TO_TEMPLATE,
  APPEND_TAG,
  APPEND_TEMP_TAG,
  APPLY_TEMPLATE,
  CHANGE_PAGE,
  CLEAR_DOCUMENT,
  CLEAR_LABELS,
  CLEAR_SMART_TAGS,
  COPY_TEMPLATE_DATA,
  DATA_TRANSFER_STATUS,
  DELETE_FIELDS,
  DELETE_TAG,
  DESELECT_FIELD,
  DESELECT_TEMP_FIELD,
  DETACH_TEMPLATE,
  QUERY_DOCUMENT,
  QUERY_DOCUMENT_LABELS,
  REDO,
  REFRESH_FIELD_COMPONENTS,
  REPEAT_DOCUMENT_FIELDS,
  RESET_ACTIVE_TEMPLATE,
  RESET_DATA_LOST_DIALOG,
  RESET_FIELD_SCALE_VALUE,
  SAVE_DOCUMENT_TEMPLATE_DATA,
  SELECT_FIELD,
  SELECT_TAG,
  SELECT_TEMP_FIELD,
  SET_ACTIVE_SMART_HOVER_TEMPLATE,
  SET_ACTIVE_SMART_TAG,
  SET_DATA_LOST_DIALOG,
  SET_DOCUMENT_SMART_HOVER_TEMPLATES,
  SET_FIELD_SCALE,
  SET_IS_SMART_TAG,
  SET_ORIGIN_SMART_TAGS,
  SET_PIXELPERFECT_OPTION_DISABLED,
  SET_REFERENCE_SMART_TAGS,
  SET_STEP_FINISH_TIMESTAMP,
  SHOW_LABELS,
  TAGGING_EDITOR_STATE,
  TAGS_BRING_TO_BACK,
  TAGS_BRING_TO_FRONT,
  TOGGLE_TAGS_VISIBILITY,
  UNDO,
  UPDATE_DOCUMENT_FIELDS,
  UPDATE_DOCUMENT_FIELDS_ORDER,
  UPDATE_FIELD_TABLE_TYPE,
  UPDATE_SMART_TAG_ORIGIN_TAG,
  UPDATE_TEMPLATE_STATUS,
  UPDATE_VALUE,
  UPSERT_DOCUMENT_FIELDS,
  UPSERT_TAG,
  UPSERT_TAGS,
} from './types';

import { getDocumentWorkflowStepByTemplateId, getFieldFromSmartTagOrigins, getTagFromField } from './utils';
import palette from '../../components/Annotation/ClassifierFields/colors';

const defaultState = { smartHoverTemplates: [], activeSmartHoverTemplate: {} };
const documentReducer = (state = defaultState, action) => {
  const document = { ...state };
  switch (action.type) {
    case QUERY_DOCUMENT:
      const activeTemplate = getActiveTemplate(action.payload);
      const palettes = addPaletteToFields(activeTemplate.template.fields);
      action.payload.activeTemplate = activeTemplate;
      action.payload.originFields = activeTemplate.template.fields;
      action.payload.palettes = palettes;
      return { ...document, ...action.payload };
    case UPSERT_TAG: {
      const { fieldId, tag: updatedTag } = action.payload;

      const activeTplClone = _.cloneDeep(document.activeTemplate);
      const field = activeTplClone.fields.find((f) => f._id === fieldId);

      let currentTag;
      if (field.dataType !== 'section') {
        currentTag = field.tags.find((t) => t._id === updatedTag._id);
      } else {
        if (updatedTag.section) {
          delete updatedTag.section;
        }
        currentTag = Object.values(field.tags[0].section).find((t) => t._id === updatedTag._id);
      }
      if (currentTag) currentTag = Object.assign(currentTag, updatedTag);
      document.activeTemplate = activeTplClone;
      return document;
    }
    case UPSERT_TAGS: {
      const fieldToTags = action.payload;
      document.templates = _.cloneDeep(document.templates);
      const activeTemplate = getActiveTemplate(document);
      activeTemplate.fields.forEach((f) => {
        if (f._id in fieldToTags) {
          f.tags = fieldToTags[f._id];
        }
      });
      return document;
    }
    case APPEND_FIELD_TO_TEMPLATE: {
      const { field } = action.payload;
      const activeTemplate = _.cloneDeep(document.activeTemplate);
      activeTemplate.fields = [...activeTemplate.fields, field];
      document.activeTemplate = activeTemplate;
      if (field.dataType === 'section' || field.multiple) document.repeatedFieldId = field._id;
      //document.activeField = undefined;
      return document;
    }
    case APPEND_TAG: {
      const { fieldId, tag } = action.payload;
      // const field = _getField(document, fieldId, true);
      const activeTplClone = _.cloneDeep(document.activeTemplate);
      const field = activeTplClone.fields.find((f) => f._id === fieldId);

      field.value = '';
      field.hasValidValue = false;
      if (field?.dataType !== 'section') {
        field.tags.push(tag);
      } else {
        if (field.tags[0] && field.tags[0].section && field.tags[0].section.end && field.tags[0].section.start) {
          field.tags = [];
        }
        if (tag.section && tag.section.start && tag.section.end) {
          field.tags = [];
        }
        field.tags.push(tag);
      }

      document.activeTemplate = activeTplClone;
      return document;
    }
    case DELETE_TAG: {
      const { fieldId, tagId } = action.payload;
      const { activeTemplate, fieldIndex } = getActiveTplAndField(document, fieldId);
      const field = activeTemplate.fields[fieldIndex];

      if (field?.dataType !== 'section') {
        field.tags = field.tags.filter((t) => t._id !== tagId);
      } else {
        if (field.tags && field.tags.length > 0) {
          if (field.tags[0].section) {
            Object.keys(field.tags[0].section).forEach((key) => {
              if (field.tags[0].section[key]._id === action.payload.tagId) {
                delete field.tags[0].section[key];
              }
            });
            if (field.tags[0] && field.tags[0].section && Object.keys(field.tags[0].section).length === 0) {
              field.tags = [];
            }
          }
        }
      }

      // if (!field.tags.length) {
      //   activeTemplate.fields = activeTemplate.fields.filter((f) => f._id !== field._id);
      //   document.repeatedFieldId = null;
      // }
      document.activeTemplate = activeTemplate;
      return document;
    }
    case SELECT_TEMP_FIELD: {
      const newActiveField = action.payload;
      const activeTemplate = _.cloneDeep(document.activeTemplate);
      const newField = _.cloneDeep(newActiveField);
      activeTemplate.activeFieldId = newField._id;
      document.activeTemplate = activeTemplate;
      document.activeField = newField;
      document.repeatedFieldId = null;
      return document;
    }
    case DESELECT_TEMP_FIELD: {
      const activeTemplate = { ...document.activeTemplate };
      activeTemplate.activeFieldId = null;
      document.activeTemplate = activeTemplate;
      document.activeField = null;
      document.repeatedFieldId = null;
      return document;
    }
    case APPEND_TEMP_TAG: {
      const { tag } = action.payload;
      const field = _.cloneDeep(document.activeField);

      field.value = '';
      field.hasValidValue = false;

      if (field?.dataType !== 'section') {
        field.tags.push(tag);
      } else {
        if (field.tags[0] && field.tags[0].section && field.tags[0].section.end && field.tags[0].section.start) {
          field.tags = [];
        }
        if (tag.section && tag.section.start && tag.section.end) {
          field.tags = [];
        }
        field.tags.push(tag);
      }
      document.activeField = field;
      const activeTpl = getActiveTemplateClone(document);
      activeTpl.fields.push(field);
      document.templates = [...document.templates, activeTpl];

      return document;
    }
    case SELECT_FIELD: {
      if (document.activeField) return state;
      const newActiveFieldId = action.payload;
      const { activeTemplate, fieldIndex } = getActiveTplAndField(document, newActiveFieldId);
      if (document.activeTemplateId) {
        const field = activeTemplate.fields[fieldIndex];

        activeTemplate.activeFieldId = newActiveFieldId;
        if (!activeTemplate.activeTagId || !field.tags.find((tag) => tag && tag._id === activeTemplate.activeTagId)) {
          if (field.dataType === 'section') {
            activeTemplate.activeTagId =
              field.tags[0] &&
              field.tags[0].section &&
              (field.tags[0].section.start?._id || field.tags[0].section.end?._id || null);
          } else {
            activeTemplate.activeTagId = field.tags[0] ? field.tags[0]._id : null;
          }
        }
      } else {
        document.activeFieldId = newActiveFieldId;
      }
      document.activeTemplate = activeTemplate;
      return document;
    }
    case SELECT_TAG: {
      const { activeTagId, activePage } = action.payload;
      if (document.activeTemplateId) {
        const activeTemplate = { ...document.activeTemplate };
        activeTemplate.activeTagId = activeTagId;
        activeTemplate.activePage = activePage;
        document.activeTemplate = activeTemplate;
      } else {
        document.activeTagId = activeTagId;
      }

      return document;
    }
    case CHANGE_PAGE: {
      document.activePage = action.payload;
      return document;
    }
    case UPSERT_DOCUMENT_FIELDS: {
      const { fields } = action.payload;
      if (document.activeTemplateId) {
        const activeTemplate = getActiveTemplate(document);
        activeTemplate.fields = fields;
        document.activeTemplate = activeTemplate;
      } else {
        document.fields = fields;
      }
      return document;
    }
    case UPDATE_DOCUMENT_FIELDS: {
      if (!document.activeTemplateId) {
        return state;
      }

      const { fields: updateFields = [] } = action.payload;

      const activeTemplate = getActiveTemplate(document);

      const updateFieldIdToFieldMap = updateFields.reduce((aggr, updateField) => {
        aggr[updateField._id] = updateField;
        return aggr;
      }, {});

      activeTemplate.fields.forEach((f) => {
        const updatedField = updateFieldIdToFieldMap[f._id];
        if (updatedField) {
          Object.assign(f, updatedField);
        }
      });

      document.activeTemplate = activeTemplate;
      return document;
    }
    case APPEND_DOCUMENT_FIELDS:
    case REPEAT_DOCUMENT_FIELDS: {
      const { newFields } = action.payload;

      if (document.activeTemplateId) {
        document.templates = _.cloneDeep(document.templates);
        const activeTemplate = getActiveTemplate(document);
        activeTemplate.fields.push(...newFields);
      } else {
        document.fields = [...document.fields, ...newFields];
      }
      return document;
    }
    case DELETE_FIELDS: {
      const { fieldIds } = action.payload;
      const activeTemplate = getActiveTemplate(document);
      activeTemplate.fields = activeTemplate.fields.filter((f) => !fieldIds.includes(f._id));
      document.activeTemplate = activeTemplate;

      document.repeatedFieldId = null;
      return document;
    }
    case APPLY_TEMPLATE:
      const { templateId, attachedTemplate, isLocked } = action.payload;

      document.templates = _.cloneDeep(document.templates);

      const replaceTemplateIndex = document.templates.findIndex(({ template }) => template?._id === templateId);

      if (replaceTemplateIndex > 0) {
        const oldTemplate = document.templates[replaceTemplateIndex];

        if (oldTemplate._id === document.activeTemplateId) {
          document.activeTemplateId = null;
        }

        document.templates[replaceTemplateIndex] = attachedTemplate;
        document.saveSuccessful = 'success';
      } else {
        document.templates.push(attachedTemplate);
        if (document.templates.length === 1) {
          document.activeTemplateId = attachedTemplate._id;
        }
      }
      document.isLocked = isLocked;
      return document;
    case DETACH_TEMPLATE: {
      const { templateId } = action.payload;

      if (document.activeTemplateId === templateId) {
        document.activeTemplateId = null;
        document.saveBtnActive = false;
      }

      if (document.activeSmartHoverTemplate.template === templateId) {
        document.activeSmartHoverTemplate = {};
      }

      // document.templates = document.templates.filter((t) => t?.template?._id !== templateId);
      document.activeTemplate = null;
      return document;
    }

    case RESET_ACTIVE_TEMPLATE: {
      const { activeTemplate, isLocked } = action.payload;
      document.activeTemplate = activeTemplate;
      document.activeTemplateId = activeTemplate?._id;
      document.activeField = null;
      document.isLocked = isLocked;
      // document.saveBtnActive = false;

      return document;
    }

    case UPDATE_VALUE: {
      const { fieldId, tagId, value, onlyValue, ocrValue, hasValidValue, hasValidOcrValue } = action.payload;
      const { activeTemplate, fieldIndex } = getActiveTplAndField(document, fieldId);
      const field = activeTemplate.fields[fieldIndex];

      if (!field) {
        return document;
      } else {
        const tag = tagId && field.tags.find((t) => t._id === tagId);
        const target = tag || field;
        target.value = value;
        if (!onlyValue) {
          target.ocrValue = ocrValue;
          target.hasValidOcrValue = hasValidOcrValue;
        }
        target.hasValidValue = hasValidValue;
      }

      document.activeTemplate = activeTemplate;
      return document;
    }

    case RESET_FIELD_SCALE_VALUE: {
      const { activeTemplate, fieldIndex } = getActiveTplAndField(document, action.payload);
      const field = activeTemplate.fields[fieldIndex];
      field.scale = 1;
      document.activeTemplate = activeTemplate;
      return document;
    }
    case TAGGING_EDITOR_STATE: {
      document.taggingEditorState = action.payload;
      return document;
    }
    case TOGGLE_TAGS_VISIBILITY: {
      document.unselectedTagsNotVisible = !document.unselectedTagsNotVisible;
      return document;
    }
    case SHOW_LABELS: {
      document.labels = action.payload;
      return document;
    }
    case SET_IS_SMART_TAG: {
      if (!document) return document;
      const isSmartTag = action.payload;
      document.smartTags = _.cloneDeep(document.smartTags ?? {});
      document.smartTags.isSmartTag = isSmartTag;
      return document;
    }
    case SET_PIXELPERFECT_OPTION_DISABLED: {
      if (!document) {
        return document;
      }
      const isDisabled = action.payload;
      document.smartTags = _.cloneDeep(document.smartTags ?? {});
      document.smartTags.pixelPerfectOptionDisabled = isDisabled;
      return document;
    }
    case SET_ORIGIN_SMART_TAGS: {
      if (!document) {
        return document;
      }
      const origin = action.payload;
      document.smartTags = _.cloneDeep(document.smartTags ?? {});
      document.smartTags.origin = origin;
      return document;
    }
    case UPDATE_SMART_TAG_ORIGIN_TAG: {
      if (!document || !document.smartTags || !document.smartTags.activeTag) {
        return state;
      }

      document.smartTags = _.cloneDeep(document.smartTags);
      const activeTag = document.smartTags?.activeTag;

      activeTag.originFields.some((field) => {
        if (field._id !== action.payload.fieldId) {
          return false;
        }
        field.tags.some((tag) => {
          if (tag._id !== action.payload.tag._id) {
            return false;
          }

          Object.assign(tag, action.payload.tag);
          return true;
        });

        return true;
      });

      const origin = document?.smartTags?.origin;
      if (!origin) {
        return state;
      }

      const field = getFieldFromSmartTagOrigins(origin, action.payload.srcFieldId, action.payload.fieldId);
      const tag = getTagFromField(field || {}, action.payload.tag._id);
      Object.assign(tag, action.payload.tag);

      document.smartTags = _.cloneDeep(document.smartTags ?? {});

      return document;
    }
    case SET_REFERENCE_SMART_TAGS: {
      if (!document) {
        return document;
      }
      const reference = action.payload;
      document.smartTags = _.cloneDeep(document.smartTags ?? {});
      document.smartTags.reference = reference;
      return document;
    }
    case SET_ACTIVE_SMART_TAG: {
      if (!document) {
        return document;
      }
      const activeTag = action.payload;
      document.smartTags = _.cloneDeep(document.smartTags ?? {});
      document.smartTags.activeTag = activeTag;
      document.activeField = null;
      return document;
    }
    case CLEAR_SMART_TAGS: {
      if (!document) {
        return document;
      }
      const origin = document.smartTags?.origin;

      delete document.smartTags;

      if (origin) {
        document.smartTags = { origin };
      }
      return document;
    }
    case CLEAR_LABELS: {
      document.labels = [];
      return document;
    }
    case CLEAR_DOCUMENT: {
      return defaultState;
    }
    case UPDATE_DOCUMENT_FIELDS_ORDER: {
      if (document.activeTemplateId) {
        document.templates = _.cloneDeep(document.templates);
        const activeTemplate = getActiveTemplate(document);
        activeTemplate.fields = action.payload;
      } else {
        document.fields = action.payload;
      }
      return document;
    }
    case UPDATE_FIELD_TABLE_TYPE: {
      const { data, fieldId, type } = action.payload;

      if (data) {
        const { activeTemplate, fieldIndex } = getActiveTplAndField(document, fieldId);
        const field = activeTemplate.fields[fieldIndex];
        const tagIndex = field.tags.findIndex((t) => t.isTable);
        if (tagIndex < 0) return document;
        const tag = field.tags[tagIndex];
        tag.table = data;
        document.activeTemplate = activeTemplate;
      }

      const tableFields = {};
      tableFields[fieldId] = type;
      document['tableTypes'] = { ...tableFields };
      return document;
    }
    case UPDATE_TEMPLATE_STATUS: {
      const isFinished = action.payload;
      const activeTemplate = { ...document.activeTemplate };
      activeTemplate.isFinished = isFinished;
      document.saveBtnActive = true;
      delete document.saveSuccessful;
      document.activeTemplate = activeTemplate;
      return document;
    }
    case DESELECT_FIELD: {
      const activeTemplate = { ...document.activeTemplate };
      activeTemplate.activeFieldId = null;
      activeTemplate.activeTagId = null;
      document.activeTemplate = activeTemplate;
      return document;
    }
    case COPY_TEMPLATE_DATA: {
      return action.payload;
    }
    case SET_DATA_LOST_DIALOG: {
      document.dataLostDialog = action.payload;
      return document;
    }
    case RESET_DATA_LOST_DIALOG: {
      document.dataLostDialog = null;
      return document;
    }
    case SAVE_DOCUMENT_TEMPLATE_DATA: {
      const { status, isLocked, collectionId } = action.payload;
      if (!status) {
        delete document.saveSuccessful;
      } else {
        document.saveSuccessful = status;
      }

      if (collectionId) {
        document.collectionId = collectionId;
      }

      document.saveBtnActive = status === 'error';
      document.isLocked = isLocked;
      return document;
    }
    case QUERY_DOCUMENT_LABELS: {
      document.pageLabels = action.payload;
      return document;
    }
    case TAGS_BRING_TO_FRONT: {
      document.tagsBringedToBack = false;
      return document;
    }
    case TAGS_BRING_TO_BACK: {
      document.tagsBringedToBack = true;
      return document;
    }
    case UNDO: {
      const {activeTemplate,activePage} = action.payload;
      document.activeTemplate = activeTemplate;
      document.activePage = activePage;
      return document;
    }
    case REDO: {
      const {activeTemplate,activePage} = action.payload;
      document.activeTemplate = activeTemplate;
      document.activePage = activePage;
      return document;
    }
    case REFRESH_FIELD_COMPONENTS: {
      if (document.activeTemplateId) {
        const { fieldId, data } = action.payload;
        document.templates = _.cloneDeep(document.templates);
        const activeTemplate = getActiveTemplate(document);
        const field = _getField(activeTemplate.fields, fieldId);
        field.formulaComponents = data;
      }
      return document;
    }
    case DATA_TRANSFER_STATUS: {
      document.transferStatus = action.payload.transferStatus;
      return document;
    }
    case SET_FIELD_SCALE: {
      const { activeTemplate, fieldIndex } = getActiveTplAndField(document, action.payload.fieldId);
      const field = activeTemplate.fields[fieldIndex];
      field && (field.scale = action.payload.scale);
      document.activeTemplate = activeTemplate;
      return document;
    }
    case SET_STEP_FINISH_TIMESTAMP: {
      const { templateId } = action.payload;

      const stepFork = getDocumentWorkflowStepByTemplateId(document, templateId);
      if (!stepFork) {
        console.log('Can not find document workflow step fork by templateId', templateId);
        return state;
      }

      stepFork.templates.some((template) => {
        if (template.template !== templateId) {
          return false;
        }
        template.finishTimestamp = new Date();
        return true;
      });

      if (document?.workflows?.length) {
        document.workflows = _.cloneDeep(document.workflows);
      }
      return document;
    }
    case SET_DOCUMENT_SMART_HOVER_TEMPLATES: {
      return { ...state, smartHoverTemplates: action.payload };
    }
    case SET_ACTIVE_SMART_HOVER_TEMPLATE: {
      return { ...state, activeSmartHoverTemplate: action.payload };
    }

    default: {
      return state;
    }
  }
};

const getActiveTplAndField = (document, fieldId) => {
  if (document.activeTemplate) {
    const activeTemplate = _.cloneDeep(document.activeTemplate);
    const fieldIndex = activeTemplate.fields.findIndex((f) => f._id === fieldId);
    return {
      activeTemplate,
      fieldIndex,
    };
  }
};

function _getField(document, fieldId, cloneTarget = true) {
  if (document.activeTemplateId) {
    if (!document.templates) return;
    if (cloneTarget) {
      document.templates = _.cloneDeep(document.templates);
    }
    const activeTemplate = getActiveTemplate(document);
    return activeTemplate.fields.find((f) => f._id === fieldId);
  } else {
    if (!document.fields) return;

    if (cloneTarget) {
      document.fields = _.cloneDeep(document.fields);
    }
    return document.fields.find((f) => f._id === fieldId);
  }
}

const getActiveTemplate = (document, clone = true) => {
  if (!document.activeTemplate && document?.activeTemplateId && document?.templates) {
    return document.templates.find((tObj) => tObj._id === document.activeTemplateId) || document.templates[0];
  } else if (!document.activeTemplate && !document?.activeTemplateId) {
    return document.templates[0];
  } else {
    return clone ? _.cloneDeep(document.activeTemplate) : document.activeTemplate;
  }
};

const addPaletteToFields = (fields) => {
  const paletteObject = {};
  for (let i = 0; i < fields.length; i++) {
    const field = fields[i];
    paletteObject[field._id] = palette[i] || palette[0];
  }
  return paletteObject;
};

export default documentReducer;
