import React, { useEffect, useState } from 'react';
// import { openMetaInfoModal } from '../../modal/service/ModalControllers';
import Search from '../Search/Search';
import { useAppDispatch } from '../../../hooks/reduxHooks';
import { queryOriginSmartTags, toggleTagsVisibility } from '../../../actions';
import { useSelector } from 'react-redux';
import { documentSelector } from '../../../store/document/selectors';
import SmartTagDropDown from '../../SmartTagDropdown';
import Button from '../../Common/Button';

const TopToolBar: React.FC = (props: any) => {
  const [state, setState] = useState({
    isSearchSelect: false,
  });
  const dispatch = useAppDispatch();

  const document = useSelector(documentSelector);
  const { selectSearchText } = props;

  const highlightSearchText = (e: any) => {
    e.preventDefault();
    setState({
      ...state,
      isSearchSelect: !state.isSearchSelect,
    });
    selectSearchText();
  };

  const handleTagsVisibility = (e: any) => {
    e.preventDefault();
    dispatch(toggleTagsVisibility());
  };

  useEffect(() => {
    dispatch(queryOriginSmartTags(true));
  }, [dispatch]);


  return (
    <div className='d-flex justify-content-between align-items-center gap-1' id='annotation-tools'>
      <Button
        variant='primary'
        onClick={highlightSearchText}
        icon='LocationSearching'
        className={`col-auto my-auto ${state.isSearchSelect ? 'crosshair-selected' : ''} `}
      >
        Search Selection
      </Button>
      <Search />
      <div className='d-flex ms-auto gap-1'>
        <Button
          variant='primary'
          onClick={handleTagsVisibility}
          icon={document.unselectedTagsNotVisible ? 'UnselectedTagsNotVisible' : 'UnselectedTagsVisible'}
          className='col-auto rounded-start rounded-0 ms-6'
        >
          {document.unselectedTagsNotVisible ? 'Show' : 'Hide'} Tags
        </Button>

        {/*TODO Tag Label Switcher button
        <TagLabelSwitcher asButton={true}/>
        */}


        <SmartTagDropDown
          currentPageNumber={document.activePage ?? 1}
          taggingEditorState={document.taggingEditorState}
          smartTags={document.smartTags || undefined}
          documentId={document._id}
        />


        {/*TODO Temporary disabled
         <SmartHoverDropDown documentId={document._id} />*/}

       {/* <Button
          variant='primary'
          onClick={() => dispatch(tagsBringSateToggle())}
          icon={document.tagsBringedToBack ? 'TagsBringToBack' : 'TagsBringToFront'}
          className='rounded-end'
        >
          {document.tagsBringedToBack ? 'Smart Tag to Front' : 'Annotation to Front'}
        </Button>*/}
        {/*{showMetadata && (*/}
        {/*  <Button*/}
        {/*    variant='primary'*/}
        {/*    onClick={openMetaInfoModal}*/}
        {/*    icon='MetadataModalOpener'*/}
        {/*    className='col-auto rounded-0 rounded-end'*/}
        {/*  >*/}
        {/*    Metadata*/}
        {/*  </Button>*/}
        {/*)}*/}
      </div>
    </div>
  );
};
export default TopToolBar;
