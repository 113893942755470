import { useEffect, useMemo, useState } from 'react';
import { SelectboxOption } from '../types';

interface UseOptionsProps<T extends any, K extends any> {
  getter: () => Promise<T[]>;
  iterator: (param: T) => SelectboxOption<string, K>;
  filterIterator?: (param: SelectboxOption<string, K>) => boolean;
  onStartLoading?: () => void;
  onEndLoading?: () => void;
}

export function useSelectboxOptions<T extends any, K extends any = any>({
  getter,
  iterator,
  filterIterator,
  onStartLoading,
  onEndLoading,
}: UseOptionsProps<T, K>): SelectboxOption<string, K>[] {
  const [usedOnce, setUsedOnce] = useState<boolean>(false);
  const [options, setOptions] = useState<SelectboxOption<string, K>[]>([]);

  useEffect(() => {
    if (usedOnce) {
      return;
    }
    if (onStartLoading) {
      onStartLoading();
    }

    setUsedOnce(true);

    getter()
      .then((list) => {
        setOptions(list.map(iterator));
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        if (onEndLoading) {
          onEndLoading();
        }
      });
  }, [usedOnce, getter, iterator, onStartLoading, onStartLoading]);

  const filteredOptions = useMemo(() => (filterIterator ? options.filter(filterIterator) : options), [
    filterIterator,
    options,
  ]);

  return filteredOptions;
}
