// by Rouben Meschian - rmeschian@gmail.com

import { guid } from './utils';

function createCell(mixin = {}) {
  return {
    id: `table-cell-${guid()}`,
    // type: 'td',
    ...mixin,
  };
}

function createVResizer(mixin = {}) {
  return {
    id: `v-resizer-${guid()}`,
    // type: 'th',
    style: { height: 0, width: 0 },
    ...mixin,
  };
}

function createHResizer(mixin = {}) {
  return {
    id: `h-resizer-${guid()}`,
    // type: 'td',
    style: { height: 0, width: 0 },
    ...mixin,
  };
}

function createTableRow(mixin = {}) {
  return {
    id: `tr-${guid()}`,
    // type: 'tr',
    td: [],
    ...mixin,
  };
}

function createTbody(mixin = {}) {
  return {
    id: `tbody-${guid()}`,
    // type: 'tbody',
    tr: [],
    ...mixin,
  };
}

function createThead(mixin = {}) {
  return {
    id: `thead-${guid()}`,
    // type: 'thead',
    tr: [createTableRow({ th: [createTopLeftCornerTh()] })],
    ...mixin,
  };
}

function createTable(mixin = {}) {
  return {
    id: `table-${guid()}`,
    // type: 'Table',
    thead: createThead(),
    tbody: createTbody(),
    style: {},
    ...mixin,
  };
}

function createTopLeftCornerTh(mixin = {}) {
  return {
    id: `top-left-corner-${guid()}`,
    style: { width: 0, height: 0 },
    ...mixin,
  };
}

function normalizeTable(table) {
  if (!table.thead) {
    table.thead = createThead();
    const vResizer = createVResizer();
    vResizer.style.width = '100%';
    table.thead.tr[0].th.push(vResizer);
  }

  if (!table.tbody) {
    table.tbody = createTbody();
    const hResizer = createHResizer();
    hResizer.style.height = '100%';
    table.tbody.tr.push(
      createTableRow({
        td: [hResizer, createCell()],
      })
    );
  }
}

export {
  createCell,
  createVResizer,
  createHResizer,
  createTableRow,
  createTbody,
  createThead,
  createTable,
  createTopLeftCornerTh,
  normalizeTable,
};
