import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import { Provider } from 'react-redux';

import { store } from './store/store';
import { Router } from 'react-router';

import history from './history';
import ModalProvider from './components/modal/service/ModalProvider';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <Provider store={store}>
    <Router history={history}>
      <App />
      <ModalProvider />
    </Router>
  </Provider>
);
