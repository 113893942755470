import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { documentActiveFieldSelector, templateFieldsSelector } from '../../../store/document/selectors';
import { getFieldsCountByPage } from '../../../helpers/common/utils';
import { changePage } from '../../../actions';
import { isEmpty } from 'lodash';
import {Button} from "@progress/kendo-react-buttons";

const ClassifierPages = () => {
  const activeField = useAppSelector(documentActiveFieldSelector);
  const fields = useAppSelector(templateFieldsSelector);
  const dispatch = useAppDispatch();

  if (!activeField) return null;

  const fieldsCountByPages: { [key: string]: number } | any = getFieldsCountByPage(fields, activeField);

  const handleClick = (page: string) => {
    dispatch(changePage(+page - 1));
  };

  return (
    <div className='classifier-pages-wrapper'>
      <h3>{activeField.name}</h3>
      <div className='classifier-page-buttons'>
        {!isEmpty(fieldsCountByPages) ? Object.keys(fieldsCountByPages).map((page, index: number) => {
          return (
            <Button className={`btn-pure`} key={index} onClick={() => handleClick(page)}>
              <u>Page </u>&nbsp;
              <u>{page}</u>&nbsp;
              <span> | {fieldsCountByPages[page]}</span>
            </Button>
          );
        }) : 'This class list is currently empty.'}
      </div>
    </div>
  );
};

export default React.memo(ClassifierPages);
