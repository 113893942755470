import React from 'react';

import { withRouter } from 'react-router-dom';
import Topnav from '../Topnav/Topnav';

const MainLayout = ({ children }) => {
  return (
    <>
      <Topnav />
      <div className='main-content pt-3'>
        <div className='container-fluid'>{children}</div>
      </div>
    </>
  );
};

export default withRouter(MainLayout);
