import React, { useEffect } from 'react';
import { useAppDispatch } from './hooks/reduxHooks';
import { setRules } from './store/auth/actions';
import { Route, Switch } from 'react-router';
import routes from './routes';
import MainLayout from './components/MainLayout';
import { getFavicon, getTitle } from './appConfig';
import { useBrowserThemeDetector } from './hooks/useBrowserThemeDetector';

const App = () => {
  const dispatch = useAppDispatch();

  document.title = getTitle();
  const browserTheme = useBrowserThemeDetector();
  // @ts-ignore
  document.getElementById('favicon')!.href = `/img/${getFavicon(browserTheme === 'dark')}`;

  useEffect(() => {
    if (localStorage.getItem('jwtToken')) {
      dispatch(setRules());
    }
  }, [dispatch]);

  return (
    <div className='App'>
      <Switch>
        {routes.map(({ path, exact, exculeTopNav, section, component: Component, ...rest }) => (
          <Route
            key={path}
            path={path}
            exact={exact}
            render={(props) =>
              exculeTopNav ? (
                // @ts-ignore
                <Component {...props} {...rest} />
              ) : (
                <MainLayout>
                  <Component {...props} {...rest} />
                </MainLayout>
              )
            }
          />
        ))}
      </Switch>
    </div>
  );
};

export default App;
