import React, { useCallback, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Icons from '../Common/Icons/Icons';
import { signIn } from '../../store/auth/actions';
import './Signin.scss';
import history from '../../history';
import { useAppDispatch } from '../../hooks/reduxHooks';
import Button from '../Common/Button';

const img = (!Math.floor(Math.random() * 2) && 'background1.jpg') || 'background2.jpg';

const Signin = () => {
  const dispatch = useAppDispatch();
  const search = useLocation().search;
  const redirectTo = new URLSearchParams(search).get('redirectTo') || '/';
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(true);

  const [errorMessage, setErrorMessage] = useState('');

  const handleEmailChange = useCallback((e) => setEmail(e.target.value), []);
  const handlePasswordChange = useCallback((e) => setPassword(e.target.value), []);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      dispatch(
        signIn(email, password, (isValid) => {
          if (isValid) {
            return history.push(redirectTo);
          }
          setErrorMessage('Invalid email or password.');
        })
      );
    },
    [dispatch, email, password, redirectTo]
  );

  const errorElement = useMemo(
    () =>
      errorMessage && (
        <div className='alert alert-danger text-center' role='alert'>
          {errorMessage}
        </div>
      ),
    [errorMessage]
  );

  return (
    <div className='sign-in container-fluid vh-100'>
      <div className='row'>
        <div className='col-lg-4 col-5 vh-100 align-items-center'>
          <div className='d-flex flex-column align-items-center justify-content-center h-100 '>
            <h1 className='display-4 text-center mb-4'>Welcome back</h1>
            <form onSubmit={(e) => handleSubmit(e)} className='w-75'>
              <div className='form-group'>
                <label className='form-label'>Email Address</label>
                <div className='input-group'>
                  <input
                    type='email1'
                    value={email}
                    onChange={handleEmailChange}
                    className={`form-control ${errorMessage ? 'border-danger' : ''}`}
                    placeholder='Enter your email'
                  />
                </div>
              </div>
              <div className='form-group'>
                <label className='form-label'>Password</label>
                <div className='input-group input-group-merge'>
                  <input
                    type={`${(showPassword && 'password') || 'text'}`}
                    value={password}
                    onChange={handlePasswordChange}
                    className={`form-control ${errorMessage ? 'is-invalid' : ''}`}
                    placeholder='Enter your password'
                    describedby='passwordEyeIcon'
                  />
                  <div id='passwordEyeIcon' className='input-group-text' onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? (
                      <span>
                        <Icons.ShowPassword />
                      </span>
                    ) : (
                      <span>
                        <Icons.HidePassword />
                      </span>
                    )}
                  </div>
                </div>
              </div>
              {errorElement}
              <div className='form-group'>
                <Button iconClass='svg-icon-md' type='submit' variant='secondary' icon='LogIn' className='float-end' />
              </div>
            </form>
          </div>
        </div>
        <div className='sign-in-image col-lg-8 col-7' style={{ backgroundImage: `url(img/${img})` }} />
      </div>
    </div>
  );
};

export default Signin;
