import React, { useState, useRef } from 'react';

import { runFormula, replaceFormulaComponents } from '../../../helpers/common/utils';
import { formatNumber } from '../../../helpers/common/formatter';

import Button from '../../Common/Button';

const FieldInput = React.memo((props) => {
  const fieldValueInput = useRef(null);
  const [formulaComponentsOpen, openFormulaComponents] = useState(false);
  const { dataType, value, fieldType } = props.field;
  const [editMode, setEditMode] = useState(false);

  const [computed] = useState(
    props.field.fieldType === 'computation' || props.field.fieldType === 'extraction and computation'
  );

  const calculateComputeFieldValues = (fields, formula) => {
    formula = replaceFormulaComponents(formula, props.field.formulaComponents);
    if (formula) {
      const value = runFormula(fields, formula);
      // FIXME: why is this UI function mutating the underlying data? This is BAD!
      props.field.hasValidValue = !isNaN(value);
      props.field.value = value;
      return !isNaN(value) ? value.toString() : 'Wrong formula';
    }
  };

  const emptyValue = (e) => {
    e.preventDefault();
    props.emptyValue();
  };

  const openFormulaComponentsSection = (e) => {
    e.preventDefault();
    openFormulaComponents(!formulaComponentsOpen);
  };

  return (
    <>
      <div className='input-group input-group-merge'>
        {dataType === 'link' && !props.linkEdited ? (
          <div className='form-control form-control-sm '>
            <a
              className='text-nowrap link-color'
              href={fieldType === 'collection' ? `/collection/${value}` : value}
              target='_blank'
            >
              {value}
            </a>
          </div>
        ) : (
          <input
            type='text'
            className='form-control form-control-sm text-end'
            onKeyDown={props.handleFieldValueOnKeyDown}
            onChange={!computed ? props.handleChange : () => {}}
            ref={fieldValueInput}
            readOnly={computed}
            value={
              computed
                ? formatNumber(calculateComputeFieldValues(props.fields, props.field.valueFormula))
                : dataType === 'number'
                ? editMode
                  ? value
                  : formatNumber(value)
                : value
            }
            onFocus={() => setEditMode(true)}
            onBlur={() => setEditMode(false)}
            disabled={props.disabled}
          />
        )}

        {!computed ? (
          <div className='input-group-text px-1 py-0 ps-2'>
            {value
              ? props.annotationPermission && (
                  <Button
                    variant='primary'
                    icon='FieldEraser'
                    onClick={(e) => emptyValue(e)}
                    className='input-button'
                  />
                )
              : ''}
          </div>
        ) : (
          <div className='input-group-text px-0 py-0 ps-0'>
            {props.annotationPermission && (
              <Button
                variant='primary'
                icon={formulaComponentsOpen ? 'FieldComponentsCollapse' : 'FieldComponentsDecollapse'}
                onClick={openFormulaComponentsSection}
              />
            )}
          </div>
        )}
      </div>
      {formulaComponentsOpen && (
        <div className='col-12'>
          <table className='w-100'>
            <tbody>
              {props.field.formulaComponents &&
                props.field.formulaComponents.map((component, index) => (
                  <tr key={`formula-${props.field._id.toString()}-${index}`}>
                    <td className='pe-1 ps-0 pt-2'>
                      <input className='form-control form-control-sm' readOnly defaultValue={component.componentName} />
                    </td>
                    <td className='ps-1 pe-0 pt-2'>
                      <input
                        className='form-control form-control-sm text-end pe-2'
                        readOnly
                        value={formatNumber(runFormula(props.fields, component.value))}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
});

export default FieldInput;
