import innolytiqApi from '../../api/innolytiqApi';
import { UPSERT_USER, QUERY_USER, IUser } from './types';
import { Dispatch } from 'redux';

export const queryUser = (userId: IUser['_id']) => async (dispatch: Dispatch) => {
  let response = { data: { _id: null } };
  try {
    response = await innolytiqApi.get(`users/${userId}`);
  } catch (e) {
    console.log({ e });
  }

  dispatch({ type: QUERY_USER, payload: response.data });
};

export const upsertUser = (user: IUser) => async (dispatch: Dispatch) => {
  const response = await innolytiqApi.post(`users/user`, user);
  if (!response.data.errors) {
    dispatch({ type: UPSERT_USER, payload: response.data });
  }
  return response;
};
