import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { documentTemplatesSelector } from '../../../../store/document/selectors';
import CustomModal from '../../../Common/CustomModal';
import Button from '../../../Common/Button';

const SelectDocumentTemplate = ({ onClose, templateIds, onSubmit }) => {
  const [documentTemplate, setDocumentTemplate] = useState(null);

  const documentTemplates = useSelector(documentTemplatesSelector);

  const filteredDocumentTemplateOptions = useMemo(() => {
    if (!documentTemplates?.length) {
      return [];
    }

    return documentTemplates
      .filter((t) => templateIds.includes(t.template._id))
      .map(({ _id, template: { name } }) => ({ label: name, value: _id }));
  }, [documentTemplates, templateIds]);

  useEffect(() => {
    if (documentTemplate || !filteredDocumentTemplateOptions.length) {
      return;
    }

    setDocumentTemplate(filteredDocumentTemplateOptions[0]);
  }, [documentTemplate, filteredDocumentTemplateOptions]);

  const handleSubmit = useCallback(() => {
    const documentTemplateObjId = documentTemplate.value;
    if (!documentTemplateObjId) {
      return;
    }

    onSubmit(documentTemplateObjId);
    onClose();
  }, [documentTemplate, onSubmit]);

  const handleClose = useCallback(() => {
    onSubmit();
    onClose();
  }, [onClose, onSubmit]);

  return (
    <CustomModal
      open={true}
      modalHandler={handleClose}
      onClick={(e) => {
        e.preventDefault();
        if (!e.target.closest('.modal-content')) {
          handleClose();
        }
      }}
      classNames={{
        modal: 'custom-modal-xsm',
      }}
    >
      <div className='modal-content'>
        <div className='modal-header'>
          <h4 className='modal-title workflow-template-flow-modal-empty-title'></h4>
        </div>
        <div className='modal-body max'>
          <form onSubmit={handleSubmit}>
            <div className='form-group'>
              <label className='form-label'>
                In order to progress in the workflow journey, we need to delve into some more insightful tasks! Let’s
                get cracking and make this project even better.
              </label>
            </div>

            <div className='workflow-template-flow-select-template-viewport'>
              {filteredDocumentTemplateOptions?.map((option) => (
                <div className='form-group' key={`template-${option.value}`}>
                  <div className='form-check form-switch'>
                    <input
                      type='checkbox'
                      id={`template-${option.value}`}
                      className='form-check-input'
                      name={`template-${option.value}`}
                      checked={option.value === documentTemplate?.value}
                      onChange={() => setDocumentTemplate(option)}
                    />
                    <label className='form-check-label' htmlFor={`template-${option.value}`}>
                      {option.label}
                    </label>
                  </div>
                </div>
              ))}
            </div>
          </form>
        </div>
        <div className='modal-footer'>
          <div className='btn-group' role='group'>
            <Button variant='secondary' icon='ModalCancel' onClick={handleClose} className='rounded-start' />
            <Button
              variant='secondary'
              icon='ModalSave'
              onClick={handleSubmit}
              disabled={!documentTemplate}
              className='rounded-end'
            />
          </div>
        </div>
      </div>
    </CustomModal>
  );
};

export default SelectDocumentTemplate;
