const palette = [
  '#D024F2',
  '#F28824',
  '#ED4949',
  '#B281F7',
  '#26EBCA',
  '#E37CF8',
  '#F6B87D',
  '#F27D7D',
  '#8D8DFC',
  '#18C7AA',
  '#EEBCF8',
  '#9F9A0B',
  '#F7BBBB',
  '#B8B8F7',
  '#24D7F2',
  '#F3ED4B',
  '#F8F6B8',
  '#EBEBFF',
  '#B5EAD1',
];

export const FALLBACK_PALETTE = palette[palette.length - 1]

export default palette;
