// by Rouben Meschian - rmeschian@gmail.com

import REDIPS from './redips-table.js';
import { createCell } from './tableFactory.js';

function merge(table, cellId1, cellId2) {
  // create an html Table from the given data
  const htmlTable = tableDataToHtmlTable(table);

  // initialize redips
  REDIPS.table.ondblclick(htmlTable, true);

  // tell redips which cells to merge
  REDIPS.table.mark(true, htmlTable.querySelector(`[data-id='${cellId1}']`));
  REDIPS.table.mark(true, htmlTable.querySelector(`[data-id='${cellId2}']`));

  // first merge cells horizontally and leave cells marked
  REDIPS.table.merge('h', false);

  // merge cells vertically and unmark cells (second parameter decides if cells are unmarked or not)
  REDIPS.table.merge('v', true);

  // copy the html Table content back to the Table data
  copyFromHtmlTableToTableData(htmlTable, table);
}

function copyFromHtmlTableToTableData(htmlTable, tableData) {
  Array.prototype.forEach.call(htmlTable.rows, (row, rowId) => {
    if (rowId === 0) return; // ignore the first row (header information)
    const trIndex = rowId - 1; // since the first row is for header information, we shift the trIndex over by one

    const trData = tableData.tbody.tr[trIndex];
    trData.td = [trData.td[0]]; // only keep the first column, reset the rest...
    Array.prototype.forEach.call(row.cells, (cell, cellIdx) => {
      if (cellIdx === 0) return;
      trData.td[cellIdx] = createCell({
        colspan: cell.colSpan,
        rowspan: cell.rowSpan,
      });
    });
  });
}

function tableDataToHtmlTable(tableData) {
  const htmlTable = document.createElement('table');
  htmlTable.innerHTML = `${renderTbody(tableData.tbody, tableData.thead)}`;
  return htmlTable;
}
function renderTbody(tbody, thead) {
  return `<tbody>
    ${[...thead.tr, ...tbody.tr].map(renderTr).join('')}
  </tbody>`;
}
function renderTr(tr, rowIndex) {
  return `<tr data-id="${tr.id}">${(tr.td || tr.th).map((td, tdIndex) => renderTd(td, tdIndex, rowIndex))}</tr>`;
}
function renderTd(td, tdIndex, rowIndex) {
  const { colspan = 1, rowspan = 1 } = td;
  return `<td id="${rowIndex + '_' + tdIndex}" data-id="${td.id}" colspan="${colspan}" rowspan="${rowspan}"></td>`;
}

export { merge };
