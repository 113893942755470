import { QUERY_TEMPLATES } from '../../actions/types';
import { TemplatesAction } from './types';
import { DataStructure } from '../datastructure/types';

const templateReducer = (state: DataStructure[] = [], action: TemplatesAction) => {
  switch (action.type) {
    case QUERY_TEMPLATES:
      return action.payload;

    default:
      return state;
  }
};

export default templateReducer;
