import React, { FC, useCallback, useLayoutEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { getLogo } from '../../appConfig';

import { setDataLostDialog } from '../../store/document/actions';
import { documentSelector, saveBtnActiveSelector } from '../../store/document/selectors';
// import { NavRoute } from '../../store/document/types';
import { signOut } from '../../store/auth/actions';
//import { selectAppName } from '../../store/app/selectors';

// import NavigateBetweenViews from './NavigateBetweenViews/NavigateBetweenViews';

import './Topnav.scss';

type Props = {
  section?: string;
};

//TODO: ADD correct type
type NavRoute = any;

const Topnav: FC<Props> = () => {
  const dispatch = useDispatch();
  const saveBtnActive = useSelector(saveBtnActiveSelector) || null;

  const [isTooltipVisible, setTooltipVisible] = useState(false);
  const docNameSpanRef = useRef<HTMLSpanElement>(null);
  const debounceDelay = 200; // Adjust the debounce delay as needed

  const handleRedirect = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>, navRoute: NavRoute) => {
      if (!saveBtnActive) {
        return navRoute.action();
      }
      e.preventDefault();
      // @ts-ignore
      dispatch(setDataLostDialog(navRoute));
    },
    [dispatch, saveBtnActive]
  );

  // const appName = 'Doc name'; //useSelector(selectAppName);
  const document = useSelector(documentSelector);
  let documentName = document?.name;
  const fileExtensions = ['.pdf', '.zip'];

  const docNameIncludesExtension = fileExtensions.some((fileExtension) =>
    documentName?.toLowerCase().endsWith(fileExtension)
  );

  if (docNameIncludesExtension) {
    documentName = documentName?.slice(0, -4);
  }

  const spanElement = docNameSpanRef.current;

  useLayoutEffect(() => {
    let resizeTimer: NodeJS.Timeout | null = null;

    const handleResize = () => {
      if (!spanElement) return;

      if (spanElement) {
        const isOverflowing = spanElement.scrollWidth > spanElement.clientWidth;
        setTooltipVisible(isOverflowing);
      }
    };

    const debouncedResize = () => {
      if (resizeTimer) {
        clearTimeout(resizeTimer);
      }

      resizeTimer = setTimeout(handleResize, debounceDelay);
    };

    const handleWindowResize = () => {
      debouncedResize();
    };

    window.addEventListener('resize', handleWindowResize);

    handleResize(); // Initial measurement on component mount

    return () => {
      window.removeEventListener('resize', handleWindowResize);
      if (resizeTimer) {
        clearTimeout(resizeTimer);
      }
    };
  }, [spanElement]);

  return (
    <nav className='navbar navbar-expand-lg navbar-light px-0' id='topnav'>
      <div className='container-fluid px-3 position-relative'>
        <div className='navbar-brand d-flex justify-content-center align-items-center'>
          <img
            src={`/img/${getLogo()}`}
            alt='logo'
            className='navbar-brand-img d-inline-block align-top me-4'
            height={44}
          />
          {/*<NavigateBetweenViews appName={appName} />*/}
        </div>

        <div className='navbar-user h1 mx-auto mb-0 w-50'>
          {isTooltipVisible ? (
            <span ref={docNameSpanRef} className='text-truncate'>
              {documentName}
            </span>
          ) : (
            <span ref={docNameSpanRef} className='text-truncate'>
              {documentName}
            </span>
          )}
        </div>

        <div className='navbar-user ms-auto'>
          <Link
            to='/signin'
            onClick={(e) =>
              handleRedirect(e, {
                label: 'Logout',
                action: signOut,
              } as NavRoute)
            }
            className='px-0'
          >
            Logout
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default Topnav;
