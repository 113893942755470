import React from 'react';
import { setActiveSmartTag, setTaggingEditorState } from '../../../actions';
import { useAppDispatch } from '../../../hooks/reduxHooks';
import { taggingEditorStateSelector } from '../../../store/document/selectors';
import { useSelector } from 'react-redux';

const PixelPerfect = () => {
  const dispatch = useAppDispatch();
  const taggingEditorState = useSelector(taggingEditorStateSelector);
  const pixelPerfectActivated = taggingEditorState === 'pixelPerfect';

  return (
    <div
      className={`
          btn bg-transparent dropdown-item dropdown-toggle dropdown-toggle pixel-perfect
          ${pixelPerfectActivated ? 'active' : ''}
        `}
    >
      <span className='title'>Pixel perfect</span>
      <div
        className='form-check form-switch'
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <input
          type='checkbox'
          className='form-check-input'
          checked={pixelPerfectActivated}
          onChange={() => {
            if (taggingEditorState === 'pixelPerfect') {
              dispatch(setTaggingEditorState(''));
              return;
            }
            dispatch(setActiveSmartTag(null));
            dispatch(setTaggingEditorState('pixelPerfect'));
          }}
        />
      </div>
    </div>
  );
};

export default PixelPerfect;
