export const getMatchedSmartHoverTag = (smartHoverData = {}, tag = {}) => {
  let tags = [];
  smartHoverData.fields?.forEach((item) => {
    tags = [...tags, ...item.tags];
  });
  return tags.find((item) => {
    return item.left === tag.left && item.top === tag.top && item.width === tag.width && item.height === tag.height;
  });
};

export const getMatchedSmartHoverTagIndex = (smartHoverData = {}, tag = {}) => {
  let tags = [];
  smartHoverData.fields?.forEach((item) => {
    tags = [...tags, ...item.tags];
  });
  return tags.findIndex((item) => {
    return item.left === tag.left && item.top === tag.top && item.width === tag.width && item.height === tag.height;
  });
};

export const getTagsByRegions = (fields) => {
  const tagsByRegions = {}
  for (let i = 0; i < fields.length; i++) {
    const field = fields[i];
    for (let y = 0; y < field.tags.length; y++) {
      const tag = field.tags[y];
      const hash = `${tag.left}-${tag.top}-${tag.width}-${tag.height}`;
      tagsByRegions[hash] = {
        _id: tag._id,
        fieldId: field._id,
        srcFieldId: field.srcFieldId
      };

    }
  }
  return tagsByRegions;
}
 export const hashFromCoordinates = (tag) => {
  return tag && `${tag.left}-${tag.top}-${tag.width}-${tag.height}`;
};