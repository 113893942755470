import { useCallback } from 'react';
import { cloneDeep } from 'lodash';
import { useSelector } from 'react-redux';
import { documentLabelsSelector, taggingEditorStateSelector } from '../../store/document/selectors';
import { calculateLabelsSize, toPercent, toPixels } from '../../helpers/common/utils';
import { updateTagOfOriginSmartTag, upsertTag } from '../../actions';
import { useAppDispatch } from '../../hooks/reduxHooks';

export const useTag = ({ smartTagMode }) => {
  const dispatch = useAppDispatch();
  const taggingEditorState = useSelector(taggingEditorStateSelector);
  const labels = useSelector(documentLabelsSelector);

  const pixelToPercent = useCallback(
    (el, img) =>
      toPercent(
        {
          x: el.style.left,
          y: el.style.top,
          w: el.style.width,
          h: el.style.height,
        },
        img
      ),
    []
  );

  const updateTag = useCallback(
    ({ tag: _tag, $tag, fieldId, element, img, page, resizeMode = false }) => {
      const dims = pixelToPercent(element, img);
      const tag = cloneDeep(_tag);

      tag.left = dims.x;
      tag.top = dims.y;
      tag.width = dims.width;
      tag.height = dims.height;
      let regionInPixels = toPixels(tag, img);

      if (taggingEditorState === 'pixelPerfect' && labels.length > 0) {
        const result = calculateLabelsSize(regionInPixels, $tag, labels, false, img);

        tag.left = result.x + '%';
        tag.top = result.y + '%';
        tag.width = result.w + '%';
        tag.height = result.h + '%';
        regionInPixels = toPixels(tag, img);
        $tag.css('left', result.x + '%');
        $tag.css('top', result.y + '%');
        $tag.css('width', result.w + '%');
        $tag.css('height', result.h + '%');
      } else if (dims) {
        $tag.css('left', dims.x);
        $tag.css('top', dims.y);

        if (resizeMode) {
          $tag.css('width', dims.width);
          $tag.css('height', dims.height);
        }
      }

      tag.page = page;
      tag.fieldId = fieldId;

      if (smartTagMode) {
        dispatch(updateTagOfOriginSmartTag(tag, regionInPixels));
      } else {
        dispatch(upsertTag(tag, true, regionInPixels, false, null));
      }
    },
    [pixelToPercent, labels, taggingEditorState, dispatch, smartTagMode]
  );

  return {
    updateTag,
  };
};
