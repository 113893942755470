/*eslint-disable no-eval */
import $ from 'jquery';
import mongoose from 'mongoose';
import { SUPPORTED_LANGUAGES } from './constants';
import { getWebUrl } from '../../appConfig';

const moment = require('moment');
const _ = require('lodash');

const validFormats = [
  // ISO 8601 patterns
  'YYYYMMDDZ', //19990322+0100
  'YYYYMMDD', //19990322
  'YYYY-MM-DD G', //1999-03-22 AD
  'YYYY-MM-DDXXX', //1999-03-22+01:00
  "YYYY-MM-DD'T'HH:mm:ss.SSS'['VV']'", //1999-03-22T05:06:07.000[Europe/Paris]
  "YYYY-MM-DD'T'HH:mm:ss.SSS", //1999-03-22T05:06:07.000
  "YYYY-MM-DD'T'HH:mm:ss", //1999-03-22T05:06:07
  "YYYY-MM-DD'T'HH:mm:ss.SSS'Z'", //1999-03-22T05:06:07.000Z
  "YYYY-MM-DD'T'HH:mm:ss.SSSXXX", //1999-03-22T05:06:07.000+01:00
  "YYYY-MM-DD'T'HH:mm:ssXXX", //1999-03-22T05:06:07+01:00
  'YYYY-DDDXXX', //1999-081+01:00
  "YYYY'W'wc", //1999W132
  "YYYY-'W'w-c", //1999-W13-2
  "YYYY-MM-DD'T'HH:mm:ss.SSSXXX'['VV']'", //1999-03-22T05:06:07.000+01:00[Europe/Paris]
  "YYYY-MM-DD'T'HH:mm:ssXXX'['VV']'", //1999-03-22T05:06:07+01:00[Europe/Paris]

  //Locale be: Belarussian
  'D.M.YY', //22.3.99
  'D.M.YY H.mm', //22.3.99 5.06
  'D.M.YYYY H.mm.ss', //22.3.1999 5.06.07

  //Locale cs: Czech
  'D.M.YYYY H:mm:ss', //22.3.1999 5:06:07

  //Locale da: Danish
  'DD-MM-YY', //22-03-99
  'DD-MM-YY HH:mm', //22-03-99 05:06
  'DD-MM-YYYY HH:mm:ss', //22-03-1999 05:06:07

  //Locale de_DE: German, Germany
  'DD.MM.YY', //22.03.99
  'D. MMMM YYYY', //22. März 1999
  'EEEE, D. MMMM YYYY', //Montag, 22. März 1999
  'DD.MM.YYYY', //22.03.1999
  'DD.MM.YY HH:mm', //22.03.99 05:06
  'D. MMMM YYYY HH:mm:ss z', //22. März 1999 05:06:07 MEZ
  'DD.MM.YYYY HH:mm:ss', //22.03.1999 05:06:07
  'DD.MM.YY HH:mm:ss', //22.03.99 05:06:07
  'DD.MM.YYYY HH:mm', //22.03.1999 05:06
  "EEEE, D. MMMM YYYY HH:mm' Uhr 'z", //Montag, 22. März 1999 05:06 Uhr MEZ

  //Locale en_CA: English, Canada
  'D-MMM-YYYY', //22-Mar-1999
  'DD/MM/YY h:mm a', //22/03/99 5:06 AM
  "EEEE, MMMM D, YYYY h:mm:ss 'o''clock' a z", //Monday, March 22, 1999 5:06:07 o'clock AM CET
  'D-MMM-YYYY h:mm:ss a', //22-Mar-1999 5:06:07 AM

  //Locale en_GB: English, United Kingdom
  'DD MMMM YYYY', //22 March 1999
  'EEEE, D MMMM YYYY', //Monday, 22 March 1999
  'DD-MMM-YYYY', //22-Mar-1999
  'DD MMMM YYYY HH:mm:ss z', //22 March 1999 05:06:07 CET
  "EEEE, D MMMM YYYY HH:mm:ss 'o''clock' z", //Monday, 22 March 1999 05:06:07 o'clock CET
  'DD-MMM-YYYY HH:mm:ss', //22-Mar-1999 05:06:07
  'DD-MMM-YY hh.mm.ss.nnnnnnnnn a', //22-Mar-99 05.06.07.000000888 AM

  //Locale en_US : English, United States
  'M/D/YY', //3/22/99
  'MM/DD/YY', //03/11/22
  'MM-DD-YY', //03-22-99
  'M-D-YY', //3-22-99
  'MMM D, YYYY', //Mar 22, 1999
  'MMMM D, YYYY', //March 22, 1999
  'EEEE, MMMM D, YYYY', //Monday, March 22, 1999
  'MMM D YYYY', //Mar 22 1999
  'MMMM D YYYY', //March 22 1999
  'MM-DD-YYYY', //03-22-1999
  'M-D-YYYY', //3-22-1999
  'YYYY-MM-DDXXX', //1999-03-22+01:00
  'DD/MM/YYYY', //22/03/1999
  'D/M/YYYY', //22/3/1999
  'MM/DD/YYYY', //03/22/1999
  'M/D/YYYY', //3/22/1999
  'YYYY/M/D', //1999/3/22
  'M/D/YY h:mm a', //3/22/99 5:06 AM
  'MM/DD/YY h:mm a', //03/22/99 5:06 AM
  'MM-DD-YY h:mm a', //03-22-99 5:06 AM
  'M-DD-YY h:mm a', //3-22-99 5:06 AM
  'MMM D, YYYY h:mm:ss a', //Mar 22, 1999 5:06:07 AM
  'EEEE, MMMM D, YYYY h:mm:ss a z', //Monday, March 22, 1999 5:06:07 AM CET
  'EEE MMM DD HH:mm:ss z YYYY', //Mon Mar 22 05:06:07 CET 1999
  'EEE, D MMM YYYY HH:mm:ss Z', //Mon, 22 Mar 1999 05:06:07 +0100
  'D MMM YYYY HH:mm:ss Z', //22 Mar 1999 05:06:07 +0100
  'MM-DD-YYYY h:mm:ss a', //03-22-1999 5:06:07 AM
  'M-D-YYYY h:mm:ss a', //3-22-1999 5:06:07 AM
  'YYYY-MM-DD h:mm:ss a', //1999-03-22 5:06:07 AM
  'YYYY-M-D h:mm:ss a', //1999-3-22 5:06:07 AM
  'YYYY-MM-DD HH:mm:ss.S', //1999-03-22 05:06:07.0
  'DD/MM/YYYY h:mm:ss a', //22/03/1999 5:06:07 AM
  'D/M/YYYY h:mm:ss a', //22/3/1999 5:06:07 AM
  'MM/DD/YYYY h:mm:ss a', //03/22/1999 5:06:07 AM
  'M/D/YYYY h:mm:ss a', //3/22/1999 5:06:07 AM
  'MM/DD/YY h:mm:ss a', //03/22/99 5:06:07 AM
  'MM/DD/YY H:mm:ss', //03/22/99 5:06:07
  'M/D/YY H:mm:ss', //3/22/99 5:06:07
  'DD/MM/YYYY h:mm a', //22/03/1999 5:06 AM
  'D/M/YYYY h:mm a', //22/3/1999 5:06 AM
  'MM/DD/YYYY h:mm a', //03/22/1999 5:06 AM
  'M/D/YYYY h:mm a', //3/22/1999 5:06 AM
  'MM-DD-YY h:mm:ss a', //03-22-99 5:06:07 AM
  'M-D-YY h:mm:ss a', //3-22-99 5:06:07 AM
  'MM-DD-YYYY h:mm a', //03-22-1999 5:06 AM
  'M-D-YYYY h:mm a', //3-22-1999 5:06 AM
  'YYYY-MM-DD h:mm a', //1999-03-22 5:06 AM
  'YYYY-M-D h:mm a', //1999-3-22 5:06 AM
  'MMM.DD.YYYY', //Mar.22.1999
  'D/MMM/YYYY H:mm:ss Z', //22/Mar/1999 5:06:07 +0100
  'DD/MMM/YY h:mm a', //22/Mar/99 5:06 AM

  //Locale es: Spanish
  'D/MM/YY', //22/03/99
  'D/MM/YY H:mm', //22/03/99 5:06
  'D.M.YY H:mm', //22.3.99 5:06

  //Locale et: Estonian
  'D.MM.YY', //22.03.99
  'D.MM.YYYY', //22.03.1999
  'D.MM.YY H:mm', //22.03.99 5:06
  'D.MM.YYYY H:mm:ss', //22.03.1999 5:06:07

  //Locale fi: Finnish
  'D.M.YYYY', //22.3.1999
  'D.M.YYYY H:mm', //22.3.1999 5:06

  //Locale fr_CA: French, Canada
  'YY-MM-DD', //99-03-22
  'YYYY-MM-DD', //1999-03-22
  'D MMMM YYYY HH:mm:ss z', //22 mars 1999 05:06:07 CET
  'MMMM D, YYYY h:mm:ss z a', //March 22, 1999 5:06:07 CET AM
  'YYYY-MM-DD HH:mm:ss', //1999-03-22 05:06:07
  "EEEE D MMMM YYYY H' h 'mm z", //lundi 22 mars 1999 5 h 06 CET

  //Locale fr_FR: French, France
  'DD/MM/YY', //22/03/99
  'D MMM YYYY', //22 mars 1999
  'D MMMM YYYY', //22 mars 1999
  'EEEE D MMMM YYYY', //lundi 22 mars 1999
  'DD/MM/YY HH:mm', //22/03/99 05:06
  'MM/DD/YY HH:mm', //03/22/99 05:06
  'M/D/YY HH:mm', //3/22/99 05:06
  'MM-DD-YY HH:mm', //03-22-99 05:06
  'M-D-YY HH:mm', //3-22-99 05:06
  'D MMM YYYY HH:mm:ss', //22 mars 1999 05:06:07
  'D MMMM YYYY HH:mm:ss z', //22 mars 1999 05:06:07 CET
  'MM-DD-YYYY HH:mm:ss', //03-22-1999 05:06:07
  'M-D-YYYY HH:mm:ss', //3-22-1999 05:06:07
  'YYYY-M-D HH:mm:ss', //1999-3-22 05:06:07
  'DD/MM/YYYY HH:mm:ss', //22/03/1999 05:06:07
  'D/M/YYYY HH:mm:ss', //22/3/1999 05:06:07
  'MM/DD/YYYY HH:mm:ss', //03/22/1999 05:06:07
  'M/D/YYYY HH:mm:ss', //3/22/1999 05:06:07
  "EEEE D MMMM YYYY HH' h 'mm z", //lundi 22 mars 1999 05 h 06 CET
  'DD/MM/YY HH:mm:ss', //22/03/99 05:06:07
  'MM/DD/YY HH:mm:ss', //03/22/99 05:06:07
  'M/D/YY HH:mm:ss', //3/22/99 05:06:07
  'DD/MM/YYYY HH:mm', //22/03/1999 05:06
  'D/M/YYYY HH:mm', //22/3/1999 05:06
  'MM/DD/YYYY HH:mm', //03/22/1999 05:06
  'M/D/YYYY HH:mm', //3/22/1999 05:06
  'MM-DD-YY HH:mm:ss', //03-22-99 05:06:07
  'M-D-YY HH:mm:ss', //3-22-99 05:06:07
  'MM-DD-YYYY HH:mm', //03-22-1999 05:06
  'M-D-YYYY HH:mm', //3-22-1999 05:06
  'YYYY-M-D HH:mm', //1999-3-22 05:06

  //Locale ga: Irish
  'YY/MM/DD HH:mm', //99/03/22 05:06

  //Locale hr: Croatian
  'YYYY.MM.DD', //1999.03.22
  'YYYY.MM.DD HH:mm:ss', //1999.03.22 05:06:07
  'YYYY.MM.DD HH:mm', //1999.03.22 05:06

  //Locale hu: Hungarian
  'YYYY.MM.DD.', //1999.03.22.
  'YYYY.MM.DD. H:mm:ss', //1999.03.22. 5:06:07
  'YYYY.MM.DD. H:mm', //1999.03.22. 5:06

  //Locale is: Icelandic
  'D.M.YYYY HH:mm:ss', //22.3.1999 05:06:07
  'D.M.YYYY HH:mm', //22.3.1999 05:06

  //Locale it_IT: Italian, Italy
  'D-MMM-YYYY', //22-mar-1999
  'DD/MM/YY H.mm', //22/03/99 5.06
  'YY-MM-DD HH:mm', //99-03-22 05:06
  'D-MMM-YYYY H.mm.ss', //22-mar-1999 5.06.07
  'D MMMM YYYY H.mm.ss z', //22 marzo 1999 5.06.07 CET
  'EEEE D MMMM YYYY H.mm.ss z', //lunedì 22 marzo 1999 5.06.07 CET

  //Locale iw: Hebrew
  'HH:mm DD/MM/YY', //05:06 22/03/99
  'HH:mm:ss DD/MM/YYYY', //05:06:07 22/03/1999

  //Locale ja_JP: Japanese, Japan
  'YY/MM/DD', //99/03/22
  'YYYY/MM/DD', //1999/03/22
  'YY/MM/DD H:mm', //99/03/22 5:06
  'MM/DD/YY H:mm', //03/22/99 5:06
  'M/D/YY H:mm', //3/22/99 5:06
  'MM-DD-YY H:mm', //03-22-99 5:06
  'M-D-YY H:mm', //3-22-99 5:06 AM
  'MM-DD-YYYY H:mm:ss', //03-22-1999 5:06:07
  'M-D-YYYY H:mm:ss', //3-22-1999 5:06:07
  'YYYY-MM-DD H:mm:ss', //1999-03-22 5:06:07
  'YY/MM/DD H:mm:ss', //99/03/22 5:06:07
  'M/D/YY h:mm:ss a ', //3/22/99 5:06:07 AM
  'YYYY/MM/DD H:mm', //1999/03/22 5:06
  'DD/MM/YYYY H:mm', //22/03/1999 5:06
  'D/M/YYYY H:mm', //22/3/1999 5:06
  'MM/DD/YYYY H:mm', //03/22/1999 5:06
  'M/D/YYYY H:mm', //3/22/1999 5:06
  'MM-DD-YY H:mm:ss', //03-22-99 5:06:07
  'M-D-YY H:mm:ss', //3-22-99 5:06:07
  'MM-DD-YYYY H:mm', //03-22-1999 5:06
  'M-D-YYYY H:mm', //3-22-1999 5:06
  'YYYY-MM-DD H:mm', //1999-03-22 5:06
  'YYYY-M-D H:mm', //1999-3-22 5:06

  //Locale ko: Korean
  'YY. M. D', //99. 3. 22
  'YYYY. M. D', //1999. 3. 22

  //Locale lt: Lithuanian
  'YY.M.D', //99.3.22
  'YY.M.D HH.mm', //99.3.22 05.06
  'YYYY-MM-DD HH.mm.ss', //1999-03-22 05.06.07

  //Locale lv: Latvian
  'YY.D.M', //99.22.3
  'YYYY.D.M', //1999.22.3
  'YY.D.M HH:mm', //99.22.3 05:06
  'YYYY.D.M HH:mm:ss', //1999.22.3 05:06:07

  //Locale mk: Macedonian
  'D.M.YY HH:mm', //22.3.99 05:06
  'D.M.YYYY HH:mm:', //22.3.1999 05:06:

  //Locale nl: Dutch
  'D-M-YY', //22-3-99
  'D-M-YY H:mm', //22-3-99 5:06

  //Locale pt: Portuguese
  'DD-MM-YYYY', //22-03-1999
  'DD-MM-YYYY H:mm', //22-03-1999 5:06

  //Locale ru: Russian
  'DD.MM.YY H:mm', //22.03.99 5:06
  'DD.MM.YYYY H:mm:ss', //22.03.1999 5:06:07

  //Locale sq: Albanian
  'YYYY-MM-DD h:mm:ss.a', //1999-03-22 5:06:07.PD
  'YY-MM-DD h.mm.a', //99-03-22 5.06.PD
  'YYYY-MM-DD h.mm.ss.a z', //1999-03-22 5.06.07.PD CET

  //Locale sr: Serbian
  'D.M.YY.', //22.3.99.
  'DD.MM.YYYY.', //22.03.1999.
  'D.M.YY. HH.mm', //22.3.99. 05.06
  'DD.MM.YYYY. HH.mm.ss', //22.03.1999. 05.06.07
  'DD.MM.YYYY. HH.mm.ss z', //22.03.1999. 05.06.07 CET

  //Locale vi: Vietnamese
  'HH:mm:ss DD-MM-YYYY', //05:06:07 22-03-1999
  'HH:mm DD/MM/YYYY', //05:06 22/03/1999

  //Locale zh_CN: Chinese (Simplified), China
  'YY-M-D', //99-3-22
  'YYYY-M-D', //1999-3-22
  "YYYY'年'M'月'D'日'", //1999年3月22日
  "YYYY'年'M'月'D'日' EEEE", //1999年3月22日 星期一
  'YY-M-D ah:mm', //99-3-22 上午5:06
  'YYYY-M-D H:mm:ss', //1999-3-22 5:06:07
  "YYYY'年'M'月'D'日' ahh'时'mm'分'ss'秒'", //1999年3月22日 上午05时06分07秒
  "YYYY'年'M'月'D'日' H'時'mm'分'ss'秒' z", //1999年3月22日 5時06分07秒 CET
  "YYYY'年'M'月'D'日' EEEE ahh'时'mm'分'ss'秒' z", //1999年3月22日 星期一 上午05时06分07秒 CET

  //With ordinals
  'Do. MMMM YYYY', //22. März 1999
  'EEEE, Do. MMMM YYYY', //Montag, 22. März 1999
  'Do. MMMM YYYY HH:mm:ss z', //22. März 1999 05:06:07 MEZ
  "EEEE, Do. MMMM YYYY HH:mm' Uhr 'z", //Montag, 22. März 1999 05:06 Uhr MEZ
  'Do-MMM-YYYY', //22-Mar-1999
  "EEEE, MMMM Do, YYYY h:mm:ss 'o''clock' a z", //Monday, March 22, 1999 5:06:07 o'clock AM CET
  'Do-MMM-YYYY h:mm:ss a', //22-Mar-1999 5:06:07 AM
  'DDo MMMM YYYY', //22 March 1999
  'EEEE, Do MMMM YYYY', //Monday, 22 March 1999
  'DDo-MMM-YYYY', //22-Mar-1999
  'DDo MMMM YYYY HH:mm:ss z', //22 March 1999 05:06:07 CET
  "EEEE, Do MMMM YYYY HH:mm:ss 'o''clock' z", //Monday, 22 March 1999 05:06:07 o'clock CET
  'DDo-MMM-YYYY HH:mm:ss', //22-Mar-1999 05:06:07
  'DDo-MMM-YY hh.mm.ss.nnnnnnnnn a', //22-Mar-99 05.06.07.000000888 AM
  'MMM Dos, YYYY', //Mar 22, 1999
  'MMMM Do, YYYY', //March 22, 1999
  'EEEE, MMMM Do, YYYY', //Monday, March 22, 1999
  'MMM Do YYYY', //Mar 22 1999
  'MMMM Do YYYY', //March 22 1999
  'MMM Do, YYYY h:mm:ss a', //Mar 22, 1999 5:06:07 AM
  'EEEE, MMMM Do, YYYY h:mm:ss a z', //Monday, March 22, 1999 5:06:07 AM CET
  'EEE MMM DDo HH:mm:ss z YYYY', //Mon Mar 22 05:06:07 CET 1999
  'EEE, Do MMM YYYY HH:mm:ss Z', //Mon, 22 Mar 1999 05:06:07 +0100
  'Do MMM YYYY HH:mm:ss Z', //22 Mar 1999 05:06:07 +0100
  'MMM.DDo.YYYY', //Mar.22.1999
  'Do/MMM/YYYY H:mm:ss Z', //22/Mar/1999 5:06:07 +0100
  'DDo/MMM/YY h:mm a', //22/Mar/99 5:06 AM
  'Do MMMM YYYY HH:mm:ss z', //22 mars 1999 05:06:07 CET
  'MMMM Do, YYYY h:mm:ss z a', //March 22, 1999 5:06:07 CET AM
  "EEEE Do MMMM YYYY H' h 'mm z", //lundi 22 mars 1999 5 h 06 CET
  'Do MMM YYYY', //22 mars 1999
  'Do MMMM YYYY', //22 mars 1999
  'EEEE Do MMMM YYYY', //lundi 22 mars 1999
  'Do MMM YYYY HH:mm:ss', //22 mars 1999 05:06:07
  'Do MMMM YYYY HH:mm:ss z', //22 mars 1999 05:06:07 CET
  "EEEE Do MMMM YYYY HH' h 'mm z", //lundi 22 mars 1999 05 h 06 CET
  'Do-MMM-YYYY H.mm.ss', //22-mar-1999 5.06.07
  'Do MMMM YYYY H.mm.ss z', //22 marzo 1999 5.06.07 CET
  'EEEE Do MMMM YYYY H.mm.ss z', //lunedì 22 marzo 1999 5.06.07 CET
];
/**
 * Return element position relative to parent.
 *
 * @param {*} e
 * @param {*} relativeToParent
 */
export const getPosition = (e, relativeToParent) => {
  const parentOffset = $(relativeToParent).offset();
  const x = e.left - parentOffset.left;
  const y = e.top - parentOffset.top;

  return { x, y };
};

/**
 * Converts dimensions to percentages.
 *
 * @param {*} dims
 * @param {*} container
 */
export const toPercent = (dims, container) => {
  const imgW = container[0].width || parseFloat(container.css('width'));
  const imgH = container[0].height || parseFloat(container.css('height'));

  let l = dims.x || '0%';
  let t = dims.y || '0%';
  let w = dims.w || dims.width || '0%';
  let h = dims.h || dims.height || '0%';

  if (isNumberType(l) || l.indexOf('%') < 0) {
    l = parseFloat(l);
    l = (100 * l) / imgW + '%';
  }

  if (isNumberType(t) || t.indexOf('%') < 0) {
    t = parseFloat(t);
    t = (100 * t) / imgH + '%';
  }

  if (isNumberType(w) || w.indexOf('%') < 0) {
    w = parseFloat(w);
    w = (100 * w) / imgW + '%';
  }

  if (isNumberType(h) || h.indexOf('%') < 0) {
    h = parseFloat(h);
    h = (100 * h) / imgH + '%';
  }

  return {
    x: l,
    y: t,
    width: w,
    height: h,
  };
};

/**
 * Converts the given dimensions in percent to the actual coordinates on the image
 *
 * @param {*} dims
 * @param {*} img
 */
export const toPixels = (dims, img) => {
  const imgW = img[0].naturalWidth,
    imgH = img[0].naturalHeight,
    xPercent = parseFloat(dims.left),
    yPercent = parseFloat(dims.top),
    wPercent = parseFloat(dims.width),
    hPercent = parseFloat(dims.height);
  return {
    x: (xPercent * imgW) / 100,
    y: (yPercent * imgH) / 100,
    w: (wPercent * imgW) / 100,
    h: (hPercent * imgH) / 100,
    widthScale: imgW,
    heightScale: imgH,
  };
};

export const calculateLabelsSize = (ui, droppable, labels, withOffset, img) => {
  let x, y, width, height;
  if (withOffset) {
    let position = getPosition(ui.offset, droppable);
    x = (position.x / img.innerWidth()) * 100;
    y = (position.y / img.innerHeight()) * 100;
    width = (parseFloat(ui.helper.css('width').replace('px', '')) / img.innerWidth()) * 100;
    height = (parseFloat(ui.helper.css('height').replace('px', '')) / img.innerHeight()) * 100;
  } else {
    x = (ui.x / img[0].naturalWidth) * 100;
    y = (ui.y / img[0].naturalHeight) * 100;
    width = (ui.w / img[0].naturalWidth) * 100;
    height = (ui.h / img[0].naturalHeight) * 100;
  }
  return calculateLabelPositions(x, y, width, height, [], labels);
};

/**
 * Calculate lables depending on position coordinates
 *
 */
export const calculateLabelPositions = (draggableLeft, draggableTop, draggableWidth, draggableHeight, arr, labels) => {
  const $droppables = labels.filter(function (label) {
    return label.value.trim() !== '';
  });
  const draggableBottom = draggableTop + draggableHeight;
  const draggableRight = draggableLeft + draggableWidth;
  let droppablesCoveredByDraggable = $droppables.filter(function ($droppable, i) {
    if (arr.length > 0) {
      if (Object.keys(arr).includes(i.toString())) {
        return false;
      }
    }
    let top = $droppable.y;
    let left = $droppable.x;
    let height = $droppable.h;
    let width = $droppable.w;
    let right = width + left;
    let bottom = top + height;
    let isCoveredByDraggable = top <= draggableBottom && bottom >= draggableTop;
    let isCoveredByVertical = left <= draggableRight && right >= draggableLeft;
    if (isCoveredByDraggable && isCoveredByVertical) {
      if (arr.length > 0) {
        if (!Object.keys(arr).includes(i.toString())) {
          arr[i] = $droppable;
        }
      } else {
        arr[i] = $droppable;
      }
    }
    return isCoveredByDraggable && isCoveredByVertical;
  });
  let x,
    xAdj = 0,
    y,
    yAdj = 0,
    width = 0,
    widthAdj = 0,
    height = 0,
    heightAdj = 0;
  let top, left, widthStyle, leftStyle, topStyle, heightStyle, widthParam;
  arr.map((droppable) => {
    top = droppable.y;
    left = droppable.x;
    leftStyle = droppable.x;
    topStyle = droppable.y;
    widthStyle = droppable.w;
    heightStyle = droppable.h;

    if (width === 0) {
      width = droppable.w;
    }
    widthParam = droppable.w;

    if (height === 0) {
      height = droppable.h;
    }
    x = !x || left < x ? left : x;
    width = left + widthParam < width ? width : left + widthParam;
    y = !y || top < y ? top : y;
    height = height < top ? top : height;

    xAdj = xAdj === 0 || leftStyle < xAdj ? leftStyle : xAdj;

    widthAdj = widthAdj === 0 || leftStyle + widthStyle > widthAdj ? leftStyle + widthStyle : widthAdj;

    yAdj = yAdj === 0 || topStyle < yAdj ? topStyle : yAdj;

    heightAdj = heightAdj === 0 || topStyle + heightStyle > heightAdj ? topStyle + heightStyle : heightAdj;

    return droppable;
  });
  if (droppablesCoveredByDraggable.length > 0 && arr.length > 0) {
    return calculateLabelPositions(x, y, width - x, height - y, arr, labels);
  } else if (arr.length > 0 && droppablesCoveredByDraggable.length <= 0) {
    return { x: xAdj, y: yAdj, w: widthAdj - xAdj, h: heightAdj - yAdj };
  } else {
    return { x: draggableLeft, y: draggableTop, w: draggableWidth, h: draggableHeight };
  }
};

const isNumberType = (x) => {
  return typeof x === 'number';
};

const runFormulaCache = {
  fields: null, // if fields change, clear formulaToResultMap values
  formulaToResultMap: {}, // this is where we cache: formula -> result
};

/**
 * Run the formula using the given set of fields.
 */
export const runFormula = (fields, inputFormula) => {
  // let nameToFieldAndTag = {};

  if (!inputFormula) return inputFormula;

  if (runFormulaCache.fields !== fields) {
    if (JSON.stringify(runFormulaCache.fields) !== JSON.stringify(fields)) {
      runFormulaCache.formulaToResultMap = {}; // cache cleared
    }
    runFormulaCache.fields = fields;
  }

  if (runFormulaCache.formulaToResultMap[inputFormula]) {
    return runFormulaCache.formulaToResultMap[inputFormula]; // cache hit
  }

  // map of srcFieldId -> map of field._id -> entry of type {field, tagValue, maxDecimal}
  const srcFieldIdToEntryMap = fields.reduce((map, field) => {
    if (!map[field.srcFieldId]) map[field.srcFieldId] = {};

    map[field.srcFieldId][field._id] = {
      field,
    };
    return map;
  }, {});

  let maxDecimal = 0;
  fields.forEach((field) => {
    if (field.dataType != 'number') return;
    let tagValue = 0;
    if (field.repeatable) {
      const srcFieldMap = srcFieldIdToEntryMap[field.srcFieldId];
      Object.values(srcFieldMap)
        .map((entry) => entry.field)
        .forEach((fld) => {
          tagValue += Number(tagValueForField(fld, maxDecimal).value);
          maxDecimal = tagValueForField(fld, maxDecimal).maxDecimal;
        });
    } else {
      tagValue = Number(tagValueForField(field, maxDecimal).value);
      maxDecimal = tagValueForField(field, maxDecimal).maxDecimal;
    }

    srcFieldIdToEntryMap[field.srcFieldId][field._id].tagValue =
      maxDecimal >= 0 && maxDecimal < 100 && !isNaN(tagValue) ? tagValue.toFixed(maxDecimal) : tagValue;
    srcFieldIdToEntryMap[field.srcFieldId][field._id].maxDecimal = maxDecimal;
  });

  const run = (formula) => {
    if (!formula) return formula;

    if (runFormulaCache.formulaToResultMap[formula] !== undefined) {
      return runFormulaCache.formulaToResultMap[formula]; // cache hit
    }

    const val = execFormula(formula, (id) => {
      let entry = srcFieldIdToEntryMap[id] && Object.values(srcFieldIdToEntryMap[id])[0];

      if (!entry) return 0;

      const { field, tagValue } = entry;

      if (field.fieldType === 'computation' || field.fieldType === 'extraction and computation') {
        if (field.useExtractedInComputation) {
          if (tagValue === '' || tagValue === undefined || tagValue === null || isNaN(tagValue)) {
            return 0;
          }
          return parseFloat(getNativeValue(tagValue, field.type).toString().replace(/,/g, ''));
        }

        const localFormula = replaceFormulaComponents(field.valueFormula, field.formulaComponents);
        const result = run(localFormula);
        return result !== '' ? result : 0;
      }

      if (tagValue !== undefined && tagValue !== null && tagValue !== 0 && tagValue !== '') {
        return parseFloat(tagValue.toString().replace(/,/g, ''));
      } else if (tagValue == '' || tagValue == undefined || tagValue == null) {
        return 0;
      } else if (field.value === '' || field.value === undefined || field.value === null) {
        return 0;
      }
      return parseFloat(getNativeValue(tagValue, field.type).toString().replace(/,/g, ''));
    });

    const result = val && maxDecimal >= 0 && maxDecimal < 100 && !isNaN(val) ? Number(val.toFixed(maxDecimal)) : val;
    runFormulaCache.formulaToResultMap[formula] = result;
    return result;
  };

  return run(inputFormula);
};

/**
 * Execute (eval) the given formula.
 *
 * formula of type [field_id] + [field2_id]
 * converted into a formula of type: $('field_id') + $('field2_id')
 * Since $ is the function passed in, which is called to return the value
 * of the field with the given id.
 */
function execFormula(formula, $) {
  const formulaReplaced = formula.replace(/\[/g, "$('").replace(/]/g, "')");
  try {
    return eval(formulaReplaced);
  } catch (e) {
    return;
  }
}

/**
 * Returns the number of decimal places in the given value.
 */
function getDecimalCount(val) {
  if (val) {
    const tagValueParts = val.toString().split('.');
    if (tagValueParts.length > 1) {
      return tagValueParts[1].length;
    }
  }
  return 0;
}

function tagValueForField(field, maxDecimal) {
  let tagValue = 0;
  let tags = field.tags;
  if (field.multiple && tags.length > 1) {
    tags.forEach((tag) => {
      if (tag !== undefined && tag.value !== '' && tag.value !== undefined && tag.value !== null) {
        maxDecimal = Math.max(maxDecimal, getDecimalCount(tag.value));
        tagValue += Number(tag.value.toString().replace(/,/g, ''));
      }
    });
  } else if (tags === undefined || tags.length == 0) {
    maxDecimal = Math.max(maxDecimal, getDecimalCount(field.value));
    tagValue = field.value ? Number(field.value.toString().replace(/,/g, '')) : '';
  } else {
    maxDecimal = Math.max(maxDecimal, getDecimalCount(tags[0].value));
    tagValue = tags[0] ? tags[0].value : 0;
  }
  return {
    value: tagValue ? tagValue.toString().replace(/,/g, '') : null,
    maxDecimal,
  };
}

export const getNativeValue = (val, type) => {
  if (val === '' || val === undefined || val === null) return val;
  switch (type) {
    case 'number':
      return val;
    case 'string':
      return String(val);
    default:
      return val;
  }
};

export const pxToPercentageH = (px, container) => {
  return (100 * px) / container[0].height || parseFloat(container.css('height'));
};

export const removeDrawnRectangle = (page) => {
  const canvas = document.getElementById(`canvas-${page}`);
  if (!canvas) {
    return;
  }
  const ctx = canvas.getContext('2d');
  ctx.clearRect(0, 0, canvas.width, canvas.height);
};

export const sameRoles = (rolesA, rolesB) => {
  if (rolesA.length !== rolesB.length) {
    return false;
  }

  rolesA = rolesA.map((role) => role._id);
  rolesB = rolesB.map((role) => role._id);

  for (let i = 0; i < rolesA.length; i++) {
    if (!rolesB.includes(rolesA[i])) return false;
  }
  return true;
};

export const processValue = (field, page, value, onlyValue, tag, metadata, resetFx, previousScale, isOcr) => {
  const result = {
    value,
    hasValidValue: false,
  };
  const { dataType, validationFormula, scale } = field;
  switch (dataType) {
    case 'date':
      result.value = value = (resetFx ? tag.ocrValue : value).trim();
      const date = moment(value, validFormats, true);
      result.hasValidValue = date.isValid();
      break;
    case 'number':
      let checkValue = null;
      let checkOcrValue = null;
      if (_.isEmpty(metadata)) {
        checkValue = value;
        checkOcrValue = value;
      } else {
        checkValue = value ? value.replace(/[^\d()%./,-]/g, '') : null;
        const isPercentage = checkValue ? checkValue.trim().startsWith('%') || checkValue.trim().endsWith('%') : false;
        checkValue = checkValue ? checkValue.replace(/%/g, '') : null;
        checkValue = checkValue
          ? getValidValue(
              checkValue,
              metadata.thousandsSeparator,
              metadata.negativeSign,
              onlyValue,
              metadata.decimalPoint,
              isPercentage,
              tag
            )
          : 0;
        if (tag) {
          checkOcrValue = tag.ocrValue;
        }
      }
      if (checkOcrValue) {
        const number = Number.parseFloat(checkOcrValue);
        const isNan = isNaN(checkOcrValue);
        const isNumber = !isNaN(number);
        result.hasValidOcrValue = isNumber && !isNan;
      }
      if (tag && (checkValue || checkValue === 0)) {
        const number = Number.parseFloat(checkValue);
        const isNan = isNaN(checkValue);
        const isNumber = !isNaN(number);
        result.hasValidValue = isNumber && !isNan;
        try {
          if (validationFormula) {
            result.hasValidValue = new Function('value', 'return (' + validationFormula + ')')(checkValue);
          }
        } catch (e) {
          result.hasValidValue = false;
        }
        result.value = resetFx
          ? checkValue
          : tag && scale && !onlyValue
          ? (checkValue * scale) / (previousScale || 1)
          : tag && metadata.scale && !onlyValue
          ? checkValue * metadata.scale
          : checkValue;
      }
      if (tag) {
        result.ocrValue = isOcr ? value : tag.ocrValue;
      }
      break;
    case 'area':
      result.hasValidValue = tag ? true : field.tags.length > 0;
      result.value = `selection on page ${page}`;
      result.ocrValue = result.value;
      result.hasValidOcrValue = result.hasValidValue;
      break;
    case 'table':
      result.hasValidValue = tag ? true : field.tags.length > 0;
      result.value = `table on page ${page}`;
      break;
    case 'text':
      result.hasValidValue = value !== null && value !== undefined && value.length > 0;
      break;
    case 'text-span':
      result.hasValidValue = value !== null && value !== undefined && value.length > 0;
      break;
    case 'link':
      result.hasValidValue = value !== null && value !== undefined && value.length > 0;
      break;
    default:
  }
  if (!onlyValue && dataType !== 'area' && dataType !== 'number') {
    result.ocrValue = value;
    result.hasValidOcrValue = result.hasValidValue;
  }
  return result;
};

export const getValueForCoordinates = async (regionInPixels, labels, textSpan = false) => {
  const draggableLeft = textSpan ? regionInPixels.x : (regionInPixels.x / regionInPixels.widthScale) * 100;
  const draggableTop = textSpan ? regionInPixels.y : (regionInPixels.y / regionInPixels.heightScale) * 100;
  const draggableWidth = textSpan ? regionInPixels.w : (regionInPixels.w / regionInPixels.widthScale) * 100;
  const draggableHeight = textSpan ? regionInPixels.h : (regionInPixels.h / regionInPixels.heightScale) * 100;
  const draggableBottom = draggableTop + draggableHeight;
  const draggableRight = draggableLeft + draggableWidth;
  let matchedLabels = labels?.filter(function ($droppable, i) {
    let top = $droppable.y;
    let left = $droppable.x;
    let height = $droppable.h;
    let width = $droppable.w;
    let right = width + left;
    let bottom = top + height;
    let isCoveredByHorizontal = top <= draggableBottom && bottom >= draggableTop;
    let isCoveredByVertical = left <= draggableRight && right >= draggableLeft;
    return isCoveredByHorizontal && isCoveredByVertical;
  });
  let value = '';
  const filtered = matchedLabels?.filter(function (el) {
    return el != null;
  });
  filtered &&
    filtered.map((word, i) => {
      if (!/\s+$/.test(word.value)) {
        word.value += ' ';
      }
      let length = word.value.length - 1;
      for (let i = 0; i < length; i++) {
        let letterEntry = {
          pageWidth: word.pageWidth,
          pageHeight: word.pageHeight,
          page: word.page,
          width: word.w / length,
          height: word.h,
          x: word.x + (i * word.w) / length,
          y: word.y,
          value: word.value.charAt(i),
        };
        let inTagBox =
          letterEntry.x + (letterEntry.width * 50) / 100 <= draggableRight &&
          letterEntry.x + (letterEntry.width * 50) / 100 >= draggableLeft;
        let inTagBoxVertical =
          draggableTop <= letterEntry.y + (letterEntry.height * 50) / 100 &&
          draggableBottom >= letterEntry.y + (letterEntry.height * 50) / 100;
        if (inTagBox && inTagBoxVertical) {
          value += letterEntry.value;
        }
      }
      if (i !== filtered.length - 1) {
        value += ' ';
      }
    });
  value = value.trim();
  return value;
};

export const getTableValues = async (table, imgW, imgH, page, labels) => {
  if (table) {
    let hasData = table.tbody.tr.some((tr) => {
      return tr.td.some((td) => td.value !== undefined);
    });
    if (hasData) return table;

    for (let trIndex = 0; trIndex < table.tbody.tr.length; trIndex++) {
      const tr = table.tbody.tr[trIndex];
      const { td: tdArray = [] } = tr;
      for (let tdIndex = 1; tdIndex < tdArray.length; tdIndex++) {
        const td = tdArray[tdIndex];
        td.value = await getCellValue(td, imgW, imgH, page, labels);
      }
    }
  }

  return table;
};

export async function getCellValue(tdData, imgW, imgH, page, labels) {
  let xPercent = parseFloat(tdData.x_left_top),
    yPercent = parseFloat(tdData.y_left_top),
    wPercent = parseFloat(tdData.width),
    hPercent = parseFloat(tdData.height);
  let regionInPixels;
  if (imgW < imgH) {
    regionInPixels = {
      x: (xPercent * imgW) / 100,
      y: (yPercent * imgH) / 100,
      w: (wPercent * imgW) / 100,
      h: (hPercent * imgH) / 100,
      widthScale: imgW,
      heightScale: imgH,
      page,
    };
  } else {
    regionInPixels = {
      x: (xPercent * imgH) / 100,
      y: (yPercent * imgW) / 100,
      w: (wPercent * imgH) / 100,
      h: (hPercent * imgW) / 100,
      widthScale: imgH,
      heightScale: imgW,
      page,
    };
  }
  return await getValueForCoordinates(regionInPixels, labels);
}

export const asyncForEach = async (array, callback) => {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
};

export const getValidValue = (value, thousandsSeparator, negativeSign, onlyValue, decimalPoint, isPercentage, tag) => {
  let result = value;
  const isNegativeSignParenthesesOrMultiple = negativeSign === '()' || negativeSign === 'multiple';
  if (String(value).startsWith('(') && String(value).endsWith(')')) {
    result = (isNegativeSignParenthesesOrMultiple ? '-' : '') + String(value).substring(1, value.length - 1);
  }
  if (!onlyValue || tag) {
    if (thousandsSeparator === '.') {
      result = String(result).replace(/[.]/g, '');
    }
    if (thousandsSeparator === ',') {
      result = String(result).replace(/,/g, '');
    }
    if (thousandsSeparator === '’') {
      result = String(result).replace(/’/g, '');
      result = String(result).replace(/'/g, '');
    }
    if (thousandsSeparator === ' ') {
      result = String(result).replace(/ /g, '');
    }
    result = String(result).replace(decimalPoint, '.');
  }
  const number = Number.parseFloat(result);
  if (number && isPercentage && tag) {
    result = String(number / 100);
  }
  const regEx = new RegExp(/^[\.\-]*$/);
  const containsOnlyDashesAndDots = regEx.test(result);

  return containsOnlyDashesAndDots ? '0' : result;
};

export const getAllTableValuesForFields = async (fields, img, labels) => {
  const imgW = img[0].naturalWidth,
    imgH = img[0].naturalHeight;
  await asyncForEach(fields, async (field) => {
    if (field.dataType === 'table') {
      await asyncForEach(field.tags, async (tag) => {
        let tableUpdates = await getTableValues(tag.table, imgW, imgH, tag.page, labels[tag.page]);
        tag.table = tableUpdates;
        tag.isTable = true;
      });
    }
  });
  return fields;
};

export const isChangedSmartTag = (oldSmartTag, newSmartTag) => {
  let isChanged = false;
  if (!oldSmartTag && !newSmartTag) {
    return false;
  }

  if (oldSmartTag && !newSmartTag) {
    return true;
  }

  isChanged = false;

  let isRenderTypeChanged =
    newSmartTag.renderType === 'cells' ||
    (oldSmartTag &&
      oldSmartTag.renderType &&
      (!newSmartTag.renderType || oldSmartTag.renderType !== newSmartTag.renderType));

  isChanged =
    isChanged ||
    isRenderTypeChanged ||
    !_.isEqual(oldSmartTag && oldSmartTag.dataStructure, newSmartTag.dataStructure) ||
    !_.isEqual(oldSmartTag && oldSmartTag.dataField, newSmartTag.dataField);

  return isChanged;
};

export const isNotValidSmartTagData = (smartTag) => {
  if (!smartTag) {
    return false;
  }

  const { dataStructure, dataField } = smartTag;

  return dataStructure && !dataField;
};

export const showFormulaFieldNames = (formula, templates) => {
  let result = formula;
  let currentValue = '';
  let fieldFormula = formula;
  let currentTemplate = '',
    currentField = '',
    currentGroup = '',
    currentChar;
  let index = 0;
  let start = false;
  for (let i = 0; i < fieldFormula.length; i++) {
    currentChar = fieldFormula[i];
    if (start) {
      if (currentChar === ']') {
        if (index % 3 === 0) {
          currentTemplate = currentValue;
        }
        if (index % 3 === 1) {
          currentGroup = currentValue;
        }
        if (index % 3 === 2) {
          currentField = currentValue;
        }
        start = false;
        index++;
      } else {
        currentValue += currentChar;
      }
    }
    if (currentChar === '[') {
      start = true;
    }
    if (!start) {
      if (currentTemplate && currentGroup && currentField) {
        let temp = templates.find((t) => {
          return t._id === currentTemplate;
        });
        let currentGroupCheck = currentGroup === null || currentGroup === 'undefined' ? '' : currentGroup;
        if (temp) {
          let fld = temp.fields.find((f) => f.groupKey === currentGroupCheck && f._id === currentField);
          result = result.replace(
            `[${currentTemplate}].[${currentGroup}].[${currentField}]`,
            `[${temp.name}].[${fld.group !== '' ? fld.group : 'None'}].[${fld.name}]`
          );
        }
        currentTemplate = currentGroup = currentField = '';
      }
      currentValue = '';
    }
  }
  return result;
};

export const replaceFormulaComponents = (formula, fieldComponents = []) => {
  if (formula && formula.trim !== '') {
    fieldComponents?.forEach((component) => {
      if (formula.includes(component.key)) {
        formula = formula.replace(component.key, component.value);
      }
    });
    return formula;
  }
  return null;
};

/**
 * Check if the value is a number.
 *
 * @param {*} value
 * @returns true if the value is number, false otherwise.
 */
export const isNumber = (value) => {
  return !isNaN(Number(value));
};

export const assignTagSectionWithKey = (field, tag, key, tagIndex) => {
  const newTag = { _id: tag._id, fieldId: field._id };
  const ObjectId = new mongoose.Types.ObjectId();

  if (field.tags[tagIndex] && field.tags[tagIndex].section) {
    newTag.section = _.cloneDeep(field.tags[tagIndex].section);
  } else {
    newTag.section = {};
  }
  newTag.section[key] = _.cloneDeep(tag);
  newTag.section[key].tagTitle = field.name;
  if (key === 'end') {
    newTag.section[key]._id = ObjectId.toString();
  }
  newTag.section[key].isTable = false;
  if (newTag.section[key].section) {
    delete newTag.section[key].section;
  }
  return newTag;
};

export const runMicrotaskValidations = ({ documentsTemplate = {}, validationFormulas = [], fields }) => {
  const _validationFormulas = _.cloneDeep(validationFormulas);
  const test = /\[(.*?)\]/g;
  const validationResult = [];
  const handleResult = async (validationItem, result, execute = true) => {
    const foundedTemplate = documentsTemplate.template._id === result[0].replace('[', '').replace(']', '');

    if (!foundedTemplate) return;

    const foundedField = fields.find((item) => {
      return item._id === result[2].replace('[', '').replace(']', '');
    });
    let value = '';
    switch (foundedField?.fieldType) {
      case 'classification':
        value = foundedField.value || null;
        break;
      case 'computation':
        const formula = replaceFormulaComponents(foundedField.valueFormula, foundedField.formulaComponents);
        if (!formula) {
          value = null;
        } else {
          value = runFormula(fields, formula);
        }
        break;

      default:
        value = foundedField?.tags[0]?.value || foundedField?.value || null;
        break;
    }
    validationItem.executableFormula = (validationItem.executableFormula || validationItem.formula).replace(
      `${result[0]}.${result[1]}.${result[2]}`,
      isNaN(value) ? JSON.stringify(value) : value
    );

    validationItem.template = foundedTemplate;
    validationItem.fields ? validationItem.fields.push(foundedField) : (validationItem.fields = [foundedField]);

    try {
      if (execute) {
        if (!eval(validationItem.executableFormula)) {
          validationItem.isValid = false;
          validationResult.push(validationItem);
        } else {
          validationItem.isValid = true;
        }
      }
    } catch (error) {
      validationItem.scriptError = true;
      validationItem.isValid = false;
      validationResult.push(validationItem);
      console.error(error, 'error');
    }
  };

  _validationFormulas.forEach((item) => {
    const result = item.formula.match(test);

    if (result.length === 3) {
      handleResult(item, result);
    } else {
      const chunkSize = 3;
      for (let i = 0; i < result.length; i += chunkSize) {
        const chunk = result.slice(i, i + chunkSize);
        handleResult(item, chunk, i + chunkSize === result.length);
      }
    }
  });
  return validationResult;
};

export const languageValueToOption = (value) => {
  const _value = value ? SUPPORTED_LANGUAGES.find((item) => item === value) : null;
  return _value
    ? {
        value: _value,
        label: _value,
      }
    : null;
};

export const generateTempField = (field) => {
  const ObjectId = new mongoose.Types.ObjectId();
  field._id = ObjectId.toString();
  field.srcFieldId = field.srcFieldId || field._id;
  field.tags = [];

  return field;
};

export const openInNewTab = (url, target = '_blank', host) => {
  const webURL = host ? host : getWebUrl();
  const path = `${webURL}${url}`;
  window.open(path, target);
};

export const getFieldsCountByPage = (fields, activeField) => {
  let fieldsCountByPages = {};
  for (let i = 0; i < fields.length; i++) {
    const field = fields[i];
    const { tags } = field;
    if (field.srcFieldId !== activeField._id || !tags[0]) continue;

    fieldsCountByPages[tags[0].page] = fieldsCountByPages[tags[0].page] ? fieldsCountByPages[tags[0].page] + 1 : 1;
  }

  return fieldsCountByPages;
};
