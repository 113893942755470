import React from 'react';
import { openConfirmResetDataStructureModal } from '../../../modal/service/ModalControllers';
import { useAppSelector } from '../../../../hooks/reduxHooks';
import { selectAnnotationRights } from '../../../../store/auth/selectors';
import { activeTemplateSelector } from '../../../../store/document/selectors';
import Button from '../../../Common/Button';

interface IResetButtonProps {
  handleReset: () => void;
}

const ResetButton = ({ handleReset }: IResetButtonProps) => {
  const annotationAllowed = useAppSelector(selectAnnotationRights);
  const activeTemplate = useAppSelector(activeTemplateSelector);
  return (
    <div className={'col-auto btn-group gap-1 pe-0'}>
      {annotationAllowed && (
        <Button
          id='reset-insight'
          variant='secondary'
          icon='ResetInsight'
          onClick={() => {
            openConfirmResetDataStructureModal({
              activeTemplateName: activeTemplate?.template?.name,
              onResponse: (resp: any) => {
                if (resp) {
                  handleReset();
                }
              },
            });
          }}
        >
          Reset Insight
        </Button>
      )}
    </div>
  );
};
export default ResetButton;
