import axios from 'axios';
import history from '../history';
import nprogress from 'nprogress';
import { getBaseURL } from '../appConfig';

const api = axios.create({
  proxy: true,
  baseURL: getBaseURL(),
  headers: {
    'x-auth': localStorage.getItem('jwtToken'),
    'Content-Type': 'application/json',
  },
  withCredentials: true,
  timeout: 6000000,
});
api.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const status = error.response?.status;
    if (status === 401) {
      localStorage.removeItem('jwtToken');
      const redirectTo = (window?.location?.pathname || '').includes('signin')
        ? ''
        : `?redirectTo=${window?.location?.pathname}`;
      history.push(`/signin${redirectTo}`);
    }
    if (status === 403) {
      history.push('/error');
      if (nprogress) {
        nprogress.done();
      }
    }
    if (status === 406 || status === 500) {
      if (nprogress) {
        nprogress.done();
        return error.response;
      }
    }
    if (status === 423) {
      history.push('/error?noAccess=true');
      if (nprogress) {
        nprogress.done();
      }
    }
    return Promise.reject(error);
  }
);

export default api;
