import React from 'react';

// TODO: need to remove [checked, className, className] props
interface RadioButtonsValue {
  labelName: string;
  value: string;
  checked: boolean;
  className: string;
  tipLable?: string;
}

type Props = {
  disabled?: boolean;
  options: RadioButtonsValue[];
  handleOptionChange: (value: string) => void;
};

const RadioToggleButtons: React.FC<Props> = ({ disabled, handleOptionChange, options }) => {
  return (
    <div className={`btn-group btn-group-toggle ${disabled ? 'disabled' : ''}`}>
      {options.map(({ labelName, className, value, checked, tipLable = labelName }, index) => {
        return (
          <label
            key={labelName}
            id={`radioToggleLabel${value}_${options.length - index}`}
            htmlFor={`radioToggleInput${value}_${options.length - index}`}
            className={className}
          >
            <input
              id={`radioToggleInput${value}_${options.length - index}`}
              type='radio'
              checked={checked}
              className='btn-check'
              value={value}
              onChange={(e) => handleOptionChange(e.target.value)}
            />
            {labelName}
          </label>
        );
      })}
    </div>
  );
};

export default RadioToggleButtons;
