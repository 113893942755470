import React, { FC } from 'react';
import { DataFieldProps } from '../types';
import _ from 'lodash';
import Icons from '../../Common/Icons/Icons';
import DataFieldOption from './DataFieldOption';
import DropDown from '../../Common/DropDown/DropDown';

const DropdownOrDataFieldTag: FC<DataFieldProps> = ({
  dataStructure,
  dataField,
  referenceFieldsData,
  onFieldClick,
  label,
  activeTagHash,
  disabled,
}) => {
  const selectedDataField = activeTagHash[1] === dataField;

  const groupedReferences = _.groupBy(referenceFieldsData, ({ renderType }) => renderType);
  const referenceFieldsHasDifferentRenderTypes = Object.keys(groupedReferences).length > 1;

  return referenceFieldsHasDifferentRenderTypes ? (
    <div>
      <DropDown
        className='tagging-editor-state-dropdown-submenu'
        position='bottom'
        key={dataField}
        data-dropdown-key={dataField}
        btnIcon={<Icons.DropdownChevronLeft className='m-0' />}
        btnText={label}
        btnClass={`btn bg-transparent dropdown-item dropdown-toggle
            ${selectedDataField ? 'active' : ''}
          `}
      >
        {Object.keys(groupedReferences).map((renderType, index) => {
          const selectedDataFieldRenderType = selectedDataField && activeTagHash[2] === renderType;
          const uniqueId = [dataStructure, dataField, renderType, index].join('_');
          return (
            <DataFieldOption
              key={uniqueId}
              id={uniqueId}
              dataStructure={dataStructure}
              dataField={dataField}
              referenceFieldsData={groupedReferences[renderType]}
              selectedDataField={selectedDataFieldRenderType}
              disabled={disabled}
              titleOrRenderType={renderType}
              onFieldClick={onFieldClick}
            />
          );
        })}
      </DropDown>
    </div>
  ) : (
    <DataFieldOption
      id={[dataStructure, dataField].join('_')}
      dataStructure={dataStructure}
      dataField={dataField}
      referenceFieldsData={referenceFieldsData}
      selectedDataField={selectedDataField}
      disabled={disabled}
      titleOrRenderType={label}
      onFieldClick={onFieldClick}
    />
  );
};

export default DropdownOrDataFieldTag;
