import { UPSERT_USER, QUERY_USER, CLEAN_USER_DATA } from './types';
import { UserAction } from './types';

const userReducer = (state = null, action: UserAction) => {
  switch (action.type) {
    case QUERY_USER:
      return action.payload;
    case UPSERT_USER:
      return action.payload;
    case CLEAN_USER_DATA:
      return null;
    default:
      return state;
  }
};

export default userReducer;
