const SUPPORTED_LANGUAGES = ['English', 'Chinese', 'French', 'German', 'Portuguese', 'Spanish'];
const SCALES = [
  { label: 'As reported', value: 1 },
  { label: 'Percentage', value: 0.01 },
  { label: 'Thousands', value: 1000 },
  { label: 'Millions', value: 1000000 },
  { label: 'Billions', value: 1000000000 },
];

const DEFAULT_OCR_TYPES = [
  { label: 'Tesseract', value: 'tesseract_ocr' },
  { label: 'Textract', value: 'textract_ocr' },
  { label: 'EasyOCR (Chinese)', value: 'easyocr_ocr' },
];

export { SCALES, SUPPORTED_LANGUAGES, DEFAULT_OCR_TYPES };
