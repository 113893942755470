import { useEffect, useState } from 'react';

const browserInitialTheme = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';

export const useBrowserThemeDetector = () => {
  const [browserTheme, setBrowserTheme] = useState(browserInitialTheme);

  useEffect(() => {
    const themeChangeListener = (e) => {
      setBrowserTheme(e.matches ? 'dark' : 'light');
    };
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', themeChangeListener);
    return () => window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', themeChangeListener);
  }, []);
  return browserTheme;
};
