import React, {useEffect, useState} from 'react';
import ButtonItem from './ButtonItem';
import {useAppSelector} from "../../../hooks/reduxHooks";
import {documentActiveFieldSelector} from "../../../store/document/selectors";

interface IButtonItem<T> {
  value: T;
  text: string;
  palette?: string;
}

interface IButtonsGroupProps<T, C> {
  buttons: IButtonItem<T>[];
  handleChange?: C;
  className?: string;
}

const ButtonsGroup = <T, C>(props: IButtonsGroupProps<T, C>) => {
  const { buttons, handleChange, className = '' } = props;
  const [active, setActive] = useState<number | null>(null);
  const activeField = useAppSelector(documentActiveFieldSelector);

  useEffect(()=>{
    if(activeField === null)
      setActive(null)
  },[activeField])



  const handleSelection = (itemIdx: number) => {
    if (itemIdx === active) {
      setActive(null);
      typeof handleChange === 'function' && handleChange(null);
    } else {
      setActive(itemIdx);
      typeof handleChange === 'function' && handleChange(buttons[itemIdx].value, buttons[itemIdx].palette);
    }
  };
  return (
    <div className={`buttons-group ${className}`}>
      {buttons.map((item, index: number) => {
        return (
          <ButtonItem variant='pure'
            key={index}
            text={item.text}
            itemIdx={index}
            active={active}
            setActive={handleSelection}
            palette={item.palette}
          />
        );
      })}
    </div>
  );
};

export default ButtonsGroup;
