import { REDO, UNDO, UPDATE_CHECKPOINT } from './types';
import { Dispatch } from 'redux';
import { RootState } from '../reducers';

export const undo = () => (dispatch: Dispatch, getState: () => RootState) => {
  const { checkpoint } = getState();
  checkpoint.undoNumber = checkpoint.undoNumber + 1;
  checkpoint.undoPressed = true;
  const docs = checkpoint.documentHistory;
  const index = docs.length - checkpoint.undoNumber - 1;
  dispatch({
    type: UPDATE_CHECKPOINT,
    payload: {
      undoNumber: checkpoint.undoNumber,
      undoPressed: checkpoint.undoPressed,
      redoPressed: false,
    },
  });

  if (docs[index]) {
    dispatch({ type: UNDO, payload: docs[index] });
  } else {
    dispatch({ type: UNDO, payload: getState().document });
  }
};
export const redo = () => (dispatch: Dispatch, getState: () => RootState) => {
  const { checkpoint } = getState();
  checkpoint.undoNumber = checkpoint.undoNumber - 1;
  checkpoint.undoPressed = false;
  const docs = checkpoint.documentHistory;
  const docsLength = docs.length - 1;

  dispatch({
    type: UPDATE_CHECKPOINT,
    payload: {
      undoNumber: checkpoint.undoNumber,
      undoPressed: checkpoint.undoPressed,
      redoPressed: true,
    },
  });
  if (docs[docsLength - checkpoint.undoNumber]) {
    dispatch({ type: REDO, payload: docs[docsLength - checkpoint.undoNumber] });
  } else {
    dispatch({ type: REDO, payload: getState().document });
  }
};
