import React from 'react';
import { connect } from 'react-redux';
import { upsertDocumentMetadata, forceOcr, dataTransfer } from '../../../actions';

import RadioToggleButtons from '../../Common/RadioToggleButtons';
import Selectbox from '../../Common/SelectBox';
import OcrSelectbox from '../../Common/SelectBox/specs/OcrSelectbox';
import TransferData from '../TransferButton/TransferData';
import { SUPPORTED_LANGUAGES, SCALES } from '../../../helpers/common/constants';
import { languageValueToOption, openInNewTab } from '../../../helpers/common/utils';
import Button from '../../Common/Button';
import CustomModal from '../../Common/CustomModal';

class MetaInfo extends React.Component {
  state = {
    language: this.props.document?.metadata?.language,
    decimalPoint:
      this.props.document.metadata && this.props.document.metadata.decimalPoint
        ? this.props.document.metadata.decimalPoint
        : '',
    thousandsSeparator:
      this.props.document.metadata && this.props.document.metadata.thousandsSeparator
        ? this.props.document.metadata.thousandsSeparator
        : '',
    negativeSign:
      this.props.document.metadata && this.props.document.metadata.negativeSign
        ? this.props.document.metadata.negativeSign
        : '',
    ocr: this.props.document.metadata.ocr,
    parenthesesSelected:
      this.props.document.metadata.negativeSign === 'multiple' || this.props.document.metadata.negativeSign === '()',
    minusSelected:
      this.props.document.metadata.negativeSign === 'multiple' || this.props.document.metadata.negativeSign === '-',
    scale: (this.props.document.metadata && this.props.document.metadata.scale) || null,
  };

  handleMetadataSave = (e) => {
    e.preventDefault();

    this.props.upsertDocumentMetadata(this.props.document._id, this.state);

    if (
      this.props.document?.metadata?.language !== this.state.language ||
      this.props.document?.metadata?.ocr !== this.state.ocr
    ) {
      this.props.forceOcr(this.props.document._id, this.props.document.src, this.state.ocr);
      const url = `/collection/${this.props.document.collectionId._id}`;
      openInNewTab(url, '_self');
    }
    this.props.onClose();
  };

  handleChangeOcr = (options) => {
    this.setState({ ocr: options.value });
  };

  handleChange = (value, name) => {
    const changed = {};
    if (name === 'decimalPoint') {
      changed.decimalPoint = value;
      this.state.thousandsSeparator === value && (changed.thousandsSeparator = value === '.' ? ',' : '.');
    }
    if (name === 'thousandsSeparator') {
      changed.thousandsSeparator = value;
      changed.decimalPoint = value !== ' ' && value !== '’' ? (value === '.' ? ',' : '.') : this.state.decimalPoint;
    }
    this.setState({ ...changed });
  };

  handleClick = (value, name) => {
    if (name === 'negativeSign') {
      let negativeSignValue = value;
      this.setNegativeSignValues(value, () => {
        if (this.state.parenthesesSelected && !this.state.minusSelected) {
          negativeSignValue = '()';
        }
        if (!this.state.parenthesesSelected && this.state.minusSelected) {
          negativeSignValue = '-';
        }
        if (this.state.parenthesesSelected && this.state.minusSelected) {
          negativeSignValue = 'multiple';
        }
        this.setState({ negativeSign: negativeSignValue });
      });
    }
  };

  setNegativeSignValues = (value, cb) => {
    switch (value) {
      case 'negativeSignParentheses':
        this.setState({ parenthesesSelected: !this.state.parenthesesSelected }, cb);
        break;
      case 'negativeSignMinus':
        this.setState({ minusSelected: !this.state.minusSelected }, cb);
        break;
      default:
        this.setState({ parenthesesSelected: true, minusSelected: false }, cb);
    }
  };

  render() {
    if (!this.props.document || !this.props.document._id) {
      return null;
    }

    return (
      <CustomModal
        minWidth={800}
        open={true}
        modalHandler={() => this.props.onClose()}
        onClick={(e) => {
          if (!e.target.closest('.modal-content')) {
            this.props.onClose();
          }
        }}
        classNames={{
          modal: this.props.classNames?.modal ? this.props.classNames.modal : 'custom-modal-xsm',
        }}
      >
        <div className={`modal-content`}>
          <form>
            <div className='modal-header'>
              <h2 className='ps-0 mb-0 col-6'>{this.props.document.name}</h2>
              <Button variant='tertiary' onClick={this.props.onClose} icon='ModalClose' className='close-btn' />
            </div>
            <div className='modal-body'>
              {this.props.document.collectionId && (
                <div className='form-group align-items-center mt-1 mx-0'>
                  <label className='form-label'>Collection</label>
                  <div className='col'>
                    <Button variant='primary' link={`/collection/${this.props.document.collectionId._id}`}>
                      {this.props.document.collectionId.collectionName}
                    </Button>
                  </div>
                </div>
              )}
              <div className='form-group'>
                <label className='form-label'>OCR language</label>
                <div className='col z-index2'>
                  <Selectbox
                    classes={`selectbox`}
                    isMulti={false}
                    onChange={(selectedOption) => this.setState({ language: selectedOption.value })}
                    placeholder='Please select OCR language'
                    value={languageValueToOption(this.state.language)}
                    options={SUPPORTED_LANGUAGES.map((key) => ({
                      value: key,
                      label: key,
                    }))}
                  />
                </div>
              </div>
              <div className='form-group'>
                <OcrSelectbox label={'OCR'} value={this.state.ocr} handleChange={this.handleChangeOcr} />
              </div>
              <div className='form-group'>
                <label className='form-label'>Decimal separator</label>
                <div className='col'>
                  <RadioToggleButtons
                    options={[
                      {
                        labelName: '.(dot)',
                        value: '.',
                        checked: this.state.decimalPoint === '.',
                        className: `btn btn-${(this.state.decimalPoint === '.' && 'primary') || 'tertiary'}`,
                      },
                      {
                        labelName: ',(comma)',
                        value: ',',
                        checked: this.state.decimalPoint === ',',
                        className: `btn btn-${(this.state.decimalPoint === ',' && 'primary') || 'tertiary'}`,
                      },
                    ]}
                    handleOptionChange={(value) => this.handleChange(value, 'decimalPoint')}
                  />
                </div>
              </div>
              <div className='form-group'>
                <label className='form-label'>Thousands separator</label>
                <div className='col'>
                  <RadioToggleButtons
                    options={[
                      {
                        labelName: '.(dot)',
                        value: '.',
                        checked: this.state.thousandsSeparator === '.',
                        className: `btn btn-${(this.state.thousandsSeparator === '.' && 'primary') || 'tertiary'}`,
                      },
                      {
                        labelName: ',(comma)',
                        value: ',',
                        checked: this.state.thousandsSeparator === ',',
                        className: `btn btn-${(this.state.thousandsSeparator === ',' && 'primary') || 'tertiary'}`,
                      },
                      {
                        labelName: '’(apostrophe)',
                        value: '’',
                        checked: this.state.thousandsSeparator === '’',
                        className: `btn btn-${(this.state.thousandsSeparator === '’' && 'primary') || 'tertiary'}`,
                      },
                      {
                        labelName: '(whitespace)',
                        value: ' ',
                        checked: this.state.thousandsSeparator === ' ',
                        className: `btn btn-${(this.state.thousandsSeparator === ' ' && 'primary') || 'tertiary'}`,
                      },
                    ]}
                    handleOptionChange={(value) => this.handleChange(value, 'thousandsSeparator')}
                  />
                </div>
              </div>
              <div className='form-group'>
                <label className='form-label'>Negative sign</label>
                <div className='col'>
                  <RadioToggleButtons
                    options={[
                      {
                        labelName: '-(hyphen)',
                        value: 'negativeSignMinus',
                        checked: '',
                        className: `btn btn-${
                          ((this.state.minusSelected || this.state.negativeSign === 'multiple') && 'primary') ||
                          'tertiary'
                        }`,
                      },
                      {
                        labelName: '()(parentheses)',
                        value: 'negativeSignParentheses',
                        checked: '',
                        className: `btn btn-${
                          ((this.state.parenthesesSelected || this.state.negativeSign === 'multiple') && 'primary') ||
                          'tertiary'
                        }`,
                      },
                    ]}
                    handleOptionChange={(value) => this.handleClick(value, 'negativeSign')}
                  />
                </div>
              </div>
              <div className='form-group'>
                <label className='form-label'>Standard scale</label>
                <div className='col z-index2'>
                  <Selectbox
                    menuPlacement={'top'}
                    classes={`selectbox`}
                    id='select2-metadata-scale'
                    name='scale'
                    isMulti={false}
                    onChange={(selectedOption) => this.setState({ scale: selectedOption.value })}
                    defaultValue={
                      this.props.document.metadata?.scale
                        ? SCALES.filter(({ value }) => value === this.props.document.metadata.scale)
                        : { value: SCALES[0].value, label: SCALES[0].label }
                    }
                    options={SCALES.map(({ value, label }) => ({
                      value: value,
                      label: label,
                    }))}
                  />
                </div>
              </div>
            </div>
            <div className='modal-footer metadata-modal-footer mt-0 pt-2 pb-3'>
              {this.props.document.collectionId?.isQic && (
                <TransferData transferStatus={this.props.document.transferStatus} id={this.props.document._id} />
              )}
              <div className='btn-group'>
                <Button
                  size='large'
                  variant='primary'
                  onClick={this.props.onClose}
                  icon='ModalCancel'
                  className='rounded-start'
                />
                <Button
                  size='large'
                  variant='secondary'
                  onClick={this.handleMetadataSave}
                  icon='ModalSave'
                  className='rounded-end'
                />
              </div>
            </div>
          </form>
        </div>
      </CustomModal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    document: state.document,
  };
};

export default connect(mapStateToProps, { upsertDocumentMetadata, forceOcr, dataTransfer })(MetaInfo);
