import React, { useMemo} from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import {
  palettesSelector,
  smartTagsSelector,
  taggingEditorStateSelector,
  templateFieldsSelector,
  templateOriginFieldsSelector,
} from '../../../store/document/selectors';
import { Field } from '../../../store/datastructure/types';
import { deselectTempField, selectTempField } from '../../../actions';

import ButtonsGroup from '../../Common/ButtonsGroup';
import { filterFieldsOnSmartTag } from '../Browse/BrowseUtils';
import {FALLBACK_PALETTE} from "./colors";

type THandleChange = (value: Field | null, palette: string) => void;

const ClassifierFields = () => {
  const originFields = useAppSelector(templateOriginFieldsSelector);
  const fields = useAppSelector(templateFieldsSelector);
  const smartTags = useAppSelector(smartTagsSelector);
  const palettes = useAppSelector(palettesSelector);
  const dispatch = useAppDispatch();
  const taggingEditorState = useAppSelector(taggingEditorStateSelector);

  const groupBySourceId: { [key: string]: Field[] } = fields.reduce((aggr: any, f: Field) => {
    if (f.srcFieldId && f.tags.length) {
      aggr[f.srcFieldId] = aggr[f.srcFieldId] ? aggr[f.srcFieldId] + 1 : 1;
    }
    return aggr;
  }, {});


  const fieldButtons = useMemo(() => {
    const filteredFields = filterFieldsOnSmartTag({
      fields: originFields,
      referenceFieldsData: smartTags?.activeTag?.referenceFieldsData,
      taggingEditorState,
    });

    return filteredFields.map((field: Field) => {
      const count = groupBySourceId[field._id!] || 0;
      const bgPalette = palettes[field._id!] || FALLBACK_PALETTE;
      return {
        value: field,
        text: `${field.name} | ${count}`,
        palette: bgPalette,
      };
    });
  }, [originFields, smartTags?.activeTag?.referenceFieldsData, taggingEditorState, groupBySourceId, palettes]);

  if (!originFields?.length) return null;

  const handleSelection: THandleChange = (value) => {
    if (value) {
      dispatch(selectTempField(value));
    } else {
      dispatch(deselectTempField());
    }
  };


  return (
    <div className='template-buttons'>
      {
        fieldButtons?.length ? <ButtonsGroup<Field, THandleChange>
            buttons={fieldButtons}
            handleChange={handleSelection}
        /> : <div className='no-smarttag-text'>
          There are no Classes linked to the selected Smart Tag.</div>
      }

    </div>
  );
};

export default ClassifierFields;
