import React from 'react';

import Icons from '../../Common/Icons';

const fieldIcon = (fieldType, dataType) => {
  switch (fieldType) {
    case 'classification':
      return <Icons.Classification className='float-start' />;
    case 'computation':
      return <Icons.Computation className='float-start' />;
    case 'extraction and computation':
      return <Icons.ExtractionAndComputation className='float-start' />;
    default:
      switch (dataType) {
        case 'table':
          return <Icons.TableField className='float-start' />;
        case 'number':
          return <Icons.NumberField className='float-start' />;
        case 'text':
          return <Icons.TextBoxField className='float-start' />;
        case 'date':
          return <Icons.CalendarField className='float-start' />;
        case 'area':
          return <Icons.AreaField className='float-start' />;
        case 'link':
          return <Icons.LinkField className='float-start' />;
        case 'text-span':
          return <Icons.TextSpanField className='float-start' />;
        default:
          return null;
      }
  }
};

export default fieldIcon;
