import { RolesAction, Rules, SET_RULES } from './types';

const initialState: Rules = {};

const rulesReducer = (state: Rules = initialState, action: RolesAction): Rules => {
  switch (action.type) {
    case SET_RULES:
      return action.payload;
    default:
      return state;
  }
};

export default rulesReducer;
