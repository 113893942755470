import innolytiqApi from '../../api/innolytiqApi';
import {
  UPDATE_TEMPLATE_STATUS,
  SAVE_DOCUMENT_TEMPLATE_DATA,
  SET_DATA_LOST_DIALOG,
  RESET_DATA_LOST_DIALOG,
  SET_STEP_FINISH_TIMESTAMP,
  SET_DOCUMENT_SMART_HOVER_TEMPLATES,
  SET_ACTIVE_SMART_HOVER_TEMPLATE,
} from './types';
import { getAllTableValuesForFields } from '../../helpers/common/utils';
import { updateSmartTags } from '../../actions/index';
import { REINITIALIZE_CHECKPOINT } from '../checkpoint/types';

export const setDataLostDialog = (data) => async (dispatch) => {
  dispatch({ type: SET_DATA_LOST_DIALOG, payload: data });
};

export const updateTemplateStatus =
  (documentId, templateId, status, img, fields, workflowChanges) => async (dispatch, getState) => {
    const { document } = getState();
    dispatch({ type: UPDATE_TEMPLATE_STATUS, payload: status });
    await getAllTableValuesForFields(fields, img, document.pageLabels);
    const response = await innolytiqApi.put(`documents/${documentId}/finish/${templateId}`, {
      status,
      fields,
      workflowChanges,
    });
    if (status) {
      dispatch({
        type: SET_STEP_FINISH_TIMESTAMP,
        payload: { templateId },
      });
      dispatch({ type: SAVE_DOCUMENT_TEMPLATE_DATA, payload: response.data });
      const {document: {activeTemplate,activePage},annotation: {tags}} = getState();
      dispatch({ type: REINITIALIZE_CHECKPOINT, payload: {activeTemplate,tags,activePage} });
    }
  };

export const saveDocument = (documentId, templateId, fields, img, version, startedAt) => async (dispatch, getState) => {
  const { document } = getState();
  await getAllTableValuesForFields(fields, img, document.pageLabels);
  const response = await innolytiqApi.post(`documents/${documentId}/save`, {
    templateId,
    fields,
    version,
    startedAt,
  });

  dispatch({ type: SAVE_DOCUMENT_TEMPLATE_DATA, payload: response.data });
  const {document: {activeTemplate,activePage},annotation: {tags}} = getState();
  dispatch({ type: REINITIALIZE_CHECKPOINT, payload: {activeTemplate,tags,activePage} });
};

export const saveDocumentTag =
  ({ templateId, fieldId, tag }, callback = () => {}) =>
  async (dispatch, getState) => {
    const { document } = getState();

    await innolytiqApi.post(`documents/${document._id}/savetag`, {
      templateId,
      fieldId,
      tag,
    });

    callback();
  };

export const resetDataLostDialog = (data) => async (dispatch) => {
  dispatch({ type: RESET_DATA_LOST_DIALOG, payload: data });
  dispatch(updateSmartTags({ resetAll: true }));
};

export const setSaveSuccessful =
  (status = 'success') =>
  (dispatch) => {
    dispatch({
      type: SAVE_DOCUMENT_TEMPLATE_DATA,
      payload: {
        status,
        isLocked: false,
      },
    });
  };

/**
 * Find all templates where the smart hover is enabled at least for one field.
 *
 * @param {*} documentId
 * @returns
 */
export const getDocumentSmartHoverTemplates = (documentId) => async (dispatch) => {
  try {
    const data = await innolytiqApi.get(`templates/smarthover/${documentId}`);
    dispatch({
      type: SET_DOCUMENT_SMART_HOVER_TEMPLATES,
      payload: data.data,
    });
  } catch (e) {
    if (e.response.status === 404) {
      dispatch({
        type: SET_DOCUMENT_SMART_HOVER_TEMPLATES,
        payload: [],
      });
    }
    console.log(e, 'e');
  }
};

/**
 * Find template from document templates (including fields).
 *
 * @param {*} param0
 * @param {*} cb
 * @returns
 */
export const getTemplate =
  ({ documentId, templateId }, cb = (data) => data) =>
  async () => {
    try {
      const response = await innolytiqApi.get(`documents/${documentId}/templates/${templateId}`);
      cb(response.data);
    } catch (e) {
      console.log(e, 'e');
    }
  };

/**
 * Select smart hover template.
 *
 * @param {*} payload
 * @returns
 */
export const setActiveSmartHoverTemplate = (payload) => (dispatch) => {
  dispatch({
    type: SET_ACTIVE_SMART_HOVER_TEMPLATE,
    payload,
  });
};
