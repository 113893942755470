import React, { useCallback, useMemo, useRef, useState } from 'react';
import { updateTableOfOriginSmartTag } from '../../actions';
import $ from 'jquery';
import TableV2 from '../TableV2/Table';
import { useAppDispatch } from '../../hooks/reduxHooks';

export const useOriginTableEdit = ({ tag, img, resizeHandle }) => {
  const [isActive, setIsActive] = useState(false);
  const referenceTableElement = useRef(null);
  const dispatch = useAppDispatch();

  const updateCallback = useCallback(
    ({ table }) => {
      if (!table) {
        return;
      }

      dispatch(
        updateTableOfOriginSmartTag(
          table,
          {
            imgW: img.naturalWidth,
            imgH: img.naturalHeight,
          },
          tag.srcFieldId,
          tag.fieldId,
          tag._id
        )
      );
    },
    [dispatch, img, tag.srcFieldId, tag.fieldId, tag._id]
  );

  const deleteCallback = useCallback(() => {
    // dispatch(deleteTag(null, Tag.fieldId, tagId));
  }, []); // dispatch, Tag.fieldId, tagId

  // Table edit
  const activate = useCallback(() => {
    setIsActive(true);
  }, []);

  const deactivate = useCallback(() => {
    setIsActive(false);
  }, [referenceTableElement]);

  const element = useMemo(
    () =>
      !isActive ? null : (
        <TableV2
          key={`${tag._id}_${resizeHandle}`}
          isSelected={true}
          containerSize={{
            width: $(`.carousel-item.active img`).width(),
            height: $(`.carousel-item[data-page-number=${tag.page}]`).height(),
          }}
          value={tag}
          onChange={updateCallback}
          onDelete={deleteCallback}
          onSelect={() => {}}
          smartTagMode={true}
        />
      ),
    [isActive, resizeHandle, deleteCallback]
  );

  return {
    element,
    activate,
    deactivate,
  };
};
