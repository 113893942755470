import React, {useEffect, useState} from "react";
import Button from "../../../Common/Button";
import {openRotatePagesModal} from "../../../modal/service/ModalControllers";
import {useAppDispatch, useAppSelector} from "../../../../hooks/reduxHooks";
import {documentSelector} from "../../../../store/document/selectors";
import {changePage} from "../../../../actions";

const PageSwitcher = () => {
    const document = useAppSelector(documentSelector);
    const dispatch = useAppDispatch();
    const [page,setPage] = useState<number | string>(document.activePage);

    useEffect(()=>{
        setPage(document.activePage)
    },[document.activePage])

    const handlePageNumberChange = (e:any) => {
        const pageNumber = parseInt(e.target.value);

        if (pageNumber >= 1 && pageNumber <= document.pages.length) {
            setPage(pageNumber);
            dispatch(changePage(pageNumber - 1));
        }
        else{
            setPage('')
        }

    };
    const handlePrevPage = (e:any) => {
        e.preventDefault();
        let activePage =
            document.activePage !== 1 ? document.activePage - 2 :document.pages.length - 1;
        setPage(activePage+1)
        dispatch(changePage(activePage));
    };

    const handleNextPage = (e:any) => {
        e.preventDefault();
        let activePage =
            document.activePage !== document.pages.length ? document.activePage : 0;
        setPage(activePage+1 )
        dispatch(changePage(activePage));
    };



    return (
        <>
        <div className='d-inline-flex align-items-center'>
            <input
                type='text'
                className='ps-0 page-number-input'
                value={page}
                onChange={handlePageNumberChange}
            />
            <span className='p-0 label ms-2'>/ {document ? document.pages?.length : ''}</span>
        </div>
        <div className='col-auto btn-group gap-1' role='group'>
            <Button
                variant='primary'
                onClick={handlePrevPage}
                icon={'DocumentPrevPage'}
                className='rounded-start'
            />
            <Button variant='primary' onClick={handleNextPage} icon={'DocumentNextPage'} className='rounded-0' />
            <Button
                variant='primary'
                onClick={() => openRotatePagesModal({ document })}
                icon={'RotatePageModalOpener'}
                className='rounded-end'
            >
                Rotate
            </Button>
        </div>
        </>
    )
}

export default PageSwitcher;