import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {cloneDeep} from 'lodash';
import {selectAnnotationRights} from '../../store/auth/selectors';
import {
    forceUpdateTagOfOriginSmartTag,
    navigateToSmartTag,
    saveFieldOfOriginSmartTag,
} from '../../actions';
import {useOriginTableEdit} from './useOriginTableEdit';
import {useOriginTagEdit} from './useOriginTagEdit';
import {smartTagsActiveTagHashSelector} from '../../store/document/selectors';
import {useAppDispatch} from '../../hooks/reduxHooks';
import Button from '../Common/Button';

export const useOriginEdit = ({isEditMode, setEditMode, tag, img, resizeHandle,smartTags}) => {
    const dispatch = useAppDispatch();

    const annotationPermission = useSelector(selectAnnotationRights);
    const activeTagHash = useSelector(smartTagsActiveTagHashSelector);

    const [oldTag, setOldTag] = useState(null);
    const [isFreezed, setFreezed] = useState(false);

    const isTable = useMemo(() => !!(tag.isTable && tag.table), [tag.isTable, tag.table]);

    const tagStyles = useMemo(
        () =>
            tag.isTable
                ? {
                    height: tag.table.style.height,
                    width: tag.table.style.width,
                    left: tag.table.style.left,
                    top: tag.table.style.top,
                }
                : {
                    top: tag.top,
                    left: tag.left,
                    height: tag.height,
                    width: tag.width,
                },
        [isTable, tag.top, tag.left, tag.height, tag.width, tag.isTable, tag.table]
    );

    // Table
    const {
        element: tableElement,
        activate: tableActivate,
        deactivate: tableDeactivate,
    } = useOriginTableEdit({
        tag,
        img,
        resizeHandle,
    });

    // Tag
    const {
        element: tagElement,
        activate: tagActivate,
        deactivate: tagDeactivate,
    } = useOriginTagEdit({
        tagStyles,
        tag,
        img,
    });

    const element = useMemo(() => (isTable ? tableElement : tagElement), [isTable, tableElement, tagElement]);
    const activate = useMemo(() => (isTable ? tableActivate : tagActivate), [isTable, tableActivate, tagActivate]);
    const deactivate = useMemo(
        () => (isTable ? tableDeactivate : tagDeactivate),
        [isTable, tableDeactivate, tagDeactivate]
    );

    // Controllers - enable
    const enableEditMode = useCallback(() => {
        if (!annotationPermission || isEditMode || isFreezed) {
            return;
        }

        setEditMode(true);
        setOldTag(cloneDeep(tag));
        activate();
    }, [setEditMode, isFreezed, annotationPermission, isEditMode, activate, tag]);

    // Controllers - disable
    const disableEditMode = useCallback(
        (revertToPrevState = true) => {
            if (!annotationPermission || !isEditMode || isFreezed) {
                return;
            }
            setEditMode(false);

            setOldTag(null);
            if (revertToPrevState) {
                const {srcFieldId, fieldId, ...tag} = oldTag;
                dispatch(forceUpdateTagOfOriginSmartTag(tag, srcFieldId, fieldId));
            }
            deactivate();
        },
        [setEditMode, isFreezed, annotationPermission, isEditMode, deactivate, dispatch, oldTag]
    );

    // Controllers - save
    const saveOriginTag = useCallback(() => {
        if (!isEditMode || isFreezed) {
            return;
        }
        setFreezed(true);
        dispatch(
            saveFieldOfOriginSmartTag(oldTag.srcFieldId, oldTag.fieldId, oldTag._id, () => {
                setFreezed(false);
                setOldTag(null);
                disableEditMode(false);
            })
        );
    }, [isEditMode, isFreezed, disableEditMode, dispatch, oldTag]);

    // Navigation between smart tags
    const navigateToTag = useCallback((direction) => {
        const activeSmartTagIdx = smartTags.findIndex((field)=>{
            return field._id === tag.fieldId
        })
        const nextField = direction === 'next' ? smartTags[activeSmartTagIdx+1] || smartTags[0] : smartTags[activeSmartTagIdx-1] || smartTags[smartTags.length - 1];
        const smartTag = nextField.tags[0];
        dispatch(navigateToSmartTag(smartTag))
    },[dispatch, smartTags, tag.fieldId])

    useEffect(() => {
        if (!activeTagHash) {
            return;
        }

        disableEditMode();
    }, [activeTagHash]);

    const originElement = useMemo(() => (!annotationPermission ? null : element), [annotationPermission, element]);

    const controllerElements = useMemo(
        () =>
            !annotationPermission ? null : (
                <div className={'position-absolute'} style={tagStyles}>
                    <div
                        className={`smart-tag-origin-edit
              ${isEditMode ? 'save-cancel-buttons' : ''}
              ${isTable ? 'table-mode' : ''}
            `}
                    >
                        {isEditMode ? (
                            <div className={'d-flex flex-row'}>
                                <Button variant='primary' icon='SmartTagEditSave' onClick={saveOriginTag}
                                        className='rounded-start'/>
                                <Button variant='primary' icon='SmartTagEditCancel' onClick={disableEditMode}
                                        className='rounded-end'/>
                            </div>
                        ) : (
                            <div className={'d-flex flex-row styled-buttons-group'}>
                                <Button variant='primary' icon='SmartTagEdit' onClick={enableEditMode} title='Edit Smart Tag'/>
                                <Button variant='primary' icon='ArrowUp' onClick={()=>navigateToTag('previous')} title='Previous Smart Tag'/>
                                <Button variant='primary' icon='ArrowDown' onClick={()=>navigateToTag('next')} title='Next Smart Tag'/>
                            </div>
                        )}
                    </div>
                </div>
            ),
        [annotationPermission, tagStyles, isEditMode, isTable, saveOriginTag, disableEditMode, enableEditMode,navigateToTag]
    );

    return {
        isTable,
        originElement,
        controllerElements,
    };
};
