// by Rouben Meschian - rmeschian@gmail.com

import React, { useState, useRef, useCallback, useEffect } from 'react';
import './Table.scss';
import Draggable from 'react-draggable';
import { readOldFormat } from './back-compatability/formatConverter';
import {
  parseNum,
  convertTableToPx,
  convertTableToPerc,
  insertRow,
  deleteRow,
  insertCol,
  deleteCol,
  mergeCells,
  updateCellIndexes,
  isTableInPercent,
  computeCoordinatesForEachCell,
} from './TableUtils';
import {
  createCell,
  createHResizer,
  createVResizer,
  createTableRow,
  createTable,
  normalizeTable,
} from './tableFactory';
import { clone } from './utils';

/**
 * Adds cell coordinates and older formats for backward compatibility.
 */
function finalizeTableData(tableData, containerSize) {
  const newTableData = clone(tableData);
  computeCoordinatesForEachCell(newTableData.table, containerSize);
  return newTableData;
}

function createInitialData() {
  const tableData = {
    table: createTable({
      style: { left: '10%', top: '10%', width: '40%', height: '40%' },
    }),
  };
  const table = tableData.table;
  table.thead.tr[0].th.push(createVResizer({ style: { width: '100%' } }));
  table.tbody.tr = [createTableRow({ td: [createHResizer({ style: { height: '100%' } }), createCell()] })];
  return tableData;
}
const initialData = createInitialData();

function Table({
  value: inputTableData,
  containerSize = {},
  onChange,
  onDelete,
  onSelect,
  isSelected = false,
  smartTagMode = false,
}) {
  const [tableData, setTableData] = useState(inputTableData ? undefined : initialData);
  const tableRef = useRef();
  const disableChangeEventsRef = useRef(0);

  useEffect(() => {
    if (disableChangeEventsRef.current > 0 || !inputTableData) return;
    if (containerSize.width === undefined || containerSize.height === undefined) return;

    if (tableData) {
      // finalize Table data for the external system (include old format, compute coordinates of each cell, etc...)
      const newTableData = finalizeTableData(tableData, containerSize);
      if (JSON.stringify(inputTableData.table) === JSON.stringify(newTableData.table)) return;
    }

    // the input data does not contain the new 'Table' data, so need to read in from an older format
    if (!inputTableData.table) {
      const newTableData = readOldFormat(inputTableData);
      setTableData(newTableData);
      return;
    }

    // input data contains the new Table format - normalize the Table
    const normalizedInputTableData = clone(inputTableData);
    normalizeTable(normalizedInputTableData.table); // fill in missing parts of the Table
    setTableData(normalizedInputTableData);
  }, [inputTableData]);

  useEffect(() => {
    if (disableChangeEventsRef.current > 0 || !tableData) return;
    if (containerSize.width === undefined || containerSize.height === undefined) return;
    if (JSON.stringify(inputTableData.table) === JSON.stringify(tableData.table)) return;

    // finalize Table data for the external system (include old format, compute coordinates of each cell, etc...)
    const newTableData = finalizeTableData(tableData, containerSize, tableRef.current);

    // if the Table has not changed, don't call onChange
    if (JSON.stringify(inputTableData.table) === JSON.stringify(newTableData.table)) return;

    // clear values in tds
    newTableData.table.tbody.tr.forEach((tr) => {
      tr.td.forEach((td) => delete td.value);
    });
    tableData.table.tbody.tr.forEach((tr) => {
      tr.td.forEach((td) => delete td.value);
    });
    newTableData.table.updated = false;

    onChange(newTableData);
  }, [tableData]);

  const mousePosRef = useRef();

  const tableClasses = ['annotationTable'];
  if (isSelected) tableClasses.push('annotationTableSelected');

  const draggableParams = {
    zIndex: 999,
    defaultClassName: 'DragHandle',
    defaultClassNameDragging: 'DragHandleActive',
    position: { x: 0, y: 0 },
    defaultPosition: { x: 0, y: 0 },
    onStop: useCallback(() => {
      disableChangeEventsRef.current--;

      // pixels to percent
      const newTableData = clone(tableData);
      convertTableToPerc(newTableData.table, containerSize, tableRef.current);
      setTableData(newTableData);
    }, [tableData, containerSize]),
    onStart: useCallback(
      (e) => {
        disableChangeEventsRef.current++;

        // store initial position of the mouse
        mousePosRef.current = {
          x: e.clientX,
          y: e.clientY,
        };

        // percent to pixels
        const newTableData = clone(tableData);
        convertTableToPx(newTableData.table, containerSize, tableRef.current);
        setTableData(newTableData);
      },
      [tableData, containerSize]
    ),
  };

  if (!tableData || !tableData.table || !tableData.table.thead || !tableData.table.tbody) {
    return null; // do not render invalid data
  }

  if (containerSize.width === undefined || containerSize.height === undefined) return null;

  // render coordinates and sizing of Table, rows and columns in pixels
  // rendering with percentages causes columns to jiggle (shake) unfortunately
  const pxTableData = clone(tableData);
  if (isTableInPercent(pxTableData.table)) {
    convertTableToPx(pxTableData.table, containerSize);
  }

  return (
    <table
      ref={tableRef}
      style={pxTableData.table.style}
      className={tableClasses.join(' ')}
      onClick={() => {
        onSelect();
      }}
    >
      <thead>
        <tr className='topHandleRow'>
          {tableData.table.thead.tr[0].th.map((thData, thIndex, thArray) => {
            const { id } = thData;
            const { style = {} } = pxTableData.table.thead.tr[0].th[thIndex];
            const { height: thHeight = 0, width: thWidth = 0 } = style;

            return (
              <th
                style={{ height: thHeight, width: thWidth }}
                key={id}
                data-col-array-index={thIndex}
                data-row-array-index={0}
              >
                {/* Column adder */}
                <div
                  className='columnAdder bottomBorder'
                  onClick={(e) => {
                    if (e.detail === 2) {
                      const newTableData = clone(tableData);
                      convertTableToPx(newTableData.table, containerSize);

                      const adderContainer = e.target.getBoundingClientRect();
                      const localXPx = e.clientX - adderContainer.left;

                      // the width of the th we are adding the column in (column we are splitting)
                      const splittingThWidthPx = parseNum(newTableData.table.thead.tr[0].th[thIndex].style.width);

                      // compute the width of the new column (to the left side)
                      const newLeftColWidthPx = localXPx;

                      // compute the updated width of the old column (to the right side)
                      const updatedRightColWidthPx = splittingThWidthPx - newLeftColWidthPx;

                      insertCol(newTableData.table, thIndex + 1);

                      // set the width of the new TH
                      if (!newTableData.table.thead.tr[0].th[thIndex].style)
                        newTableData.table.thead.tr[0].th[thIndex].style = {};
                      newTableData.table.thead.tr[0].th[thIndex].style.width = `${newLeftColWidthPx}px`;

                      // update the width of the TH that was split (to the right of the split)
                      if (!newTableData.table.thead.tr[0].th[thIndex + 1].style)
                        newTableData.table.thead.tr[0].th[thIndex + 1].style = {};
                      newTableData.table.thead.tr[0].th[thIndex + 1].style.width = `${updatedRightColWidthPx}px`;

                      convertTableToPerc(newTableData.table, containerSize);
                      setTableData(newTableData);
                    }
                  }}
                ></div>

                {/* Column resizer */}
                <Draggable
                  {...draggableParams}
                  axis='x'
                  onDrag={(e, { deltaX }) => {
                    if (deltaX === 0) return;

                    const localDeltaXPx = e.clientX - mousePosRef.current.x; // how much mouse has traveled
                    mousePosRef.current.x = e.clientX;

                    const newTableData = clone(tableData);
                    const newTargetTr = newTableData.table.thead.tr[0];

                    if (thIndex === thArray.length - 1) {
                      // right-most column

                      newTargetTr.th.forEach((curTh, idx, arr) => {
                        if (idx === 0) return; // ignore the corner th

                        // get width of the current th
                        let thWidthPx = parseNum(curTh?.style?.width);

                        // update the width of the target th
                        if (idx === arr.length - 1) {
                          thWidthPx += localDeltaXPx;
                        }

                        if (thWidthPx < 3) return; // bounding constraint

                        if (!curTh.style) curTh.style = {};
                        curTh.style.width = `${thWidthPx}px`; // set updated th width in pixels
                      });

                      const updatedTableWidthPx = newTargetTr.th.reduce((aggr, th) => {
                        return aggr + parseNum(th.style.width);
                      }, 0);

                      // bounding constraint
                      if (parseNum(newTableData.table.style.left) + updatedTableWidthPx > containerSize.width) {
                        return;
                      }
                    } else if (thIndex === 0) {
                      // left-most column

                      // update th width
                      const newWidth = parseNum(newTargetTr.th[1]?.style?.width) - localDeltaXPx;

                      if (newWidth < 3) return; // bounding constraint

                      if (!newTargetTr.th[1].style) newTargetTr.th[1].style = {};
                      newTargetTr.th[1].style.width = `${newWidth}px`;

                      // update Table left position
                      if (!newTableData.table.style) newTableData.table.style = {};
                      newTableData.table.style.left = `${parseNum(newTableData.table?.style?.left) + localDeltaXPx}px`;

                      // bounding constraint
                      if (parseNum(newTableData.table.style.left) < 0) {
                        return;
                      }
                    } else {
                      // columns in between

                      const leftCol = newTargetTr.th[thIndex];
                      const rightCol = newTargetTr.th[thIndex + 1];

                      const updatedLeftColWidth = parseNum(leftCol?.style?.width) + localDeltaXPx;
                      const updatedRightColWidth = parseNum(rightCol?.style?.width) - localDeltaXPx;

                      if (updatedLeftColWidth - 6 <= 0 || updatedRightColWidth - 6 <= 0) return;

                      if (!leftCol.style) leftCol.style = {};
                      leftCol.style.width = `${updatedLeftColWidth}px`;

                      if (!rightCol.style) rightCol.style = {};
                      rightCol.style.width = `${updatedRightColWidth}px`;
                    }

                    setTableData(newTableData);
                  }}
                >
                  <div
                    className='tableResizer verticalTableResizer'
                    onClick={(e) => {
                      if (e.detail === 2) {
                        // delete column
                        if (thIndex === 0 || thIndex === thArray.length - 1) return;

                        const newTableData = clone(tableData);
                        deleteCol(newTableData.table, thIndex);
                        setTableData(newTableData);
                      }
                    }}
                  >
                    ▼
                  </div>
                </Draggable>

                {/* Table mover */}
                {thIndex === 0 ? (
                  <Draggable
                    {...draggableParams}
                    onDrag={(e, { deltaX, deltaY }) => {
                      if (deltaX === 0 && deltaY === 0) return;

                      const localDeltaX = e.clientX - mousePosRef.current.x; // how much mouse has traveled
                      const localDeltaY = e.clientY - mousePosRef.current.y;
                      mousePosRef.current.x = e.clientX;
                      mousePosRef.current.y = e.clientY;

                      const newTableData = clone(tableData);
                      const tableWidthPx = newTableData.table.thead.tr[0].th.reduce((aggr, th) => {
                        return aggr + parseNum(th?.style?.width);
                      }, 0);
                      const tableHeightPx = newTableData.table.tbody.tr.reduce((aggr, tr) => {
                        return aggr + parseNum(tr.td[0]?.style?.height);
                      }, 0);
                      const newLeftPx = parseNum(newTableData.table?.style?.left) + localDeltaX;
                      if (newLeftPx >= 0 && newLeftPx + tableWidthPx <= containerSize.width)
                        // bounding constraint
                        newTableData.table.style.left = `${newLeftPx}px`;

                      const newTopPx = parseNum(newTableData.table?.style?.top) + localDeltaY;
                      if (newTopPx >= 0 && newTopPx + tableHeightPx <= containerSize.height)
                        // bounding constraint
                        newTableData.table.style.top = `${newTopPx}px`;

                      setTableData(newTableData);
                    }}
                  >
                    <div className='tableMoveHandle'>☰</div>
                  </Draggable>
                ) : null}

                {/* Table delete */}
                {!smartTagMode && thIndex === thArray.length - 1 ? (
                  <div
                    className='tableDeleteBtn'
                    onClick={(e) => {
                      e.stopPropagation();
                      onDelete();
                    }}
                  >
                    ×
                  </div>
                ) : null}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>
        {tableData.table.tbody.tr.map(({ style = {}, id, td: tdArray = [] }, trIndex) => {
          return (
            <tr style={style} key={id}>
              {tdArray.map((tdData, tdIndex) => {
                const { id, colspan = 1, rowspan = 1 } = tdData;
                const { style = {} } = pxTableData.table.tbody.tr[trIndex].td[tdIndex];
                const { width: tdWidth = 0, height: tdHeight = 0 } = style;
                return (
                  <td
                    key={id}
                    style={{ width: tdWidth, height: tdHeight }}
                    colSpan={colspan}
                    rowSpan={rowspan}
                    data-id={id}
                    data-col-array-index={tdIndex}
                    data-row-array-index={trIndex + 1}
                  >
                    {tdIndex === 0 ? (
                      <>
                        {/* Row adder */}
                        <div
                          className='rowAdder rightBorder'
                          onClick={(e) => {
                            if (e.detail === 2) {
                              const adderContainer = e.target.getBoundingClientRect();
                              const localYPx = e.clientY - adderContainer.top;

                              const newTableData = clone(tableData);
                              convertTableToPx(newTableData.table, containerSize);

                              // height of the row we are splitting
                              const splittingThHeightPx = parseNum(
                                newTableData.table.tbody.tr[trIndex].td[0]?.style?.height
                              );

                              // compute the height of the new row (to the left side)
                              const newLeftColHeightPx = localYPx;

                              // compute the updated height of the old row (to the right side)
                              const updatedRightColHeightPx = splittingThHeightPx - newLeftColHeightPx;

                              insertRow(newTableData.table, trIndex + 1);

                              if (!newTableData.table.tbody.tr[trIndex].td[0].style)
                                newTableData.table.tbody.tr[trIndex].td[0].style = {};
                              newTableData.table.tbody.tr[trIndex].td[0].style.height = `${newLeftColHeightPx}px`;

                              if (!newTableData.table.tbody.tr[trIndex + 1].td[0].style)
                                newTableData.table.tbody.tr[trIndex + 1].td[0].style = {};
                              newTableData.table.tbody.tr[
                                trIndex + 1
                              ].td[0].style.height = `${updatedRightColHeightPx}px`;

                              convertTableToPerc(newTableData.table, containerSize);
                              setTableData(newTableData);
                            }
                          }}
                        ></div>

                        {/* Row resizer */}
                        <Draggable
                          axis='y'
                          {...draggableParams}
                          onDrag={(e, { deltaY }) => {
                            if (deltaY === 0) return;

                            const localDeltaY = e.clientY - mousePosRef.current.y; // how much mouse has traveled
                            mousePosRef.current.y = e.clientY;

                            const newTableData = clone(tableData);
                            const newTargetTable = newTableData.table;

                            if (trIndex === 0) {
                              // top-most row

                              const newHeight = parseNum(newTargetTable.tbody.tr[0].td[0]?.style?.height) - localDeltaY;
                              if (newHeight < 3) return;
                              if (!newTargetTable.tbody.tr[0].td[0].style) newTargetTable.tbody.tr[0].td[0].style = {};
                              newTargetTable.tbody.tr[0].td[0].style.height = `${newHeight}px`;

                              // update top position of the Table
                              const newTopPx = parseNum(newTargetTable?.style?.top) + localDeltaY;
                              newTargetTable.style.top = `${newTopPx}px`;
                              if (newTopPx < 0)
                                //
                                return;
                            } else {
                              // rows in between

                              const leftCol = newTargetTable.tbody.tr[trIndex - 1].td[0];
                              const rightCol = newTargetTable.tbody.tr[trIndex].td[0];

                              const leftPx = parseNum(leftCol?.style?.height) + localDeltaY;
                              const rightPx = parseNum(rightCol?.style?.height) - localDeltaY;

                              if (leftPx - 3 <= 0 || rightPx - 3 <= 0) return;

                              if (!leftCol.style) leftCol.style = {};
                              leftCol.style.height = `${leftPx}px`;

                              if (!rightCol.style) rightCol.style = {};
                              rightCol.style.height = `${rightPx}px`;
                            }

                            setTableData(newTableData);
                          }}
                          zIndex={999}
                        >
                          <div
                            className='tableResizer horizontalTableResizer'
                            onClick={(e) => {
                              if (e.detail === 2) {
                                if (trIndex === 0) return;

                                const newTableData = clone(tableData);
                                deleteRow(newTableData.table, trIndex);
                                setTableData(newTableData);
                              }
                            }}
                          >
                            ▶
                          </div>
                        </Draggable>

                        {/* Bottom row resizer */}
                        {trIndex === tableData.table.tbody.tr.length - 1 ? (
                          <Draggable
                            axis='y'
                            {...draggableParams}
                            onDrag={(e, { deltaY }) => {
                              if (deltaY === 0) return;

                              const localDeltaY = e.clientY - mousePosRef.current.y; // how much mouse has traveled
                              mousePosRef.current.y = e.clientY;

                              const newTableData = clone(tableData);

                              // bottom-most row

                              // update row height
                              const targetTd = newTableData.table.tbody.tr[trIndex].td[0];
                              const newHeight = Math.floor(parseNum(targetTd?.style?.height) + localDeltaY);
                              if (newHeight < 3) return;

                              if (!targetTd.style) targetTd.style = {};
                              targetTd.style.height = `${newHeight}px`;

                              const tableHeightPx = newTableData.table.tbody.tr.reduce((aggr, tr) => {
                                return aggr + parseNum(tr.td[0]?.style?.height);
                              }, 0);

                              // bounding constraint
                              if (tableHeightPx + parseNum(newTableData.table.style.top) > containerSize.height) return;

                              setTableData(newTableData);
                            }}
                            zIndex={999}
                          >
                            <div className='tableResizer horizontalTableResizer' style={{ top: 'inherit', bottom: 3 }}>
                              ▶
                            </div>
                          </Draggable>
                        ) : null}
                      </>
                    ) : null}

                    {/* Merge rows */}
                    {trIndex < tableData.table.tbody.tr.length && tdIndex > 0 ? (
                      <div
                        onClick={(e) => {
                          if (e.detail === 2) {
                            try {
                              const newTableData = clone(tableData);

                              updateCellIndexes(newTableData.table);

                              const targetCol = newTableData.table.tbody.tr[trIndex].td[tdIndex];

                              let rightCol;
                              for (let i = trIndex + 1; i < newTableData.table.tbody.tr.length; i++) {
                                const nextTr = newTableData.table.tbody.tr[i];
                                rightCol = nextTr.td.find((td) => td.colIndex === targetCol.colIndex);
                                if (rightCol !== undefined) {
                                  break;
                                }
                              }

                              if (!rightCol) return;

                              mergeCells(newTableData.table, targetCol.id, rightCol.id);
                              setTableData(newTableData);
                            } catch (e) {}
                          }
                        }}
                        className='mergeRowHandle bottomBorder'
                      ></div>
                    ) : null}

                    {/* Merge columns */}
                    {tdIndex > 0 && tdIndex < tdArray.length - 1 ? (
                      <div
                        onClick={(e) => {
                          if (e.detail === 2) {
                            try {
                              const newTableData = clone(tableData);

                              const targetCol = newTableData.table.tbody.tr[trIndex].td[tdIndex];
                              const rightCol = newTableData.table.tbody.tr[trIndex].td[tdIndex + 1];

                              if (!rightCol) return;

                              mergeCells(newTableData.table, targetCol.id, rightCol.id);
                              setTableData(newTableData);
                            } catch (e) {}
                          }
                        }}
                        className='mergeColHandle rightBorder'
                      ></div>
                    ) : null}

                    {/* Rightmost column */}
                    {tdIndex !== 0 && tdIndex === tdArray.length - 1 ? (
                      <div
                        style={{ position: 'absolute', top: 0, right: -1, bottom: 0, width: 5 }}
                        className='rightBorder'
                      ></div>
                    ) : null}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default React.memo(Table, (prevProps, nextProps) => {
  // Avoid unnecessary renders.  Only call the Table function if certain values in props
  // have changed.  This is the comparator function that determines if something
  // meaningful changed.

  // if container size changed
  if (
    nextProps.containerSize &&
    prevProps.containerSize &&
    nextProps.containerSize.width !== prevProps.containerSize.width &&
    nextProps.containerSize.height !== prevProps.containerSize.height
  ) {
    return false;
  }
  if (!nextProps.containerSize || (!prevProps.containerSize && nextProps.containerSize !== prevProps.containerSize)) {
    return false;
  }

  // if isSelected changed
  if (nextProps.isSelected !== prevProps.isSelected) {
    return false;
  }

  // if value changed
  if (JSON.stringify(nextProps.value) !== JSON.stringify(prevProps.value)) {
    return false;
  }

  return true; // don't re-render since things didn't change
});
