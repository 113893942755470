import axios from 'axios';

type TNewUserMessage = {
  newMessage: string;
  src: string;
  chatApiUrl: string;
};

export const newUserMessage = ({ chatApiUrl, newMessage, src }: TNewUserMessage) => {
  const config = {
    method: 'get',
    url: `${chatApiUrl}/question?id=${src}&query=${newMessage}`,
  };

  return axios(config);
};

type TUploadPdfFile = { chatApiUrl: string; pdfFileBlob: Blob; documentName: string };

export const uploadPdfFile = ({ chatApiUrl, pdfFileBlob, documentName }: TUploadPdfFile) => {
  const formData = new FormData();
  formData.append('file', pdfFileBlob, documentName);

  const config = {
    method: 'post',
    url: `${chatApiUrl}/init`,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: formData,
  };
  return axios(config);
};

type TInitDocument = { chatApiUrl: string; documentId: string };

export const initByDocuemnt = ({ chatApiUrl, documentId }: TInitDocument) => {
  const config = {
    method: 'get',
    url: `${chatApiUrl}/init_with_document_id?document_id=${documentId}`,
  };

  return axios(config);
};
