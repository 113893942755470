import React, { useRef, useEffect } from 'react';
import $ from 'jquery';
import 'select2';
import 'select2/dist/css/select2.min.css';

import './ClassificationFieldInputSelect2.css';
import Icons from '../../Common/Icons/Icons';
import _ from 'lodash';
import { useAppDispatch } from '../../../hooks/reduxHooks';
import { changeClassificationField } from '../../../actions';

const ClassificationFieldInput = (props) => {
  const select2Classification = useRef(null);
  const dispatch = useAppDispatch();

  const handleClassChange = (value, field) => {
    let newField = _.cloneDeep(field);
    newField.value = value;
    dispatch(changeClassificationField(newField));
  };

  const handleAddCustomClass = (className, field) => {
    let newField = _.cloneDeep(field);
    newField.value = className;
    newField.classes = field.classes ? field.classes + '; ' + className : className;
    dispatch(changeClassificationField(newField));
  };

  const select2Event = () => {
    $(select2Classification.current).select2({
      tags: props.allowCustomClass,
      tokenSeparators: [';'],
    });
    $(select2Classification.current)
      .on('change.select2', function (e) {
        if (e.target.value) {
          const newValue = e.target.value;
          const isNew = $(this).find('[data-select2-tag="true"]');
          if (isNew.length > 0 && props.allowCustomClass) {
            isNew.remove();
            handleAddCustomClass(newValue, props.field);
          } else {
            if (props.tag) {
              let tagInfo = props.tag.tagInfo;
              tagInfo['fieldId'] = props.field._id;
              props.upsertTag(tagInfo, true, props.tag.regionInPixels, true);
            }
            handleClassChange(newValue, props.field);
          }
        }
      })
      .val(props.fieldValue);
  };

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const prevValueAllowCustomClass = usePrevious(props.allowCustomClass);
  const prevValueClasses = usePrevious(props.field.classes);
  const prevTag = usePrevious(props.tag);

  useEffect(() => {
    if (!$(select2Classification.current).hasClass('select2-hidden-accessible')) {
      select2Event();
    } else if (
      prevValueAllowCustomClass !== props.allowCustomClass ||
      (prevValueClasses && prevValueClasses !== props.field.classes)
    ) {
      $(select2Classification.current).select2('destroy');
      select2Event();
    } else if (prevTag !== props.tag) {
      select2Event();
    }
  }, [props.tag, select2Classification.current]);

  const emptyValue = (e) => {
    e.preventDefault();
    props.emptyValue();
  };

  const classes = props.field.classes ? props.field.classes.split(';') : [];
  const fieldDataType = props.field?.dataType;
  return (
    <div className={`row ${fieldDataType ? 'section-field' : ''}`}>
      <div className='col'>
        <div className='input-group flex-nowrap input-group-merge classification-field-select2 overflow-hidden'>
          <select
            className='form-control form-control-sm'
            ref={select2Classification}
            value={props.field.value}
            onChange={() => {}}
            disabled={!!props.disabled}
          >
            {classes.length ? classes.map((c, index) => <option key={index}>{c}</option>) : ''}
            {!classes.includes(props.field.value) && (
              <option disabled hidden>
                {props.field.value}
              </option>
            )}
          </select>
          <div className='input-group-text px-1 py-0 ps-3'>
            {props.field.value && !props.field.isLocked && (
              <span onClick={(e) => emptyValue(e)}>
                <Icons.FieldEraser className='input-button cursor-pointer primary-color' />
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClassificationFieldInput;
