import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useTagUI } from '../Tag/useTagUI';
import Button from '../Common/Button';

export const useOriginTagEdit = ({ tagStyles, tag }) => {
  const referenceTagElement = useRef(null);
  const [isActive, setIsActive] = useState(false);
  const [isInitedOnce, setIsInitedOnce] = useState(false);

  const {
    initTagUI,
    resizerGrids,
    tagClassNames,
    enable: enableTagUI,
    disable: disableTagUI,
  } = useTagUI({
    tagElement: referenceTagElement,
    smartTagMode: true,
  });

  // Tag edit
  const activate = useCallback(() => {
    setIsActive(true);

    if (!isInitedOnce) {
      setIsInitedOnce(true);
      initTagUI({
        tag,
        fieldId: tag.fieldId,
      });
    }

    enableTagUI();
  }, [enableTagUI, initTagUI, tag, isInitedOnce]);

  const deactivate = useCallback(() => {
    if (!isInitedOnce) {
      return;
    }

    setIsActive(false);
    disableTagUI();
  }, [disableTagUI, isInitedOnce]);

  const element = useMemo(
    () => (
      <div
        id={`origin-tag-${tag._id}`}
        className={`${isActive ? 'tag selected ' : 'd-none'} ${tagClassNames}`}
        style={tagStyles}
        data-tip={tag.tagTitle}
        data-field-id={tag.fieldId}
        ref={referenceTagElement}
      >
        {resizerGrids}
        <Button variant='secondary' icon='SmartTagEditCancel' />
      </div>
    ),
    [tag._id, isActive, tagClassNames, tagStyles, tag.tagTitle, tag.fieldId, referenceTagElement, resizerGrids]
  );

  return {
    element,
    activate,
    deactivate,
  };
};
