import React, { useState, useEffect, Fragment, useCallback } from 'react';
import Selectbox from '../../Common/SelectBox';
import { metadataSelector } from '../../../store/document/selectors';
import { useSelector } from 'react-redux';
import { SCALES } from '../../../helpers/common/constants';
import Button from '../../Common/Button';

const FiledScaleSelect = React.memo((props) => {
  const { field, value, tagId, showScaleValue, showScaleSystemLine, showOcrValue } = props;

  const setFieldScale = (selectedOption) => {
    const scale = selectedOption.value;
    const previousScale = field?.scale || 1;
    if (scale !== props.field?.scale) {
      props.setFieldScale(props.field._id, scale, () => {
        props.updateValue(props.field._id, tagId, value, false, false, previousScale);
      });
    }
  };

  const parseIntScaleToString = useCallback((intValue) => {
    switch (intValue) {
      case 1:
        return SCALES[0];
      case 0.01:
        return SCALES[1];
      case 1000:
        return SCALES[2];
      case 1000000:
        return SCALES[3];
      case 1000000000:
        return SCALES[4];
      default:
        return 'Select scale';
    }
  }, []);

  const metadata = useSelector(metadataSelector);

  const [selectedScale, setSelectedScale] = useState(null);

  useEffect(() => {
    setSelectedScale(parseIntScaleToString(field.scale || metadata.scale));
  }, [parseIntScaleToString, field.scale]);

  return (
    <Fragment>
      <div
        className={`input-group input-group-merge ps-2 selectbox-sm  ${showOcrValue ? 'pt-2' : ''} ${
          !showScaleValue && 'hidden-div'
        }`}
        onClick={(e) => e.stopPropagation()}
      >
        {showScaleSystemLine && <div className='system-line-dropdown-up'></div>}
        {showScaleSystemLine && <div className='system-line-dropdown-down'></div>}
        <Selectbox
          classes={`${(props.tagInput || props.extraction) && props.removeSystemLine ? '' : 'ms-0'} selectbox-sm`}
          id='fieldScale'
          isMulti={false}
          onChange={setFieldScale}
          value={selectedScale && selectedScale.value ? selectedScale : SCALES[0]}
          options={SCALES.map((item) => {
            const { label, value } = item;
            return { label, value };
          })}
        />
        {tagId && (
          <div className='pt-1 ps-1 pe-2 scale'>
            <Button variant='primary' icon='FieldScale' />
          </div>
        )}
      </div>
    </Fragment>
  );
});

export default FiledScaleSelect;
