import { createSelector } from 'reselect';
import { RootState } from '../reducers';

const stateSelector = (state: RootState) => state.annotation;

export const scaledValueSelector = createSelector(stateSelector, (value) => value.scaledValue);
export const imagesDimensionsSelector = createSelector(stateSelector, (value) => value.imagesDimensions);
export const tagsSelector = createSelector(stateSelector, (value) => value.tags);
export const smartLabelSelector = createSelector(stateSelector, (value) => value.smartLabel);



// export const tagSelector = createSelector(
//     [
//         stateSelector,
//         (state, region) => region
//     ],
//     (state, region) => {
//         return state.tags && state.tags[region]
//     }
// );