import React, { useState, useRef } from 'react';
import { runFormula, replaceFormulaComponents } from '../../../helpers/common/utils';
import { formatNumber, parseAndFormatNumber } from '../../../helpers/common/formatter';
import { useSelector } from 'react-redux';
import { metadataSelector } from '../../../store/document/selectors';
import FieldScaleSelect from './FieldScaleSelect';
import Icons from '../../Common/Icons';

const FieldTagComputedInput = React.memo((props) => {
  const fieldValueInput = useRef(null);
  const ocrFieldValueInput = useRef(null);
  const { dataType, valueFormula } = props.field;
  const [formulaComponentsOpen, openFormulaComponents] = useState(false);
  const { value } = props.tag;
  const [fxApplicable] = useState(dataType === 'number' || dataType === 'date');
  const fields = props.fields;
  const metadata = useSelector(metadataSelector);
  const [editMode, setEditMode] = useState(false);

  const calculateComputeFieldValues = () => {
    let formula = replaceFormulaComponents(valueFormula, props.field.formulaComponents);
    if (formula) {
      const val = runFormula(fields, formula);
      // FIXME: why is this UI function mutating the underlying data?  This is BAD!
      props.field.hasValidValue = !isNaN(val);
      props.field.value = val;
      return !isNaN(val) ? val.toString() : 'Wrong formula';
    }

    return '';
  };
  const removeTag = (e) => {
    e.preventDefault();
    props.emptyTag();
  };

  const removeManualTag = (e) => {
    e.preventDefault();
    props.emptyManualValue();
  };

  const openFormulaComponentsSection = (e) => {
    e.preventDefault();
    openFormulaComponents(!formulaComponentsOpen);
  };

  return (
    <div className={`${props.multipleClass}`}>
      Computed:
      <div className={`input-group input-group-merge`}>
        <input
          readOnly
          type='text'
          className={'form-control form-control-sm text-end'}
          value={formatNumber(calculateComputeFieldValues())}
          id={`calc-${props.field._id}`}
          disabled={true}
        />

        <div className='input-group-text px-0 py-0 ps-0'>
          {props.annotationPermission && (
            <span className='px-1 cursor-pointer' onClick={openFormulaComponentsSection}>
              {formulaComponentsOpen ? (
                <Icons.FieldComponentsCollapse className='input-button primary-color' />
              ) : (
                <Icons.FieldComponentsDecollapse className='input-button primary-color' />
              )}
            </span>
          )}
        </div>
      </div>
      {formulaComponentsOpen && (
        <div className='col-12'>
          <table className='w-100'>
            <tbody>
              {props.field.formulaComponents &&
                props.field.formulaComponents.map((component, index) => (
                  <tr key={`formula-${props.field._id.toString()}-${index}`}>
                    <td className='ps-0 pe-1 pt-2'>
                      <input className='form-control form-control-sm' readOnly defaultValue={component.componentName} />
                    </td>
                    <td className='ps-1 pe-0 pt-2'>
                      <input
                        className='form-control form-control-sm text-end pe-2'
                        readOnly
                        value={formatNumber(runFormula(props.fields, component.value))}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}
      Extracted:
      <div className='position-relative'>
        {dataType === 'number' &&
          !props.removeSystemLine &&
          (props.shortSystemLine ? <div className='system-line-short'></div> : <div className='system-line'></div>)}
        <div
          className={`input-group input-group-merge ${
            !props.removeSystemLine && dataType === 'number' && 'ms-2 pe-2'
          } ${!props.showOcrValue && 'hidden-div'}`}
        >
          <input
            type='text'
            spellCheck='false'
            className='form-control form-control-sm '
            onKeyDown={props.handleFieldValueOnKeyDown}
            onChange={(e) => props.handleChange(e, true)}
            ref={fieldValueInput}
            onClick={props.handleTagClick}
            value={props.tag.ocrValue || ''}
            id={props.field._id}
            disabled={props.disabled}
          />
          <div className='input-group-text px-1 py-0 ps-2 rounded-end'>
            {props.field.tags && props.annotationPermission && (
              <span onClick={(e) => removeTag(e)}>
                <Icons.FieldEraser className='input-button cursor-pointer primary-color' />
              </span>
            )}
          </div>
          {fxApplicable && props.annotationPermission && (
            <div className={`px-2 py-1`}>{props.field.tags && <Icons.Abc height={25} />}</div>
          )}
        </div>

        {props.showScaleSelector && dataType === 'number' && (
          <div>
            <FieldScaleSelect
              showScaleValue={props.showScaleValue}
              field={props.field}
              setFieldScale={props.setFieldScale}
              value={value ? value.toString() : ''}
              tagId={props.tag._id.toString()}
              documentId={props.documentId}
              updateValue={props.updateValue}
              extraction={true}
              showScaleSystemLine={props.showScaleSystemLine}
              showOcrValue={props.showOcrValue}
              removeSystemLine={props.removeSystemLine}
            />
          </div>
        )}
        {dataType === 'number' && (
          <div
            className={`input-group input-group-merge ${!props.removeSystemLine && 'pt-2'} ${
              !props.removeSystemLine && 'ms-2 pe-2'
            }  ${!props.showFxValue && 'hidden-div'}`}
          >
            <input
              spellCheck='false'
              type='text'
              className='form-control form-control-sm text-end'
              onKeyDown={props.handleFieldValueOnKeyDown}
              onChange={props.handleManualChange}
              ref={ocrFieldValueInput}
              onClick={props.handleTagClick}
              value={editMode ? props.tag.value : parseAndFormatNumber(props.tag.value, metadata)}
              disabled={props.disabled}
              onFocus={() => setEditMode(true)}
              onBlur={() => setEditMode(false)}
            />
            <div className='input-group-text px-1 py-0 ps-2 rounded-end'>
              {props.field.tags && (
                <span onClick={(e) => removeManualTag(e)}>
                  <Icons.FieldEraser className='input-button cursor-pointer primary-color' />
                </span>
              )}
            </div>
            {fxApplicable && <div className='px-2 py-1'>{props.field.tags && <Icons.Fx />}</div>}
          </div>
        )}
      </div>
    </div>
  );
});

export default FieldTagComputedInput;
