import React from 'react';

import { ReactComponent as Delete } from '../../../assets/icons/delete_forever_FILL0_wght700_GRAD0_opsz48.svg';
import { ReactComponent as TextSpanField } from '../../../assets/icons/text_fields_FILL0_wght700_GRAD0_opsz48.svg';
import { ReactComponent as TextBoxField } from '../../../assets/icons/insert_text_FILL0_wght700_GRAD0_opsz48.svg';
import { ReactComponent as TableField } from '../../../assets/icons/grid_on_FILL0_wght700_GRAD0_opsz48.svg';
import { ReactComponent as SmartTag } from '../../../assets/icons/smart_button_FILL0_wght700_GRAD0_opsz48.svg';
import { ReactComponent as Edit } from '../../../assets/icons/edit_FILL0_wght700_GRAD0_opsz48.svg';
import { ReactComponent as Person } from '../../../assets/icons/person_FILL0_wght700_GRAD0_opsz48.svg';
import { ReactComponent as Filter } from '../../../assets/icons/filter_list_FILL0_wght700_GRAD0_opsz48.svg';
import { ReactComponent as CalendarField } from '../../../assets/icons/calendar_today_FILL0_wght700_GRAD0_opsz48.svg';
import { ReactComponent as Eye } from '../../../assets/icons/visibility_FILL0_wght700_GRAD0_opsz48.svg';
import { ReactComponent as EyeOff } from '../../../assets/icons/visibility_off_FILL0_wght700_GRAD0_opsz48.svg';
import { ReactComponent as Campaign } from '../../../assets/icons/campaign_FILL0_wght700_GRAD0_opsz48.svg';
import { ReactComponent as NumberField } from '../../../assets/icons/123_FILL0_wght700_GRAD0_opsz48.svg';
import { ReactComponent as Classification } from '../../../assets/icons/category_FILL0_wght700_GRAD0_opsz48.svg';
import { ReactComponent as Computation } from '../../../assets/icons/functions_FILL0_wght700_GRAD0_opsz48.svg';
import { ReactComponent as ExtractionAndComputation } from '../../../assets/icons/calculate_FILL0_wght700_GRAD0_opsz48.svg';
import { ReactComponent as Undo } from '../../../assets/icons/undo_FILL0_wght700_GRAD0_opsz48.svg';
import { ReactComponent as Redo } from '../../../assets/icons/redo_FILL0_wght700_GRAD0_opsz48.svg';
import { ReactComponent as AutoAwesome } from '../../../assets/icons/auto_awesome_FILL0_wght700_GRAD0_opsz48.svg';
import { ReactComponent as AccountTree } from '../../../assets/icons/account_tree_FILL0_wght700_GRAD0_opsz48.svg';
import { ReactComponent as FileSpreadsheet } from '../../../assets/icons/table_view_FILL0_wght700_GRAD0_opsz48.svg';
import { ReactComponent as Search } from '../../../assets/icons/search_FILL0_wght700_GRAD0_opsz48.svg';
import { ReactComponent as Eraser } from '../../../assets/icons/backspace_FILL0_wght700_GRAD0_opsz48.svg';
import { ReactComponent as EraserResetInsight } from '../../../assets/icons/backspace_FILL0_wght700_GRAD0_opsz48.svg';
import { ReactComponent as Plus } from '../../../assets/icons/add_FILL0_wght700_GRAD0_opsz48.svg';
import { ReactComponent as Folder } from '../../../assets/icons/folder_FILL0_wght700_GRAD0_opsz48.svg';
import { ReactComponent as Question } from '../../../assets/icons/school_FILL0_wght700_GRAD0_opsz48.svg';
import { ReactComponent as Tune } from '../../../assets/icons/tune_FILL0_wght700_GRAD0_opsz48.svg';
import { ReactComponent as BlockMove } from '../../../assets/icons/drag_handle_FILL0_wght700_GRAD0_opsz48.svg';
import { ReactComponent as AreaField } from '../../../assets/icons/crop_square_FILL0_wght700_GRAD0_opsz48.svg';
import { ReactComponent as Copy } from '../../../assets/icons/content_copy_FILL0_wght700_GRAD0_opsz48.svg';
import { ReactComponent as FileDownload } from '../../../assets/icons/file_download_FILL0_wght700_GRAD0_opsz48.svg';
import { ReactComponent as Download } from '../../../assets/icons/download_FILL0_wght700_GRAD0_opsz48.svg';
import { ReactComponent as ZipDownload } from '../../../assets/icons/folder_zip_FILL0_wght700_GRAD0_opsz48.svg';
import { ReactComponent as Open } from '../../../assets/icons/open_in_browser_FILL0_wght700_GRAD0_opsz48.svg';
import { ReactComponent as Move } from '../../../assets/icons/drive_file_move_outline_FILL0_wght700_GRAD0_opsz48.svg';
import { ReactComponent as Save } from '../../../assets/icons/save_FILL0_wght700_GRAD0_opsz48.svg';
import { ReactComponent as FinishLine } from '../../../assets/icons/done_all_FILL0_wght700_GRAD0_opsz48.svg';
import { ReactComponent as Lock } from '../../../assets/icons/lock_FILL0_wght700_GRAD0_opsz48.svg';
import { ReactComponent as Rotate } from '../../../assets/icons/autorenew_FILL0_wght700_GRAD0_opsz48.svg';
import { ReactComponent as ZoomOut } from '../../../assets/icons/zoom_out_FILL0_wght700_GRAD0_opsz48.svg';
import { ReactComponent as ZoomIn } from '../../../assets/icons/zoom_in_FILL0_wght700_GRAD0_opsz48.svg';
import { ReactComponent as MenuClose } from '../../../assets/icons/close_FILL0_wght700_GRAD0_opsz48.svg';
import { ReactComponent as ArrowLeft } from '../../../assets/icons/keyboard_arrow_left_FILL0_wght700_GRAD0_opsz48.svg';
import { ReactComponent as ArrowRight } from '../../../assets/icons/keyboard_arrow_right_FILL0_wght700_GRAD0_opsz48.svg';
import { ReactComponent as ArrowDown } from '../../../assets/icons/keyboard_arrow_down_FILL0_wght700_GRAD0_opsz48.svg';
import { ReactComponent as ArrowUp } from '../../../assets/icons/keyboard_arrow_up_FILL0_wght700_GRAD0_opsz48.svg';
import { ReactComponent as BringForward } from '../../../assets/icons/flip_to_front_FILL0_wght700_GRAD0_opsz48.svg';
import { ReactComponent as SendBackward } from '../../../assets/icons/flip_to_back_FILL0_wght700_GRAD0_opsz48.svg';
import { ReactComponent as Tag } from '../../../assets/icons/sell_FILL0_wght700_GRAD0_opsz48.svg';
import { ReactComponent as CircleEmptyRemove } from '../../../assets/icons/cancel_FILL0_wght700_GRAD0_opsz48.svg';
import { ReactComponent as ReportFail } from '../../../assets/icons/report_FILL0_wght700_GRAD0_opsz48.svg';
import { ReactComponent as CheckSuccess } from '../../../assets/icons/check_FILL0_wght700_GRAD0_opsz48.svg';
import { ReactComponent as RotatePageLeft } from '../../../assets/icons/rotate_left_FILL0_wght700_GRAD0_opsz48.svg';
import { ReactComponent as RotatePageRight } from '../../../assets/icons/rotate_right_FILL0_wght700_GRAD0_opsz48.svg';
import { ReactComponent as Attach } from '../../../assets/icons/add_link_FILL0_wght700_GRAD0_opsz48.svg';
import { ReactComponent as Detach } from '../../../assets/icons/link_off_FILL0_wght700_GRAD0_opsz48.svg';
import { ReactComponent as Options } from '../../../assets/icons/more_vert_FILL0_wght700_GRAD0_opsz48.svg';
import { ReactComponent as Code } from '../../../assets/icons/code_FILL0_wght700_GRAD0_opsz48.svg';
import { ReactComponent as Straighten } from '../../../assets/icons/straighten_FILL0_wght700_GRAD0_opsz48.svg';
import { ReactComponent as AttachedInsight } from '../../../assets/icons/link_FILL0_wght700_GRAD0_opsz48.svg';
import { ReactComponent as LocationSearching } from '../../../assets/icons/location_searching_FILL0_wght700_GRAD0_opsz48.svg';
import { ReactComponent as ClearSearchTag } from '../../../assets/icons/search_off_FILL0_wght700_GRAD0_opsz48.svg';
import { ReactComponent as PageSettings } from '../../../assets/icons/settings_FILL0_wght700_GRAD0_opsz48.svg';
import { ReactComponent as DownloadPdf } from '../../../assets/icons/description_FILL0_wght700_GRAD0_opsz48.svg';
import { ReactComponent as DocumentReopen } from '../../../assets/icons/remove_done_FILL0_wght700_GRAD0_opsz48.svg';
import { ReactComponent as DeepSearch } from '../../../assets/icons/manage_search_FILL0_wght700_GRAD0_opsz48.svg';
import { ReactComponent as LogIn } from '../../../assets/icons/login_FILL0_wght700_GRAD0_opsz48.svg';
import { ReactComponent as Publish } from '../../../assets/icons/publish_FILL0_wght700_GRAD0_opsz48.svg';
import { ReactComponent as Abc } from '../../../assets/icons/abc_FILL0_wght700_GRAD0_opsz48.svg';
import { ReactComponent as Fx } from '../../../assets/icons/function_FILL0_wght700_GRAD0_opsz48.svg';
import { ReactComponent as Metrics } from '../../../assets/icons/auto_graph_FILL0_wght700_GRAD0_opsz48.svg';
import { ReactComponent as Play } from '../../../assets/icons/play_arrow_FILL0_wght700_GRAD0_opsz48.svg';
import { ReactComponent as SentimentVerySatisfied } from '../../../assets/icons/sentiment_very_satisfied_FILL0_wght700_GRAD-25_opsz48.svg';
import { ReactComponent as Lightbulb } from '../../../assets/icons/lightbulb_FILL0_wght700_GRAD-25_opsz48.svg';
import { ReactComponent as QuestionMark } from '../../../assets/icons/question_mark_FILL0_wght700_GRAD0_opsz48.svg';
import { ReactComponent as SmartHover } from '../../../assets/icons/smart_hover.svg';
import { ReactComponent as AttachFile } from '../../../assets/icons/attach_file_FILL0_wght700_GRAD0_opsz48.svg';
import { ReactComponent as SearchDocument } from '../../../assets/icons/search-similar-document.svg';
import { ReactComponent as ArrowBack } from '../../../assets/icons/arrow_back_ios_FILL0_wght700_GRAD0_opsz48.svg';
import { ReactComponent as ArrowForward } from '../../../assets/icons/arrow_forward_ios_FILL0_wght700_GRAD0_opsz48.svg';
import { ReactComponent as OpenInFull } from '../../../assets/icons/open_in_full_FILL0_wght700_GRAD0_opsz48.svg';
import { ReactComponent as CloseFullScreen } from '../../../assets/icons/close_fullscreen_FILL0_wght700_GRAD0_opsz48.svg';
import { ReactComponent as Label } from '../../../assets/icons/label_FILL0_wght700_GRAD-25_opsz48.svg';
import { ReactComponent as LabelOff } from '../../../assets/icons/label_off_FILL0_wght700_GRAD-25_opsz48.svg';

import './Icons.scss';

const iconsMap = {
  // Navbar-top
  BrandLogo: Search,
  PageCollections: Folder,
  PageInsights: Lightbulb,
  PageAI: AutoAwesome,

  // Navbar-bottom
  PageKnowlagebase: Question,
  PageWorkflow: AccountTree,
  PageSettings,
  PageAnnouncement: Campaign,
  PagesUsers: Person,

  // Toolbar - Fields pane's - First Row
  HistoryUndo: Undo,
  HistoryRedo: Redo,
  ResetInsight: EraserResetInsight,
  RunGenie: AutoAwesome,

  // Toolbar - Fields pane's - Second Row
  QuestionMark,
  AttachedInsights: Lightbulb,
  AttachedInsight,
  AttachedInsightsModalOpener: Lightbulb,
  InsightAttach: Attach,
  InsightDetach: Detach,
  AttachFile,

  ExcelDownload: FileSpreadsheet,
  FilterFields: Filter,

  // Toolbar - Document pane's - First Row
  LocationSearching,

  DropdownChevronDown: ArrowDown,
  DropdownChevronLeft: ArrowLeft,
  DropdownChevronRight: ArrowRight,

  NextSearchTag: ArrowDown,
  PrevSearchTag: ArrowUp,
  ClearSearchTag,

  UnselectedTagsNotVisible: Eye,
  UnselectedTagsVisible: EyeOff,

  SmartTagDropdownOpener: SmartTag,
  SmartHoverDropdownOpener: SmartHover,

  TagsBringToBack: BringForward,
  TagsBringToFront: SendBackward,
  MetadataModalOpener: Tag,
  DocumentTransfer: Publish,
  DocumentTransferSuccessStatus: CheckSuccess,
  DocumentTransferFailStatus: ReportFail,

  // Toolbar - Document pane's - Second Row
  ZoomIn,
  ZoomOut,

  DocumentPrevPage: ArrowLeft,
  DocumentNextPage: ArrowRight,

  RotatePageLeft,
  RotatePageRight,
  RotatePageModalOpener: Rotate,

  // Template Group
  GroupDragIcon: BlockMove,

  // Document Group
  GroupEraser: Eraser,
  GroupRepeat: Plus,

  // Template Field
  FieldDragIcon: BlockMove,

  // Document field
  FieldEraser: Eraser,
  FieldOptions: Options,
  FieldScale: Straighten,
  ValidFieldIcon: CheckSuccess,

  // Field type icons
  Classification,
  Computation,
  ExtractionAndComputation,

  // Field data type icons
  NumberField,
  TextSpanField,
  TextBoxField,
  TableField,
  CalendarField,
  LinkField: AttachedInsight,
  AreaField,

  // Classification field
  DownloadFieldCategories: FileDownload,

  // Field Tag components
  FieldComponentsCollapse: ArrowUp,
  FieldComponentsDecollapse: ArrowDown,

  // Document page
  SmartTagEditCancel: MenuClose,
  SmartTagEditSave: Save,
  SmartTagEdit: Edit,

  // MultipleFileUpload modal
  RemoveFileFromMultipleUpload: Delete,

  // Document finish/save block
  DocumentSaveSuccessStatusIcon: CheckSuccess,
  DocumentSaveFailStatusIcon: ReportFail,
  DocumentFinish: FinishLine,
  DocumentReopen,

  // Modal
  ModalClose: MenuClose,
  ModalCancel: CircleEmptyRemove,
  ModalSave: Save,

  // Documents list
  DocumentSearchSettings: Tune,
  DeepSearch,
  DownloadCollectionDocuments: FileDownload,

  // Documents list: single document row
  DocumentLockedStatus: Lock,

  DocumentOpenIn: Open,
  DocumentOpenInAnnotationView: Open,

  MoveDocumentBetweenCollections: Move,

  DocumentDownloadDropdownOpener: Download,
  DocumentArchiveDownload: ZipDownload,
  DocumentPdfFileDownload: DownloadPdf,

  // Insights list
  DuplicateInsight: Copy,

  // List / Tables
  SearchInputIcon: Search,

  // List / Tables: Item controller
  CreateItem: Plus,
  EditItem: Edit,

  // Pagination
  PaginationPrevPage: ArrowLeft,
  PaginationNextPage: ArrowRight,

  // Step
  PrevStep: ArrowLeft,
  NextStep: ArrowRight,

  // Ai interface create
  // Templates mode:
  AiInterfaceCreatingError: ReportFail,

  // Document Ai
  RunDevelopmentEnvironment: Code,
  IsGenieModelVersion: AutoAwesome,
  Play,
  Publish,

  // Authentication
  LogIn,
  ShowPassword: Eye,
  HidePassword: EyeOff,

  // Graph AI
  GraphAI: Metrics,

  // Workflow step Icons
  StepHumanMicrotask: SentimentVerySatisfied,
  StepWorktimalHumanMicrotask: SentimentVerySatisfied,
  StepDocumentAI: AutoAwesome,
  StepGraphAI: Metrics,
  StepHumanAssistedDocumentAI: AutoAwesome,
  StepWorktimalHumanAssistedDocumentAI: AutoAwesome,
  StepHumanAssistedGraphAI: Metrics,
  StepWorktimalHumanAssistedGraphAI: Metrics,

  // Commons
  Cancel: CircleEmptyRemove,
  Save,
  Delete,
  Search,
  Abc,
  Fx,
  Metrics,
  ArrowDown,
  ArrowUp,

  //link document
  SearchDocument,
  ArrowBack,
  ArrowForward,
  OpenInFull,
  CloseFullScreen,
  Label,
  LabelOff
};

Object.entries(iconsMap).forEach(([key, Component]) => {
  iconsMap[key] = ({ ...props }) => (
    <Component {...props} className={`svg-icon svg-icon-sm ${props?.className || ''}`} />
  );
});
export default iconsMap;
