import React from 'react';
const AppSpinner = () => {
  return (
    <div className='d-flex align-items-center vh-100 spinner-content'>
      <div className='spinner-border text-primary documents-loader mt-0' role='status'></div>
    </div>
  );
};

export default AppSpinner;
