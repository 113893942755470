import React, { ReactNode } from 'react';
import { Button } from '@progress/kendo-react-buttons';
interface IButtonItemProps {
  text: string | ReactNode;
  itemIdx: number;
  setActive: (itemIdx: number) => void;
  active: number | null;
  palette?: string;
  variant?: string;
}

const ButtonItem = (props: IButtonItemProps) => {
  const {variant, text, itemIdx, setActive, active, palette } = props;

  return (
    <Button
      togglable={true}
      onClick={() => setActive(itemIdx)}
      selected={active === itemIdx}
      className={`classfier-field-btn btn-${variant}`}
    >
      <div className='field-btn-color me-1' style={{background: palette}}></div>
      <div>{text}</div>
    </Button>
  );
};

export default ButtonItem;
