import {
  EMPTY_SEARCH,
  HIGHLIGHT_SEARCH_RESULT,
  ISearchLabelTag,
  SEARCH_IN_DOCUMENT,
  SearchAction,
  SET_SEARCH_REGION,
  TRegionPixels,
  TSearchLabel,
} from './types';

interface ISearchState {
  searchTags?: TSearchLabel[];
  searchText?: string;
  selectedSearchTag?: TSearchLabel;
  selectedSearchQnATag?: ISearchLabelTag | [];
  region?: TRegionPixels;
}

const initialState: ISearchState = {};

const searchReducer = (state: ISearchState = initialState, action: SearchAction): ISearchState => {
  const searchState = { ...state };
  switch (action.type) {
    case SEARCH_IN_DOCUMENT: {
      searchState.searchTags = action.payload.labels;
      searchState.searchText = action.payload.text;
      searchState.selectedSearchTag = action.payload.labels[0];
      searchState.selectedSearchQnATag = action.payload.labelQnA;
      return searchState;
    }
    case EMPTY_SEARCH: {
      searchState.searchTags = undefined;
      searchState.searchText = '';
      searchState.selectedSearchQnATag = [];
      return searchState;
    }
    case HIGHLIGHT_SEARCH_RESULT: {
      searchState.selectedSearchTag = action.payload;
      return searchState;
    }
    case SET_SEARCH_REGION: {
      searchState.region = action.payload;
      return searchState;
    }
    default:
      return state;
  }
};

export default searchReducer;
