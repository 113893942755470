import React from 'react';
import Button from '../../Common/Button';
import {useSelector} from "react-redux";
import {searchTagsSelector} from "../../../store/search/selectors";
import {TSearchLabel} from "../../../store/search/types";

type TSearchControl = {
  handlePrevSearch: (e: any) => void;
  handleNextSearch: (e: any) => void;
  handleEmptySearch: (e: any) => void;
};

const SearchControl = ({ handlePrevSearch, handleNextSearch, handleEmptySearch }: TSearchControl) => {
    const searchTags = useSelector(searchTagsSelector) as TSearchLabel[];
    const activeBtns = searchTags?.length;
  return (
    <div className={`col-auto btn-group ps-3 pe-auto gap-1`} role='group'>
        {activeBtns && <>
            <Button
                type='button'
                variant='primary'
                onClick={handlePrevSearch}
                icon='PrevSearchTag'
                className='rounded-start'
            />
            <Button type='button' variant='primary' onClick={handleNextSearch} icon='NextSearchTag' className='rounded-0' />        </>}

      <Button
        type='button'
        variant='primary'
        onClick={handleEmptySearch}
        icon='ClearSearchTag'
        className={`${activeBtns ? 'rounded-end' : 'rounded'}`}
      />
    </div>
  );
};

export default SearchControl;
