import React, { useEffect, useState } from 'react';
import ModalService from './ModalService';

export default function ModalProvider() {
  const [, setModalProviderChange] = useState();
  useEffect(() => {
    let changeCount = 0;
    const handler = () => setModalProviderChange(++changeCount);
    ModalService.onChange(handler);
    return () => ModalService.removeOnChange(handler);
  }, []);

  const keyToModals = ModalService.getModalsMap();

  return (
    <div className='modal-provider'>
      {Object.keys(keyToModals).map((key) => {
        const dialogInfo = keyToModals[key];
        return <div key={key}>{dialogInfo.render()}</div>;
      })}
    </div>
  );
}
